import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUser} from "./actions";
import {AppDispatch, AppState} from "../store";
import {useFetchDoctors} from "../doctors/helpers";

export const useFetchUser = () => {
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user.user);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoading && user === undefined) {
            setIsLoading(true)
            dispatch(fetchUser())
                .catch(e => console.log("Error fetching user", e))
                .then(() => {
                })
                .finally(() => setIsLoading(false));
        }
    }, [user, dispatch]);

    return user || null;
};

export const useFetchUserDoctor = () => {
    const user = useFetchUser();
    const doctors = useFetchDoctors();

    return doctors.find(d => d.id === user?.doctorId);
};