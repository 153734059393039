import React from "react";
import {useTheme} from "styled-components";
import icSyringe from "../../assets/images/ic_syringe.svg";
import {Vaccine} from "../../state/vaccines/types";
import {
    EventBubble,
    EventName,
    Footnote,
    LegendBubble,
    LegendTitle,
    LegendWrapper,
    RowSeparator,
    Table,
    TH,
    TH1,
    VaccinationsCard
} from "./VaccinationTable.styles";


const timeUnits: string[] = [
    "1\ndzień",
    "6\ntygodni",
    "2\nmiesiąc",
    "3\nmiesiąc",
    "4\nmiesiąc",
    "5\nmiesiąc",
    "6\nmiesiąc",
    "7\nmiesiąc",
    "13-15\nmiesiąc",
    "16-18\nmiesiąc",
    "6\nlat",
    "12-13\nlat",
    "14\nlat",
    "19\nlat",
]

const VaccinationTable: React.FC<{ vaccines: Vaccine[] }> = ({vaccines}) => {
    const theme = useTheme()
    return (
        <VaccinationsCard>
            <LegendWrapper>
                <img src={icSyringe} alt="szczepienia"/>
                <LegendTitle>Szczepienia obowiązkowe</LegendTitle>
                <LegendBubble $color={theme.color.violet400}/>
                <LegendTitle>i zalecane</LegendTitle>
                <LegendBubble $color={theme.color.violet300}/>
            </LegendWrapper>
            <Table>
                <TH1>Szczepionka przeciw:</TH1>
                {timeUnits.map((title, index) => (
                    <TH key={`tu-${index}`}>{title}</TH>
                ))}
                {vaccines.map((event, index) => (
                    <React.Fragment key={index}>
                        <RowSeparator key={`sep-${index}`}/>
                        <EventName key={`event-${index}`}>{event.name}</EventName>
                        {event.intervals.map((age, i) => (
                            <EventBubble
                                key={`bubble-${index}-${i}`}
                                $startAge={age.start}
                                $endAge={age.end}
                                $color={event.mandatory ? theme.color.violet400 : theme.color.violet300}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </Table>
            <Footnote>Osoby z grup podwyższonego ryzyka są szczepione również przeciwko: ospie wietrznej, błonicy,
                tężcowi i wściekliźnie.</Footnote>
        </VaccinationsCard>
    )
}
export default VaccinationTable