import {HumanDiagnosesState} from "./type";
import {ProfileAction} from "../profile/actions";
import {Action} from "./actions";

const initialState: HumanDiagnosesState = {
    diagnoses: null
};

export const humanDiagnosesReducer = (state = initialState, action: Action | ProfileAction): HumanDiagnosesState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "HUMAN_DIAGNOSIS_SUCCESS":
            return {
                ...state,
                diagnoses: action.data || [],
            };
        default:
            return state;
    }
};