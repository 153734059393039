import React from "react";
import imgDoctorSuite from "../../assets/images/img_doctor.svg";
import ButtonPrimary from "../Shared/Button/ButtonPrimary/ButtonPrimary";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../config/paths";
import {useFetchDiagnoses} from "../../state/aiDiagnoses/helpers";


const ResultsContent = styled.div`
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;


  @media (max-width: 599px) {
    padding: 108px 0 32px 0;
    gap: 40px;
  }

  @media (min-width: 600px) {
    align-items: center;
    height: 100vh;
    padding: 150px 0;
    gap: 96px;
  }
`

const MobileGoToHomeLink = styled.a`

  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.28px;
    text-align: center;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

const Buttons = styled.div`
  display: flex;

  @media (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
`

const ContentCardYellow = styled.div`

  flex-direction: column;
  box-shadow: 0 18px 40px 0 rgba(23, 15, 87, 0.04), 0 73px 73px 0 rgba(23, 15, 87, 0.04), 0 164px 98px 0 rgba(23, 15, 87, 0.02), 0 292px 117px 0 rgba(23, 15, 87, 0.01), 0 456px 128px 0 rgba(23, 15, 87, 0.00);

  @media (max-width: 599px) {
    display: flex;
    padding: 24px;
    align-items: center;
    gap: 40px;

    border-radius: 20px;
    background: #EAE2D8;
    margin: 0 20px;
  }

  @media (min-width: 600px) {
    display: inline-flex;
    background: ${props => props.theme.color.beige300};
    padding: 64px 20px;
    width: 60%;
    margin-bottom: 20px;
    border-radius: 32px;
    gap: 96px;
    align-items: center;
  }
`
const ContentCard = styled.div`
  flex-direction: column;
  background: ${props => props.theme.color.gray100};

  @media (max-width: 599px) {
    border-radius: 20px;
    display: flex;
    margin: 0 20px;
    padding: 24px;
    gap: 24px;
    justify-content: left;
  }

  @media (min-width: 600px) {
    border-radius: 40px;
    gap: 96px;
    align-items: center;
    display: inline-flex;
    width: 60%;
    padding: 64px 20px;
    margin-top: 20px;
  }
`

const ImagePhysician = styled.img`
  @media (max-width: 599px) {
    height: 98.529px;
  }

  @media (min-width: 600px) {
    height: 200px;
  }
`

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;

  @media (max-width: 599px) {
    gap: 24px;
  }

  @media (min-width: 600px) {
    align-items: center;

  }
`
const Subtitle = styled.h5`
  font-weight: 400;
  line-height: 140%;

  @media (max-width: 599px) {
    color: ${props => props.theme.color.gray1000};
    font-size: 14px;
    font-style: normal;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    color: ${props => props.theme.color.gray800};
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: -0.4px;
  }
`;


const Title = styled.h3`
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 599px) {
    text-align: left;
  }

  @media (min-width: 600px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const AiDiagnosisResult: React.FC<{ diagnosisId: number }> = ({diagnosisId}) => {

    const diagnoses = useFetchDiagnoses()
    const diagnosis = diagnoses.find(diagnosis => diagnosis.id === diagnosisId)
    const navigate = useNavigate()

    return (
        <ResultsContent>
            <ContentCard>
                <ImagePhysician src={imgDoctorSuite}/>
                <Titles>
                    <Title>Diagnoza</Title>
                    <Subtitle>{diagnosis?.diagnosis || ""}</Subtitle>
                </Titles>
            </ContentCard>
            {<ContentCardYellow>
                <Titles>
                    <Title>Umów wizytę</Title>
                    <Subtitle>Zalecamy wizytę u pediatry w ciągu 24-48 godzin w celu oceny stanu zdrowia
                        dziecka, potwierdzenia diagnozy i określenia dalszego leczenia.</Subtitle>
                    <Subtitle>Warto podkreślić, że diagnoza powinna być zawsze potwierdzana przez lekarza. W
                        przypadku, gdy objawy są trudne do zidentyfikowania lub występują inne, bardziej
                        specyficzne objawy, konieczna jest konsultacja z lekarzem w celu prawidłowej diagnozy i
                        leczenia.</Subtitle>
                    <Buttons>
                        <ButtonPrimary
                            title="Umów wizytę"
                            onClick={() => navigate(Paths.DOCTORS_ALL, {replace: true})}
                        />

                        <MobileGoToHomeLink>
                            Wróć na stronę główną
                        </MobileGoToHomeLink>
                    </Buttons>


                </Titles>
            </ContentCardYellow>}
        </ResultsContent>
    )
}

export default AiDiagnosisResult