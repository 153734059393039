import styled from "styled-components";

export const Tag = styled.p`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.theme.color.pink200};

  @media (max-width: 1199px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: 1200px) {

  }
`