import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import chevron from '../../../assets/images/ic_chevron.svg';
import chevronWhite from '../../../assets/images/ic_chevron_white.svg';
import {
    AccordionContainer,
    Answer,
    BackgroundThread,
    BackgroundThread2,
    BottomWave,
    FaqItem,
    Question,
    SectionWrapper,
    SectionWrapperWrapper,
    Title,
    TopWave
} from "./FaqSection.styles";
import {SecondComponentHandles} from "../Welcome";
import styled from "styled-components";


const ImgChevronBlack = styled.img`
  @media (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {

  }
`


const ImgChevronWhite = styled.img`
  @media (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    display: none;
  }
`

interface FAQ {
    question: string;
    answer: string;
}

const FaqSection = forwardRef<SecondComponentHandles, {}>((props, ref) => {
    const sectionRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
        scrollToSection: () => {
            sectionRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }));

    const faqData: FAQ[] = [
        {
            question: "Czy mogę ufać diagnozie AI?",
            answer: "Nasz algorytm AI został opracowany przy współpracy z ekspertami medycznymi i stale się uczy na podstawie nowych danych. Jednak zawsze zalecamy traktowanie diagnozy AI jako wstępnej oceny i konsultację z lekarzem w celu uzyskania dokładnej diagnozy."
        },
        {
            question: "Jakie informacje są potrzebne do wygenerowania diagnozy AI?",
            answer: "Do wygenerowania diagnozy AI wymagane są szczegółowe informacje na temat objawów dziecka, historii chorób, aktualnych leków i wszelkich innych istotnych informacji medycznych, które mogą pomóc w dokładnej ocenie."
        },
        {
            question: "Czy mogę uzyskać dostęp do historii medycznej mojego dziecka na platformie?",
            answer: "Tak, zarówno w wersji bezpłatnej, jak i płatnej, użytkownicy mają dostęp do historii medycznej swojego dziecka. Jednak pełna funkcjonalność, tak jak dostęp do szczegółowych zapisów i analiz, może być dostępna tylko w wersji płatnej."
        },
        {
            question: "Jak umówić się na teleporadę?",
            answer: "Aby umówić się na teleporadę, wystarczy zalogować się na swoje konto, wybrać opcję 'Teleporada' i postępować zgodnie z instrukcjami. Użytkownicy płatnej wersji mają priorytetowy dostęp do lekarzy."
        },
        {
            question: "Jak przygotować się do teleporady?",
            answer: "Przed teleporadą upewnij się, że masz stabilne połączenie internetowe, działającą kamerę i mikrofon. Przygotuj wszelkie informacje medyczne, które mogą być istotne dla lekarza oraz spisz pytania, które chcesz zadać."
        },
        {
            question: "Czy platforma jest dostępna 24/7?",
            answer: "Tak, nasza platforma jest dostępna 24/7 dla wszystkich użytkowników. Jednak dostępność teleporad z lekarzami może być ograniczona do konkretnych godzin. Proszę sprawdzić dostępność lekarzy w zakładce 'Teleporada'."
        }
    ];

    const [openIndex, setOpenIndex] = useState<number | null>(null);

    return (
        <SectionWrapperWrapper ref={sectionRef}>
            <TopWave/>
            <BottomWave/>
            <SectionWrapper>
                <BackgroundThread/>
                <BackgroundThread2/>
                <Title>Często zadawane pytania</Title>
                <AccordionContainer>
                    {faqData.map((faq, index) => (
                        <FaqItem key={index}>
                            <Question onClick={() => {
                                if (index === openIndex) {
                                    setOpenIndex(null)
                                } else {
                                    setOpenIndex(index)
                                }
                            }}>
                                {faq.question}
                                <ImgChevronBlack alt="expand" src={chevron}/>
                                <ImgChevronWhite alt="expand" src={chevronWhite}/>
                            </Question>
                            <Answer open={index === openIndex}>{faq.answer}</Answer>
                        </FaqItem>
                    ))}
                </AccordionContainer>
            </SectionWrapper>
        </SectionWrapperWrapper>
    );
})

export default FaqSection;