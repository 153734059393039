import React from "react";
import {useTheme} from "styled-components";
import imgDecoration1 from "../../../assets/images/img_decoration_1.svg";
import imgDecoration2 from "../../../assets/images/img_decoration_2.svg";
import imgDecoration3 from "../../../assets/images/img_decoration_3.svg";
import imgSymbolChild from "../../../assets/images/img_symbol_child.svg";
import imgSymbolFetus from "../../../assets/images/img_symbol_fetus.svg";
import ButtonUpCircle from "../../Shared/Button/ButtonUpCircle/ButtonUpCircle";
import imgThread1 from "../../../assets/images/bg_thread_8.svg";
import imgThread2 from "../../../assets/images/bg_thread_9.svg";
import {useNavigate} from "react-router-dom";
import ButtonClose from "../../Shared/Button/ButtonClose/ButtonClose";
import {
    Card,
    CardButton,
    CardButtonTitle,
    CardContainer,
    CardHeadline,
    CardTitle,
    CircleButtonWrapper,
    Container,
    Decoration1Graphic,
    Decoration2Graphic,
    Decoration3Graphic,
    Decoration4Graphic,
    Headline,
    SymbolChildGraphic,
    SymbolFetusGraphic,
    Thread1Graphic,
    Thread2Graphic,
    Title
} from "./SelectChildType.styles";
import {Paths} from "../../../config/paths";

const SelectChildType: React.FC = () => {
    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <Container>
            <Headline>
                <Title>Dodaj dziecko</Title>
            </Headline>
            <CardContainer>
                <Card>
                    <CardHeadline>
                        <CardTitle>Wybierz typ karty</CardTitle>
                        <ButtonClose onClick={() => {
                            navigate(Paths.DEPENDANTS_ALL, {replace: true})
                        }}/>
                    </CardHeadline>
                    <CardButton
                        $bgColor={theme.color.violet100}
                        onClick={(event) => {
                            event.preventDefault()
                            navigate(Paths.CHILDREN_ADD)
                        }}
                    >
                        <CardButtonTitle>Dziecko</CardButtonTitle>
                        <Decoration1Graphic src={imgDecoration1}/>
                        <Decoration2Graphic src={imgDecoration2}/>
                        <SymbolChildGraphic src={imgSymbolChild}/>
                        <CircleButtonWrapper>
                            <ButtonUpCircle/>
                        </CircleButtonWrapper>
                    </CardButton>
                    <CardButton
                        $bgColor={theme.color.pink200}
                        onClick={(event) => {
                            event.preventDefault()
                            navigate(Paths.PREGNANCY_ADD)
                        }}
                    >
                        <CardButtonTitle>Ciąża</CardButtonTitle>
                        <Thread1Graphic src={imgThread1}/>
                        <Thread2Graphic src={imgThread2}/>
                        <Decoration3Graphic src={imgDecoration2}/>
                        <Decoration4Graphic src={imgDecoration3}/>
                        <SymbolFetusGraphic src={imgSymbolFetus}/>
                        <CircleButtonWrapper>
                            <ButtonUpCircle/>
                        </CircleButtonWrapper>
                    </CardButton>
                </Card>
            </CardContainer>
        </Container>)
}

export default SelectChildType

