import api from "../../config/api";
import {Endpoints} from "../../config/endpoints";
import {AiDiagnosis} from "./types";

interface FetchDiagnosisStart {
    type: "DIAGNOSIS_START"
}

interface FetchDiagnosisSuccess {
    type: "DIAGNOSIS_SUCCESS",
    data: AiDiagnosis[];
}

interface FetchDiagnosisFailure {
    type: "DIAGNOSIS_ERROR"
}

export const fetchDiagnoses = () => async (dispatch: any) => {
    try {
        dispatch({type: "DIAGNOSIS_START"});
        const url = Endpoints.AI_DIAGNOSES_GET_ALL
        const response = await api.get(url)
        if (response.status !== 200) {
            return Promise.reject()
        }
        dispatch({type: "DIAGNOSIS_SUCCESS", data: response.data})
        return Promise.resolve()
    } catch (error) {
        dispatch({type: "DIAGNOSIS_ERROR"})
        return Promise.reject(error)
    }
}

export const fetchPatientDiagnoses = (patientId: number) => async (dispatch: any) => {
    try {
        const url = Endpoints.DOCTOR_AI_DIAGNOSES_GET_ALL_FOR_CHILD.replace(":childId",patientId.toString())
        const response = await api.get(url)
        if (response.status !== 200) {
            return Promise.reject()
        }
        const aiDiagnoses: AiDiagnosis[] = response.data
        return Promise.resolve(aiDiagnoses)
    } catch (error) {
        return Promise.reject(error)
    }
}

export type Action = FetchDiagnosisStart | FetchDiagnosisSuccess | FetchDiagnosisFailure;