import styled from "styled-components";

export const DoctorAvatarContainer = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  z-index: 1;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DoctorDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`

export const DoctorDetailsTexts = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 25px;

`

export const DoctorDetailsSpecialization = styled.p`
  color: ${props => props.theme.color.gray700};
`

export const DoctorDetailsName = styled.h5`
  line-height: normal;
`

export const DoctorAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
