import React from 'react';

const IcChevronLeft: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path
            d="M10.5999 13.2101C10.5061 13.1171 10.4317 13.0065 10.381 12.8846C10.3302 12.7628 10.3041 12.6321 10.3041 12.5001C10.3041 12.368 10.3302 12.2373 10.381 12.1155C10.4317 11.9936 10.5061 11.883 10.5999 11.7901L15.1899 7.21006C15.2836 7.1171 15.358 7.0065 15.4088 6.88464C15.4595 6.76278 15.4857 6.63207 15.4857 6.50006C15.4857 6.36805 15.4595 6.23734 15.4088 6.11548C15.358 5.99362 15.2836 5.88302 15.1899 5.79006C15.0025 5.60381 14.749 5.49927 14.4849 5.49927C14.2207 5.49927 13.9672 5.60381 13.7799 5.79006L9.18986 10.3801C8.62806 10.9426 8.3125 11.7051 8.3125 12.5001C8.3125 13.2951 8.62806 14.0576 9.18986 14.6201L13.7799 19.2101C13.9661 19.3948 14.2175 19.499 14.4799 19.5001C14.6115 19.5008 14.7419 19.4756 14.8638 19.4258C14.9856 19.3761 15.0964 19.3027 15.1899 19.2101C15.2836 19.1171 15.358 19.0065 15.4088 18.8846C15.4595 18.7628 15.4857 18.6321 15.4857 18.5001C15.4857 18.368 15.4595 18.2373 15.4088 18.1155C15.358 17.9936 15.2836 17.883 15.1899 17.7901L10.5999 13.2101Z"
            fill="#C5C5C5"/>
    </svg>
);

export default IcChevronLeft;

