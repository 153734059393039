import imgCog from "../../assets/images/img_help_cog.svg";
import imgAi from "../../assets/images/img_help_ai.svg";
import imgPadlock from "../../assets/images/img_help_padlock.svg";
import imgDocument from "../../assets/images/img_help_document.svg";
import imgPhone from "../../assets/images/img_help_phone.svg";
import imgStethoscope from "../../assets/images/img_help_stethoscope.svg";

export interface HelpSectionItem {
    title: string
    content: string
}

export interface HelpSectionContent {
    id: string
    title: string,
    image: string,
    items: HelpSectionItem[]
}

export const items: HelpSectionContent[] = [
    {
        id: 'account',
        image: imgCog,
        title: "Konto użytkownika",
        items: [
            {
                title: "Jak mogę założyć konto na portalu?",
                content: "Aby założyć konto, kliknij przycisk \"Zarejestruj się\" na stronie głównej. Następnie wypełnij formularz rejestracyjny, podając swoje podstawowe dane, takie jak imię, adres e-mail i hasło. Otrzymasz e-mail z linkiem aktywacyjnym, który należy kliknąć, aby aktywować konto.",
            },
            {
                title: "Czy moje dane osobowe są bezpieczne?",
                content: "Tak, zapewniamy pełne bezpieczeństwo danych osobowych. Stosujemy zaawansowane środki bezpieczeństwa, aby chronić Twoje informacje przed nieautoryzowanym dostępem. Nie udostępniamy danych osobowych żadnym stronom trzecim bez Twojej wyraźnej zgody.",
            },
            {
                title: "Jak mogę zresetować zapomniane hasło?",
                content: "Jeśli zapomniałeś hasła, kliknij link „Zapomniałem hasła” na stronie logowania. Następnie wpisz swój adres e-mail użyty podczas rejestracji. Otrzymasz e-mail z instrukcjami dotyczącymi resetowania hasła.",
            },
            {
                title: "Czy mogę zmienić adres e-mail powiązany z moim kontem?",
                content: "Tak, aby zmienić adres e-mail, zaloguj się na swoje konto i przejdź do ustawień konta. W sekcji „Informacje o koncie” znajdziesz opcję zmiany adresu e-mail. Po wprowadzeniu nowego adresu e-mail, otrzymasz wiadomość z potwierdzeniem zmiany.",
            },
        ],
    },
    {
        id: "diagnosis",
        image: imgAi,
        title: "Diagnoza AI",
        items: [
            {
                title: "Jak działa proces diagnozy AI na tym portalu?",
                content: "Proces diagnozy rozpoczyna się od wypełnienia przez rodzica lub opiekuna formularza, w którym należy szczegółowo opisać objawy dziecka. Następnie nasza zaawansowana sztuczna inteligencja analizuje te informacje i, na podstawie ogromnej bazy danych medycznych, przedstawia potencjalne diagnozy. Pamiętaj, że diagnoza AI nie zastępuje konsultacji z lekarzem.",
            },
            {
                title: "Czy diagnoza AI jest dokładna?",
                content: "Nasz system AI jest zaprojektowany, aby zapewniać jak najdokładniejsze diagnozy na podstawie dostępnych informacji. Jednakże, jak w każdym narzędziu diagnostycznym, istnieje pewien margines błędu. Zawsze zalecamy skonsultowanie wyników z kwalifikowanym lekarzem.",
            },
            {
                title: "Czy potrzebuję szczegółowych informacji medycznych, aby korzystać z diagnozy AI?",
                content: "Nie jest wymagana specjalistyczna wiedza medyczna, ale dokładność diagnozy zależy od szczegółowości i precyzji podanych informacji. Zalecamy podanie jak najwięcej szczegółów dotyczących objawów, ich czasu trwania i innych istotnych czynników.",
            },
            {
                title: "Czy mogę używać diagnozy AI dla dorosłych?",
                content: "Nasz system diagnozy AI jest specjalnie zaprojektowany z myślą o dzieciach i ich specyficznych potrzebach medycznych. Nie zalecamy używania go do diagnozowania dorosłych, ponieważ algorytmy mogą nie być odpowiednio dostosowane do diagnozowania dolegliwości u osób dorosłych.",
            },
        ],
    },
    {
        id: "data-and-security",
        image: imgPadlock,
        title: "Dane i bezpieczeństwo",
        items: [
            {
                title: "Jakie środki bezpieczeństwa stosujecie, aby chronić dane mojego dziecka?",
                content: "Zapewniamy wysoki poziom bezpieczeństwa danych, stosując zaawansowane technologie szyfrowania i zabezpieczeń. Regularnie aktualizujemy nasze systemy, aby zapobiegać nieautoryzowanemu dostępowi. Wszystkie dane są przechowywane na zabezpieczonych serwerach i są dostępne tylko dla uprawnionego personelu.",
            },
            {
                title: "Czy moje dane osobowe są udostępniane osobom trzecim?",
                content: "Nie udostępniamy danych osobowych użytkowników osobom trzecim bez wyraźnej zgody. Dane mogą być udostępniane jedynie w celach diagnostycznych, leczniczych lub naukowych, pod warunkiem, że wyrazisz na to zgodę.",
            },
            {
                title: "Jak mogę usunąć swoje konto i wszystkie powiązane z nim dane?",
                content: "Jeśli zdecydujesz się usunąć swoje konto, możesz to zrobić w ustawieniach konta. Po usunięciu konta wszystkie powiązane z nim dane zostaną trwale usunięte z naszych systemów zgodnie z naszą polityką prywatności.",
            },
            {
                title: "Jak mogę uzyskać dostęp do danych, które podałem, lub je zmienić?",
                content: "Możesz uzyskać dostęp i zmienić swoje dane osobowe w każdej chwili, logując się na swoje konto i przechodząc do sekcji ustawień. Jeśli potrzebujesz pomocy lub chcesz uzyskać więcej informacji, skontaktuj się z naszą obsługą klienta.",
            },
        ],
    },
    {
        id: "documentation",
        image: imgDocument,
        title: "Dokumentacja",
        items: [
            {
                title: "Jakie dokumenty są potrzebne do korzystania z narzędzia diagnozy AI?",
                content: "Aby skorzystać z narzędzia diagnozy AI, nie jest wymagane dostarczanie żadnych oficjalnych dokumentów medycznych. Wystarczy, że podczas wypełniania formularza diagnozy podasz dokładne i szczegółowe informacje o objawach dziecka. Jednakże, wszelkie dodatkowe informacje medyczne mogą pomóc w dokładniejszej diagnozie.",
            },
            {
                title: "Czy otrzymam pisemną dokumentację diagnozy od AI?",
                content: "Tak, po przeprowadzeniu diagnozy, system wygeneruje pisemny raport, który zawierać będzie podsumowanie podanych objawów oraz sugerowane diagnozy. Ten dokument możesz wydrukować lub zapisać dla dalszego wykorzystania, na przykład podczas wizyty u lekarza.",
            },
            {
                title: "Jak mogę uzyskać kopię mojej historii diagnoz?",
                content: "Twoja historia diagnoz jest dostępna na Twoim koncie. Możesz ją przeglądać i pobierać w dowolnym momencie. Wystarczy zalogować się na konto i przejść do sekcji „Historia diagnoz”.",
            },
            {
                title: "Czy informacje zawarte w raporcie diagnozy mogę przekazać mojemu lekarzowi?",
                content: "Tak, zachęcamy do dzielenia się raportem diagnozy z Twoim lekarzem. Może to pomóc w dalszej diagnostyce i leczeniu. Pamiętaj jednak, że diagnoza AI służy jako narzędzie wspomagające i nie zastępuje profesjonalnej opinii medycznej.",
            },
        ],
    },
    {
        id: "consultations",
        image: imgPhone,
        title: "Teleporady",
        items: [
            {
                title: "Czym są teleporady i jak mogę z nich skorzystać?",
                content: "Teleporady to konsultacje zdrowotne przeprowadzane zdalnie za pomocą połączeń wideo lub telefonicznych. Aby skorzystać z teleporady, wystarczy zalogować się na swoje konto na portalu, wybrać opcję „Zarezerwuj teleporadę” i wybrać dostępnego specjalistę oraz dogodny termin.",
            },
            {
                title: "Czy teleporady są bezpieczne i poufne?",
                content: "Tak, zapewniamy pełną poufność i bezpieczeństwo teleporad. Wszystkie połączenia są szyfrowane, a dane pacjentów chronione, zgodnie z przepisami o ochronie danych osobowych. Zapewniamy, że informacje udostępniane podczas teleporady są traktowane z najwyższą poufnością.",
            },
            {
                title: "Czy potrzebuję specjalnego sprzętu do przeprowadzenia teleporady?",
                content: "Do przeprowadzenia teleporady potrzebny jest komputer, tablet lub smartfon z dostępem do internetu oraz kamerą i mikrofonem. Zalecamy również korzystanie z niezawodnego połączenia internetowego, aby zapewnić płynność połączenia.",
            },
            {
                title: "Co zrobić, jeśli potrzebuję pilnej pomocy, a nie ma dostępnych teleporad?",
                content: "W przypadku pilnych sytuacji medycznych zalecamy skontaktowanie się bezpośrednio z najbliższą placówką medyczną lub pogotowiem. Nasz serwis teleporad nie jest przeznaczony do obsługi nagłych wypadków medycznych.",
            },
        ],
    },
    {
        id: "other",
        image: imgStethoscope,
        title: "Pozostałe pytania",
        items: [
            {
                title: "Czy mogę korzystać z portalu z poza granic mojego kraju?",
                content: "Tak, nasz portal jest dostępny online i może być używany z dowolnego miejsca na świecie, o ile masz dostęp do internetu. Pamiętaj jednak, że nasze zalecenia i diagnozy są oparte na standardach medycznych obowiązujących w kraju, w którym portal został stworzony, co może mieć wpływ na ich trafność w innym kontekście kulturowym lub medycznym.",
            },
            {
                title: "Czy portal może być używany do celów edukacyjnych?",
                content: "Tak, nasz portal może być użytecznym narzędziem edukacyjnym dla studentów medycyny, opiekunów zdrowotnych lub rodziców chcących lepiej zrozumieć symptomy i potencjalne choroby. Zaznaczamy jednak, że informacje zawarte na portalu nie zastępują profesjonalnego szkolenia medycznego.",
            },
            {
                title: "Jak mogę zgłosić problem techniczny lub błąd na stronie?",
                content: "Jeśli napotkasz problem techniczny lub błąd, prosimy o kontakt z naszym zespołem wsparcia technicznego poprzez formularz kontaktowy na stronie lub wysyłając e-mail na podany adres. Dołożymy wszelkich starań, aby jak najszybciej rozwiązać problem.",
            },
            {
                title: "Czy portal oferuje wsparcie w języku innym niż język główny strony?",
                content: "Aktualnie nasza strona i wsparcie są dostępne głównie w języku, w którym portal został stworzony. Pracujemy jednak nad rozszerzeniem naszych usług o dodatkowe języki, aby umożliwić korzystanie z portalu szerszemu kręgowi użytkowników.",
            },
        ],
    },
]