import React, {useState} from "react";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import {useTheme} from "styled-components";
import {isValidEmail, isValidPassword, passwordRequirements, Requirement} from "../../../config/utils";
import {
    ButtonWrapper,
    FormPart,
    HeaderPart,
    HeaderSubtitle,
    HeaderTitle,
    LinkButtonWrapper,
    PasswordRequirement,
    PasswordRequirements,
    PasswordRequirementText,
    RecoveryLink,
    RecoveryText,
    RegisterFormWrapper,
    UnderInputs
} from "./RegisterForm.styles";
import SvgFill from "../../Shared/Icons/SvgFill";
import IcIndicatorOk from "../../Shared/Icons/IcIndicatorOk";
import IcIndicatorError from "../../Shared/Icons/IcIndicatorError";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {signupUser} from "../../../state/profile/actions";
import InputField from "../../Shared/Input/Input";
import CheckBox from "../../Shared/Input/CheckBox";

const RegisterForm: React.FC<{
    onRegisterComplete: (email: string, password: string) => void,
    navigateLogin: () => void,
    navigateDoctorLogin?: () => void,
}> = (
    {
        onRegisterComplete,
        navigateLogin,
        navigateDoctorLogin,
    }
) => {

    const [validateEmailOnType, setValidateEmailOnType] = useState(false);
    const [validatePasswordOnType, setValidatePasswordOnType] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [emailCorrect, setEmailCorrect] = useState<boolean | null>(null);
    const [passwordCorrect, setPasswordCorrect] = useState<boolean | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [checkboxError, setCheckboxError] = useState<string | null>(null);
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch: AppDispatch = useDispatch()
    const theme = useTheme();

    const validateEmailCorrect = (email: string) => {
        const isValid = isValidEmail(email);
        setEmailCorrect(isValid)
    }

    const validatePasswordCorrect = (password: string) => {
        setPasswordCorrect(isValidPassword(password))
    }

    const validatePasswordError = (password: string) => {
        const isValid = isValidPassword(password)
        setPasswordError(!isValid ? "Nieprawidłowy format hasła" : null)
        return isValid
    }

    const validateChecked = (ch: boolean) => {
        setCheckboxError(!ch ? "Zaakceptuj regulamin i politykę prywatności" : null)
        return ch
    }

    const validateEmailError = (email: string) => {
        const isValid = isValidEmail(email)
        setEmailError(!isValid ? "Nieprawidłowy adres email" : null)
        return isValid
    }

    const validateForm = () => {
        const isEmailValid = validateEmailError(email)
        const isPasswordValid = validatePasswordError(password)
        const isChecked = validateChecked(checked)
        return isEmailValid && isPasswordValid && isChecked
    }

    const getColorForValidator = (valid: boolean) => {
        if (passwordError && !valid) {
            return theme.color.error900;
        }
        if (valid) {
            return theme.color.success900;
        }
        return theme.color.gray1000;
    }

    const renderRequirement = (requirement: Requirement) => {
        const valid = requirement.validation(password)
        const color = getColorForValidator(valid)
        return (
            <PasswordRequirement key={requirement.title}>
                <SvgFill color={color}>
                    {valid ? <IcIndicatorOk/> : <IcIndicatorError/>}
                </SvgFill>
                <PasswordRequirementText $color={color}>
                    {requirement.title}
                </PasswordRequirementText>
            </PasswordRequirement>
        )
    }

    function renderPasswordRequirements() {
        return <PasswordRequirements>
            {passwordRequirements.map(requirement => renderRequirement(requirement))}
        </PasswordRequirements>;
    }

    function renderDoctorLoginLink() {
        if (!navigateDoctorLogin) {
            return null
        }
        return <RecoveryLink onClick={navigateDoctorLogin}>Przejdź do strony dla lekarzy</RecoveryLink>;
    }

    function renderButtonSection() {
        return <ButtonWrapper>
            <ButtonPrimary
                title="Zarejestruj się"
                onClick={() => {
                    if (!validateForm()) return
                    setLoading(true)
                    setEmailError(null)
                    setPasswordError(null)
                    dispatch(signupUser(email, password))
                        .then((response) => {
                            switch (response) {
                                case "CREDENTIALS_INVALID":
                                    setEmailError("Wystąpił nieznany błąd.")
                                    setPasswordError("Wystąpił nieznany błąd.")
                                    break;
                                case "USER_EXISTS":
                                    setEmailError("Użytkownik z takim adresem email już istanieje")
                                    break
                                case "SUCCESS":
                                    onRegisterComplete(email, password)
                                    break;
                            }
                        })
                        .catch(() => {
                            setEmailError("Wystąpił nieznany błąd.")
                            setPasswordError("Wystąpił nieznany błąd.")
                        })
                        .finally(() => setLoading(false))
                }}
                progress={loading}/>
            <div>
                <LinkButtonWrapper>
                    <RecoveryText>Masz już konto?</RecoveryText>
                    <RecoveryLink onClick={navigateLogin}>Zaloguj się</RecoveryLink>
                </LinkButtonWrapper>
                <LinkButtonWrapper>
                    <RecoveryText>Jesteś lekarzem?</RecoveryText>
                    {renderDoctorLoginLink()}
                </LinkButtonWrapper>
            </div>
        </ButtonWrapper>;
    }

    function renderFormSection() {
        return <FormPart>
            <InputField
                label="Email"
                type={"email"}
                value={email}
                onChange={(em: string) => {
                    setEmail(em)
                    if (validateEmailOnType) {
                        validateEmailError(em)
                    }
                    validateEmailCorrect(em)
                }}
                error={emailError}
                correct={emailCorrect}
                onBlur={() => {
                    setValidateEmailOnType(true);
                    validateEmailError(email);
                }}
            />
            <InputField
                label="Hasło"
                type="password"
                value={password}
                onChange={(p: string) => {
                    setPassword(p)
                    if (validatePasswordOnType) {
                        validatePasswordError(p)
                    }
                    validatePasswordCorrect(p)
                }}
                onBlur={() => {
                    setValidatePasswordOnType(true);
                    validatePasswordError(password);
                }}
                correct={!!passwordCorrect}
                error={passwordError}
            />
            {renderPasswordRequirements()}
            <UnderInputs>
                <CheckBox
                    checked={checked}
                    onChange={() => {
                        const newChecked = !checked
                        setChecked(newChecked);
                        validateChecked(newChecked);
                    }}
                    error={checkboxError}
                >Akceptuję regulamin i politykę prywatności</CheckBox>
            </UnderInputs>
        </FormPart>;
    }

    const renderRegisterForm = () => {
        return (
            <RegisterFormWrapper>
                <HeaderPart>
                    <HeaderTitle>Dołącz do nas</HeaderTitle>
                    <HeaderSubtitle>Zarejestruj się, żeby zobaczyć pełną diagnozę oraz umówić się ze
                        specjalistą</HeaderSubtitle>
                </HeaderPart>
                {renderFormSection()}
                {renderButtonSection()}
            </RegisterFormWrapper>
        );
    }

    return renderRegisterForm()
}

export default RegisterForm