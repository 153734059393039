import React, {useState} from "react";
import icPersonalDetails from "../../../../../assets/images/ic_personal_details.svg"
import iMeasurements from "../../../../../assets/images/ic_mesurements.svg"
import {Child} from "../../../../../state/children/types";
import {
    Chart,
    Column1,
    Column2,
    Container,
    Option,
    RightContainer,
    RightContainerTitle,
    Row,
    Section,
    SectionTitle,
    SectionTitleText,
    Select,
    SelectContainer,
    TabWrapper,
    TitleSection
} from "./PersonalDetailsTab.styles";
import ButtonOutline from "../../../../Shared/Button/ButtonOutline/ButtonOutline";

const PersonalDetailsTab: React.FC<{ child: Child }> = ({child}) => {
    const [selected, setSelected] = useState("waga")

    const renderSidePanel = () => {
        if (1 === 1) {
            return null
        }
        return <RightContainer>
            <RightContainerTitle>
                Pomiary
            </RightContainerTitle>
            <SelectContainer>
                <Select
                    value={selected}
                    onChange={(e) => {
                        e.preventDefault()
                        setSelected(e.target.value)
                    }}
                >
                    <Option value="weight">Waga</Option>
                    <Option value="height">Wzrost</Option>
                </Select>
            </SelectContainer>

            <Chart>Brak danych</Chart>
        </RightContainer>;
    };

    function renderMeasurementSection() {
        if (1 === 1) {
            return null
        }
        return <Section>
            <TitleSection>
                <SectionTitle>
                    <img alt="Pomiary" src={iMeasurements}/>
                    <SectionTitleText>Pomiary</SectionTitleText>
                </SectionTitle>

                <ButtonOutline
                    title="Dodaj pomiary"
                    onClick={() => {
                    }}
                />
            </TitleSection>
            <Row>
                <Column1>Waga</Column1>
                <Column2></Column2>
            </Row>
            <Row>
                <Column1>Wzrost</Column1>
                <Column2></Column2>
            </Row>
            <Row>
                <Column1>Obwód głowy</Column1>
                <Column2></Column2>
            </Row>
            <Row>
                <Column1>Obwód klatki piersiowej</Column1>
                <Column2></Column2>
            </Row>
        </Section>;
    }

    return (
        <TabWrapper>
            <Container>
                <Section>
                    <SectionTitle>
                        <img alt="Dane osobowe" src={icPersonalDetails}/>
                        <SectionTitleText>Dane osobowe</SectionTitleText>
                    </SectionTitle>
                    <Row>
                        <Column1>Imię i nazwisko</Column1>
                        <Column2>{child.name}</Column2>
                    </Row>
                    <Row>
                        <Column1>Data urodzenia</Column1>
                        <Column2>{child.dob} r.</Column2>
                    </Row>
                    <Row>
                        <Column1>PESEL</Column1>
                        <Column2>{child.pesel}</Column2>
                    </Row>
                </Section>

                {renderMeasurementSection()}
            </Container>
            {renderSidePanel()}
        </TabWrapper>
    )
}

export default PersonalDetailsTab