import React from "react";
import switchOn from "../../../../assets/images/img_switch_on.svg";
import switchOff from "../../../../assets/images/img_switch_off.svg";
import {LoadingIndicator, LoadingWrapper} from "./ToggleSwitch.styles";

const ToggleSwitch: React.FC<
    {
        on: boolean | null,
        loading: boolean | null,
        onClick: () => void
    }
> = (
    {
        on,
        loading,
        onClick,
    }
) => {

    const renderSwitch = () => {
        if (loading) {
            return (
                <LoadingWrapper>
                    <LoadingIndicator/>
                </LoadingWrapper>
            )
        } else if (on) {
            return (
                <LoadingWrapper>
                    <img alt="ON" src={switchOn} onClick={onClick}/>
                </LoadingWrapper>
            )
        } else {
            return (
                <LoadingWrapper>
                    <img alt="OFF" src={switchOff} onClick={onClick}/>
                </LoadingWrapper>
            )
        }
    }

    return renderSwitch()
}

export default ToggleSwitch