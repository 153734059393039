import styled from "styled-components";

export const Title = styled.h3`
  padding-top: 56px;
  margin-bottom: 40px;
  font-size: 36px;
`

export const Container = styled.div`
  box-sizing: border-box;
  padding: 0 40px;
  min-height: 100vh;
`

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
