import React from 'react';
import {PopupContent, PopupDim} from "./PoPup.styles";

interface PopupProps {
    visible: boolean;
    onClose: () => void;
    children: React.ReactNode
}

const Popup: React.FC<PopupProps> = ({visible, onClose, children}) => {
    return (
        <PopupDim $isVisible={visible} onClick={onClose}>
            <PopupContent onClick={(e) => e.stopPropagation()}>
                {children}
            </PopupContent>
        </PopupDim>
    );
};

export default Popup;
