import BackArrowButtonTitled from "../../Shared/Button/BackArrowButtonTitled/BackArrowButtonTitled";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../../../state/store";
import {formatDateToDDMMYYYYWithDashes} from "../../../config/utils";
import AiBanner from "./AiBanner/AiBanner";
import DoctorCardComponent from "./DoctorCardComponent";
import ChildSelectionCard from "./ChildSelectionCard";
import {fetchAllDoctors, fetchDoctorAppointments} from "../../../state/doctors/actions";
import AppointmentSelectionForm, {FormInfo} from "./AppointmentSelectionForm";
import PaymentSummaryCard from "./PaymentSummaryCard";
import {createAppointment, CreateAppointmentPayload, fetchPaymentRedirect,} from "../../../state/appointments/actions";
import CalendarPickerCard from "./CalendarPickerCard";
import TimeSlotPickerCard from "./TimeSlotPickerCard";
import {Background, Content} from "./CreateAppointment.styles";

interface RouteParams {
    [key: string]: string | undefined;

    doctorId: string;
}


const CreateAppointment: React.FC = () => {
    const location = useLocation();
    const dateString = location.state?.date;
    const date = dateString ? new Date(dateString) : null;

    const {doctorId: doctorIdString} = useParams<RouteParams>()

    const [childId, setChildId] = useState<number | null>(null)
    const [appointmentId, setAppointmentId] = useState<number | null>(null)
    const [loading, setLoading] = useState(false)
    const [appointmentDate, setAppointmentDate] = useState<Date | null>(date)
    const [appointmentTime, setAppointmentTime] = useState<Date | null>(date)
    const [creatingAppointment, isCreatingAppointment] = useState(false)

    const doctorId = parseInt(doctorIdString ?? "", 10)
    const doctor = useSelector((state: AppState) => state.doctors.data?.find((doctor) => doctor.id === doctorId));
    const [loadingDoctor, setLoadingDoctor] = useState(false)

    const [disabledSlots, setDisabledSlots] = useState<Date[] | null>(null);
    const [loadingDisabledSlots, setIsLoadingDisabledSlots] = useState(false);

    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!disabledSlots && !loadingDisabledSlots) {
            setIsLoadingDisabledSlots(true)
            dispatch(fetchDoctorAppointments(doctorId))
                .then(disabled => setDisabledSlots(disabled))
                .catch(error => console.log("error fetching appointment", error))
                .finally(() => setIsLoadingDisabledSlots(false))
        }
    }, [disabledSlots, loadingDisabledSlots, dispatch]);

    useEffect(() => {
        if (!loadingDoctor && !doctor) {
            setLoadingDoctor(true)
            dispatch(fetchAllDoctors())
                .catch((err) => {
                    console.log("error fetching doctors", err)
                })
                .finally(() => {
                    setLoadingDoctor(false)
                })
        }
    }, [doctor, loadingDoctor, dispatch])

    const handleSubmitData = (form: FormInfo) => {
        if (!appointmentDate) {
            return
        }
        if (!appointmentTime) {
            return
        }
        if (!childId) {
            return
        }
        const payload: CreateAppointmentPayload = {
            childId: childId,
            parentName: form.name,
            parentPhone: form.phone,
            doctorId: doctorId,
            startTime: appointmentTime,
        }
        isCreatingAppointment(true)
        dispatch(createAppointment(payload))
            .then(resp => {
                setAppointmentId(resp)
            })
            .catch(error => {
                console.log("error creating appointment", error)
            })
            .finally(() => {
                isCreatingAppointment(false)
            })
    }

    const renderPaymentSummaryScreen = () => (
        <PaymentSummaryCard
            progress={loading}
            onConfirm={() => {
                if (appointmentId) {
                    if (!loading && appointmentId) {
                        setLoading(true)
                        dispatch(fetchPaymentRedirect(appointmentId)).catch(error => {
                            console.log("error fetching payment redirect", error)
                        }).then(redirect => {
                            if (redirect) {
                                window.location.href = redirect
                            }
                        }).finally(() => {
                            setLoading(false)
                        })
                    }
                }
            }}
            onCancel={() => {
                setAppointmentTime(null)
            }}/>
    )

    const renderChildSelection = () => (
        <ChildSelectionCard
            onCancel={() => onBackClicked()}
            onConfirm={id => setChildId(id)}
        />
    )

    const renderAppointmentSelectionForm = (cid: number) => (
        <AppointmentSelectionForm
            onSubmit={form => handleSubmitData(form)}
            childId={cid}
            onCancel={() => setChildId(null)}
            progress={creatingAppointment}
        />
    )

    const renderTimeSlotPicker = (d: Date) => (
        <TimeSlotPickerCard
            date={d}
            onSubmit={time => {
                setAppointmentTime(time)
            }}
            onCancel={onBackClicked}
            disabled={disabledSlots || []}/>
    )

    const renderCalendarPicker = () => {
        return (
            <CalendarPickerCard
                onSubmit={date => setAppointmentDate(date)}
                onCancel={onBackClicked}
            />
        )
    }

    const renderContent = () => {
        if (!appointmentDate) {
            return renderCalendarPicker();
        }
        if (!appointmentTime) {
            return renderTimeSlotPicker(appointmentDate);
        }
        if (!childId) {
            return renderChildSelection();
        }
        if (appointmentId) {
            return renderPaymentSummaryScreen();
        }
        return renderAppointmentSelectionForm(childId);
    }

    const onBackClicked = () => {
        if (appointmentId) {
            navigate(-1)
            return;
        }
        if (appointmentTime) {
            setAppointmentTime(null)
            return
        }
        if (appointmentDate) {
            setAppointmentDate(null)
            return;
        }
        navigate(-1)
    }

    return (
        <Background>
            <BackArrowButtonTitled onBackClicked={onBackClicked}/>
            <Content>
                <DoctorCardComponent
                    doctor={doctor || null}
                    date={formatDateToDDMMYYYYWithDashes(appointmentDate)}
                />
                <AiBanner/>
                {renderContent()}
            </Content>
        </Background>
    )
}

export default CreateAppointment