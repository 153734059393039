import ButtonUpLink from "../../Shared/Button/ButtonUpLink/ButtonUpLink";
import React, {forwardRef, useImperativeHandle, useRef} from "react";

import {
    Background,
    LinkButtonWrapper,
    Section,
    SectionBackground,
    SectionDecorationThread1,
    Title
} from "./DiagnosisSection.styles";
import {SecondComponentHandles} from "../Welcome";
import HeroGallery from "../HeroGallery/HeroGallery";

const DiagnosisSection = forwardRef<SecondComponentHandles, {}>((props, ref) => {
    const sectionRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
        scrollToSection: () => {
            sectionRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }));

    return (
        <Background ref={sectionRef}>
            <Section>
                <SectionBackground/>
                <SectionDecorationThread1/>
                <Title>Zobacz jak działa diagnoza AI</Title>
                <LinkButtonWrapper>
                    <ButtonUpLink>Sprawdź naszą metodologię</ButtonUpLink>
                </LinkButtonWrapper>
                <HeroGallery/>
            </Section>

        </Background>
    );
})

export default DiagnosisSection;