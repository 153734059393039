import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../state/store";
import icBell from "../../assets/images/ic_bell.svg";
import {updateSettings} from "../../state/settings/actions";
import icPhone from "../../assets/images/ic_phone.svg";
import ToggleSwitch from "../Shared/Button/ToggleSwitch/ToggleSwitch";
import {useFetchSettings} from "../../state/settings/helpers";
import {
    CellSubtitle,
    CellTitle,
    Container,
    ContentColumn,
    ContentColumnCell1,
    ContentRow,
    Section,
    SectionTitle,
    SectionTitleText,
    TabWrapper,
    TitleSection
} from "./DoctorNotifications.styles";

const DoctorNotifications: React.FC = () => {

    const [loading, setLoading] = useState<string | null>("waboonda")
    const settings = useFetchSettings()
    const dispatch: AppDispatch = useDispatch()

    function toggleEmilConfirmations() {
        setLoading("emailConfirmations")
        dispatch(updateSettings({emailConfirmations: !settings?.emailConfirmations}))
            .catch(error => {
                console.log("error updating settings", error)
            })
            .finally(() => setLoading(null))
    }

    const renderSmsNotifications = () => {
        return <Section>
            <TitleSection>
                <SectionTitle>
                    <img alt="Pomiary" src={icPhone}/>
                    <SectionTitleText>Powiadomienia SMS</SectionTitleText>
                </SectionTitle>
            </TitleSection>
            <ContentRow>
                <ContentColumn>
                    <ContentColumnCell1>
                        <CellTitle>Potwierdzenie wizyty</CellTitle>
                        <CellSubtitle>Dodaj numer telefonu, żeby otrzymywać potwierdzenia wizyt.</CellSubtitle>
                    </ContentColumnCell1>
                    <ToggleSwitch
                        on={settings?.smsConfirmations || null}
                        loading={loading === "smsConfirmations"}
                        onClick={() => {
                            setLoading("smsConfirmations")
                            dispatch(updateSettings({smsConfirmations: !settings?.smsConfirmations}))
                                .catch(error => {
                                    console.log("error updating settings", error)
                                })
                                .finally(() => setLoading(null))
                        }}
                    />
                </ContentColumn>

                <ContentColumn>
                    <ContentColumnCell1>
                        <CellTitle>Przypomnienie o wizycie</CellTitle>
                        <CellSubtitle>Dodaj numer telefonu, żeby otrzymywać przypomnienia o
                            wizytach.</CellSubtitle>
                    </ContentColumnCell1>
                    <ToggleSwitch
                        on={settings?.smsReminders || null}
                        loading={loading === "smsReminders"}
                        onClick={() => {
                            setLoading("smsReminders")
                            dispatch(updateSettings({smsReminders: !settings?.smsReminders}))
                                .catch(error => {
                                    console.log("error updating settings", error)
                                })
                                .finally(() => setLoading(null))
                        }}
                    />
                </ContentColumn>
            </ContentRow>
        </Section>;
    }

    return (
        <TabWrapper>
            <Container>
                <Section>
                    <TitleSection>
                        <SectionTitle>
                            <img alt="O mnie" src={icBell}/>
                            <SectionTitleText>Powiadomienia email</SectionTitleText>
                        </SectionTitle>
                    </TitleSection>
                    <CellTitle>
                        Wybierz sposób powiadamiania pacjentów o potwierdzeniu, odrzuceniu bądź odwołaniu wizyty.
                    </CellTitle>
                    <ContentRow>
                        <ContentColumn>
                            <ContentColumnCell1>
                                <CellTitle>Potwierdzenie wizyty</CellTitle>
                                <CellSubtitle>Po umówieniu wizyty otrzymasz wiadomość ze wszystkimi
                                    szczegółami.</CellSubtitle>
                            </ContentColumnCell1>
                            <ToggleSwitch
                                on={settings?.emailConfirmations || null}
                                loading={loading === "emailConfirmations"}
                                onClick={toggleEmilConfirmations}
                            />
                        </ContentColumn>
                        <ContentColumn>
                            <ContentColumnCell1>
                                <CellTitle>Przypomnienie o wizycie</CellTitle>
                                <CellSubtitle>Dostaniesz od nas email 24h przed planowaną wizytą.</CellSubtitle>
                            </ContentColumnCell1>
                            <ToggleSwitch
                                on={settings?.emailReminders || null}
                                loading={loading === "emailReminders"}
                                onClick={() => {
                                    setLoading("emailReminders")
                                    dispatch(updateSettings({emailReminders: !settings?.emailReminders}))
                                        .catch(error => {
                                            console.log("error updating settings", error)
                                        })
                                        .finally(() => setLoading(null))
                                }}
                            />
                        </ContentColumn>
                    </ContentRow>
                </Section>
                {/*TODO: Uncomment when supporting SMS messaging*/}
                {/*{renderSmsNotifications()}*/}
            </Container>
        </TabWrapper>
    )
}

export default DoctorNotifications