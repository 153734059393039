import React from "react";
import ArticlesSectionComponent from "../ArticlesSectionComponent/ArticlesSectionComponent";
import {RightSection} from "./RightContent.styles";

const RightContent: React.FC = () => {
    return (
        <RightSection>
            <ArticlesSectionComponent/>
        </RightSection>
    )
}

export default RightContent