export class Paths {
    static readonly HOME = "/"
    static readonly LOGIN = "/login"
    static readonly REGISTER = "/register"
    // Dependants
    static readonly DEPENDANTS_ALL = "/dependants"
    static readonly DEPENDANTS_SELECT_TYPE = "/dependants/add"
    // Children
    static readonly CHILDREN_ADD = "/dependants/child/add"
    static readonly CHILDREN_DETAILS = "/dependants/child/view/:id"
    // Pregnancy
    static readonly PREGNANCY_ADD = "/dependants/pregnancy/add"
    static readonly PREGNANCY_DETAILS = "/dependants/pregnancy/view/:id"
    // Doctors
    static readonly DOCTORS_ALL = "/doctors"
    static readonly DOCTORS_DETAILS = "/doctors/:id"
    // Diagnosis
    // static readonly DIAGNOSIS_ALL = "/diagnoses/all"
    static readonly DIAGNOSIS_RESULT = "/diagnoses/results/:id"
    static readonly DIAGNOSIS_NEW = "/diagnoses/new"
    // Appointment
    static readonly APPOINTMENT_CREATE = "/appointments/create/:doctorId"
    static readonly APPOINTMENT_DETAILS = "/appointments/view/:id"
    static readonly PATIENT_APPOINTMENT = "/appointment/:id"
    // Doctor appointments
    static readonly DOCTOR_APPOINTMENT = "/doctor-appointment/:id"
    // Vaccines
    static readonly VACCINATIONS_CALENDAR = "/vaccinations"
    // Patient Appointments
    static readonly PATIENT_APPOINTMENTS_LIST = "/patient-appointments"
    // Help
    static readonly SETTINGS = "/settings"
    static readonly HELP = "/help"
    static readonly HELP_SECTION = "/help/:id"
    // Doctor
    static readonly PATIENTS = "/patients"
    static readonly UPCOMING_VISITS = "/upcoming"
    static readonly CONFIRMATIONS = "/confirmations"
    static readonly DOCTOR_SETTINGS = "/doctor-settings"
    static readonly PATIENT_DETAILS = "/patients/:id"
    // Doctor AI Diagnosis
    static readonly AI_DIAGNOSIS_LIST = "/diagnoses/ai/list/:childId"
    static readonly AI_DIAGNOSIS_DETAILS = "/diagnoses/ai/details/:childId/:id"

    // Article
    static readonly ARTICLE = "/article/:id"
    static readonly ARTICLES_SCREEN = "/articles/screen"
    static readonly ARTICLES_PAGE = "/articles/page"
    static readonly ARTICLE_DETAILS_PAGE = "/article/page/:id"
    // Other
    static readonly SIMPLE_TEXT_PAGE = "/simple-text-page/:id"

}