import React, {useEffect} from 'react';
import imgWave from '../../../assets/images/bg_wave_login.png'
import {Background, BackgroundWave, Container, LoginFormContainer, LoginLogoTop} from "./Login.styles";
import LoginForm from "./LoginForm";
import {useSelector} from "react-redux";
import {AppState} from "../../../state/store";
import {useNavigate} from "react-router-dom";
import icLogoMobile from "../../../assets/images/ic_logo_mobile.svg";

const LoginPage: React.FC = () => {

    const isLoggedIn = useSelector((state: AppState) => state.profile.profile != null);
    const navigate = useNavigate();

    const navigateToRecover = () => {
        navigate("/recover");
    };

    const navigateRegister = () => {
        navigate("/register");
    }
    const navigateDoctorLogin = () => {
        navigate("/doctor-login");
    }
    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);

    return (
        <Container>
            <Background>
                <BackgroundWave src={imgWave}/>
            </Background>
            <LoginFormContainer>
                <LoginLogoTop src={icLogoMobile}/>
                <LoginForm
                    navigateDoctorLogin={navigateDoctorLogin}
                    navigateRegister={navigateRegister}
                    navigateToRecover={navigateToRecover}
                />
            </LoginFormContainer>
        </Container>
    );
}

export default LoginPage;
