import styled from "styled-components";

export const CardWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 16px;
  background: ${props => props.theme.color.gray100};
  
  @media (max-width: 599px) {
    margin: 0 20px;
    padding: 24px;
  }

  @media (min-width: 600px) {
    width: 910px;
    padding: 40px 56px 48px 56px;
    align-items: center;
    justify-content: start;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    gap: 40px;
    width: 100%;
  }
`
export const LinkButton = styled.button`
  color: ${props => props.theme.color.violet600};
`
