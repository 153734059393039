import React, {useEffect, useState} from "react";
import {AppDispatch, AppState} from "../../../state/store";
import {useDispatch, useSelector} from "react-redux";
import {fetchDiagnoses} from "../../../state/aiDiagnoses/actions";
import LeftBottomDiagnosisTable from "./LeftBottomDiagnosisTable/LeftBottomDiagnosisTable";
import LeftBottomOnboardingContent from "./LeftBottomOnboardingContent/LeftBottomOnboardingContent";
import {fetchAppointments} from "../../../state/appointments/actions";
import LeftBottomAppointmentsTable from "./LeftBottomAppointmentsTable/LeftBottomAppointmentsTable";

const LeftBottomContent: React.FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const diagnoses = useSelector((state: AppState) => state.aiDiagnosis.diagnoses);
    const [loadingDiagnoses, setLoadingDiagnoses] = useState(false)
    const [loadingAppointments, setLoadingAppointments] = useState(false)

    const appointments = useSelector((state: AppState) => state.appointments.appointments);

    useEffect(() => {
        if (diagnoses == null && !loadingDiagnoses) {
            setLoadingDiagnoses(true)
            dispatch(fetchDiagnoses())
                .catch((error) => {
                    console.log("error fetching diagnoses", error)
                })
                .finally(() => {
                    setLoadingDiagnoses(false)
                })
        }
    }, [diagnoses, loadingDiagnoses, setLoadingDiagnoses, dispatch])

    useEffect(() => {
        if (appointments == null && !loadingAppointments) {
            setLoadingAppointments(true)
            dispatch(fetchAppointments())
                .catch((error) => {
                    console.log("error fetching appointments", error)
                })
                .finally(() => {
                    setLoadingAppointments(false)
                })
        }
    }, [appointments, loadingAppointments, setLoadingAppointments, dispatch])

    const renderContent = () => {
        if (!diagnoses || !appointments) {
            return null
        } else if (diagnoses.length > 0 || appointments.length > 0) {
            return (
                <>
                    <LeftBottomAppointmentsTable appointments={appointments}/>
                    <LeftBottomDiagnosisTable diagnoses={diagnoses} size={"small"}/>
                </>
            )
        } else {
            return <LeftBottomOnboardingContent/>
        }
    }

    return renderContent()
}

export default LeftBottomContent