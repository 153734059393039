import styled from "styled-components";
import {Card} from "../../../Shared/Div/Card";

export const TableCardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`

export const CardBig = styled(Card)<{ $height: string }>`
  position: relative;
  width: 100%;
  height: ${props => props.$height};
  flex-shrink: 0;
  overflow: hidden;
  
  @media (max-width: 599px) {
    height: auto;
    margin-top: 16px;
  }

  @media (min-width: 600px) {
  }
`

export const ChildAvatarContainer = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  overflow: hidden;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ChildAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const HeaderCell = styled.div`
  width: 33%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
`

export const HeaderTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  padding: 8px 16px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray300};

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`

export const Cell = styled.div`
  width: 33%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 8px;
`

export const IcChild = styled.img`
  background-color: ${props => props.theme.color.gray300};
  
  @media (max-width: 599px) {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 36px;
  }

  @media (min-width: 600px) {
    padding: 10px;
    width: 26px;
    height: 26px;
    border-radius: 23px;
  }
`

export const IcChildImage = styled.img`
  background-color: ${props => props.theme.color.gray300};
 

  @media (max-width: 599px) {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 36px;
  }

  @media (min-width: 600px) {
    width: 26px;
    height: 26px;
    border-radius: 23px;
  }
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
`

export const CellText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const TableCardRows = styled.div<{ $height: string }>`
  display: flex;
  flex-direction: column;
  height: ${props => props.$height};
  padding-top: 16px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* For Webkit browsers */
  }

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`

export const Headline = styled.h4`
  @media (max-width: 599px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
  }

  @media (min-width: 600px) {
  }
`

export const OkBubble = styled.div`
  display: flex;
  height: 24px;
  padding: 0 8px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${props => props.theme.color.success100}; //var(--semantics-success-success-100, #E9F9E9);
`

export const ResultsCell = styled(Cell)`
  justify-content: space-between;
  cursor: pointer;
`