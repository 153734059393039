import React, {useState} from "react";
import LoginForm from "../Authentication/Login/LoginForm";
import RegisterForm from "../Authentication/Register/RegisterForm";
import RecoverForm from "../Authentication/RecoverPassword/RecoverForm";
import {useNavigate} from "react-router-dom";
import RegisterSuccessForm from "../Authentication/Register/RegisterSuccessForm";
import RecoverSuccessForm from "../Authentication/RecoverPassword/RecoverSuccessForm";
import {ContentCard} from "./AuthPage.styles";

interface Credentials {
    email: string,
    password: string,
}

const AuthPage: React.FC = () => {

    const [selectedAuthPage, setSelectedAuthPage] = useState<"RECOVER_SUCCESS" | "REGISTER_COMPLETE" | "LOGIN" | "REGISTER" | "RECOVER">("REGISTER")
    const [registrationCredentials, setRegistrationCredentials] = useState<Credentials | null>(null)
    const navigate = useNavigate()

    const renderLoginForm = () => {
        switch (selectedAuthPage) {
            case "LOGIN":
                return (
                    <ContentCard>
                        <LoginForm
                            navigateRegister={() => setSelectedAuthPage("REGISTER")}
                            navigateToRecover={() => setSelectedAuthPage("RECOVER")}
                        />
                    </ContentCard>
                )
            case "RECOVER":
                return (
                    <ContentCard>
                        <RecoverForm
                            navigateLogin={() => setSelectedAuthPage("LOGIN")}
                            navigateSuccess={() => setSelectedAuthPage("RECOVER_SUCCESS")}/>
                    </ContentCard>
                )
            case "REGISTER":
                return (
                    <ContentCard>
                        <RegisterForm
                            navigateLogin={() => setSelectedAuthPage("LOGIN")}
                            onRegisterComplete={(email, password) => {
                                setRegistrationCredentials({email, password})
                                setSelectedAuthPage("REGISTER_COMPLETE")
                            }}/>
                    </ContentCard>
                )
            case "REGISTER_COMPLETE":
                return (
                    <ContentCard>
                        <RegisterSuccessForm
                            email={registrationCredentials?.email || null}
                            password={registrationCredentials?.password || null}
                            navigateBack={() => navigate("/")}
                        />
                    </ContentCard>
                )
            case "RECOVER_SUCCESS":
                return (
                    <ContentCard>
                        <RecoverSuccessForm
                            email={registrationCredentials?.email || null}
                            navigateLogin={() => setSelectedAuthPage("LOGIN")}
                        />
                    </ContentCard>
                )
        }
    }

    return renderLoginForm()
}

export default AuthPage