import {useTheme} from 'styled-components';
import icnUpRight from '../../../../assets/images/ic_right_up.svg'
import React from "react";
import {ButtonContainer, ButtonIcon, ButtonText} from "./ButtonSecondary.styles";

interface Props {
    title: string
    width?: string
    backgroundColor?: string
    fontColor?: string
    icon?: React.ReactNode
    onClick?: () => void
}

const ButtonSecondary: React.FC<Props> = (
    {
        title,
        width,
        backgroundColor,
        fontColor,
        icon,
        onClick,
    }
) => {
    const theme = useTheme()
    return (
        <ButtonContainer
            onClick={event => {
                if (onClick) {
                    event.preventDefault()
                    onClick()
                }
            }}
            $width={width}
            $backgroundColor={backgroundColor ? backgroundColor : theme.color.pink600}
        >
            <ButtonText
                $color={fontColor ? fontColor : theme.color.gray1000}
            >{title}</ButtonText>
            {icon ? icon : <ButtonIcon src={icnUpRight}/>}
        </ButtonContainer>
    );
}

export default ButtonSecondary