import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid ${props => props.theme.color.violet600};
  color: ${props => props.theme.color.violet600};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  cursor: pointer;
  white-space: nowrap;
`;