import ButtonUpLink from "../../../Shared/Button/ButtonUpLink/ButtonUpLink";
import {Tag} from "../../../Shared/Div/Tag";
import React from "react";
import {
    BlogCard,
    BlogCardMobile,
    BlogHeading,
    BlogImage,
    BlogSection,
    BlogTexts,
    BlogTextsWrapper,
    BlogTextTitle,
    BlogTitle,
    BodySmall,
    TagMobile
} from "./ArticlesSectionComponent.styles";
import {Paths} from "../../../../config/paths";
import {useNavigate} from "react-router-dom";
import {useFetchArticles} from "../../../../state/articles/helpers";
import {Article} from "../../../../state/articles/type";
import {formatDateToDDMMYYYYWithDelimiter, parseDate} from "../../../../config/utils";

const ArticlesSectionComponent = () => {

    const articles = useFetchArticles()
    const navigate = useNavigate()


    const renderMobileArticle = () => {
        if (articles.length === 0) {
            return null
        }
        const article = articles[0]
        return (
            <BlogCardMobile onClick={() => navigate(Paths.ARTICLE_DETAILS_PAGE.replace(":id", article.id.toString()))}>
                <BlogImage src={process.env.REACT_APP_API_BASE_URL + article.image}/>
                <BlogTextsWrapper>
                    <BlogTexts>
                        {article.tags.map((tag, index) => (<TagMobile key={index}>{tag}</TagMobile>))}
                        <BlogTextTitle>{article.title}</BlogTextTitle>
                    </BlogTexts>
                    <BodySmall>{formatDateToDDMMYYYYWithDelimiter(parseDate(article.createdAt), "/")}</BodySmall>
                </BlogTextsWrapper>
            </BlogCardMobile>
        )
    }
    const renderArticle = (article: Article, index: number) => (
        <BlogCard key={index}
                  onClick={() => navigate(Paths.ARTICLE_DETAILS_PAGE.replace(":id", article.id.toString()))}>
            <BlogImage src={process.env.REACT_APP_API_BASE_URL + article.image}/>
            <BlogTextsWrapper>
                <BlogTexts>
                    {article.tags.map((tag, index) => (<Tag key={index}>{tag}</Tag>))}
                    <BlogTextTitle>{article.title}</BlogTextTitle>
                </BlogTexts>
                <BodySmall>{formatDateToDDMMYYYYWithDelimiter(parseDate(article.createdAt), "/")}</BodySmall>
            </BlogTextsWrapper>
        </BlogCard>
    );

    return (
        <BlogSection>
            <BlogHeading>
                <BlogTitle>Artykuły</BlogTitle>
                <ButtonUpLink onClick={() => navigate(Paths.ARTICLES_PAGE)}>Przejdź do artykułów</ButtonUpLink>
            </BlogHeading>
            {renderMobileArticle()}
            {articles.slice(0, 3).map((article, index) => renderArticle(article, index))}

        </BlogSection>
    )
}

export default ArticlesSectionComponent