import styled from "styled-components";

export const Background = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.color.beige200};

  @media (max-width: 599px) {
    
  }

  @media (min-width: 600px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  @media (max-width: 599px) {
    margin-bottom: 24px;

  }

  @media (min-width: 600px) {
    width: 910px;
    margin-top: 24px;
    margin-bottom: 24px;

  }
`
