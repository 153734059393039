import api from "../../config/api";
import { Endpoints } from "../../config/endpoints";
import { Specialization } from "./types";

interface FetchSpecializationsStart {
    type: "SPECIALIZATIONS_START"
}

interface FetchSpecializationsSuccess {
    type: "SPECIALIZATIONS_SUCCESS",
    items: Specialization[];
}

interface FetchSpecializationsFailure {
    type: "SPECIALIZATIONS_ERROR"
}

export const fetchSpecializations = () => async (dispatch: any) => {
    dispatch({ type: "SPECIALIZATIONS_START" });
    const endpoint = Endpoints.SPECIALIZATIONS_SEARCH;
    api.get(endpoint).then(response => {
        dispatch({ type: "SPECIALIZATIONS_SUCCESS", items: response.data })
    }).catch(error => {
        dispatch({ type: "SPECIALIZATIONS_ERROR" })
    });
}

export type Action = FetchSpecializationsStart | FetchSpecializationsSuccess | FetchSpecializationsFailure;