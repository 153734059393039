import styled from "styled-components";

export const SearchBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

export const Input = styled.input`
  border: 1px solid #ccc;

  @media (max-width: 599px) {
    min-width: 200px;
    box-sizing: border-box;
    height: 36px;
    padding-left: 12px;
  }

  @media (min-width: 600px) {
    width: 468px;
    height: 25px;
    padding: 8px;
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const TopNavigationWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
`

export const Background = styled.div`
  background: ${props => props.theme.color.gray100};
  display: flex;
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  
  @media (max-width: 599px) {
    
  }

  @media (min-width: 600px) {
    width: 100%;
    min-width: 1200px;
  }
`;

export const TagBubble = styled.p`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.theme.color.violet200};
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  line-height: 20px;
`

export const ArticleImage = styled.img`
  height: 290px;
  width: 100%;
  max-width: 1220px;
  max-height: 724px;
  object-fit: cover;
  background: lightgray 50% / cover no-repeat;
`

export const ArticleBubbles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: 599px) {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  @media (min-width: 600px) {
  }
`
export const BottomWaveContainer = styled.div`
  position: relative;
  align-self: stretch;
  background: ${props => props.theme.color.gray100};
  overflow: hidden;
  height: 160px;
`

export const ArticleDate = styled.p`
  color: ${props => props.theme.color.gray800};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 599px) {
    margin-bottom: 48px;
  }

  @media (min-width: 600px) {
    margin-bottom: 96px;
  }
`

export const CardsContainer = styled.div`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
  }

  @media (min-width: 600px) {
    display: grid;
    width: 100%;
    box-sizing: border-box;
    padding: 0 175px;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }
`;

export const ArticlesTitles = styled.div`
  display: flex;

  @media (max-width: 599px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin: 76px 20px 88px;
  }

  @media (min-width: 600px) {
    padding: 96px 175px;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ArticleTitlesTexts = styled.div`
  display: flex;
  gap: 14px;

  @media (max-width: 599px) {
    flex-direction: row;
  }

  @media (min-width: 600px) {
    flex-direction: column;
  }
`

export const ArticlesTitle = styled.h1`
  text-align: left;
  padding: 0;
  margin: 0;

  @media (max-width: 599px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; 
    letter-spacing: -0.48px;
  }

  @media (min-width: 600px) {
    
  }
`

export const ArticlesSubtitle = styled.h5`
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.4px;

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {

  }
`

export const ArticleItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 599px) {
    gap: 8px;
  }

  @media (min-width: 600px) {
    width: 100%;
    flex-shrink: 0;
    gap: 24px;
  }
`

export const SearchButtonClear = styled.img`
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
`

export const FeaturedArticleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  gap: 24px;

  @media (max-width: 599px) {
    flex-direction: column;
    padding: 0 20px;
  }

  @media (min-width: 600px) {
    padding: 0 175px;
    margin-bottom: 150px;
    width: 100%;
    flex-direction: row;
  }
`

export const FeaturedArticleImage = styled.img`
  align-self: stretch;
  background: lightgray 50% / cover no-repeat;
  object-fit: cover;
  

  @media (max-width: 599px) {
    height: 226px;
    align-self: stretch;
  }

  @media (min-width: 600px) {
    width: 755px;
    height: 454px;
  }
`

export const FeaturedArticleTexts = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media (max-width: 599px) {
    margin-bottom: 48px;
    gap: 8px;
  }

  @media (min-width: 600px) {
    gap: 24px;

  }
`

export const FeaturedArticleBubbles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

export const FeaturedArticleTitle = styled.h3`
  
  @media (max-width: 599px) {
    margin-top: 8px;
  }

  @media (min-width: 600px) {

  }
`

export const FeaturedArticleDate = styled.p`



  @media (max-width: 599px) {
    color: ${props => props.theme.color.gray700};

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  @media (min-width: 600px) {

  }
  
`

export const DesktopContent = styled.div`


  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`