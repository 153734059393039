import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  padding: 50px 40px;
  min-height: 100vh;
`

export const Title = styled.h3`
  font-size: 36px;
`

export const Content = styled.div`
  margin-right: 40px;
`;


export const SectionTitleText = styled.h5`
  color: ${props => props.theme.color.gray1000};
`

export const TitleWrapper = styled.div`
  padding-top: 56px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Card = styled.div`
  padding: 32px;
  background-color: ${props => props.theme.color.gray100};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 40px;
  gap: 24px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const Column1 = styled.div`
  width: 290px;
`

export const Column2 = styled.div`
`

export const HeaderCell = styled.p`
  width: 14%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
`

export const HeaderCellCentered = styled(HeaderCell)`
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Headline = styled.h4`
  margin-bottom: 8px;
`

export const TableCardRows = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding-top: 16px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* For Webkit browsers */
  }
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
`

export const HeaderTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  padding: 8px 16px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray300};
`

export const TableCardContent = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
`

export const CardBig = styled(Card)`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
`

export const CellText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const Cell = styled.div`
  width: 14%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const CellCentered = styled(Cell)`
  align-items: center;
  justify-content: center;
`

export const ClickableCellCentered = styled(CellCentered)`
  cursor: pointer;
`

export const Alpha = styled.div<{ $alpha: number }>`
  opacity: ${props => props.$alpha};
`

export const CellBubble = styled.p<{ $color: string, $textColor: string }>`
  display: flex;
  padding: 4px 8px;
  min-width: 47px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${props => props.$textColor};
  background: ${props => props.$color};
`
