import {styled} from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
`

export const ButtonText = styled.div<{
    $color: string
}>`
  color: ${props => props.$color};
  font-family: ${props => props.theme.font.default};

  font-style: normal;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 1023px) {
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`

export const ButtonIcon = styled.img`
`;
