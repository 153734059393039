import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import icnOpenWhite from '../../../assets/images/ic_right_up_white.svg'
import {
    ButtonsWrapper,
    Container,
    Header,
    LinkButton,
    LoginButton,
    Logo,
    LogoContainer,
    LogoMobile,
    Navbar,
    RegisterButtonText,
    RegisterButtonWrapper
} from "./TopNavigation.styles";
import logo from "../../../assets/images/img_logo_flat.svg"
import {Paths} from "../../../config/paths";
import styled from "styled-components";


const Nav = styled.nav`
  @media (min-width: 1023px) {
    display: none;
  }

  position: relative;
`;

const MenuButton = styled.p`
  cursor: pointer;
  padding-top: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
`;

const MenuContainer = styled.div<{ $isOpen: boolean }>`
  display: ${props => props.$isOpen ? 'block' : 'none'};
  position: absolute;
  width: 200px;
  top: 40px;
  right: -30px;
  z-index: 14;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  // background: ${props => props.theme.gray1000};
  padding: 0 20px;
  align-items: start;
`;

const MenuItem = styled.p`
  display: block;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
`;

const MenuItemStrong = styled.a`
  cursor: pointer;
  display: block;
  padding: 10px;
  text-decoration: none;
`;

export const TopNavigation: React.FC<{
    onAiDiagnosisClicked: () => void,
    onDoctorsClicked: () => void,
    onFaqClicked: () => void,
    onBlogClicked: () => void,
}> = (
    {
        onAiDiagnosisClicked,
        onDoctorsClicked,
        onFaqClicked,
        onBlogClicked,
    }
) => {
    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate(Paths.LOGIN);
    };

    const navigateToRegister = () => {
        navigate(Paths.REGISTER);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const renderBurgerMenu = () => {
        return (
            <Nav>
                <MenuButton onClick={toggleMenu}>
                    Menu
                </MenuButton>
                <MenuContainer $isOpen={isOpen}>
                    <Menu>
                        <MenuItem onClick={onAiDiagnosisClicked}>Diagnoza AI</MenuItem>
                        <MenuItem onClick={onDoctorsClicked}>Lekarze</MenuItem>
                        <MenuItem onClick={onFaqClicked}>FAQ</MenuItem>
                        <MenuItem onClick={onBlogClicked}>Artykuły</MenuItem>
                        <MenuItem onClick={onBlogClicked}>Artykuły</MenuItem>
                        <MenuItemStrong onClick={navigateToLogin}>Zaloguj się</MenuItemStrong>
                        <MenuItemStrong onClick={navigateToRegister}>Dołącz do nas</MenuItemStrong>
                    </Menu>
                </MenuContainer>
            </Nav>
        )
    };

    function renderHorizontalMenu() {
        return <Navbar>
            <LinkButton onClick={onAiDiagnosisClicked}>Diagnoza AI</LinkButton>
            <LinkButton onClick={onDoctorsClicked}>Lekarze</LinkButton>
            <LinkButton onClick={onFaqClicked}>FAQ</LinkButton>
            <LinkButton onClick={onBlogClicked}>Artykuły</LinkButton>
            <ButtonsWrapper>
                <LoginButton onClick={navigateToLogin}>Zaloguj się</LoginButton>
                <RegisterButtonWrapper onClick={navigateToRegister}>
                    <RegisterButtonText>Dołącz do nas</RegisterButtonText>
                    <img alt="open" src={icnOpenWhite}/>
                </RegisterButtonWrapper>
            </ButtonsWrapper>
        </Navbar>;
    }

    function renderMenu() {
        return <>
            {renderBurgerMenu()}
            {renderHorizontalMenu()}
        </>;
    }

    return (
        <Header>
            <Container>
                <LogoContainer>
                    <Logo src={logo} onClick={() => navigate(Paths.HOME)}/>
                    <LogoMobile onClick={() => navigate(Paths.HOME)}>HappyKiddo</LogoMobile>
                </LogoContainer>
                {renderMenu()}
            </Container>
        </Header>
    );
};