import styled from "styled-components";

export const DoctorDetailsHeadline = styled.div`

  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.28px;

    height: 25px;
  }

  @media (min-width: 600px) {
    

  }

`

export const MobileDivider = styled.div`
  height: 1px;
  background: ${props => props.theme.color.gray500};
  margin: 4px 0;
`
export const DoctorDetailsSubtitle = styled.div`

  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
  }

  @media (min-width: 600px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`

export const DoctorDetailsDate = styled.div`

  @media (max-width: 599px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
  }

  @media (min-width: 600px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`

export const CalendarRow = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 599px) {
    align-items: center;
    gap: 8px;
  }

  @media (min-width: 600px) {
    gap: 10px;
  }
`

export const DoctorDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  
  @media (max-width: 599px) {

  }

  @media (min-width: 600px) {
    align-items: start;
    width: 30%;
  }
`

export const Star = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 11px;
`

export const RatingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  align-self: flex-end;
  margin-top: auto;
`

export const Rating = styled.p`
  line-height: normal;
`
export const DoctorSpecialization = styled.p`
  color: ${props => props.theme.color.gray800};
  font-size: 14px;
  line-height: 150%; /* 21px */
`
export const DoctorSeparator = styled.div`
  
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    background: ${props => props.theme.color.gray500};
    width: 1px;
    align-self: stretch;
  }
`

export const DoctorName = styled.h5`
  
  @media (max-width: 599px) {
    
  }

  @media (min-width: 600px) {
    font-weight: 600;
    
  }
`

export const DoctorLeftSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    align-items: center;
  }

`

export const DoctorInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const DoctorImage = styled.img`
  border-radius: 8px;
  background-color: lightgray;
  
  @media (max-width: 599px) {
    width: 86px;
    height: 86px;
  }

  @media (min-width: 600px) {
    width: 106px;
    height: 106px;
  }
`

export const DoctorCard = styled.div`
  display: flex;
  padding: 24px;
  gap: 12px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray100};

  @media (max-width: 599px) {
    flex-direction: column;
    margin: 100px 20px 0;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: start;
    align-items: center;

  }
`

export const AboutDoctor = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 599px) {

  }

  @media (min-width: 600px) {
    width: 40%;

  }
`

export const PriceDesktop = styled.p`

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    margin-left: auto;
  }
`

export const PriceMobile = styled.p`

  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    display: none;
  }
`
