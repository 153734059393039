import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../store";
import {fetchArticles} from "./actions";

export const useFetchArticles = () => {
    const articles = useSelector((appState: AppState) => appState.articles.articles)
    const [loading, setLoading] = useState(false)
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!loading && !articles) {
            dispatch(fetchArticles())
                .then(() => {
                })
                .catch(e => console.log("error fetching articles", e))
                .finally(() => setLoading(false))
        }
    }, [articles, loading]);

    return articles || []
}