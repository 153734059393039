import styled from "styled-components";
import {Card} from "../Shared/Div/Card";

export const EventName = styled.li`
  grid-column: 1;
  color: ${props => props.theme.color.gray1000};

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 8px;
`

export const RowSeparator = styled.div`
  grid-column: 1 / 16;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.color.gray500};
  }

  &:last-child:after {
    background-color: transparent; /* No border for the last element */
  }
`

export const Footnote = styled.p`
  color: ${props => props.theme.color.gray1000};
  margin: 24px 0;
  width: 400px;
`


export const Table = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: auto repeat(15, 1fr);
`;

export const TH = styled.div`
  text-align: center;
  white-space: pre-line;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

export const TH1 = styled(TH)`
  width: 400px;

  @media (max-width: 599px) {
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  @media (min-width: 600px) {
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;

export const EventBubble = styled.div<{ $startAge: number; $endAge: number, $color: string }>`
  grid-column: ${({$startAge, $endAge}) => `${$startAge + 1} / ${$endAge + 2}`};
  background-color: ${props => props.$color};
  border-radius: 12px;
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  @media (max-width: 599px) {
    gap: 8px;
    align-items: center;
  }

  @media (min-width: 600px) {
    gap: 16px;
  }
`

export const LegendBubble = styled.div<{ $color: string }>`
  align-self: stretch;
  border-radius: 16px;
  background: ${props => props.$color};

  @media (max-width: 599px) {
    width: 32px;
  }

  @media (min-width: 600px) {
    width: 56px;
  }
`

export const LegendTitle = styled.h5`

  @media (max-width: 599px) {
    font-size: 12px;
  }

  @media (min-width: 600px) {
  }
`
export const VaccinationsCard = styled(Card)`
  
  @media (max-width: 599px) {
    padding: 20px;
    overflow: scroll;
  }

  @media (min-width: 600px) {
    padding: 32px;
  }
`