import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  min-height: 100vh;
  justify-content: center;
`;
export const Headline = styled.div`
  margin-top: 56px;
  margin-left: 40px;
  margin-bottom: 40px;
`
export const Title = styled.h3`
`

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Card = styled.div`
  width: 400px;
  display: flex;
  padding: 40px 56px 56px 56px;
  flex-direction: column;
  gap: 40px;
  border-radius: 32px;
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 18px 40px 0 rgba(23, 15, 87, 0.04), 0 73px 73px 0 rgba(23, 15, 87, 0.04), 0 164px 98px 0 rgba(23, 15, 87, 0.02), 0 292px 117px 0 rgba(23, 15, 87, 0.01), 0 456px 128px 0 rgba(23, 15, 87, 0.00);
`

export const CardTitle = styled.h4`
  color: ${props => props.theme.color.gray1000};
`

export const CardHeadline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 41px;
`

export const PeselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`