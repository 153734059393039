import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {AppState} from '../../../state/store';
import {useSelector} from 'react-redux';
import IcPersonSmall from "../../Shared/Icons/IcPersonSmall";
import Tabs from "./Tabs/Tabs";
import PersonalDetailsTab from "./Tabs/PersonalDetailsTab/PersonalDetailsTab";
import {daysUntilDueDate, getCurrentDayOfPregnancy, parseDate} from "../../../config/utils";
import {
    ChildImageContainer,
    PregnancyInfo,
    ChildInfoAge,
    ChildInfoColumn,
    ChildInfoName,
    Container,
    Content,
    Title
} from "./PregnancyDetails.styles";
import NotImplementedTab from "./Tabs/NotImplementedTab/NotImplementedTab";

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}

const PregnancyDetails: React.FC = () => {
    const tabs: string[] = [
        'Dane osobowe',
        // 'Dokumenty medyczne',
        // 'Szczepienia',
        // 'Leki',
    ]
    const [activeTab, setActiveTab] = useState(0);
    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);
    const pregnancy = useSelector((state: AppState) => state.pregnancies.pregnancies?.find((pregnancy) => pregnancy.id === id));
    const getDueDate = () => {
        if (!pregnancy?.due_date) {
            return null
        }
        return parseDate(pregnancy.due_date)
    }

    function renderContent() {
        if (!pregnancy) {
            return null
        }
        switch (activeTab) {
            case 0:
                return (<PersonalDetailsTab pregnancy={pregnancy}/>)
            default:
                return (<NotImplementedTab title="Brak danych"/>)
        }
    }

    return (
        <Container>
            <Title>Profil ciąży</Title>
            <PregnancyInfo>
                <ChildImageContainer>
                    <IcPersonSmall/>
                </ChildImageContainer>
                <ChildInfoColumn>
                    <ChildInfoName>{getCurrentDayOfPregnancy(getDueDate())} dzień ciąży</ChildInfoName>
                    <ChildInfoAge>pozostało {daysUntilDueDate(getDueDate())} dni</ChildInfoAge>
                </ChildInfoColumn>
            </PregnancyInfo>
            <Tabs
                tabs={tabs}
                activeTab={activeTab}
                onTabClick={setActiveTab}
            />
            <Content>
                {renderContent()}
            </Content>
        </Container>
    );
}

export default PregnancyDetails;