import api from "../../config/api";
import {Endpoints} from "../../config/endpoints";
import {Child} from "./types";
import {formatDateApi} from "../../config/utils";

interface FetchChildrenSuccess {
    type: "CHILDREN_SUCCESS",
    data: Child[],
}

export const deleteChild = (id: number) => async (dispatch: any) => {
    try {
        const response1 = await api.delete(Endpoints.CHILDREN_DELETE + "?id=" + id)
        if (response1.status !== 200) {
            return Promise.reject()
        }
        const response2 = await api.get(Endpoints.CHILDREN_GET);
        dispatch({type: "CHILDREN_SUCCESS", data: response2.data})
        return Promise.resolve(response2.data)
    } catch (error) {
        return Promise.reject(error)
    }
};

export const addPregnancy = (week: number, day: number, dueDate: Date, sex: string | null) => async (dispatch: any) => {
    try {
        const url = Endpoints.PREGNANCY_ADD

        const formData = new FormData();
        formData.append('week', week.toString());
        formData.append('day', day.toString());
        formData.append('due_date', formatDateApi(dueDate));
        if (sex) {
            formData.append('sex', sex);
        }

        const response1 = await api.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response1.status !== 200) {
            dispatch({type: 'CHILDREN_ERROR'})
            return Promise.reject()
        }
        const response2 = await api.get(Endpoints.CHILDREN_GET)
        dispatch({type: "CHILDREN_SUCCESS", data: response2.data})
        return Promise.resolve(response2.data)
    } catch (error) {
        dispatch({type: 'CHILDREN_ERROR'})
        return Promise.reject(error)
    }
};


export const editChild = (formData: FormData) => async (dispatch: any) => {
    try {
        const url = Endpoints.CHILDREN_EDIT

        const response1 = await api.put(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response1.status !== 200) {
            return Promise.reject()
        }
        const response2 = await api.get(Endpoints.CHILDREN_GET)
        dispatch({type: "CHILDREN_SUCCESS", data: response2.data})
        return Promise.resolve(response2.data)
    } catch (error) {
        return Promise.reject(error)
    }
};

export const addChild = (name: string, pesel: string | null, dob: Date, sex: string) => async (dispatch: any) => {
    try {
        const url = Endpoints.CHILDREN_ADD

        const formData = new FormData();
        formData.append('name', name);
        if (pesel) {
            formData.append('pesel', pesel);
        }
        formData.append('sex', sex);
        formData.append('dob', formatDateApi(dob));

        const response1 = await api.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response1.status !== 200) {
            return Promise.reject()
        }
        const response2 = await api.get(Endpoints.CHILDREN_GET)
        dispatch({type: "CHILDREN_SUCCESS", data: response2.data})
        return Promise.resolve(response2.data)
    } catch (error) {
        return Promise.reject(error)
    }
};

export const fetchChildren = () => async (dispatch: any): Promise<Awaited<boolean>> => {
    try {
        const response = await api.get(Endpoints.CHILDREN_GET)
        dispatch({type: "CHILDREN_SUCCESS", data: response.data})
        return Promise.resolve(true)
    } catch (e) {
        return Promise.resolve(false)
    }
}

export type Action = FetchChildrenSuccess