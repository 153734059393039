import {Action} from "./actions";
import {PatientState} from "./types";
import {ProfileAction} from "../profile/actions";


const initialState: PatientState = {
    patients: null,
};

export const patientsReducer = (state = initialState, action: Action | ProfileAction): PatientState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "FETCH_PATIENTS_SUCCESS":
            return {
                ...state,
                patients: action.data || [],
            };
        default:
            return state;
    }
};
