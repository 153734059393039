import React, {useState} from "react";
import Tabs from "../Doctors/DoctorsDetails/Tabs/Tabs";
import AccountDetails from "./PersonalDetailsTab";
import NotImplementedTab from "../Doctors/DoctorsDetails/Tabs/NotImplementedTab/NotImplementedTab";
import NotificationsTab from "./NotificationsTab";
import {Content, HelpContainer, Title} from "./Settings.styles";
import styled from "styled-components";


export const ButtonTab = styled.button<{ $isActive: boolean }>`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: ${props => props.$isActive ? props.theme.color.violet400 : props.theme.color.violet200};
  font-size: 14px;
  font-style: normal;
  font-weight: ${props => props.$isActive ? 600 : 400};
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
`

export const MobileTabs = styled.div`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: row;
    gap: 18px;
    margin-bottom: 24px;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

const Settings: React.FC = () => {
    const tabs: string[] = [
        'Szczegóły konta',
        // 'Subskrypcje',
        'Powiadomienia'
    ]

    const [activeTab, setActiveTab] = useState(0);

    function renderTabContent() {
        switch (activeTab) {
            case 0:
                return (<AccountDetails/>)
            case 2:
                return (<NotImplementedTab title="Brak subskrypcji"/>)
            case 1:
                return (<NotificationsTab/>)
            default:
                return null
        }
    }

    return (
        <HelpContainer>
            <Title>Ustawienia konta</Title>
            <MobileTabs>
                {tabs.map((tab, index) => (
                    <ButtonTab
                        key={index}
                        $isActive={activeTab === index}
                        onClick={() => setActiveTab(index)}
                    >{tab}</ButtonTab>))}
            </MobileTabs>
            <Tabs
                tabs={tabs}
                activeTab={activeTab}
                onTabClick={setActiveTab}
            />
            <Content>
                {renderTabContent()}
            </Content>
        </HelpContainer>
    )
}

export default Settings