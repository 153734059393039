import React from 'react';

const IcUpRight: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="arrow-right-up">
            <path id="vector"
                  d="M7.80754 6.54919C7.46483 6.53827 7.20934 6.80806 7.23692 7.15172C7.26451 7.49538 7.56473 7.78277 7.90744 7.79369L15.0507 8.02092L6.90039 16.1712C6.67127 16.4004 6.69335 16.7939 6.94969 17.0503C7.20604 17.3066 7.59961 17.3287 7.82873 17.0996L15.9791 8.9492L16.2064 16.0926C16.2173 16.4353 16.5047 16.7356 16.8484 16.7632C17.192 16.7907 17.4618 16.5353 17.4509 16.1926L17.1812 7.71518C17.175 7.52217 17.1033 7.33979 16.9884 7.19063C16.9645 7.15664 16.9372 7.12403 16.9066 7.09339C16.8759 7.06276 16.8433 7.03539 16.8092 7.01146C16.6601 6.89666 16.4778 6.82504 16.2849 6.8189L7.80754 6.54919Z"
                  fill="#0F0F0F"/>
        </g>
    </svg>

);

export default IcUpRight;
