import {DoctorDiagnosis} from "./type";
import {Endpoints} from "../../config/endpoints";
import api from "../../config/api";

interface FetchDiagnosisSuccess {
    type: "DOCTOR_DIAGNOSIS_SUCCESS",
    data: DoctorDiagnosis[];
}

export const fetchDoctorDiagnoses = () => async (dispatch: any) => {
    try {
        const url = Endpoints.DOCTOR_DIAGNOSIS_GET_ALL
        const response = await api.get(url)
        if (response.status !== 200) {
            return Promise.reject()
        }
        dispatch({type: "DOCTOR_DIAGNOSIS_SUCCESS", data: response.data})
        return Promise.resolve()
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateDoctorDiagnosis = (formData: FormData) => async (dispatch: any) => {
    try {
        const url = Endpoints.DOCTOR_DIAGNOSIS_UPDATE
        const response1 = await api.put(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response1.status !== 200) {
            return Promise.reject()
        }
        const url2 = Endpoints.DOCTOR_DIAGNOSIS_GET_ALL
        const response2 = await api.get(url2)
        if (response2.status !== 200) {
            return Promise.reject()
        }
        dispatch({type: "DOCTOR_DIAGNOSIS_SUCCESS", data: response2.data})
        return Promise.resolve()
    } catch (error) {
        return Promise.reject(error)
    }
};

export const createDoctorDiagnosis = (formData: FormData) => async (dispatch: any) => {
    try {
        const url = Endpoints.DOCTOR_DIAGNOSIS_UPDATE
        const response1 = await api.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        if (response1.status !== 200) {
            return Promise.reject()
        }
        const url2 = Endpoints.DOCTOR_DIAGNOSIS_GET_ALL
        const response2 = await api.get(url2)
        if (response2.status !== 200) {
            return Promise.reject()
        }
        dispatch({type: "DOCTOR_DIAGNOSIS_SUCCESS", data: response2.data})
        return Promise.resolve()
    } catch (error) {
        return Promise.reject(error)
    }
};

export type Action = FetchDiagnosisSuccess;