import React from "react";
import icMail from "../../assets/images/ic_mail.svg"
import icPhone from "../../assets/images/ic_phone.svg"
import switchOn from "../../assets/images/img_switch_on.svg"
import switchOff from "../../assets/images/img_switch_off.svg"
import {
    Container,
    Section,
    SectionTitle,
    SectionTitleText,
    TabWrapper,
    TitleSection
} from "./PersonalDetailsTab.styles";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../../state/store";
import {updateSettings} from "../../state/settings/actions";
import {CellSubtitle, CellTitle, ContentColumn, ContentColumnCell1, ContentRow} from "./NotificationsTab.styles";


const NotificationsTab: React.FC = () => {

    const settings = useSelector((state: AppState) => state.settings.settings)

    const dispatch: AppDispatch = useDispatch()


    function renderSwitch(on: boolean | null, onClick: () => void) {
        if (on) {
            return (<img alt="ON" src={switchOn} onClick={onClick}/>)
        } else {
            return (<img alt="ON" src={switchOff} onClick={onClick}/>)
        }
    }

    const renderSmsNotifications = () => {
        return <Section>
            <TitleSection>
                <SectionTitle>
                    <img alt="Pomiary" src={icPhone}/>
                    <SectionTitleText>Powiadomienia SMS</SectionTitleText>
                </SectionTitle>
            </TitleSection>
            <ContentRow>
                <ContentColumn>
                    <ContentColumnCell1>
                        <CellTitle>Potwierdzenie wizyty</CellTitle>
                        <CellSubtitle>Dodaj numer telefonu, żeby otrzymywać potwierdzenia wizyt.</CellSubtitle>
                    </ContentColumnCell1>
                    {renderSwitch(settings?.smsConfirmations || null, () => {
                        dispatch(updateSettings({smsConfirmations: !settings?.smsConfirmations}))
                            .catch(error => {
                                console.log("error updating settings", error)
                            })
                    })}
                </ContentColumn>

                <ContentColumn>
                    <ContentColumnCell1>
                        <CellTitle>Przypomnienie o wizycie</CellTitle>
                        <CellSubtitle>Dodaj numer telefonu, żeby otrzymywać przypomnienia o
                            wizytach.</CellSubtitle>
                    </ContentColumnCell1>
                    {renderSwitch(settings?.smsReminders || null, () => {
                        dispatch(updateSettings({smsReminders: !settings?.smsReminders}))
                            .catch(error => {
                                console.log("error updating settings", error)
                            })
                    })}
                </ContentColumn>

            </ContentRow>
        </Section>;
    }

    return (
        <TabWrapper>
            <Container>
                <Section>
                    <TitleSection>
                        <SectionTitle>
                            <img alt="O mnie" src={icMail}/>
                            <SectionTitleText>Powiadomienia email</SectionTitleText>
                        </SectionTitle>
                    </TitleSection>
                    <ContentRow>
                        <ContentColumn>
                            <ContentColumnCell1>
                                <CellTitle>Potwierdzenie wizyty</CellTitle>
                                <CellSubtitle>Po umówieniu wizyty otrzymasz wiadomość ze wszystkimi
                                    szczegółami.</CellSubtitle>
                            </ContentColumnCell1>
                            {renderSwitch(settings?.emailConfirmations || null, () => {
                                dispatch(updateSettings({emailConfirmations: !settings?.emailConfirmations}))
                                    .catch(error => {
                                        console.log("error updating settings", error)
                                    })
                            })}
                        </ContentColumn>
                        <ContentColumn>
                            <ContentColumnCell1>
                                <CellTitle>Przypomnienie o wizycie</CellTitle>
                                <CellSubtitle>Dostaniesz od nas email 24h przed planowaną wizytą.</CellSubtitle>
                            </ContentColumnCell1>
                            {renderSwitch(settings?.emailReminders || null, () => {
                                dispatch(updateSettings({emailReminders: !settings?.emailReminders}))
                                    .catch(error => {
                                        console.log("error updating settings", error)
                                    })
                            })}
                        </ContentColumn>
                        <ContentColumn>
                            <ContentColumnCell1>
                                <CellTitle>Planowane szczepienia</CellTitle>
                                <CellSubtitle>Powiadomienia o zaplnowanych szczepieniach.</CellSubtitle>
                            </ContentColumnCell1>
                            {renderSwitch(settings?.emailPlanned || null, () => {
                                dispatch(updateSettings({emailPlanned: !settings?.emailPlanned}))
                                    .catch(error => {
                                        console.log("error updating settings", error)
                                    })
                            })}
                        </ContentColumn>
                    </ContentRow>
                </Section>
                {/*TODO: Uncomment when supporting SMS messaging*/}
                {/*{renderSmsNotifications()}*/}
            </Container>
        </TabWrapper>
    )
}

export default NotificationsTab