import React, {useState} from "react";
import Tabs from "./Tabs";
import DoctorNotifications from "./DoctorNotifications";
import DoctorMessages from "./DoctorMessages";
import DoctorInfoWidget from "../Shared/DoctorInfoWidget/DoctorInfoWidget";
import {useFetchUserDoctor} from "../../state/user/helpers";
import {Content, HelpContainer, Title, TitleWrapper} from "./DoctorSettings.styles";

const DoctorSettings: React.FC = () => {
    const tabs: string[] = [
        'Wiadomości',
        'Powiadomienia',
    ]

    const [activeTab, setActiveTab] = useState(0);
    const doctor = useFetchUserDoctor();

    function renderTabContent() {
        switch (activeTab) {
            case 0:
                return (<DoctorMessages/>)
            case 1:
                return (<DoctorNotifications/>)
            default:
                return null
        }
    }

    return (
        <HelpContainer>
            <TitleWrapper>
                <Title>Ustawienia konta</Title>
                <DoctorInfoWidget doctor={doctor}/>
            </TitleWrapper>
            <Tabs
                tabs={tabs}
                activeTab={activeTab}
                onTabClick={setActiveTab}
            />
            <Content>
                {renderTabContent()}
            </Content>
        </HelpContainer>
    )
}

export default DoctorSettings