import React, {useEffect, useState} from 'react';
import CalendarHeader from "../CalendarHeader/CalendarHeader";
import {Container, TimeGrid, TimeSlot} from "./TimeSlotPicker.styles";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../../state/store";
import api from "../../../../../config/api";
import {Endpoints} from "../../../../../config/endpoints";

interface Props {
    currentDate: Date,
    selectedTime: Date | null,
    onTimeSlotClick: (time: Date) => void;
    onDateClick: (date: Date) => void;
    disabled: Date[],
}

interface TimeSlot {
    text: string
    selected: boolean
    disabled: boolean
}

const fetchTimeSlots = (year: number, month: number, day: number) => async () => {
    try {
        const endpoint = Endpoints.DOCTORS_SLOTS + "?year=" + year + "&month=" + month + "&day=" + day
        const response = await api.get(endpoint)
        if (response.status !== 200) {
            return Promise.reject()
        }
        const dateStrings: Date[] = response.data
        const dates: Date[] = dateStrings.map(dateString => new Date(dateString))
        return Promise.resolve(dates)
    } catch (exception: any) {
        return Promise.reject(exception)
    }
}

const useFetchTimeSlots = (year: number, month: number, day: number) => {
    const [timeSlots, setTimeSlots] = useState<Date[] | null>(null)
    const [loading, setLoading] = useState(false)
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!timeSlots && !loading) {
            setLoading(true)
            dispatch(fetchTimeSlots(year, month, day))
                .then(items => {
                    setTimeSlots(items)
                })
                .catch(() => {

                })
                .finally(() => setLoading(false))
        }

    }, [timeSlots, loading]);

    return timeSlots || []
}


const TimeSlotPicker: React.FC<Props> = (
    {
        currentDate,
        selectedTime,
        onTimeSlotClick,
        onDateClick,
        disabled
    }
) => {

    const rawTimeSlots = useFetchTimeSlots(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())

    const isTimeDisabled = (hour: number, minute: number): boolean => {
        const now = new Date();
        const twoHoursLater = new Date(now.getTime() + 2 * 60 * 60 * 1000);
        if (now?.getFullYear() === currentDate.getFullYear() && now?.getMonth() === currentDate.getMonth() && now?.getDate() === currentDate.getDate()) {
            return hour < twoHoursLater.getHours()
        }

        return disabled.some(nextDisabled => {

            if (nextDisabled?.getFullYear() !== currentDate.getFullYear()) {
                return false
            }
            if (nextDisabled?.getMonth() !== currentDate.getMonth()) {
                return false
            }
            if (nextDisabled?.getDate() !== currentDate.getDate()) {
                return false
            }

            if (hour !== nextDisabled.getHours()) {
                return false
            }
            return minute === nextDisabled.getMinutes();
        })
    }
    const isTimeSelected = (hour: number, minute: number): boolean => {
        if (selectedTime?.getFullYear() !== currentDate.getFullYear()) {
            return false
        }
        if (selectedTime?.getMonth() !== currentDate.getMonth()) {
            return false
        }
        if (selectedTime?.getDate() !== currentDate.getDate()) {
            return false
        }
        if (hour !== selectedTime.getHours()) {
            return false
        }
        return minute === selectedTime.getMinutes();
    }

    const generateTimeSlots = (): TimeSlot[] => {
        const slots: TimeSlot[] = [];
        for (const slot of rawTimeSlots) {
            const timeSlot = `${slot.getHours().toString().padStart(2, '0')}:${slot.getMinutes().toString().padStart(2, '0')}`
            slots.push({
                text: timeSlot,
                selected: isTimeSelected(slot.getHours(), slot.getMinutes()),
                disabled: isTimeDisabled(slot.getHours(), slot.getMinutes()),
            });
        }
        return slots;
    }

    const timeSlots = generateTimeSlots();

    const previousDay = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - 1);
        if (isPreviousDayDisabled()) {
            return
        }
        onDateClick(newDate);
    }

    const nextDay = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + 1);
        onDateClick(newDate);
    }

    function getHeaderTitle() {
        const day = currentDate.toLocaleString('pl', {day: '2-digit'});
        const monthName = currentDate.toLocaleString('pl', {month: 'short', year: 'numeric'});
        const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
        return `${day} ${capitalizedMonthName}`
    }

    const isPreviousDayDisabled = () => {
        const oneDayBefore = new Date(currentDate);
        oneDayBefore.setDate(currentDate.getDate() - 1);

        const now = new Date();
        oneDayBefore.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);
        return oneDayBefore < now;
    }

    return (
        <Container>
            <CalendarHeader
                title={getHeaderTitle()}
                onPreviousClicked={previousDay}
                onNextClicked={nextDay}
                isBackDisabled={isPreviousDayDisabled()}
            />
            <TimeGrid>
                {timeSlots.map((slot, index) => (
                    <TimeSlot
                        key={index}
                        onClick={() => {
                            if (slot.disabled) {
                                return
                            }
                            const components = slot.text.split(":")
                            const newDate = new Date(
                                currentDate?.getFullYear(),
                                currentDate?.getMonth(),
                                currentDate?.getDate(),
                                Number(components[0]),
                                Number(components[1]),
                            )
                            onTimeSlotClick(newDate)
                        }}
                        $selected={slot.selected}
                        $disabled={slot.disabled}
                    >
                        {slot.text}
                    </TimeSlot>
                ))}
            </TimeGrid>
        </Container>
    );
}

export default TimeSlotPicker;
