import {Action} from "./actions";
import {AiDiagnosisState} from "./types";
import {ProfileAction} from "../profile/actions";

const initialState: AiDiagnosisState = {
    diagnoses: null,
    loading: false,
    error: null,
};

export const aiDiagnosisReducer = (state = initialState, action: Action | ProfileAction): AiDiagnosisState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "DIAGNOSIS_START":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "DIAGNOSIS_SUCCESS":
            return {
                ...state,
                diagnoses: action.data || [],
                loading: false,
                error: null,
            };
        case "DIAGNOSIS_ERROR":
            return {
                ...state,
                loading: false,
                error: "something went wrong"
            };
        default:
            return state;
    }
};
