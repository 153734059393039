import api from "../../config/api";
import {Endpoints} from "../../config/endpoints";
import {Doctor} from "./types";
import {parseDateTime} from "../../config/utils";

interface FetchDoctorsStart {
    type: "DOCTORS_START"
}

interface FetchDoctorsSuccess {
    type: "DOCTORS_SUCCESS",
    data: Doctor[];
}

interface FetchDoctorsFailure {
    type: "DOCTORS_ERROR"
}

export const fetchAllDoctors = () => (fetchDoctors(null, []))

export const fetchDoctors = (query: string | null, specializations: number[]) => async (dispatch: any) => {
    dispatch({type: "DOCTORS_START"})
    api.get(constructURL(Endpoints.DOCTORS_SEARCH, {query, specializations})).then(response => {
        dispatch({type: "DOCTORS_SUCCESS", data: response.data})
    }).catch(() => {
        dispatch({type: "DOCTORS_ERROR"})
    });
}

export const fetchDoctorAppointments = (doctorId: number) => async (_: any) => {
    try {
        const endpoint = Endpoints.DOCTORS_APPOINTMENTS.replace(":doctorId", doctorId.toString())
        const response = await api.get(endpoint)
        if (response.status !== 200) {
            return Promise.reject()
        }
        const strings: string[] = response.data
        const data = strings.map(s => parseDateTime(s)).filter((date): date is Date => date !== null)
        return Promise.resolve(data)
    } catch (exception: any) {
        return Promise.reject(exception)
    }
}

function constructURL(baseURL: string, params: {
    query: string | null;
    specializations: number[];
}): string {
    const queryParams: string[] = []

    if (params.query) {
        queryParams.push(`query=${encodeURIComponent(params.query)}`)
    }

    if (params.specializations && params.specializations.length > 0) {
        queryParams.push(`specializations=${params.specializations.join(',')}`)
    }

    return `${baseURL}${queryParams.length > 0 ? '?' : ''}${queryParams.join('&')}`
}

export type Action = FetchDoctorsStart | FetchDoctorsSuccess | FetchDoctorsFailure