import React from "react";
import {Container, ContentRow, LeftSection, Title} from "./Home.styles";
import LeftBottomContent from "./LeftBottomContent/LeftBottomContent";
import {useFetchUser} from "../../state/user/helpers";
import TopLeftContent from "./TopLeftContent/TopLeftContent";
import RightContent from "./RightContent/RightContent/RightContent";

const Home: React.FC = () => {
    const user = useFetchUser();

    return (
        <Container>
            <Title>Witaj, {user?.email || ""}</Title>
            <ContentRow>
                <LeftSection>
                    <TopLeftContent/>
                    <LeftBottomContent/>
                </LeftSection>
                <RightContent/>
            </ContentRow>
        </Container>
    );
}

export default Home;