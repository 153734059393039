import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  
  @media (max-width: 599px) {
    padding:  80px 16px 20px 16px;
  }

  @media (min-width: 600px) {
    box-sizing: border-box;
    padding: 50px 40px 0 40px;
  }
`

export const Title = styled.h3`

  @media (max-width: 599px) {
    color: ${props => props.theme.color.gray1000};
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;

    margin-bottom: 24px;
  }

  @media (min-width: 600px) {
    margin-bottom: 40px;
    font-size: 36px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ContentRow = styled(Row)`
  
  @media (max-width: 599px) {
    gap: 16px;
    flex-direction: column;
  }

  @media (min-width: 600px) {
    gap: 20px;
  }
`
export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    width: 100%;
  }

  @media (min-width: 600px) {
    gap: 20px;
    
    width: 60%;
  }
`
