import {Action, ArticlesState} from "./type";


const initialState: ArticlesState = {
    articles: null,
};

export const articlesReducer = (state = initialState, action: Action): ArticlesState => {
    switch (action.type) {
        case "ARTICLES_FETCHED":
            return {
                ...state,
                articles: action.articles,
            };
        default:
            return state;
    }
};