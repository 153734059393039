import {User} from "./types";
import {Endpoints} from "../../config/endpoints";
import api from "../../config/api";

interface GetUserSuccess {
    type: "GET_USER_SUCCESS"
    data: User | null | undefined
}

export const fetchUser = () => async (dispatch: any) => {
    try {
        const endpoint = Endpoints.BASE_URL_USERS;
        const response = await api.get(endpoint)
        dispatch({
            type: "GET_USER_SUCCESS",
            data: response.data
        })
        return Promise.resolve()
    } catch (error: any) {
        return Promise.reject()
    }
}

export type UserAction = GetUserSuccess