import React, {useEffect, useRef, useState} from "react";
import icClose from "../../../assets/images/ic_close.svg";
import Popup from "../../Shared/PopUp/PoPup";
import IcPersonSmall from "../../Shared/Icons/IcPersonSmall";
import InputField from "../../Shared/Input/Input";
import {formatDateApi, getPeselBirthDate, isAllDigits, isValidPESEL, parseDate} from "../../../config/utils";
import CheckBox from "../../Shared/Input/CheckBox";
import StyledDatePicker from "../../Shared/Input/StyledDatePicker";
import DropDownInput from "../../Shared/Input/DropDownInput";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import {Child} from "../../../state/children/types";
import LinkButton from "../../Shared/Button/ButtonLink/ButtonLink";
import {editChild} from "../../../state/children/actions";
import {
    ButtonsWrapper,
    ChildImage,
    ChildImageButton,
    ChildImageContainer,
    ChildImageRow,
    PeselWrapper,
    PopupContent,
    TitleWrapper
} from "./EditChildPopUp.styles";


const EditChildPopUp: React.FC<{
    child: Child,
    editing: boolean,
    onClose: () => void
}> = (
    {
        child,
        editing,
        onClose
    }
) => {

    const [sex, setSex] = useState("");
    const [sexError, setSexError] = useState<string | null>(null);
    const [sexCorrect, setSexCorrect] = useState<boolean | null>(null);

    const [validatePeselOnType, setValidatePeselOnType] = useState(false);
    const [peselError, setPeselError] = useState<string | null>(null);
    const [peselCorrect, setPeselCorrect] = useState<boolean | null>(null);
    const [pesel, setPesel] = useState("");
    const [noPesel, setNoPesel] = useState(false);

    const [firstAndLastName, setFirstAndLastName] = useState("");
    const [validateFirstAndLastNameOnType, setValidateFirstAndLastNameOnType] = useState(false);
    const [firstAndLastNameError, setFirstAndLastNameError] = useState<string | null>(null);
    const [firstAndLastNameCorrect, setFirstAndLastNameCorrect] = useState<boolean | null>(null);

    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
    const [dateOfBirthCorrect, setDateOfBirthCorrect] = useState<boolean | null>(null);
    const [dateOfBirthError, setDateOfBirthError] = useState<string | null>(null);

    const dispatch: AppDispatch = useDispatch();

    const [file, setFile] = useState<File | null>(null);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
    const [progress, setProgress] = useState(false);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setFile(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        setSex(child.sex)
        setPesel(child.pesel)
        setNoPesel(child.pesel === "")
        setFirstAndLastName(child.name)
        setDateOfBirth(parseDate(child.dob))
        if (child.image) {
            setImagePreviewUrl(process.env.REACT_APP_API_BASE_URL + child.image)
        }
    }, [child]);

    const handleSubmit = () => {
        const formData = new FormData()
        formData.append('id', child.id.toString())

        if (firstAndLastName !== child.name) {
            formData.append('name', firstAndLastName)
        }
        if (pesel !== child.pesel) {
            formData.append('pesel', pesel)
        }
        if (sex !== child.sex) {
            formData.append('sex', sex)
        }
        if (file) {
            formData.append('image', file)
        }
        if (formatDateApi(dateOfBirth) != child.dob) {
            formData.append('dob', formatDateApi(dateOfBirth));
        }
        setProgress(true)
        dispatch(editChild(formData))
            .then(() => {
                onClose()
            })
            .catch((err) => {
                console.log("error", err)
            })
            .finally(() => {
                setProgress(false)
            })
        ;
    };

    const validatePeselError = (pesel: string) => {
        const isValid = isValidPESEL(pesel);
        setPeselError(!isValid ? "Nieprawidłowy numer PESEL" : null)
        return isValid;
    }

    const validateFirstAndLastNameError = (firstAndLastName: string) => {
        const isValid = firstAndLastName !== "";
        setFirstAndLastNameError(!isValid ? "To pole nie może być puste" : null)
        return isValid;
    }

    const validatePeselCorrect = (pesel: string) => {
        const isValid = isValidPESEL(pesel);
        setPeselCorrect(isValid)
    }

    const validateDateOfBirthCorrect = (date: Date | null) => {
        setDateOfBirthCorrect(date != null)
    }

    const validatefirstAndLastNameCorrect = (firstAndLastName: string) => {
        const isValid = firstAndLastName !== "";
        setFirstAndLastNameCorrect(isValid)
    }

    const validateFields = () => {
        setValidatePeselOnType(true);
        setValidateFirstAndLastNameOnType(true);

        validateFirstAndLastNameError(firstAndLastName);
        validatePeselError(pesel);
        validateDateOfBirthError(dateOfBirth)
        validateSexError(sex)
    }

    const isFormValid = () => {
        return !peselError || !firstAndLastNameError || !dateOfBirthError
    }

    const validateDateOfBirthError = (dateOfBirth: Date | null) => {
        const isValid = dateOfBirth != null;
        setDateOfBirthError(!isValid ? "Nieprawidłowy format daty" : null)
        return isValid;
    }

    const validateSexError = (sex: string) => {
        const isValid = sex === "M" || sex === "F";
        setSexError(!isValid ? "To pole jest wymagane" : null)
        return isValid;
    }

    const validateSexCorrect = (sex: string) => {
        const isValid = sex === "M" || sex === "F";
        setSexCorrect(isValid)
    }

    const openFilePicker = () => {
        fileInputRef.current?.click();
    };

    const renderImage = () => {
        if (imagePreviewUrl) {
            return (
                <ChildImageContainer>
                    <ChildImage src={imagePreviewUrl} alt="Preview"/>
                </ChildImageContainer>
            )
        } else {
            return (
                <ChildImageContainer>
                    <IcPersonSmall/>
                </ChildImageContainer>
            )
        }
    }

    return (
        <Popup visible={editing} onClose={() => {
        }}>
            <PopupContent>
                <TitleWrapper>
                    <div>Dane dziecka</div>
                    <img alt="close" src={icClose} onClick={onClose}/>
                </TitleWrapper>
                <ChildImageRow>
                    {renderImage()}
                    <input
                        type="file"
                        style={{display: 'none'}}
                        name="image"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                    <ChildImageButton onClick={openFilePicker}>
                        Dodaj zdjęcie
                    </ChildImageButton>
                </ChildImageRow>
                <InputField
                    type="text"
                    label="Imię i nazwisko"
                    value={firstAndLastName}
                    correct={firstAndLastNameCorrect}
                    error={firstAndLastNameError}
                    onChange={newValue => {
                        setFirstAndLastName(newValue)
                        if (validateFirstAndLastNameOnType) {
                            validateFirstAndLastNameError(newValue)
                            validatefirstAndLastNameCorrect(newValue)
                        }
                    }}
                    onBlur={() => {
                        setValidateFirstAndLastNameOnType(true);
                        validateFirstAndLastNameError(firstAndLastName);
                        validatefirstAndLastNameCorrect(firstAndLastName)
                    }}
                />
                <PeselWrapper>
                    <InputField
                        type="text"
                        label="PESEL"
                        value={!noPesel ? pesel : ""}
                        correct={peselCorrect}
                        error={peselError}
                        onChange={newValue => {
                            if (newValue === "" || isAllDigits(newValue)) {
                                setPesel(newValue)
                            }
                            if (validatePeselOnType) {
                                validatePeselError(newValue)
                            }
                            validatePeselCorrect(newValue)
                        }}
                        onBlur={() => {
                            setValidatePeselOnType(true);
                            validatePeselError(pesel);
                            const date = getPeselBirthDate(pesel)
                            if (date) {
                                setDateOfBirth(date)
                                validateDateOfBirthCorrect(date)
                            }
                        }}

                        disabled={noPesel}
                    />
                    <CheckBox
                        checked={noPesel}
                        onChange={() => {
                            setNoPesel(!noPesel)
                        }}
                    >Brak peselu</CheckBox>
                </PeselWrapper>
                <StyledDatePicker
                    label="Data urodzenia"
                    value={dateOfBirth}
                    correct={dateOfBirthCorrect}
                    error={dateOfBirthError}
                    onValueChange={(newValue) => {
                        setDateOfBirth(newValue)
                        validateDateOfBirthError(newValue)
                        validateDateOfBirthCorrect(newValue)
                    }}
                    onBlur={() => {
                        validateDateOfBirthError(dateOfBirth)
                    }}
                />
                <DropDownInput
                    options={[
                        {
                            key: "",
                            label: "",
                        },
                        {
                            key: "F",
                            label: "Dziewczynka",
                        },
                        {
                            key: "M",
                            label: "Chłopczyk",
                        },
                    ]}
                    label="Płeć biologiczna"
                    value={sex}
                    onChange={newValue => {
                        setSex(newValue)
                        validateSexError(newValue)
                        validateSexCorrect(newValue)
                    }}
                    correct={sexCorrect}
                    error={sexError}
                    onBlur={() => {
                        validateSexError(sex)
                    }}
                />
                <ButtonsWrapper>
                    <LinkButton
                        onClick={onClose}
                        title="Anuluj"
                    />
                    <ButtonPrimary
                        progress={progress}
                        title="Zapisz"
                        onClick={() => {
                            validateFields()
                            if (isFormValid()) {
                                handleSubmit()
                            }
                        }}/>
                </ButtonsWrapper>
            </PopupContent>
        </Popup>
    );
}

export default EditChildPopUp