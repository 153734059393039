import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  padding: 10px;
  min-height: 100vh;
`;

export const Title = styled.h3`

  @media (max-width: 599px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
  }

  @media (min-width: 600px) {
  }
`

export const CardButton = styled.div`
  display: flex;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 56px;
  background: ${props => props.theme.color.violet500};
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);
`

export const CardButtonText = styled.button`
  color: ${props => props.theme.color.gray100};
  
  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    
  }`

export const Headline = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 599px) {
    margin-top: 76px;
    margin-left: 40px;
    margin-bottom: 40px;
  }

  @media (min-width: 600px) {
    margin-top: 56px;
    margin-left: 20px;
    margin-bottom: 40px;
  }
`

export const ChildrenGrid = styled.div`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  @media (min-width: 600px) {
    margin-left: 40px;
    margin-right: 40px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    flex-wrap: wrap;
  }
  
  
`

export const ChildCard = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 32px;
  background: ${props => props.theme.color.violet600};
  width: 242px;
  height: 329px;
`


export const AddChildIconWrapper = styled.div`
  width: 242px;
  height: 242px;
  border-radius: 24px;
  background-color: ${props => props.theme.color.violet500};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AddChildIcon = styled.img`
  width: 90px;
  height: 90px;
`
