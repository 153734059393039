import icStar from '../../assets/images/ic_star.svg';
import Space from "../Shared/Div/Space";
import React from "react";
import {
    Container,
    Dat,
    DateRow,
    DoctorInfoColumn,
    Image,
    Name,
    Price,
    PriceRow,
    Rating,
    RatingRow,
    Row,
    Specialization,
    Specializations,
    Star,
    When
} from "./DoctorCard.styles";


interface Props {
    image: string
    name: string
    specializations: string[]
    rating?: string | null
    price?: string | null
    when: string
    dates: string[]
    onClick?: () => void
}

const DoctorCard: React.FC<Props> = (
    {
        image,
        name,
        specializations,
        rating,
        price,
        when,
        dates,
        onClick
    }
) => {
    function getRating() {
        if (!rating) return null
        return <RatingRow>
            <Star src={icStar}/>
            <Rating>{rating}</Rating>
        </RatingRow>;
    }

    return (
        <Container onClick={() => {
            if (onClick) {
                onClick()
            }
        }}>
            <Row>
                <Image src={image}/>
                <DoctorInfoColumn>
                    <Name>{name}</Name>
                    <Specializations>
                        {specializations.map((s, i) => (<Specialization key={i}>{s}</Specialization>))}
                    </Specializations>
                    {getRating()}
                </DoctorInfoColumn>
            </Row>
            <Space height='16px'/>
            <PriceRow>
                <Price>Konsultacja pediatryczna</Price>
                <Price>{price}</Price>
            </PriceRow>
            <Space height='8px'/>
            <When>{when}</When>
            <Space height='8px'/>
            <DateRow>
                {dates.slice(0,4).map((date, index) => (<Dat key={index}>{date}</Dat>))}
            </DateRow>
        </Container>
    );
}

export default DoctorCard;