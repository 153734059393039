import BackArrowButtonTitled from "../Shared/Button/BackArrowButtonTitled/BackArrowButtonTitled";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {TopNavigation} from "../Welcome/TopNavigation/TopNavigation";
import Footer from "../Welcome/Footer/Footer";
import {BottomWave} from "../Welcome/Articles/Articles.styles";
import {Paths} from "../../config/paths";
import {
    ArticleSection,
    BackArrowInnerWrapper,
    BackArrowWrapper,
    Background,
    BottomWaveContainer,
    ContentColumn,
    ContentTitle,
} from "./SimpleTextPage.styles";
import {simpleTexts} from "./data";

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}

const SimpleTextPage: React.FC = () => {

    const {id} = useParams<RouteParams>();
    const navigate = useNavigate()
    const content = simpleTexts?.find(el => el.id === id)

    const renderSection = (text: string) => {
        return (
            <ArticleSection>
                <p>{text}</p>
            </ArticleSection>
        )
    }

    const renderSections = () => {
        if (!content) {
            return
        }
        return content.paragraphs.map(section => renderSection(section));
    }

    return (
        <Background>
            <TopNavigation
                onAiDiagnosisClicked={() => navigate(Paths.HOME)}
                onDoctorsClicked={() => navigate(Paths.HOME)}
                onFaqClicked={() => navigate(Paths.HOME)}
                onBlogClicked={() => navigate(Paths.HOME)}
            />
            <BackArrowWrapper>
                <BackArrowInnerWrapper>
                    <BackArrowButtonTitled onBackClicked={() => navigate(-1)}/>
                </BackArrowInnerWrapper>
            </BackArrowWrapper>
            <ContentTitle>{content?.title || ""}</ContentTitle>
            <ContentColumn>{renderSections()}</ContentColumn>
            <BottomWaveContainer>
                <BottomWave/>
            </BottomWaveContainer>
            <Footer
                openPopup={() => navigate(Paths.HOME)}
                onFaqClicked={() => navigate(Paths.HOME)}
            />
        </Background>
    )
}

export default SimpleTextPage