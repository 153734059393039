import React from "react";
import chevronDown from '../../../../assets/images/ic_chevron_down.svg'
import {ButtonText, MoreButton} from "./ExpandCollapseButton.styles";

const ExpandCollapseButton: React.FC<{
    buttonExpanded: boolean,
    expandedText: string,
    collapsedText: string,
    onChanged: (expanded: boolean) => void
}> = ({buttonExpanded, expandedText, collapsedText, onChanged}) => {

    function renderButton() {
        if (buttonExpanded) {
            return (
                <MoreButton onClick={() => onChanged(false)}>
                    <ButtonText>{expandedText}</ButtonText>
                    <img alt="collapse" src={chevronDown} style={{transform: 'rotate(180deg)'}}/>
                </MoreButton>
            )
        } else {
            return (
                <MoreButton onClick={() => onChanged(true)}>
                    <ButtonText>{collapsedText}</ButtonText>
                    <img alt="expand" src={chevronDown}/>
                </MoreButton>
            )
        }
    }

    return renderButton()
}

export default ExpandCollapseButton
