import {useTheme} from 'styled-components';
import React, {useEffect, useRef, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {fetchDoctors} from '../../../state/doctors/actions';
import {AppState} from '../../../state/store';
import {Doctor, DoctorsState} from '../../../state/doctors/types';
import {Specialization, SpecializationsState} from '../../../state/specializations/types';
import {fetchSpecializations} from '../../../state/specializations/actions';
import {useNavigate} from 'react-router-dom';
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import IcSearch from "../../Shared/Icons/IcSearch";
import icClear from "../../../assets/images/ic_close.svg";
import icSearch from "../../../assets/images/ic_search_black.svg";
import IcCross from "../../Shared/Icons/IcCross";
import DoctorCard from "../DoctorCard";
import {formatPrice} from "../../../config/utils";
import icChevronDown from "../../../assets/images/ic_chevron_down_black.svg";
import {
    Bubble,
    BubbleWrapper,
    CardsContainer,
    Container,
    ContentWrapper,
    DesktopSearchWrapper,
    Input,
    InputBubbleWrapper,
    MobileSearchDropdown,
    MobileSearchDropdownText,
    MobileSearchWrapper,
    PopupContent,
    PopupContentText,
    PopupDropdown,
    SearchBoxWrapper,
    SearchButtonIcon,
    SuggestionItem,
    Suggestions,
    SvgFill2,
    Title,
    TitleWrapper
} from "./DoctorsList.styles";
import {Paths} from "../../../config/paths";
import {PopupDim} from "../../Shared/PopUp/PoPup.styles";


interface Props {
    doctors: DoctorsState;
    specializations: SpecializationsState;
    fetchDoctors: (query: string | null, specializations: number[]) => void;
    fetchSpecializations: () => void;
}

const DoctorsList: React.FC<Props> = ({doctors, specializations, fetchDoctors, fetchSpecializations}) => {
    const navigate = useNavigate();
    const theme = useTheme()
    const [query, setQuery] = useState<string>('');
    const [focus, setFocus] = useState<boolean>(false);
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
    const [submittedQuery, setSubmittedQuery] = useState<string>('');
    const [suggestions, setSuggestions] = useState<Specialization[]>([]);
    const [selected, setSelected] = useState<Specialization[]>([]);
    const docs = useSelector(() => doctors.data);
    const mouseDownOnSuggestion = useRef(false);

    const shouldLoad = useSelector(() =>
        !doctors.loading
        && !doctors.error
        && doctors.data === null
        && !specializations.loading
        && !specializations.error
        && specializations.items === null
    );

    const isLoading = useSelector(() => doctors.loading || specializations.loading);

    useEffect(() => {
        if (shouldLoad && !isLoading) {
            fetchDoctors(null, []);
            fetchSpecializations();
        }
    }, [shouldLoad, isLoading, fetchDoctors, fetchSpecializations]);

    const filterDoctors = (doctor: Doctor) => {
        if (submittedQuery === "" && selected.length == 0) {
            return true
        }

        const isQueryMatching = doctor.name.toLowerCase().includes(submittedQuery.toLowerCase())
        const isSpecializationMatching = doctor.specializations.some(s => selected.some(el => el.id === s.id))

        if (submittedQuery !== "" && selected.length > 0) {
            return isQueryMatching && isSpecializationMatching
        }

        if (submittedQuery !== "") {
            return isQueryMatching
        }

        if (selected.length > 0) {
            return isSpecializationMatching
        }

        return true;
    }

    const renderDoctors = () => {
        if (doctors.loading) {
            return (<div>Loading...</div>);
        } else if (doctors.error) {
            return (<div>{doctors.error}</div>);
        } else {
            const doctors = docs?.filter(filterDoctors) || [];
            if (doctors.length === 0) {
                return <></>;
            }
            return doctors.filter(filterDoctors).map((doctor: Doctor, idx) => {
                const rating = doctor.rating
                const ratingTxt = rating ? `${rating.value} (${rating.count} opinie)` : null
                const priceTxt = doctor.price ? formatPrice(doctor.price) : null

                return (<DoctorCard
                        key={idx}
                        image={process.env.REACT_APP_API_BASE_URL + doctor.image}
                        name={doctor.name}
                        specializations={doctor.specializations.map(s => s.name)}
                        rating={ratingTxt}
                        price={priceTxt}
                        when={formatDate()}
                        dates={doctor.availability.slice(0, 5).map(el => el.start)}
                        onClick={() => {
                            navigate(Paths.DOCTORS_DETAILS.replace(":id", doctor.id.toString()))
                        }}
                    />
                )
            })
        }
    }

    const renderSearchIcon = () => {
        if (!query) {
            return <SearchButtonIcon src={icSearch}/>;
        } else {
            return null
        }
    };
    const renderClearButton = () => {
        if (query) {
            return <SearchButtonIcon
                src={icClear}
                onClick={() => {
                    setSubmittedQuery("")
                    setQuery("")
                    setSuggestions([])
                }}
            />;
        } else {
            return null
        }
    };

    const getAllSpecializations = () => specializations.items ? specializations.items : []

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
        setSuggestions(getAllSpecializations().filter(s => s.name.toLowerCase().includes(value.toLowerCase())));
    };

    const handleSuggestionClick = (id: number) => {
        const sug = getAllSpecializations().find((s: Specialization) => s.id === id)
        setSelected(sug ? [sug] : []);
        setQuery('');
        setSuggestions([]);
    };

    const handleBubbleClick = (item: number) => {
        setSelected(selected.filter(s => s.id !== item));
    };

    const renderInput = () => (
        <Container>
            <InputBubbleWrapper>
                <Input
                    value={query}
                    onChange={handleInputChange}
                    onFocus={() => {
                        setFocus(true)
                        setSuggestions(getAllSpecializations())
                    }}
                    placeholder="Wyszukaj po specjalizacji lub nazwisku lekarza"
                    onBlur={() => {
                        if (!mouseDownOnSuggestion.current) {
                            setFocus(false);
                        }
                        mouseDownOnSuggestion.current = false;
                    }}
                />
            </InputBubbleWrapper>
            {suggestions.length > 0 && (
                <Suggestions
                    $focus={focus}
                    onMouseDown={() => mouseDownOnSuggestion.current = true}
                >
                    {suggestions.map((suggestion, idx) => (
                        <SuggestionItem key={idx} onClick={() => handleSuggestionClick(suggestion.id)}>
                            {suggestion.name}
                        </SuggestionItem>
                    ))}
                </Suggestions>
            )}
        </Container>
    );

    const renderSearchBox = () => {
        return (
            <SearchBoxWrapper>
                {selected.map((item, idx) => (
                    <BubbleWrapper
                        key={idx}
                        onClick={() => {
                            handleBubbleClick(item.id)
                        }}
                    >
                        <Bubble>{item.name}</Bubble>
                        <SvgFill2 color={theme.color.gray100}>
                            <IcCross/>
                        </SvgFill2>
                    </BubbleWrapper>
                ))}
                {renderInput()}
                {renderClearButton()}
            </SearchBoxWrapper>
        )
    }

    const renderDesktopSearchBox = () => <DesktopSearchWrapper>
        {renderSearchBox()}
        <ButtonPrimary
            title="Szukaj"
            onClick={() => {
                setSubmittedQuery(query)
            }}
            icon={<IcSearch/>}
        />
    </DesktopSearchWrapper>;

    function getMobileSearchDropdownText() {
        if (selected?.length > 0) {
            return selected[0].name
        }
        return "Specjalizacja";
    }

    const renderMobileSearchBox = () => <MobileSearchWrapper>
        <MobileSearchDropdown onClick={() => setIsPopupVisible(true)}>
            <MobileSearchDropdownText>{getMobileSearchDropdownText()}</MobileSearchDropdownText>
            <img alt="Wszystkie specjalizacje" src={icChevronDown}/>
        </MobileSearchDropdown>
        <Input
            value={query}
            onChange={(event) => {
                const value = event.target.value;
                setQuery(value);
                setSubmittedQuery(value)
            }}
            onFocus={() => {
                setFocus(true)
            }}
            placeholder="Szukaj"
            onBlur={() => {
                if (!mouseDownOnSuggestion.current) {
                    setFocus(false);
                }
                mouseDownOnSuggestion.current = false;
            }}
        />
        {renderClearButton()}
        {renderSearchIcon()}
    </MobileSearchWrapper>;

    return (
        <ContentWrapper>
            <PopupDim $isVisible={isPopupVisible} onClick={() => setIsPopupVisible(false)}>
                <PopupDropdown>
                    <PopupContent onClick={(e) => e.stopPropagation()}>
                        <PopupContentText
                            onClick={() => {
                                setSelected([])
                                setIsPopupVisible(false)
                            }}
                        >Wszystkie specjalizacje</PopupContentText>
                    </PopupContent>
                    <PopupContent onClick={(e) => e.stopPropagation()}>
                        {specializations.items?.map((s, index) => (
                            <PopupContentText
                                key={index}
                                $selected={selected.some(s2 => s2.id === s.id)}
                                onClick={() => {
                                    handleSuggestionClick(s.id)
                                    setIsPopupVisible(false)
                                }}
                            >{s.name}</PopupContentText>
                        ))}
                    </PopupContent>
                </PopupDropdown>
            </PopupDim>
            <TitleWrapper>
                <Title>Katalog lekarzy</Title>
                {renderMobileSearchBox()}
                {renderDesktopSearchBox()}
            </TitleWrapper>
            <CardsContainer>
                {renderDoctors()}
            </CardsContainer>
        </ContentWrapper>
    );
};

const formatDate = (): string => {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = today.getFullYear();
    return `Dzisiaj, ${day}/${month}/${year}`;
}

const mapStateToProps = (state: AppState) => ({
    doctors: state.doctors,
    specializations: state.specializations,
});

const mapDispatchToProps = {
    fetchDoctors,
    fetchSpecializations,
};


export default connect(mapStateToProps, mapDispatchToProps)(DoctorsList);
