import BackArrowButtonTitled from "../Shared/Button/BackArrowButtonTitled/BackArrowButtonTitled";
import React from "react";
import styled from "styled-components";
import AiDiagnosisResult from "./AiDiagnosisResult";
import {useNavigate, useParams} from "react-router-dom";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: ${props => props.theme.color.beige200};
`;

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}

const AiDiagnosisResultsScreen: React.FC = () => {

    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);
    const navigate = useNavigate()

    return (
        <Container>
            <BackArrowButtonTitled onBackClicked={() => navigate(-1)}/>
            <AiDiagnosisResult diagnosisId={id}/>
        </Container>
    )
}

export default AiDiagnosisResultsScreen