import Space from "../../Shared/Div/Space";
import icFacebook from "../../../assets/images/ic_facebook.svg"
import icLinkedin from "../../../assets/images/ic_linkedin.svg"
import React from "react";
import {
    LeftSideLogo,
    LeftSideTitle,
    LeftSideWrapper,
    MenuColumn,
    MenuItem,
    MenuItemWrapper,
    MenuTitle,
    MenuTitleLast,
    RightSideWrapper,
    RightsText,
    SectionWrapperWrapper,
    SectionWrapperWrapperWrapper,
    SocialsContainer
} from "./Footer.styles";
import {Paths} from "../../../config/paths";
import {useNavigate} from "react-router-dom";

interface MenuItems {
    title: string;
    items: string[];
}

const FooterSection: React.FC<{
    openPopup: () => void,
    onFaqClicked: () => void,
}> = ({openPopup, onFaqClicked}) => {
    const navigate = useNavigate()

    const menuItems: MenuItems[] = [
        {
            title: "Serwis",
            items: [
                "O nas",
                "Dla lekarzy",
                // "Cennik",
                "Regulamin",
                "Polityka prywatności",
            ]
        },
        {
            title: "Dla pacjenta",
            items: [
                "Uzyskaj diagnozę AI",
                "Umów wizytę",
                "Lekarze",
                "Artykuły",
                "FAQ",
                "Pomoc"
            ]
        }
    ]

    const renderMenuItem = (items: string[]) => (items.map(item => (
            <MenuItem
                key={item}
                onClick={() => {
                    switch (item) {
                        case "Uzyskaj diagnozę AI":
                            navigate(Paths.DIAGNOSIS_NEW)
                            break
                        case "Artykuły":
                            navigate(Paths.ARTICLES_SCREEN)
                            break
                        case "FAQ":
                            onFaqClicked()
                            break
                        case "Polityka prywatności":
                            navigate(Paths.SIMPLE_TEXT_PAGE.replace(":id", "privacy-policy"))
                            break
                        case "Regulamin":
                            navigate(Paths.SIMPLE_TEXT_PAGE.replace(":id", "tnc"))
                            break
                        default:
                            openPopup()
                            break
                    }
                }}
            >{item}</MenuItem>))
    )

    const renderMenu = (item: MenuItems) => (
        <MenuColumn key={item.title}>
            <MenuTitle>{item.title}</MenuTitle>
            <MenuItemWrapper>{renderMenuItem(item.items)}</MenuItemWrapper>
        </MenuColumn>
    )

    return (
        <SectionWrapperWrapperWrapper>
            <SectionWrapperWrapper>
                <LeftSideWrapper>
                    <LeftSideTitle>Zdiagnozujemy chorobę Twojego dziecka za pomocą algorytmów AI</LeftSideTitle>
                    <LeftSideLogo>HappyKiddo</LeftSideLogo>
                </LeftSideWrapper>
                <RightSideWrapper>
                    {menuItems.map(menu => renderMenu(menu))}
                    <MenuColumn>
                        <MenuTitleLast>Śledź nas w mediach społecznościowych</MenuTitleLast>
                        <SocialsContainer>
                            <img key="1" alt="facebook" src={icFacebook}/>
                            <img key="2" alt="linkedin" src={icLinkedin}/>
                        </SocialsContainer>
                    </MenuColumn>
                </RightSideWrapper>
            </SectionWrapperWrapper>
            <RightsText>Copyright HappyKiddo 2023</RightsText>
        </SectionWrapperWrapperWrapper>
    );
}

export default FooterSection;