import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {Endpoints} from './endpoints';
import {store} from '../state/store';

const api: AxiosInstance = axios.create({
    baseURL: Endpoints.BASE_URL
});

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    _retry?: boolean;
}

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response: AxiosResponse) => {
        return response
    },
    async (error: AxiosError) => {
        const originalRequest = error.config as CustomAxiosRequestConfig
        if (!originalRequest) {
            return Promise.reject(error)
        }
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            try {
                const {data} = await axios.post(Endpoints.REFRESH_TOKEN, {refreshToken})
                localStorage.setItem('token', data.token)
                localStorage.setItem('refreshToken', data.refreshToken)
                originalRequest.headers = originalRequest.headers || {}
                originalRequest.headers.token = data.token
                return api(originalRequest)
            } catch (refreshErr) {
                localStorage.clear()
                store.dispatch({type: "LOGOUT_SUCCESS"})
                return Promise.reject(refreshErr)
            }
        }
        return Promise.reject(error)
    }
);

export default api;
