import {useEffect, useState} from "react";
import {fetchSettings} from "./actions";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../store";


export const useFetchSettings = () => {
    const settings = useSelector((state: AppState) => state.settings.settings)
    const [loading, setLoading] = useState(false)
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!settings && !loading) {
            setLoading(true)
            dispatch(fetchSettings())
                .catch(() => {
                    console.log("error fetching settings")
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [settings, loading]);

    return settings
}