import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const HelpContainer = styled.div`
  min-height: 100vh;
  padding: 48px;
  box-sizing: border-box;
`

export const Title = styled.h3`
  margin-bottom: 68px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
