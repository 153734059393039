import React, {useState} from "react";
import icThermometer from "../../../../../assets/images/ic_thermometer.svg"
import BigButtonOutline from "../../../../Shared/Button/BigButtonOutline/BigButtonOutline";
import {
    Chart,
    Column1,
    Column2,
    Container,
    NoEntries,
    Option,
    RightContainer,
    RightContainerTitle,
    Row,
    Section,
    SectionTitle,
    SectionTitleText,
    Select,
    SelectContainer,
    TabWrapper,
    TitleSection
} from "./SymptomsTab.styles";

const PersonalDetails: React.FC = () => {
    const [selected, setSelected] = useState("week")

    return (
        <TabWrapper>
            <Container>
                <Section>
                    <TitleSection>
                        <SectionTitle>
                            <img alt="objawy" src={icThermometer}/>
                            <SectionTitleText>Objawy</SectionTitleText>
                        </SectionTitle>
                        <BigButtonOutline
                            title="Dodaj objaw"
                            onClick={() => {
                            }}
                        />
                    </TitleSection>
                    <Row>
                        <Column1>Typ</Column1>
                        <Column1>Rodzaj</Column1>
                        <Column2>Data</Column2>
                    </Row>
                    <Row>
                        <NoEntries>
                            Brak wprowadzonych objawów
                        </NoEntries>
                    </Row>
                </Section>
            </Container>
            <RightContainer>
                <RightContainerTitle>
                    Dziennik objawów
                </RightContainerTitle>
                <SelectContainer>
                    <Select
                        value={selected}
                        onChange={(e) => {
                            e.preventDefault()
                            setSelected(e.target.value)
                        }}
                    >
                        <Option value="week">Ostatni tydzień</Option>
                        <Option value="month">Ostatni miesiąc</Option>
                        <Option value="year">Ostatni rok</Option>=
                    </Select>
                </SelectContainer>
                <Chart>Brak danych</Chart>
            </RightContainer>
        </TabWrapper>
    )
}

export default PersonalDetails