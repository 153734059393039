import {PaymentStatus} from "../state/appointments/types";

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export const isValidEmail = (email: string) => {
    return emailRegex.test(email)
}

export interface Requirement {
    title: string,
    validation: (password: string) => boolean
}

export const passwordRequirements: Requirement[] = [
    {
        title: "min. 6 znaków",
        validation: (password) => (password.length >= 6)
    },
    {
        title: "min. 1 wielką literę",
        validation: (password) => (/[A-Z]/.test(password))
    },
    {
        title: "min. 1 małą literę",
        validation: (password) => (/[a-z]/.test(password))
    }
]

export const isValidPassword = (password: string) => {
    return passwordRequirements.every((requirement) => requirement.validation(password))
}

export const isValidName = (name: string) => {
    return name.length > 2
}

export const isValidPhone = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/[^\d]/g, '');

    // Check if the length is exactly 9 digits (standard length for Polish phone numbers)
    return cleaned.length === 9;
}

export const isValidPESEL = (pesel: string) => {
    if (!/^\d{11}$/.test(pesel)) {
        return false;
    }
    let weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    let sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += weights[i] * +pesel[i];
    }
    let checkDigit = 10 - (sum % 10);
    checkDigit = checkDigit === 10 ? 0 : checkDigit;
    return +pesel[10] === checkDigit;
}

export const formatDateToDDMMYYYYWithDashes = (date: Date | null) => {
    if (date == null) {
        return ""
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 since months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

// Converting Date to "2 stycznia 2024 o godzinie 14:15"
export const formatDateToDMMMMYYYYTimePolish = (date: Date | null): string => {
    if (!date) {
        return ""
    }
    const formatter = new Intl.DateTimeFormat('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    });
    return formatter.format(date) + ' o godzinie ' + date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2)
}

export const formatDateToDMMMMYYYYTimePolishShort = (date: Date | null): string => {
    if (!date) {
        return ""
    }
    const formatter = new Intl.DateTimeFormat('pl-PL', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    });
    return formatter.format(date)
}

export const formatDateToDMMMMYYYYTimePolishWithComa = (date: Date | null): string => {
    if (!date) {
        return ""
    }
    const formatter = new Intl.DateTimeFormat('pl-PL', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: false
    });
    return formatter.format(date);
}

export const formatDateToDMMMMYYYYPolish = (date: Date | null | undefined): string => {
    if (!date) {
        return ""
    }
    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    };

    return new Intl.DateTimeFormat('pl-PL', options).format(date);
};

export const formatDateToDDMMYYYYWithDelimiter = (date: Date | null, delimiter: string) => {
    if (date == null) {
        return ""
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 since months are zero-based
    const year = date.getFullYear();
    return `${day}${delimiter}${month}${delimiter}${year}`;
}

export const formatDateApi = (date: Date | null) => {
    if (date == null) {
        return ""
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 since months are zero-based
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}


export const formatTime = (date: Date | null) => {
    if (date == null) {
        return ""
    }
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    return `${hour}:${minute}`;
}

export const formatPaymentStatus = (status: PaymentStatus): string => {
    switch (status) {
        case "pending_payment":
            return "Nie opłacono"
        case "paid":
            return "Opłacono"
        case "cancelled":
            return "Odwołano"
    }
    return "nieznany"
}

export const parsePaymentStatus = (status: string | null | undefined): PaymentStatus => {
    switch (status) {
        case "pending_payment":
            return "pending_payment"
        case "paid":
            return "paid"
        case "cancelled":
            return "cancelled"
    }
    return "unknown"
}

export const parseDate = (input: string | null | undefined): Date | null => {
    if (input == null) {
        return null
    }
    const parts = input.split('.');
    if (parts.length !== 3) {
        return null;
    }
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
}

export const parseDateTime = (input: string | null | undefined): Date | null => {
    if (input == null) {
        return null
    }
    return new Date(input);
}

export const getPeselBirthDate = (pesel: string): Date | null => {
    if (pesel.length !== 11) {
        return null;
    }
    let year = parseInt(pesel.substring(0, 2), 10) + 2000;
    let month = parseInt(pesel.substring(2, 4), 10);
    const day = parseInt(pesel.substring(4, 6), 10);

    const birthDate = new Date(year, month - 1, day);
    if (birthDate.getDate() !== day || birthDate.getMonth() + 1 !== month || birthDate.getFullYear() !== year) {
        return null;
    }
    return birthDate;
};

export const isAllDigits = (str: string): boolean => {
    return /^\d+$/.test(str);
}

export const convertToAge = (birthDate: Date): string => {
    const today = new Date();
    if (isNaN(birthDate.getTime())) {
        throw new Error('Invalid date string');
    }
    let ageInYears = today.getFullYear() - birthDate.getFullYear();
    let ageInMonths = (today.getMonth() - birthDate.getMonth()) + (ageInYears * 12);
    if (today.getMonth() < birthDate.getMonth()) {
        ageInYears--;
    } else if (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()) {
        ageInYears--;
    }
    if (ageInYears < 1) {
        return `${ageInMonths} ${monthsPlural(ageInMonths)}`;
    } else if (ageInYears < 3) {
        const remainingMonths = ageInMonths % 12;
        return `${ageInYears} ${yearsPlural(ageInYears)} i ${remainingMonths} ${monthsPlural(remainingMonths)}`;
    } else {
        return `${ageInYears} ${yearsPlural(ageInYears)}`;
    }
}

const yearsPlural = (amount: number) => {
    switch (amount) {
        case 0:
            return "lat"
        case 1:
            return "rok"
        case 2:
        case 3:
        case 4:
            return "lata"
        default:
            return "lat"
    }
}

const monthsPlural = (amount: number) => {
    switch (amount) {
        case 0:
            return "miesięcy"
        case 1:
            return "miesiąc"
        case 2:
        case 3:
        case 4:
            return "miesiące"
        default:
            return "miesięcy"
    }
}

export const formatSex = (date: string | null) => {
    switch (date) {
        case "M":
            return "chłopczyk"
        case "F":
            return "dziewczynka"
        default:
            return "nie ustalono"
    }
}

export const getCurrentWeekOfPregnancy = (dueDate: Date | null): number => {
    if (dueDate == null) {
        return 0
    }
    const today = new Date();
    const diffInMilliseconds = dueDate.getTime() - today.getTime();
    const diffInWeeks = diffInMilliseconds / (1000 * 60 * 60 * 24 * 7);
    return 40 - Math.ceil(diffInWeeks);
}

export const getCurrentDayOfPregnancy = (dueDate: Date | null): number => {
    if (dueDate == null) {
        return 0
    }
    const today = new Date();
    const diffInMilliseconds = dueDate.getTime() - today.getTime();
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    return 280 - Math.ceil(diffInDays);
}

export function daysUntilDueDate(dueDate: Date | null): number {
    if (!dueDate) {
        return 0
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const diffInMilliseconds = dueDate.getTime() - today.getTime();
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.ceil(diffInDays);
}

export const formatPrice = (value: number | null): string => {
    if (value == null) {
        return ""
    }
    return `${value / 100} zł`
}

export const isLaterThanYesterday = (date: Date | null): boolean => {
    if (!date) {
        return false
    }
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0); // Set time to 00:00:00 for comparison

    return date > yesterday;
}

export const isEarlierThanNow = (date: Date | null): boolean => {
    if (!date) {
        return false
    }
    const now = new Date();
    return date < now;
}
