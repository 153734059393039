import styled from "styled-components";

type InputState = 'default' | 'error' | 'disabled' | 'success';

interface InputProps {
    $state?: InputState;
}

export const Row = styled.div<InputProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  overflow: hidden;

  border-radius: ${props => props.theme.radius.radius500};
  border: 1px solid ${props => props.theme.color.gray600};

  &:focus {
    border: 2px solid ${props => props.theme.color.gray900};
    outline: none;
  }

  &:checked {
    background-color: ${props => props.theme.color.violet600};
  }

  ${({$state, theme}) => $state === 'error' && `
      border-width: 2px;
      border-color: ${theme.color.error600};
      background-color: ${theme.color.error100};
      &:focus {
        border: 2px solid ${theme.color.error600};
      }
    `
  }

  ${({$state, theme}) => $state === 'success' && `
      border-width: 2px;
      border-color: ${theme.color.violet600};
      background-color: ${theme.color.violet100};
      &:focus {
        border: 2px solid ${theme.color.violet600};
      }
    `
  }
`

export const PhoneInputField = styled.input`
  padding: 12px 0;
  border-radius: 0;
  border: none;
  background-color: transparent;

  &:focus {
    border: none;
    outline: none;
  }

  &:checked {
    background-color: transparent;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;


  @media (max-width: 599px) {

  }

  @media (min-width: 600px) {
    width: 100%;
  }
`;

export const CountryCodeSpan = styled.span`
  color: ${props => props.theme.color.gray700};
  background-color: ${props => props.theme.color.gray400};
  margin: 0;
  padding: 8px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.4px;
`