import React from "react";
import icInfo from "../../../../assets/images/ic_info.svg"
import {AiBannerContent, AiBannerWrapper, Headline, HeadlineWrapper} from "./AiBanner.styles";

const AiBanner: React.FC = () => {
    return (
        <AiBannerWrapper>
            <HeadlineWrapper>
                <img alt="info" src={icInfo}/>
                <Headline>Konieczna diagnoza AI</Headline>
            </HeadlineWrapper>
            <AiBannerContent>
                Wykonanie diagnozy AI jest obowiązkowe przed skorzystaniem z teleporady, jest to bardzo istotne dla
                naszych lekarzy i usprawnia proces trafnej diagnozy. Możesz wykonać diagnozę AI zaraz po umówieniu
                wizyty, ale wyślemy Ci także przypomnienie o jej wykonaniu 12h przed planowaną wizytą.
            </AiBannerContent>
        </AiBannerWrapper>
    )
}

export default AiBanner