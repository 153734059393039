import {styled} from "styled-components"

export const Specializations = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: stretch;
  border-radius: 16px;
  background: ${props => props.theme.color.gray100};

  overflow: hidden;
  cursor: pointer;


  @media (min-width: 600px) and (max-width: 1199px) {
    width: 300px;
  }

  @media (min-width: 1200px) {
    width: 380px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const DateRow = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 8px;
`

export const RatingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  align-self: flex-end;
  margin-top: auto;
`

export const DoctorInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
`

export const Image = styled.img`
  width: 86px;
  height: 86px;
  border-radius: 8px;
  margin-right: 16px;
`

export const Star = styled.img`
  
  @media (min-height: 800px) {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  @media (min-width: 600px) {
    width: 24px;
    height: 24px;
    margin-right: 11px;
  }
`

export const Name = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};

  font-style: normal;
  align-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  @media (max-width: 599px) {
    font-size: 18px; 
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.36px;
  }

  @media (min-width: 600px) {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;

  }
`

export const Specialization = styled.p`
  color: ${props => props.theme.color.gray800};

  line-height: normal;
  margin-top: 4px;
  align-self: flex-start;



  @media (max-width: 599px) {
    font-size: 14px;
    
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    font-size: 16px;

  }
`

export const Price = styled.p`
  font-weight: 600;
  line-height: normal;

  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    font-size: 20px;

  }
  
`

export const Rating = styled.p`
  line-height: normal;
  
  @media (max-width: 599px) {
    font-size: 12px;
  }

  @media (min-width: 600px) {

  }
`

export const When = styled.p`
  line-height: normal;


  @media (max-width: 599px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    

  }
`

export const Dat = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  text-align: center;


  @media (max-width: 599px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    background: ${props => props.theme.color.violet200};

    height: 24px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 600px) and (max-width: 1199px) {
    background: ${props => props.theme.color.violet200};
    width: 82px;

    padding-top: 7px;
    padding-bottom: 7px;
  }

  @media (min-width: 1200px) {
    background: ${props => props.theme.color.pink200};
    width: 72px;
    
    padding-top: 7px;
    padding-bottom: 7px;
  }


`