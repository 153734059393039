import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 40px;
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);
  color: ${props => props.theme.color.gray100};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  cursor: pointer;

  background: ${props => props.theme.color.violet600};
`;

export const CircularProgress = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;