import React, {useState} from 'react';
import icAvailability from '../../../../../assets/images/ic_availabilioty_indicator.svg'
import CalendarHeader from "../CalendarHeader/CalendarHeader";
import {Container, Day, DayName, DayNames, Days, DayText, DisabledDay, ImageIndicator} from "./CalendarPicker.styles";

const CalendarPicker: React.FC<{ date?: Date | null, onDateSelected: (date: Date) => void }> = (
    {
        date,
        onDateSelected
    }
) => {
    const newTodayDate = () => {
        const now = new Date()
        now.setHours(0, 0, 0, 0)
        return now
    }
    const today = newTodayDate()

    const [displayingDate, setDisplayingDate] = useState(today);

    const daysShort = ["Nie", "Pon", "Wt", "Śr", "Czw", "Pt", "So"];

    const handlePrevMonth = () => {
        if (isPreviousMonthDisabled(displayingDate)) {
            return
        }
        setDisplayingDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1));
    };

    const handleNextMonth = () => {
        setDisplayingDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1));
    };

    const getDaysInMonth = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    };

    const isAvailable = (day: number, month: number, year: number) => {
        const target = new Date(year, month, day)
        return target >= today;
    }

    const onDaySelected = (year: number, month: number, day: number) => {
        const newDate = new Date(year, month, day)
        if (today > newDate) {
            return
        }
        // TODO: check if available
        onDateSelected(new Date(year, month, day))
    }

    const isSelected = (y: number, m: number, d: number): boolean => {
        return (
            d === date?.getDate() &&
            m === date?.getMonth() &&
            y === date?.getFullYear()
        );
    }

    const renderDays = () => {
        const daysInMonth = getDaysInMonth(displayingDate);
        let daysPreviousInMonth = getDaysInMonth(new Date(displayingDate.getFullYear(), displayingDate.getMonth() - 1));
        const startDay = new Date(displayingDate.getFullYear(), displayingDate.getMonth(), 1).getDay();
        const endDay = new Date(displayingDate.getFullYear(), displayingDate.getMonth(), daysInMonth).getDay();
        const days = [];

        const tmp = [];
        for (let i = 0; i < startDay; i++) {
            tmp.push(
                <DisabledDay
                    onClick={handlePrevMonth}
                    key={`empty-start-${i}`}>
                    {daysPreviousInMonth}
                </DisabledDay>
            );
            daysPreviousInMonth--
        }
        days.push(tmp.reverse())

        for (let d = 1; d <= daysInMonth; d++) {
            const y = displayingDate.getFullYear()
            const m = displayingDate.getMonth()
            days.push(
                <Day
                    $today={isToday(y, m, d)}
                    $selected={isSelected(y, m, d)}
                    key={d}
                    onClick={() => onDaySelected(y, m, d)}>
                    <DayText>{d}</DayText>
                    {isAvailable(d, displayingDate.getMonth(), displayingDate.getFullYear()) ?
                        <ImageIndicator src={icAvailability}/> : null}
                </Day>
            );
        }

        for (let i = 1; i <= 6 - endDay; i++) {
            days.push(<DisabledDay onClick={handleNextMonth} key={`empty-end-${i}`}>{i}</DisabledDay>);
        }

        return days;
    };

    function isPreviousMonthDisabled(date: Date) {
        if (date.getFullYear() > today.getFullYear()) {
            return false
        }
        return date.getMonth() - 1 < today.getMonth();
    }

    function getHeaderTitle() {
        const monthName = displayingDate.toLocaleString('pl', {month: 'long'});
        const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
        return `${capitalizedMonthName} ${displayingDate.getFullYear()}`
    }

    return (
        <Container>
            <CalendarHeader
                title={getHeaderTitle()}
                onPreviousClicked={handlePrevMonth}
                onNextClicked={handleNextMonth}
                isBackDisabled={isPreviousMonthDisabled(displayingDate)}
            />
            <DayNames>
                {daysShort.map(day => (
                    <DayName key={day}>{day}</DayName>
                ))}
            </DayNames>
            <Days>
                {renderDays()}
            </Days>
        </Container>
    );
};

const isToday = (y: number, m: number, d: number): boolean => {
    const today = new Date();
    return (
        d === today.getDate() &&
        m === today.getMonth() &&
        y === today.getFullYear()
    );
}


export default CalendarPicker