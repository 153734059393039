import styled from "styled-components";

export const Container = styled.div`
  background: ${props => props.theme.color.beige200};
  position: relative;

  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    width: 100%;
    align-items: center;
    overflow: scroll;
  }
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`

export const Title = styled.h3`

  @media (max-width: 599px) {
    text-align: left;
    margin-bottom: 8px;
  }

  @media (min-width: 600px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled.h5`
  color: ${props => props.theme.color.gray800};
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.4px;

  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
    text-align: left;
  }

  @media (min-width: 600px) {
    margin-bottom: 30px;
    text-align: center;
  }

`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    width: 100%;
    max-width: 300px;
  }
`;


export const ButtonNext220 = styled.div`

  display: flex;
  
  @media (max-width: 599px) {
    align-self: center;
  }

  @media (min-width: 600px) {
    width: 220px;
    flex-direction: column;
  }

`

export const ButtonNext300 = styled.div`
  display: flex;
  
  @media (max-width: 599px) {
    align-self: center;
  }

  @media (min-width: 600px) {
    width: 300px;
    flex-direction: column;
  }

`
export const Option = styled.button<{
    $selected: boolean
}>`
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  transition: background-color 0.3s;
  border-radius: 56px;
  border: 1px solid ${props => props.$selected ? props.theme.color.violet600 : props.theme.color.violet200};
  background: ${props => props.$selected ? props.theme.color.violet200 : props.theme.color.violet100};

  &:hover {
    border: 1px solid ${props => props.theme.color.violet600};
    background: ${props => props.theme.color.violet200};
  }
`;

export const ContentCard = styled.div`
  flex-direction: column;
  background: ${props => props.theme.color.gray100};
  z-index: 1;

  @media (max-width: 599px) {
    margin: 0 20px;
    display: flex;
    padding: 24px;
    gap: 40px;
    border-radius: 20px;
    //align-items: center;
  }

  @media (min-width: 600px) {
    margin-top: 20px;
    padding: 64px 20px;
    width: 60%;
    display: inline-flex;
    align-items: center;
    gap: 96px;
    border-radius: 40px;
  }
`

export const TextInput = styled.textarea`
  display: flex;
  background: ${props => props.theme.color.violet100};
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);

  justify-content: start;
  align-items: flex-start;

  line-height: 20px;
  text-align: left;
  vertical-align: top;
  resize: vertical;

  &:focus {
    border: 2px solid ${props => props.theme.color.violet200};
    outline: none;
  }

  @media (max-width: 599px) {
    border-radius: 12px;
    padding: 16px;
    flex-grow: 1;
    //width: 100%;
    //margin: 0 20px;
  }

  @media (min-width: 600px) {
    width: 60%;
    border-radius: 40px;
    height: 120px;
    min-height: 200px;
    padding: 24px;
    gap: 6px;
  }
`

export const CircularProgress2 = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid ${props => props.theme.color.beige300};
  border-radius: 50%;
  border-top-color: ${props => props.theme.color.violet500};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  @media (max-width: 599px) {
    align-self: center;
  }

  @media (min-width: 600px) {
  }
`;

export const ProgressContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;


  @media (max-width: 599px) {
    bottom: 16px;
  }

  @media (min-width: 600px) {
    bottom: 32px;

  }
`

export const ImagePhysician = styled.img`
  @media (max-width: 599px) {
    height: 98.529px;
  }

  @media (min-width: 600px) {
    height: 200px;

  }
`
