import {useTheme} from 'styled-components';
import React from "react";
import SvgFill from "../../Icons/SvgFill";
import {ButtonContainer, ButtonText} from "./ButtonUpLink.styles";
import IcUpRight from "../../Icons/IcUpRight";

interface Props {
    color?: string;
    children: React.ReactNode;
    onClick?: () => void
}

const ButtonUpLink: React.FC<Props> = ({children, color, onClick}) => {
    const theme = useTheme()

    return (
        <ButtonContainer onClick={onClick}>
            <ButtonText $color={color ? color : theme.color.gray1000}>{children}</ButtonText>
            <SvgFill color={color ? color : theme.color.gray1000}>
                <IcUpRight/>
            </SvgFill>
        </ButtonContainer>
    );
}

export default ButtonUpLink;