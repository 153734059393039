import styled from "styled-components";

export const ContentCard = styled.div`
  background: ${props => props.theme.color.gray100};
  z-index: 1;


  @media (max-width: 599px) {
    display: flex;
    flex-direction: row;
    margin: 0 20px;
    border-radius: 20px;
  }

  @media (min-width: 600px) {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    width: 60%;
    min-width: 450px;
    padding: 64px 20px;
    gap: 96px;
    border-radius: 40px;
  }
`
