import React from 'react';

const IcSettings: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_1316_915)">
            <path
                d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
                fill="white"/>
            <path
                d="M12.7172 5.06259C12.1406 4.95752 11.5478 4.98047 10.981 5.12983C10.4142 5.27919 9.88715 5.55129 9.43714 5.92689C8.98712 6.30249 8.62516 6.77241 8.37685 7.30339C8.12855 7.83436 7.99998 8.41342 8.00024 8.99959C8.00024 9.2648 8.1056 9.51916 8.29314 9.70669C8.48067 9.89423 8.73503 9.99959 9.00024 9.99959C9.26546 9.99959 9.51981 9.89423 9.70735 9.70669C9.89488 9.51916 10.0002 9.2648 10.0002 8.99959C9.99999 8.70536 10.0647 8.41471 10.1896 8.14835C10.3146 7.88198 10.4968 7.64648 10.7233 7.45862C10.9497 7.27076 11.2148 7.13517 11.4997 7.06153C11.7845 6.98789 12.0821 6.97801 12.3712 7.03259C12.7663 7.10927 13.1295 7.30197 13.4145 7.58608C13.6995 7.87018 13.8933 8.23279 13.9712 8.62759C14.0499 9.04199 13.9956 9.47067 13.816 9.85233C13.6364 10.234 13.3407 10.5491 12.9712 10.7526C12.3594 11.1071 11.8538 11.6191 11.5072 12.2354C11.1605 12.8517 10.9854 13.5496 11.0002 14.2566V14.9996C11.0002 15.2648 11.1056 15.5192 11.2931 15.7067C11.4807 15.8942 11.735 15.9996 12.0002 15.9996C12.2655 15.9996 12.5198 15.8942 12.7073 15.7067C12.8949 15.5192 13.0002 15.2648 13.0002 14.9996V14.2566C12.9877 13.9086 13.067 13.5634 13.2303 13.2559C13.3936 12.9483 13.635 12.6892 13.9302 12.5046C14.6547 12.1067 15.2382 11.494 15.6003 10.751C15.9624 10.0079 16.0855 9.17095 15.9525 8.35511C15.8195 7.53928 15.4371 6.78468 14.8577 6.19508C14.2784 5.60549 13.5306 5.20985 12.7172 5.06259Z"
                fill="white"/>
            <path
                d="M13.0003 17.9998C13.0003 17.4475 12.5525 16.9998 12.0002 16.9998C11.448 16.9998 11.0002 17.4475 11.0002 17.9998C11.0002 18.552 11.448 18.9998 12.0002 18.9998C12.5525 18.9998 13.0003 18.552 13.0003 17.9998Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1316_915">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default IcSettings;
