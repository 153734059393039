import styled from "styled-components";

export const MoreButton = styled.div`
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const ButtonText = styled.p`
  color: ${props => props.theme.color.violet600};
  font-weight: 400;
  line-height: 150%;
`