import React, {useEffect, useState} from "react";
import icPortrait from "../../assets/images/ic_portrait.svg"
import icShield from "../../assets/images/ic_shield.svg"
import icClose from "../../assets/images/ic_close.svg"
import {
    Container,
    DesktopContent,
    HorizontalDivider,
    MobileButton,
    Section,
    SectionTitle,
    SectionTitleText,
    TabWrapper,
    TitleSection
} from "./PersonalDetailsTab.styles";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../../state/store";
import ButtonOutline from "../Shared/Button/ButtonOutline/ButtonOutline";
import {isValidName, isValidPhone} from "../../config/utils";
import PhoneNumberInput from "../Shared/Input/PhoneNumberInput";
import Input from "../Shared/Input/Input";
import ButtonPrimary from "../Shared/Button/ButtonPrimary/ButtonPrimary";
import {fetchSettings, updateSettings} from "../../state/settings/actions";
import {useFetchUser} from "../../state/user/helpers";

const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;


  @media (max-width: 599px) {
    gap: 24px;
  }

  @media (min-width: 600px) {
    gap: 52px;
    margin-top: 57px;
  }
`

const ContentColumn = styled.div`
  flex-grow: 1;
  display: flex;

  @media (max-width: 599px) {
    flex-direction: column;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
`

export const ValueTextField = styled.p`

  @media (max-width: 599px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.32px;
  }

  @media (min-width: 600px) {
  }

`

const ContentColumnCell1 = styled.p`
  @media (max-width: 599px) {
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    width: 300px;
  }
`

const DeleteAccountCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const ImgClose = styled.img`
  cursor: pointer;
`

const InputWrapper = styled.div`
 
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  @media (max-width: 599px) {
    
  }

  @media (min-width: 600px) {
    width: 400px;
  }
`

const InputMessage = styled.p`
  color: ${props => props.theme.color.gray800};
  font-size: 14px;
  line-height: 150%;
`

const PhoneNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;


  @media (max-width: 599px) {
    margin-top: 8px;
  }

  @media (min-width: 600px) {
    width: 400px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

const LinkButton = styled.button`
  color: ${props => props.theme.color.violet600};
`

const PersonalDetailsTab: React.FC = () => {
    const [isEditingPersonalDetails, setIsEditingPersonalDetails] = useState(false)

    const [nameEdited, setNameEdited] = useState("")
    const [nameError, setNameError] = useState<string | null>(null)
    const [nameOk, setNameOk] = useState<boolean>(false)
    const [validateNameOnType, setValidateNameOnType] = useState(false)

    const [phoneEdited, setPhoneEdited] = useState("")
    const [phoneError, setPhoneError] = useState<string | null>(null)
    const [phoneOk, setPhoneOk] = useState<boolean>(false)
    const [validatePhoneOnType, setValidatePhoneOnType] = useState(false)

    const [updatingSettings, setUpdatingSettings] = useState(false)

    const user = useFetchUser()
    const settings = useSelector((state: AppState) => state.settings.settings)
    const [loadingSettings, setLoadingSettings] = useState(false)

    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!settings && !loadingSettings) {
            setLoadingSettings(true)
            dispatch(fetchSettings())
                .catch(() => {
                    console.log("error fetching settings")
                })
                .finally(() => {
                    setLoadingSettings(false)
                })
        }
    }, [settings, loadingSettings]);

    function renderFormalName() {
        if (isEditingPersonalDetails) {
            return (
                <InputWrapper>
                    <Input
                        value={nameEdited}
                        onChange={newValue => {
                            setNameEdited(newValue)
                            if (validateNameOnType) {
                                const valid = isValidName(newValue || "")
                                setNameError(!valid ? "Nieprawidłowe dane" : null);
                            }
                            const valid = isValidName(newValue || "")
                            if (valid) {
                                setNameError(null)
                                setNameOk(true)
                            }
                        }}
                        error={nameError}
                        correct={nameOk}
                        onBlur={() => {
                            setValidateNameOnType(true)
                            const valid = isValidName(nameEdited)
                            setNameError(!valid ? "Nieprawidłowe dane" : null);
                            setNameOk(valid)
                        }}
                    />
                    {!nameError ? <InputMessage>Użyjemy Twoich danych podczas umawiania wizyty.</InputMessage> : null}
                </InputWrapper>
            )
        }
        return <ValueTextField>{settings?.fullName}</ValueTextField>;
    }

    function renderPhoneNumber() {
        const p = settings?.phone
        if (!p) {
            return null
        }
        return (<ValueTextField>{formatPhone(removeCountryCode(p))}</ValueTextField>);
    }

    const renderPhone = () => {
        if (isEditingPersonalDetails) {
            return (
                <PhoneNumberWrapper>
                    <PhoneNumberInput
                        phoneNumber={phoneEdited}
                        setPhoneNumber={(newValue) => {
                            setPhoneEdited(newValue)
                            if (validatePhoneOnType) {
                                const valid = isValidPhone(newValue || "")
                                setPhoneError(!valid ? "Nieprawidłowy numer telefonu" : null);
                            }
                            const valid = isValidPhone(newValue || "")
                            if (valid) {
                                setPhoneError(null)
                                setPhoneOk(true)
                            }
                        }}
                        error={phoneError || undefined}
                        correct={phoneOk}
                        onBlur={() => {
                            setValidatePhoneOnType(true)
                            const valid = isValidPhone(phoneEdited)
                            if (valid) {
                                setPhoneError(null)
                                setPhoneOk(true)
                            }
                        }}
                    />
                    {!phoneError ? <InputMessage>Na podany numer wyślemy przypomnienie o wizycie.</InputMessage> : null}

                </PhoneNumberWrapper>
            )
        }
        return renderPhoneNumber()
    }

    const renderPersonalDetailsButtons = () => {
        if (isEditingPersonalDetails) {
            return <ButtonsWrapper>
                <LinkButton onClick={() => {
                    setIsEditingPersonalDetails(false)
                }}>Anuluj</LinkButton>
                <ButtonPrimary
                    progress={updatingSettings}
                    title="Zapisz"
                    onClick={() => {
                        const n = nameEdited !== settings?.fullName ? nameEdited : undefined
                        let p = phoneEdited !== settings?.phone ? phoneEdited : undefined
                        if (p) {
                            p = "+48" + p?.replaceAll(" ", "")
                        }
                        setUpdatingSettings(true)

                        dispatch(updateSettings({
                            fullName: n,
                            phone: p
                        }))
                            .catch(error => {
                                console.log("error updating settings", error)
                            })
                            .finally(() => {
                                setUpdatingSettings(false)
                            })

                        setIsEditingPersonalDetails(false)
                    }}
                />
            </ButtonsWrapper>
        }
        return <ButtonOutline
            title="Uzupełnij dane"
            onClick={() => {
                setNameEdited(settings?.fullName || "")
                setNameOk(false)
                setNameError(null)
                setValidateNameOnType(false)

                setPhoneEdited(removeCountryCode(settings?.phone) || "")
                setPhoneOk(false)
                setPhoneError(null)
                setValidatePhoneOnType(false)

                setIsEditingPersonalDetails(true)
            }}
        />;
    };

    const removeCountryCode = (phoneNumber: string | null | undefined): string => {
        return phoneNumber?.replace(/^\+48/, '') || ""
    }

    return (
        <TabWrapper>
            <Container>
                <Section>
                    <TitleSection>
                        <SectionTitle>
                            <img alt="O mnie" src={icPortrait}/>
                            <SectionTitleText>Dane osobowe</SectionTitleText>
                        </SectionTitle>
                        <DesktopContent>
                            {renderPersonalDetailsButtons()}
                        </DesktopContent>
                    </TitleSection>
                    <ContentRow>
                        <ContentColumn>
                            <ContentColumnCell1>Imię i nazwisko</ContentColumnCell1>
                            {renderFormalName()}
                        </ContentColumn>
                        <ContentColumn>
                            <ContentColumnCell1>Email</ContentColumnCell1>
                            <ValueTextField>{user?.email || ""}</ValueTextField>
                        </ContentColumn>
                        <ContentColumn>
                            <ContentColumnCell1>Numer Telefonu</ContentColumnCell1>
                            {renderPhone()}
                        </ContentColumn>
                    </ContentRow>
                    <MobileButton>{renderPersonalDetailsButtons()}</MobileButton>
                </Section>
                <HorizontalDivider/>
                <Section>
                    <TitleSection>
                        <SectionTitle>
                            <img alt="Pomiary" src={icShield}/>
                            <SectionTitleText>Bezpieczeństwo</SectionTitleText>
                        </SectionTitle>
                        <DesktopContent>
                            <ButtonOutline
                                title="Zmień hasło"
                                onClick={() => {
                                }}
                            />
                        </DesktopContent>
                    </TitleSection>
                    <ContentRow>
                        <ContentColumn>
                            <ContentColumnCell1>Hasło zostało ustawione</ContentColumnCell1>
                            <ValueTextField>{settings?.fullName}</ValueTextField>
                        </ContentColumn>
                        <ContentColumn>
                            <ContentColumnCell1>Usuń konto</ContentColumnCell1>
                            <DeleteAccountCell><ValueTextField>Skasuj moje konto użytkownika</ValueTextField><ImgClose
                                src={icClose}/></DeleteAccountCell>
                        </ContentColumn>
                    </ContentRow>
                    <MobileButton>
                        <ButtonOutline
                            title="Zmień hasło"
                            onClick={() => {
                            }}
                        />
                    </MobileButton>
                </Section>
            </Container>
        </TabWrapper>
    )
}
const formatPhone = (phoneNumber: string) => {
    if (phoneNumber.length !== 9) {
        return phoneNumber
    }
    const formatted = phoneNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
    return `+48 ${formatted}`;
}
export default PersonalDetailsTab