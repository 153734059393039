import styled from "styled-components";
import imgLogin from "../../../assets/images/img_login_background.jpg";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: #FFF;
`;

export const Background = styled.div`
  position: relative;
  flex: 6;
  background-image: url(${imgLogin});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 599px) {
    display: none;
  }
`;

export const BackgroundWave = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  object-fit: contain;
  height: 100%;
`

export const LoginFormContainer = styled.div`
  flex-direction: column;
  gap: 48px;
  border-radius: 40px;
  display: flex;
  padding: 20px;
  
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
    justify-content: flex-start;
    flex: 1;
  }
  @media (min-width: 600px) {
    justify-content: center;
    flex: 4;
  }
`;

export const LoginLogoTop = styled.img`
  height: 34px;
  
  @media (max-width: 599px) {

  }
  @media (min-width: 600px) {
    display: none;
  }
`
