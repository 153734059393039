import React from "react";
import ArticleDetailsContent from "./ArticleDetailsContent";
import {useParams} from "react-router-dom";
import styled from "styled-components";

export const Background = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 599px) {
    margin: 0 20px;
  }

  @media (min-width: 600px) {
    min-width: 1200px;
    width: 100%;
    background: ${props => props.theme.color.gray100};
  }
  
  
`;

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}

const ArticleDetailsPage: React.FC = () => {


    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);

    return (
        <Background>
            <ArticleDetailsContent id={id}/>
        </Background>
    )
}

export default ArticleDetailsPage