import {styled} from "styled-components";
import {ReactComponent as imgThread} from '../../../assets/images/bg_thread_4.svg';
import {ReactComponent as imgThread2} from '../../../assets/images/bg_thread_5.svg';
import {ReactComponent as imgThread1Mobile} from '../../../assets/images/bg_thread_10.svg';
import heroImage from '../../../assets/images/bg_marker.svg';

export const Background = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.beige100};
`

export const Container = styled.div`
  padding-top: 64px;
  padding-left: 40px;
  padding-right: 40px;
  align-self: stretch;
  position: relative;
  z-index: 1;
`

export const BenefitsCards = styled.div`

  display: flex;
  overflow: hidden;

  @media (max-width: 1023px) {
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    gap: 64px;
  }

  @media (min-width: 1024px) {
    align-items: flex-start;
    flex-direction: row;
    height: 688px;
    margin-top: 64px;
    gap: 97.5px;
  }
`

export const BenefitCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
`

export const BenefitCardItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  @media (max-width: 1023px) {
    gap: 8px;
  }

  @media (min-width: 1024px) {
    gap: 24px;
  }
`

export const BenefitsCardTitle = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;

  @media (max-width: 1023px) {
  }

  @media (min-width: 1024px) {
    justify-content: center;
    align-items: center;
  }
`

export const BenefitCardTitleText = styled.div`
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};

  font-style: normal;
  font-weight: 500;
  line-height: 120%;


  @media (max-width: 1023px) {
    font-size: 18px;
    letter-spacing: -0.36px;
    max-width: 350px;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
    letter-spacing: -0.72px;
  }
`

export const BenefitCardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

export const BenefitCardContentText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};

  font-style: normal;
  font-weight: 400;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.28px;
    
    max-width: 350px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 150%;
  }
`

export const SectionDecorationThread = styled(imgThread)`
  position: absolute;
  margin: 0;
  left: 0;
  top: -250px;
  flex-shrink: 0;
  z-index: -1;


  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`
export const SectionDecorationThread1Mobile = styled(imgThread1Mobile)`
  position: absolute;
  margin: 0;
  left: calc(50% - 180px);
  top: 450px;
  flex-shrink: 0;
  z-index: -1;


  @media (max-width: 1023px) {
    display: block;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

export const SectionDecorationThread2 = styled(imgThread2)`
  position: absolute;
  margin: 0;
  left: 0;
  top: 100px;
  z-index: -1;
  width: 100%;
  height: 100%;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`

export const BenefitsTitleMobile = styled.h2`
  text-align: center;

  font-size: 24px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.48px;
`
export const BenefitsTitle = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.96px;
  align-items: center;
`

export const BenefitsTitleWrapperMobile = styled.div`
  @media (max-width: 1023px) {
    display: block;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`
export const BenefitsTitleWrapper = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`

export const Marker = styled.div`
  background-image: url(${heroImage});
  padding: 8px;
  margin-left: 4px;
`
