import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../../config/paths";
import {
    formatDateToDDMMYYYYWithDashes,
    formatDateToDMMMMYYYYTimePolish,
    formatPaymentStatus,
    formatTime,
    isEarlierThanNow,
    parseDateTime,
    parsePaymentStatus
} from "../../../../config/utils";
import {Appointment} from "../../../../state/appointments/types";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../../../../state/store";
import {fetchAllDoctors} from "../../../../state/doctors/actions";
import {fetchSpecializations} from "../../../../state/specializations/actions";
import {fetchChildren} from "../../../../state/children/actions";
import {
    CardBig,
    Cell,
    CellText,
    ChildAvatarContainer,
    ChildAvatarImage,
    ChildName,
    HeaderCell,
    HeaderTableRow,
    Headline,
    MobileCardBadge,
    MobileCardBadgeText,
    MobileCardBadgeWrapper,
    MobileCardContent,
    MobileCardDate,
    MobileCardHeader,
    MobileCardItem,
    OkBubble,
    OutlineBubble,
    ResultsCellCentered,
    TableCardContent,
    TableCardRows,
    TableRow,
} from "./LeftBottomAppointmentsTable.styles";
import IcUpRight from "../../../Shared/Icons/IcUpRight";

const LeftBottomAppointmentsTable: React.FC<{
    appointments: Appointment[],
}> = ({appointments}) => {

    const navigate = useNavigate()
    const doctors = useSelector((state: AppState) => state.doctors.data)
    const specializations = useSelector((state: AppState) => state.specializations.items)
    const children = useSelector((state: AppState) => state.children.children)
    const [childrenLoading, setChildrenLoading] = useState(false)
    const [doctorsLoading, setDoctorsLoading] = useState(false)
    const [specializationsLoading, setSpecializationsLoading] = useState(false)
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!children && !childrenLoading) {
            setChildrenLoading(true)
            dispatch(fetchChildren())
                .catch(error => {
                    console.log("error loading children", error)
                })
                .finally(() => {
                    setChildrenLoading(false)
                })
        }
    }, [children, childrenLoading, setChildrenLoading, dispatch]);


    useEffect(() => {
        if (!specializations && !specializationsLoading) {
            setSpecializationsLoading(true)
            dispatch(fetchSpecializations())
                .catch(error => {
                    console.log("error loading specializations", error)
                })
                .finally(() => {
                    setSpecializationsLoading(false)
                })
        }
    }, [specializations, specializationsLoading, setSpecializationsLoading, dispatch]);

    useEffect(() => {
        if (!doctors && !doctorsLoading) {
            setDoctorsLoading(true)
            dispatch(fetchAllDoctors())
                .catch(error => {
                    console.log("error loading doctors", error)
                })
                .finally(() => {
                    setDoctorsLoading(false)
                })
        }
    }, [doctors, doctorsLoading, setDoctorsLoading, dispatch]);

    const createChildName = (appointment: Appointment) => {
        if (!appointment.childId) {
            return "nie podano"
        }
        return children?.find(c => c.id === appointment.childId)?.name || "nie podano"
    }
    const createDate = (appointment: Appointment) => {
        if (!appointment.startTime) {
            return "nie podano"
        }
        const date = parseDateTime(appointment.startTime)
        if (!date) {
            return "nie podano"
        }
        return formatDateToDDMMYYYYWithDashes(date)
    }

    const createTime = (appointment: Appointment) => {
        if (!appointment.startTime) {
            return "nie podano"
        }
        const date = parseDateTime(appointment.startTime)
        if (!date) {
            return "nie podano"
        }
        return formatTime(date)
    }

    const createDoctor = (appointment: Appointment): string => {
        const doctor = doctors?.find(doc => doc.id === appointment.doctorId)
        if (!doctor) {
            return "nie podano"
        }
        return doctor.name
    }

    const createStatus = (appointment: Appointment): string => {
        if (isEarlierThanNow(parseDateTime(appointment.startTime))) {
            return "Zakończono"
        }
        return formatPaymentStatus(parsePaymentStatus(appointment.status))
    }

    const createStatusColor = (appointment: Appointment): string => {
        switch (appointment.status) {
            case "pending_payment":
                return "red"
            case "paid":
                return "green"
            case "cancelled":
                return "yellow"
        }
        return "yellow"
    }

    function renderOpenChatButton(appointment: Appointment) {
        if (!appointment.roomUrl) {
            return null
        }
        if (parsePaymentStatus(appointment.status) !== "paid") {
            return null
        }
        if (isBeforeOneHour(parseDateTime(appointment.startTime))) {
            return null
        }
        return <OutlineBubble
            onClick={() => window.open(appointment.roomUrl, '_blank', 'noopener,noreferrer')}
        >Połącz</OutlineBubble>;
    }

    function isBeforeOneHour(dateToCheck: Date | null): boolean {
        if (!dateToCheck) {
            return false
        }

        const currentTime = new Date()
        const oneHourAgo = new Date(currentTime.getTime() - 1000 * 60 * 60)
        return dateToCheck < oneHourAgo
    }


    const renderTableRow = (appointment: Appointment, index: number) => {
        return (
            <TableRow key={index}>
                <Cell>
                    <CellText>{createChildName(appointment)}</CellText>
                </Cell>
                <Cell>
                    <CellText>{createDate(appointment)}</CellText>
                </Cell>
                <Cell>
                    <CellText>{createTime(appointment)}</CellText>
                </Cell>
                <Cell>
                    <CellText>{createDoctor(appointment)}</CellText>
                </Cell>
                <ResultsCellCentered>
                    {renderOpenChatButton(appointment)}
                </ResultsCellCentered>
                <ResultsCellCentered onClick={() => {
                    navigate(Paths.APPOINTMENT_DETAILS.replace(":id", appointment.id.toString()))
                }}>
                    <OkBubble
                        $color={createStatusColor(appointment)}
                    ><CellText>{createStatus(appointment)}</CellText></OkBubble>
                </ResultsCellCentered>
            </TableRow>
        );
    }

    const renderMobileCardContent = () => {
        if (appointments.length == 0) {
            return null
        }
        const appointment = appointments[0]
        const child = children?.find(el => el.id === appointment.childId)
        if (!child) {
            return null
        }
        return (
            <MobileCardContent>
                <MobileCardItem onClick={() => {
                    navigate(Paths.APPOINTMENT_DETAILS.replace(":id", appointment.id.toString()))
                }}>
                    <MobileCardHeader>
                        <ChildAvatarContainer>
                            <ChildAvatarImage
                                alt={child.name}
                                src={`${process.env.REACT_APP_API_BASE_URL}${child.image}`}
                            />
                        </ChildAvatarContainer>
                        <ChildName>{child.name}</ChildName>
                        <IcUpRight/>
                    </MobileCardHeader>
                    <MobileCardDate>{formatDateToDMMMMYYYYTimePolish(parseDateTime(appointment.startTime))}</MobileCardDate>
                    <MobileCardBadgeWrapper>
                        <MobileCardBadge>
                            <MobileCardBadgeText>{formatPaymentStatus(parsePaymentStatus(appointment.status))}</MobileCardBadgeText>
                        </MobileCardBadge>
                    </MobileCardBadgeWrapper>
                </MobileCardItem>
            </MobileCardContent>
        );
    };

    return (
        <CardBig>
            <TableCardContent>
                <Headline>Nadchodząca wizyta</Headline>
                {renderMobileCardContent()}
                <HeaderTableRow>
                    <HeaderCell>Dziecko</HeaderCell>
                    <HeaderCell>Data</HeaderCell>
                    <HeaderCell>Godzina</HeaderCell>
                    <HeaderCell>Specjalista</HeaderCell>
                    <ResultsCellCentered>Teleporada</ResultsCellCentered>
                    <ResultsCellCentered>Płatność</ResultsCellCentered>
                </HeaderTableRow>
                <TableCardRows>
                    {appointments.map((el, i) => renderTableRow(el, i))}
                </TableCardRows>
            </TableCardContent>
        </CardBig>
    )
}

export default LeftBottomAppointmentsTable