import React, {useState} from "react";
import icCalendar from "../../../../../assets/images/ic_calendar.svg";
import ButtonPrimary from "../../../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {
    ButtonSchedule,
    DateSummary,
    RightContainer,
    RightContainerTitle,
    SummaryTitle,
    SummaryValue,
    SummaryWrapper
} from "./CalendarSideWidget.styles";
import CalendarDateTimePicker from "./CalendarDateTimePicker";
import {Paths} from "../../../../../config/paths";
import {useNavigate} from "react-router-dom";


const CalendarSideWidget: React.FC<{ disabled: Date[], doctorId: number }> = ({disabled, doctorId}) => {
    const [selectedDay, setSelectedDay] = useState<Date | null>(null)
    const [selectedSlot, setSelectedSlot] = useState<Date | null>(null)

    const navigate = useNavigate()

    const renderSummaryDate = (date: Date) => {
        const monthName = date.toLocaleString('pl', {month: 'short'});
        const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
        return `${date.getDate()} ${capitalizedMonthName} ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`
    }

    const renderWidgetFooter = () => {
        if (!selectedSlot) {
            return null
        }
        return (
            <>
                <SummaryWrapper>
                    <SummaryTitle>Podsumowanie</SummaryTitle>
                    <DateSummary>
                        <img alt="podsumowanie" src={icCalendar}/>
                        <SummaryValue>{renderSummaryDate(selectedSlot)}</SummaryValue>
                    </DateSummary>
                </SummaryWrapper>
                <ButtonSchedule>
                    <ButtonPrimary
                        title="Umów wizytę"
                        onClick={() => {
                            const sd = selectedDay
                            if (!sd) {
                                return
                            }
                            const ss = selectedSlot
                            if (!ss) {
                                return
                            }
                            const date = new Date(sd?.getFullYear(), sd?.getMonth(), sd?.getDate(), ss?.getHours(), ss?.getMinutes(), 0, 0)
                            const dateString = date.toISOString()
                            navigate(
                                Paths.APPOINTMENT_CREATE.replace(":doctorId", doctorId.toString()),
                                {state: {date: dateString}}
                            )
                        }}
                    />
                </ButtonSchedule>
            </>
        );
    };

    function renderWidgetHeader() {
        return <>
            <RightContainerTitle>
                Terminy
            </RightContainerTitle>
        </>;
    }

    return (
        <RightContainer>
            {renderWidgetHeader()}
            <CalendarDateTimePicker
                day={selectedDay}
                setDay={el => {
                    setSelectedDay(el)
                }}
                time={selectedSlot}
                setTime={el => {
                    setSelectedSlot(el)
                }}
                disabled={disabled}/>
            {renderWidgetFooter()}
        </RightContainer>
    );
}

export default CalendarSideWidget