import {styled} from "styled-components";

export const ButtonContainer = styled.div<{ $width: string | undefined, $backgroundColor: string }>`
  display: flex;
  width: ${props => props.$width ? props.$width : 'auto'};
  padding: 12px 16px 12px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 40px;
  background-color: ${props => props.$backgroundColor};
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);
  gap: 10px;
  cursor: pointer;
`

export const ButtonText = styled.p<{ $color: string }>`
  font-weight: 500;
  color: ${props => props.$color};

  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    line-height: 160%;
  }
`

export const ButtonIcon = styled.img``
