import styled from "styled-components";

export const PeselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 41px;
`

export const ChildImageContainer = styled.div`
  display: flex;
  width: 106px;
  height: 106px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.gray700};
  background: ${props => props.theme.color.gray100};
  overflow: hidden;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const PopupContent = styled.div`

  display: flex;
  flex-direction: column;
  gap: 32px;
  
  @media (max-width: 599px) {
    
  }

  @media (min-width: 600px) {
    width: 400px;
  }
`

export const ChildImageRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ChildImageButton = styled.p`
  cursor: pointer;
  color: ${props => props.theme.color.violet600};
  line-height: 150%; /* 24px */
`

export const ChildImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`