import React, {useState} from "react";
import icSpyglass from "../../../assets/images/ic_search.svg"
import SettingsCard from "./SettingsCard";
import {Paths} from "../../../config/paths";
import {useNavigate} from "react-router-dom";
import {items} from "../type";
import {
    CardsRow,
    ContentWrapper,
    HelpContainer,
    Icon,
    InputHeaderWrapper,
    InputLabel,
    InputWrapper,
    StyledInput,
    Title
} from "./Help.styles";

const Help: React.FC = () => {

    const [query, setQuery] = useState("")
    const navigate = useNavigate()

    return (
        <HelpContainer>
            <Title>Pomoc</Title>
            <ContentWrapper>
                <InputHeaderWrapper>
                    <InputLabel>Jak możemy pomóc?</InputLabel>
                    <InputWrapper>
                        <Icon src={icSpyglass}/>
                        <StyledInput
                            value={query}
                            onChange={e => {
                                e.preventDefault()
                                setQuery(e.target.value)
                            }}
                            onFocus={() => {
                            }}
                            placeholder="Wpisz szukane słowa, np. umawianie wizyty"
                            onBlur={() => {
                            }}
                        />
                    </InputWrapper>
                </InputHeaderWrapper>

                <CardsRow>
                    {items
                        .filter(el => el.title.toLowerCase().includes(query.toLowerCase()))
                        .map((c, i) => (
                            <SettingsCard
                                key={i}
                                img={c.image}
                                title={c.title}
                                onClick={() => navigate(Paths.HELP_SECTION.replace(":id", c.id))}
                            />
                        ))}
                </CardsRow>
            </ContentWrapper>
        </HelpContainer>
    )
}

export default Help