import React from 'react';
import {ErrorIcon, ErrorText, ErrorWrapper} from "./Input.styles";
import icError from "../../../assets/images/ic_input_error.svg";
import {CountryCodeSpan, InputWrapper, PhoneInputField, Row} from "./PhoneNumberInput.styles";

const PhoneNumberInput: React.FC<{
    phoneNumber: string,
    error?: string,
    correct?: boolean,
    disabled?: boolean,
    setPhoneNumber: (phone: string) => void
    onBlur: () => void
}> = (
    {
        phoneNumber,
        setPhoneNumber,
        error,
        correct,
        onBlur,
        disabled,
    }
) => {
    const formatPhoneNumber = (value: string) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
        }
        return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 10)}`;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
    };

    const renderError = () => {
        if (!error) return null;
        return (
            <ErrorWrapper>
                <ErrorIcon src={icError}/>
                <ErrorText>{error}</ErrorText>
            </ErrorWrapper>
        );
    }

    const getInputState = () => {
        if (disabled) {
            return "disabled"
        }
        if (error) {
            return "error"
        }
        if (correct) {
            return "success"
        }
        return "default"
    }

    return (
        <InputWrapper>
            <Row $state={getInputState()}>
                <CountryCodeSpan>+48</CountryCodeSpan>
                <PhoneInputField
                    type="text"
                    value={formatPhoneNumber(phoneNumber)}
                    onChange={handleChange}
                    placeholder="000 000 000"
                    onBlur={() => onBlur()}
                />
            </Row>
            {renderError()}
        </InputWrapper>
    );
};

export default PhoneNumberInput;
