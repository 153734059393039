import React from "react";
import icAi from '../../../assets/images/ic_ai.svg'
import ButtonUpLink from "../../Shared/Button/ButtonUpLink/ButtonUpLink";
import {formatDateToDDMMYYYYWithDashes, formatPaymentStatus, formatTime, parseDateTime, parsePaymentStatus} from "../../../config/utils";
import {
    CardBig,
    Cell,
    CellText,
    ClickableCell,
    HeaderCell,
    HeaderTableRow,
    Headline,
    TableCardContent,
    TableCardRows,
    TableRow
} from "./ConfirmedAppointments.styles";
import IcUpRight from "../../Shared/Icons/IcUpRight";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../config/paths";
import {ConfirmedAppointment} from "../../../state/doctorAppointments/type";

const ConfirmedAppointments: React.FC<{
    appointments: ConfirmedAppointment[],
    onCancelClicked: (id: number) => void,
    onShowAllClicked?: () => void,
}> = ({
          appointments,
          onCancelClicked,
          onShowAllClicked,
      }) => {

    const navigate = useNavigate()

    const renderActionCell = (appointment: ConfirmedAppointment) => {
        const dt = parseDateTime(appointment.date)
        if (!dt || new Date() > dt) {
            return (<Cell $align="center"></Cell>)
        }
        return (
            <ClickableCell $align="center" onClick={() => onCancelClicked(appointment.id)}>
                <CellText>Odwołaj</CellText>
            </ClickableCell>
        );
    };
    const renderAiDiagnosisCell = (appointment: ConfirmedAppointment) => {
        if (appointment.hasAiDiagnosis) {
            return (
                <ClickableCell
                    $align="center"
                    onClick={() => navigate(Paths.AI_DIAGNOSIS_LIST.replace(":childId", appointment.childId.toString()))}
                >
                    <img src={icAi} alt="AI" style={{opacity: 1}}></img>
                </ClickableCell>
            )
        }
        return (
            <Cell $align="center">
                <img src={icAi} alt="AI" style={{opacity: 0.3}}></img>
            </Cell>
        )
    }

    const renderTableRow = (appointment: ConfirmedAppointment, index: number) => {
        return (
            <TableRow key={index}>
                <Cell $align="start">
                    <CellText>{appointment.childName}</CellText>
                </Cell>
                <Cell $align="start">
                    <CellText>{formatDateToDDMMYYYYWithDashes(parseDateTime(appointment.date))}</CellText>
                </Cell>
                <Cell $align="start">
                    <CellText>{formatTime(parseDateTime(appointment.date))}</CellText>
                </Cell>
                <Cell $align="start">
                    <CellText>{appointment.parentName}</CellText>
                </Cell>
                <Cell $align="start">
                    <CellText>{formatPaymentStatus(parsePaymentStatus(appointment.status))}</CellText>
                </Cell>
                {renderAiDiagnosisCell(appointment)}
                {renderActionCell(appointment)}
                <ClickableCell
                    $align="end"
                    onClick={() => navigate(Paths.DOCTOR_APPOINTMENT.replace(":id", appointment.id.toString()))}>
                    <IcUpRight/>
                </ClickableCell>
            </TableRow>
        );
    }

    const renderButtonUpLink = () => {
        if (!onShowAllClicked) {
            return null
        }
        return (
            <ButtonUpLink
                children="Zobacz wszystkie"
                onClick={onShowAllClicked}
            />
        )
    };

    return (
        <CardBig>
            <TableCardContent>
                <Headline>Nadchodzące wizyty ({appointments.length})</Headline>
                {renderButtonUpLink()}
                <HeaderTableRow>
                    <HeaderCell $align="left">Pacjent</HeaderCell>
                    <HeaderCell $align="left">Data</HeaderCell>
                    <HeaderCell $align="left">Godzina</HeaderCell>
                    <HeaderCell $align="left">Opiekun</HeaderCell>
                    <HeaderCell $align="left">Płatność</HeaderCell>
                    <HeaderCell $align="center">Diagnozy AI</HeaderCell>
                    <HeaderCell $align="center">Akcja</HeaderCell>
                    <HeaderCell $align="right">Otwórz</HeaderCell>
                </HeaderTableRow>
                <TableCardRows>
                    {appointments.map((appointment, i) => renderTableRow(appointment, i))}
                </TableCardRows>
            </TableCardContent>
        </CardBig>
    )
}

export default ConfirmedAppointments