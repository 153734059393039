import {SettingsState} from "./types";
import {SettingsAction} from "./actions";
import {ProfileAction} from "../profile/actions";


const initialState: SettingsState = {
    settings: null,
}

export const settingsReducer = (state = initialState, action: SettingsAction | ProfileAction): SettingsState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "GET_SETTINGS":
            return {
                ...state,
                settings: action.settings,
            };
        default:
            return state
    }
}