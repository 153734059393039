import styled from "styled-components";

export const Link = styled.span`
  cursor: pointer;
  color: ${props => props.theme.color.violet600};
`

export const CardWrapper = styled.div`
  border-radius: 16px;
  background: ${props => props.theme.color.gray100};
  
  @media (max-width: 599px) {
    margin: 0 20px;
    padding: 24px;
  }

  @media (min-width: 600px) {
    box-sizing: border-box;
    align-items: center;
    justify-content: start;
    width: 910px;
    padding: 40px 56px 48px 56px;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    width: 400px;
    margin: auto;
    gap: 24px;
  }
`

export const CardTitle = styled.h4`
  color: ${props => props.theme.color.gray1000};
  
  @media (max-width: 599px) {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.36px;
  }

  @media (min-width: 600px) {
    
  }
`

export const LinkButton = styled.button`
  color: ${props => props.theme.color.violet600};
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    gap: 40px;
    margin-top: 20px;
  }
`

export const Przelewy24CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.beige500};
  background: ${props => props.theme.color.gray100};
  
  @media (max-width: 599px) {
    padding: 24px;
    gap: 24px;

    border-radius: 8px;
    border: 1px solid ${props => props.theme.color.beige500};
    background: ${props => props.theme.color.gray100};

    box-shadow: 0 6px 20px 0 rgba(233, 218, 209, 0.80);
  }

  @media (min-width: 600px) {
    padding: 24px 48px;
    gap: 48px;
  }
`

export const Przelewy24Logo = styled.img`
`

export const PaymentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const PaymentDetailsTitle = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-weight: 500;
  line-height: 20px;
`

export const PaymentDetailsSubtitle = styled.p`
  color: ${props => props.theme.color.gray800};
  font-size: 12px;
  line-height: 150%;
`