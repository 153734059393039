import React, {useState} from 'react';
import icError from "../../../assets/images/ic_input_error.svg"
import {
    CorrectIconOutline,
    ErrorIcon,
    ErrorIconOutline,
    ErrorText,
    ErrorWrapper,
    InputContainer,
    InputLabel,
    InputWithProps,
    InputWrapper,
    OutlineIconsWrapper,
    ShowText
} from "./Input.styles";

interface InputFieldProps {
    type?: string;
    label?: string;
    value: string;
    error?: string | null;
    correct?: boolean | null;
    onChange: (event: string) => void;
    onBlur?: () => void;
    disabled?: boolean
}

const InputField: React.FC<InputFieldProps> = (
    {
        label,
        value,
        error,
        type,
        correct,
        onChange,
        onBlur,
        disabled,
    }
) => {

    const [isObfuscated, setIsObfuscated] = useState(true);

    const renderError = () => {
        if (!error) return null;
        return (
            <ErrorWrapper>
                <ErrorIcon src={icError}/>
                <ErrorText>{error}</ErrorText>
            </ErrorWrapper>
        );
    }

    const handleClick = () => {
        setIsObfuscated(!isObfuscated)
    };

    const renderIndicator = () => {
        if (type === "password") {
            return (
                <OutlineIconsWrapper>
                    <ShowText onClick={(event) => {
                        event.preventDefault();
                        handleClick();
                    }}>{isObfuscated ? "pokaż" : "ukryj"}</ShowText>
                </OutlineIconsWrapper>
            );
        } else if (error) {
            return (
                <OutlineIconsWrapper>
                    <ErrorIconOutline/>
                </OutlineIconsWrapper>
            )
        } else if (correct) {
            return (
                <OutlineIconsWrapper>
                    <CorrectIconOutline/>
                </OutlineIconsWrapper>
            );
        }
    }

    const actualType = () => {
        if (type === "password") {
            return isObfuscated ? "password" : "text"
        }
        return type || "text"
    }

    const getInputState = () => {
        if (disabled) {
            return "disabled"
        }
        if (error) {
            return "error"
        }
        if (correct) {
            return "success"
        }
        return "default"
    }

    return (
        <InputWrapper>
            <InputLabel>{label}</InputLabel>
            <InputContainer>
                <InputWithProps
                    onBlur={() => {
                        if (onBlur) {
                            onBlur()
                        }
                    }}
                    type={actualType()}
                    placeholder=""
                    value={value}
                    onChange={(e) => {
                        e.preventDefault();
                        onChange(e.target.value);
                    }}
                    disabled={disabled}
                    $state={getInputState()}
                />
                {renderIndicator()}
            </InputContainer>
            {renderError()}
        </InputWrapper>
    );
}

export default InputField