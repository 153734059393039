import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import IcPersonSmall from "../../Shared/Icons/IcPersonSmall";
import Tabs from "./Tabs/Tabs";
import PersonalDetailsTab from "./Tabs/PersonalDetailsTab/PersonalDetailsTab";
import MedicalDocumentsTab from "./Tabs/MedicalDocumentsTab/MedicalDocumentsTab";
import SymptomsTab from "./Tabs/SymptomsTab/SymptomsTab";
import NotImplementedTab from "./Tabs/NotImplementedTab/NotImplementedTab";
import {convertToAge} from "../../../config/utils";
import {
    ButtonsWrapper,
    ButtonTab,
    ChildImage,
    ChildImageContainer,
    ChildInfo,
    ChildInfoAge,
    ChildInfoButtonDark,
    ChildInfoButtonLight,
    ChildInfoButtons,
    ChildInfoColumn,
    ChildInfoDetails,
    ChildInfoName,
    Container,
    Content,
    MobileTabs,
    Title,
    TitleWrapper
} from "./ChildDetails.styles";
import ButtonOutline from "../../Shared/Button/ButtonOutline/ButtonOutline";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import imgEdit from "../../../assets/images/ic_edit.svg"
import {Paths} from "../../../config/paths";
import EditChildPopUp from "./EditChildPopUp";
import {useFetchChildren} from "../../../state/children/useFetchChildren";

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}


const ChildDetails: React.FC = () => {
    const tabs: string[] = ['Dane osobowe']
    const [activeTab, setActiveTab] = useState(0);
    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);
    const children = useFetchChildren()
    const child = children.find((child) => child.id === id)
    const navigate = useNavigate()

    const getChildAge = () => {
        const dob = child?.dob
        if (dob) {
            const [day, month, year] = dob.split('.').map(Number);
            const birthDate = new Date(year, month - 1, day);
            return convertToAge(birthDate)
        }
        return ""
    }

    function renderContent() {
        if (!child) {
            return null
        }
        switch (activeTab) {
            case 0:
                return (<PersonalDetailsTab child={child}/>)
            case 1:
                return (<MedicalDocumentsTab/>)
            case 2:
                return (<NotImplementedTab title="Brak diagnoz"/>)
            case 3:
                return (<SymptomsTab/>)
            case 4:
                return (<NotImplementedTab title="Brak leków"/>)
            default:
                return null
        }
    }

    const [editing, setEditing] = useState(false)
    const renderPopup = () => {
        if (!child) {
            return null
        }
        return (
            <EditChildPopUp
                child={child}
                editing={editing}
                onClose={() => {
                    setEditing(false)
                }}
            />
        );
    }

    const renderImage = () => {
        if (child?.image) {
            return (
                <ChildImageContainer>
                    <ChildImage src={process.env.REACT_APP_API_BASE_URL + child?.image} alt="Preview"/>
                </ChildImageContainer>
            )
        } else {
            return (
                <ChildImageContainer>
                    <IcPersonSmall/>
                </ChildImageContainer>
            )
        }
    }

    const renderMobileTabs = () => {
        return (
            <MobileTabs>
                {tabs.map((tab, index) => (
                    <ButtonTab
                        key={index}
                        $isActive={activeTab === index}
                        onClick={() => setActiveTab(index)}
                    >{tab}</ButtonTab>))}
            </MobileTabs>
        )
    }

    const navigateToNewDiagnosis = () => {
        let query = ""
        if (child?.id) {
            query = "?childId=" + child.id.toString()
        }
        navigate(Paths.DIAGNOSIS_NEW + query)
    };

    return (
        <div>
            {renderPopup()}
            <div style={{overflowY: 'auto', maxHeight: '100vh'}}>
                <Container>
                    <TitleWrapper>
                        <Title>Profil dziecka</Title>
                        <ButtonsWrapper>
                            <ButtonOutline title="Wykonaj diagnozę AI" onClick={() => navigateToNewDiagnosis()}/>
                            <ButtonPrimary
                                title="Edytuj profil"
                                onClick={() => setEditing(true)}
                                icon={<img src={imgEdit} alt="Edytuj profil"/>}
                            />
                        </ButtonsWrapper>
                    </TitleWrapper>
                    {renderMobileTabs()}
                    <ChildInfo>
                        <ChildInfoDetails>
                            {renderImage()}
                            <ChildInfoColumn>
                                <ChildInfoName>{child?.name}</ChildInfoName>
                                <ChildInfoAge>{getChildAge()}</ChildInfoAge>
                            </ChildInfoColumn>
                        </ChildInfoDetails>
                        <ChildInfoButtons>
                            <ChildInfoButtonLight
                                onClick={() => setEditing(true)}
                            >Edytuj profil</ChildInfoButtonLight>
                            <ChildInfoButtonDark
                                onClick={() => navigateToNewDiagnosis()}
                            >Wykonaj diagnozę AI</ChildInfoButtonDark>
                        </ChildInfoButtons>
                    </ChildInfo>
                    <Tabs
                        tabs={tabs}
                        activeTab={activeTab}
                        onTabClick={setActiveTab}
                    />
                    <Content>
                        {renderContent()}
                    </Content>
                </Container>
            </div>
        </div>
    );
}

export default ChildDetails;