import React from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../state/store';
import Dashboard from '../Dashboard/Dashboard';
import WelcomeScreen from '../Welcome/Welcome';

const RootPage: React.FC = () => {
    const isLoggedIn = useSelector((state: AppState) => state.profile.profile != null);
    return (isLoggedIn ? <Dashboard/> : <WelcomeScreen/>);
}

export default RootPage;
