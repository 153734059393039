import styled from "styled-components";

export const AccordionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 599px) {
    gap: 16px;
    margin: 0 16px 16px 26px;
  }

  @media (min-width: 600px) {
    width: 100%;
    gap: 24px;
    margin-bottom: 88px;
  }
`;

export const FaqItem = styled.div`
  position: relative;
  justify-content: stretch;
  box-sizing: border-box;
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 10px 20px 0 rgba(233, 218, 209, 0.26);
  
  @media (max-width: 599px) {
    border-radius: 8px;
    align-items: start;
  }

  @media (min-width: 600px) {
    width: 100%;
    border-radius: 16px;
  }
`

export const Question = styled.p`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
  z-index: 2;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;


  @media (max-width: 599px) {
    font-size: 14px;
    padding: 16px;
  }

  @media (min-width: 600px) {
    border-radius: 56px;
    padding: 18px 32px 18px 40px;

  }
`;

export const Answer = styled.p <{ open: boolean }>`
  max-height: ${props => (props.open ? '1000px' : '0')};
  padding: ${props => (props.open ? '16px 32px 32px 40px' : '0')};
  transition: ${props => (props.open ? 'all 0.3s cubic-bezier(0, 1, 0, 1)' : 'all 0.3s cubic-bezier(1, 0, 1, 0)')};
  background: ${props => props.theme.color.gray100};
  overflow: hidden;
  border-radius: 0 0 24px 24px;
  color: ${props => props.theme.color.gray1000};
`;

export const Title = styled.h3`
  color: ${props => props.theme.color.gray1000};
`
export const HelpContainer = styled.div`
  min-height: 100vh;
  box-sizing: border-box;
  
  @media (max-width: 599px) {
    
  }

  @media (min-width: 600px) {
    padding: 48px;
  }
`

export const InputLabel = styled.h5`
  color: ${props => props.theme.color.gray1000};


  @media (max-width: 599px) {
    padding: 16px 24px;
  }

  @media (min-width: 600px) {

  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
`

export const InputHeaderWrapper = styled.div`

  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    width: 800px;
    margin-top: 125px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid #ccc;

  border-radius: ${props => props.theme.radius.radius500};
  border: 1px solid ${props => props.theme.color.gray600};
  margin-bottom: 136px;

  &:focus {
    border: 2px solid ${props => props.theme.color.gray900};
    outline: none;
  }

  &:checked {
    background-color: ${props => props.theme.color.violet600};
  }

  background-color: ${props => props.theme.color.gray100};


  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  flex-grow: 1;
  height: 50px;
  border-radius: 0;
  border: none;

  &:focus {
    border: none;
    outline: none;
  }

  &:checked {
    background: none;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  padding: 0 16px;
`;

export const BackIcon = styled.img`
`

export const BackButton = styled.div`
  margin-top: 16px;
  cursor: pointer;
  display: inline-flex;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1;
`

export const BackText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-weight: 400;
  line-height: 150%;
`

export const ChevronImage = styled.img`
  transform: rotate(90deg);
`