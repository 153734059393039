import {styled} from 'styled-components';
import icErrorOutline from "../../../assets/images/ic_input_error_outline.svg"
import icCorrectOutline from "../../../assets/images/ic_input_correct_outline.svg"

type InputState = 'default' | 'error' | 'disabled' | 'success';

interface InputProps {
    $state?: InputState;  // It's optional, so if not provided, the input will use the default styles
}

export const InputWithProps = styled.input<InputProps>`
  box-sizing: border-box;
  width: 100%;
  min-height: ${props => props.theme.size.size1400};
  padding: ${props => props.theme.spacing.spacing400};

  ${({$state, theme}) => $state === 'error' && `
      border-width: 2px;
      border-color: ${theme.color.error600};
      background-color: ${theme.color.error100};
      &:focus {
        border: 2px solid ${theme.color.error600};
      }
    `
  }

  ${({$state, theme}) => $state === 'success' && `
      border-width: 2px;
      border-color: ${theme.color.violet600};
      background-color: ${theme.color.violet100};
      &:focus {
        border: 2px solid ${theme.color.violet600};
      }
    `
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

export const InputLabel = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.spacing200};
`

export const ErrorIcon = styled.img`
`

export const ErrorText = styled.p`
  color: ${props => props.theme.color.error600};
  font-size: 14px;
  line-height: 16px;
`

export const OutlineIconsWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`

export const ErrorIconOutline = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${icErrorOutline});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const CorrectIconOutline = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${icCorrectOutline});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const InputContainer = styled.div`
  position: relative;
`
export const ShowText = styled.p`
  color: ${props => props.theme.color.gray1000};
  text-align: right;
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  cursor: pointer;
`