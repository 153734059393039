import styled from "styled-components";

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;


  @media (max-width: 599px) {
    gap: 24px;
    margin-top: 24px;
  }

  @media (min-width: 600px) {
    gap: 52px;
    margin-top: 57px;
  }
`

export const ContentColumn = styled.div`
  flex-grow: 1;
  display: flex;

  flex-direction: row;

  @media (max-width: 599px) {
    gap: 8px;
    justify-content: space-between;
  }

  @media (min-width: 600px) {
  }
`

export const ContentColumnCell1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    width: 50%;
  }

`

export const CellTitle = styled.p`
  letter-spacing: -0.28px;
`

export const CellSubtitle = styled.p`
  color: ${props => props.theme.color.gray800};
  
  @media (max-width: 599px) {
    line-height: 20px;
    font-size: 12px;
  }

  @media (min-width: 600px) {
    font-size: 14px;
  }
`