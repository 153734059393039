import styled from "styled-components";

export const Title = styled.h3`
  padding-top: 56px;
  margin-bottom: 40px;
  font-size: 36px;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Container = styled.div`
  box-sizing: border-box;
  padding: 0 40px;
  min-height: 100vh;
`

export const Card = styled.div`
  background-color: ${props => props.theme.color.gray100};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  min-height: 600px;
`
