import React, {useRef, useState} from "react";
import {BubblesHolder, InputField, InputWrapper, SearchIcon} from "./HeroFormInput.styles";
import styled from "styled-components";
import icClose from "../../../assets/images/ic_close_small.svg"

export const BubbleWrapper = styled.div`
  padding: 8px;
  box-sizing: border-box;
  height: 24px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;


  @media (max-width: 599px) {
    background: ${props => props.theme.color.violet400};
    &:hover {
      background: ${props => props.theme.color.violet200};
    }
  }

  @media (min-width: 600px) {
    background: ${props => props.theme.color.pink500};
    &:hover {
      background: ${props => props.theme.color.pink200};
    }
  }
`
export const Bubble = styled.span`
  white-space: nowrap;
  color: ${props => props.theme.color.gray1000};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  
`
export const SuggestionItem = styled.li`
  margin: 8px;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.color.pink200};
  }
`;
export const Suggestions = styled.ul<{ $focus: boolean }>`
  display: ${props => props.$focus ? 'block' : 'none'};
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background: white;
  border-radius: 12px;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const Input: React.FC<{ selected: string[], setSelected: (selected: string[]) => void }> = (
    {
        selected,
        setSelected
    }
) => {

    const suggestions: string[] = [
        "Gorączka",
        "Kaszel",
        "Biegunka",
        "Wymioty",
        "Wysypka",
        "Ból brzucha",
        "Zmęczenie",
        "Trudności w oddychaniu",
        "Ból ucha",
        "Zmiany w apetycie",
    ]
    const [focus, setFocus] = useState(false)
    const mouseDownOnSuggestion = useRef(false)
    const [inputValue, setInputValue] = useState("")

    const renderSuggestion = (suggestion: string, idx: number) => {
        return (
            <SuggestionItem
                key={idx}
                onClick={() => {
                    setSelected([...selected, suggestion])
                    setFocus(false)
                    setInputValue("")
                }}
            >{suggestion}</SuggestionItem>
        );
    }

    function renderSuggestions() {
        if (suggestions.length == 0) {
            return null
        }
        let filtered = suggestions.filter(el => !selected.includes(el))
        if (inputValue !== "") {
            filtered = filtered.filter(el => el.toLowerCase().startsWith(inputValue.toLowerCase()))
        }
        return (
            <Suggestions
                $focus={focus}
                onMouseDown={() => {
                    mouseDownOnSuggestion.current = true
                }}
            >
                {filtered.map((suggestion, idx) => renderSuggestion(suggestion, idx))}
            </Suggestions>
        );
    }

    return (
        <Container>
            <InputWrapper>
                <SearchIcon/>
                <BubblesHolder>
                    {selected.map((item, idx) => (
                        <BubbleWrapper
                            key={idx}
                            onClick={() => {
                                setSelected(selected.filter(el => el !== item))
                            }}
                        >
                            <Bubble>{item}</Bubble>
                            <img src={icClose} alt="close"/>
                        </BubbleWrapper>
                    ))}
                    <InputField
                        placeholder="Wpisz objawy, np. ból głowy, gorączka"
                        value={inputValue}
                        onChange={e => {
                            e.preventDefault()
                            setInputValue(e.target.value)
                        }}
                        onFocus={() => {
                            setFocus(true)
                        }}
                        onBlur={() => {
                            if (!mouseDownOnSuggestion.current) {
                                setFocus(false);
                            }
                            mouseDownOnSuggestion.current = false;
                        }}
                    />
                </BubblesHolder>

            </InputWrapper>
            {renderSuggestions()}
        </Container>
    );
};
