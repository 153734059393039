import React, {useState} from "react";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import {isValidEmail} from "../../../config/utils";
import {
    ButtonWrapper,
    FormPart,
    HeaderPart,
    HeaderSubtitle,
    HeaderTitle,
    LinkButtonWrapper,
    RecoverFormWrapper,
    RecoveryLink,
    RecoveryText
} from "./RecoverForm.styles";
import InputField from "../../Shared/Input/Input";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {recoverPassword} from "../../../state/profile/actions";

const RecoverForm: React.FC<{
    navigateLogin: () => void,
    navigateSuccess: (email: string) => void,
}> = (
    {
        navigateLogin,
        navigateSuccess,
    }
) => {
    const [validateEmailOnType, setValidateEmailOnType] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [emailCorrect, setEmailCorrect] = useState<boolean | null>(null);

    const [loading, setLoading] = useState(false);
    const dispatch: AppDispatch = useDispatch()


    const validateEmailCorrect = (email: string) => {
        const isValid = isValidEmail(email);
        setEmailCorrect(isValid)
        return isValid
    }

    const validateEmailError = (email: string) => {
        const isValid = isValidEmail(email);
        setEmailError(!isValid ? "Nieprawidłowy adres email" : null)
        return isValid;
    }

    const validateForm = () => {
        return validateEmailError(email)
    }
    const renderRecoverForm = () => {
        return <RecoverFormWrapper>
            <HeaderPart>
                <HeaderTitle>Zresetuj hasło</HeaderTitle>
                <HeaderSubtitle>Nie pamiętasz hasła? Nie martw się, na podany adres email wyślemy wiadomość z
                    instrukcjąc jak ustawić nowe hasło.</HeaderSubtitle>
            </HeaderPart>
            <FormPart>
                <InputField
                    label="Email"
                    type={"email"}
                    value={email}
                    onChange={(em: string) => {
                        setEmail(em)
                        if (validateEmailOnType) {
                            validateEmailError(em)
                        }
                        validateEmailCorrect(em)
                    }}
                    error={emailError}
                    correct={emailCorrect}
                    onBlur={() => {
                        setValidateEmailOnType(true);
                        validateEmailError(email);
                    }}
                />
            </FormPart>
            <ButtonWrapper>
                <ButtonPrimary
                    title="Dalej"
                    onClick={() => {
                        if (!validateForm()) return
                        setLoading(true)
                        setEmailError(null)
                        dispatch(recoverPassword(email))
                            .then((response) => {
                                switch (response) {
                                    case "NO_USER":
                                        setEmailError("Użytkownik nie istnieje.")
                                        break;
                                    case "FAILURE":
                                        setEmailError("Wystąpił nieznany błąd.")
                                        break;
                                    case "SUCCESS":
                                        navigateSuccess(email)
                                        // do nothing
                                        break;
                                }
                            })
                            .catch(() => {
                                setEmailError("Wystąpił nieznany błąd.")
                            })
                            .finally(() => setLoading(false))
                    }}
                    progress={loading}/>
                <div>
                    <LinkButtonWrapper>
                        <RecoveryText>Zamiast tego</RecoveryText>
                        <RecoveryLink onClick={navigateLogin}>Wróć do logowania</RecoveryLink>
                    </LinkButtonWrapper>
                </div>
            </ButtonWrapper>
        </RecoverFormWrapper>;
    }

    return renderRecoverForm()

}

export default RecoverForm