import {Action, VaccinesState} from "./types";
import {ProfileAction} from "../profile/actions";


const initialState: VaccinesState = {
    vaccines: null,
    vaccinations: null,
};

export const vaccinesReducer = (state = initialState, action: Action | ProfileAction): VaccinesState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "FETCH_VACCINES_SUCCESS":
            return {
                ...state,
                vaccines: action.data || [],
            };
        case "FETCH_VACCINATIONS_SUCCESS":
            return {
                ...state,
                vaccinations: action.data || [],
            };
        default:
            return state;
    }
};