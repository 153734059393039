import React, {useState} from "react";
import icChevronBack from "../../../../../assets/images/ic_chevron_left.svg";
import styled from "styled-components";
import TimeSlotPicker from "../TimeSlotPicker/TimeSlotPicker";
import icCalendar from "../../../../../assets/images/ic_calendar.svg"
import {formatDateToDMMMMYYYYTimePolishShort} from "../../../../../config/utils";
import ButtonPrimary from "../../../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {Paths} from "../../../../../config/paths";
import {useNavigate} from "react-router-dom";


export const PopupTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.36px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

`

export const PopupHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`

export const PopupSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
  gap: 16px;

`
export const PopupSummaryDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

export const PopupSummaryTitle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.28px;
`

export const PopupSummaryDate = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.32px;
`

const CalendarMobilePopup: React.FC<{ onClose: () => void, doctorId: number, disabled: Date[], }> = ({onClose, doctorId, disabled}) => {
    const navigate = useNavigate()
    const [selectedDay, setSelectedDay] = useState<Date>(new Date())
    const [selectedSlot, setSelectedSlot] = useState<Date | null>(null)

    const renderSummary = () => {
        if (!selectedSlot) {
            return null
        }
        return <PopupSummaryDateWrapper>
            <img src={icCalendar} alt="calendar"/>
            <PopupSummaryDate>{formatDateToDMMMMYYYYTimePolishShort(selectedSlot)}</PopupSummaryDate>
        </PopupSummaryDateWrapper>;
    };


    const renderSummaryTitle = () => {
        if (!selectedSlot) {
            return <PopupSummaryTitle>Wybierz godzinę wizyty</PopupSummaryTitle>
        }
        return <PopupSummaryTitle>Podsumowanie</PopupSummaryTitle>;
    };

    function renderButton() {
        if (!selectedSlot) {
            return null
        }
        return <ButtonPrimary
            title="Umów wizytę"
            onClick={() => {
                const sd = selectedDay
                if (!sd) {
                    return
                }
                const ss = selectedSlot
                if (!ss) {
                    return
                }
                const date = new Date(sd?.getFullYear(), sd?.getMonth(), sd?.getDate(), ss?.getHours(), ss?.getMinutes(), 0, 0)
                const dateString = date.toISOString()
                navigate(
                    Paths.APPOINTMENT_CREATE.replace(":doctorId", doctorId.toString()),
                    {state: {date: dateString}}
                )
            }
            }/>;
    }

    return (
        <Content>
            <PopupHeader onClick={onClose}>
                <img src={icChevronBack} alt="back"/>
                <PopupTitle>Umów wizytę</PopupTitle>
            </PopupHeader>
            <TimeSlotPicker
                onTimeSlotClick={(newTime) => {
                    if (newTime.toISOString() === selectedSlot?.toISOString()) {
                        setSelectedSlot(null)
                    } else {
                        setSelectedSlot(newTime)
                    }
                }}
                onDateClick={(date) => setSelectedDay(date)}
                selectedTime={selectedSlot}
                currentDate={selectedDay}
                disabled={disabled}
            />
            <PopupSummary>
                {renderSummaryTitle()}
                {renderSummary()}
            </PopupSummary>
            {renderButton()}
        </Content>
    );
}

export default CalendarMobilePopup