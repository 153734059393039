import styled from "styled-components";
import {Card} from "../../Shared/Div/Card";

export const TableCardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`

export const CardBig = styled(Card)`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
`

export const HeaderCell = styled.p<{ $align: "left" | "center" | "right" }>`
  width: 12%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
  text-align: ${props => props.$align};
`

export const HeaderTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  padding: 8px 16px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray300};
`

export const Cell = styled.div<{ $align: "start" | "center" | "end" }>`
  width: 12%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: ${props=>props.$align};
  justify-content: ${props=>props.$align};
`


export const ClickableCell = styled(Cell)`
  cursor: pointer;
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
`

export const CellText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const TableCardRows = styled.div`
  display: flex;
  flex-direction: column;
  //height: 475px;
  padding-top: 16px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Headline = styled.h4`
  margin-bottom: 8px;
`
export const OutlineBubble = styled.p`
  display: flex;
  height: 24px;
  padding: 0 8px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.gray1000}; // var(--greys-grey-1000, #161618)
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  cursor: pointer;
`
