import styled from "styled-components";

export const Container = styled.div`
  padding-top: 10px;
  padding-left: 40px;
  padding-bottom: 40px;
  min-height: 100vh;
`;

export const Title = styled.h3`
  margin-top: 56px;
  margin-bottom: 60px;
`

export const PregnancyInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 45px;
`

export const ChildInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
`

export const ChildInfoName = styled.h4`
  color: ${props => props.theme.color.gray1000};
`

export const ChildInfoAge = styled.p`
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
`
export const ChildImageContainer = styled.div`
  display: flex;
  width: 106px;
  height: 106px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.gray700};
  background: ${props => props.theme.color.gray100};
`

export const Content = styled.div`
  margin-right: 40px;
`;
