import React from 'react';
import {Tab, TabsContainer} from "./Tabs.styles";

interface TabsProps {
    tabs: string[];
    activeTab: number;
    onTabClick: (tab: number) => void;
}

const Tabs: React.FC<TabsProps> = ({tabs, activeTab, onTabClick}) => {
    return (
        <TabsContainer>
            {tabs.map((tab, index) => (
                <Tab key={tab} $isActive={tabs[activeTab] === tabs[index]} onClick={() => onTabClick(index)}>
                    {tab}
                </Tab>
            ))}
        </TabsContainer>
    );
};

export default Tabs
