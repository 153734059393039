import React from 'react';
import DatePicker from 'react-datepicker';
import icError from "../../../assets/images/ic_input_error.svg";
import {
    ErrorIcon,
    ErrorText,
    ErrorWrapper,
    InputContainer,
    InputLabel,
    InputWrapper,
    StyledInput
} from "./StyledDatePicker.styles";

interface Props {
    value: Date | null
    disabled?: boolean | null
    onValueChange: (date: Date) => void
    label: string
    correct?: boolean | null
    error?: string | null
    onBlur?: () => void
}

const StyledDatePicker: React.FC<Props> = (
    {
        label,
        value,
        onValueChange,
        error,
        correct,
        disabled,
        onBlur,
    }
) => {
    const getInputState = () => {
        if (disabled) {
            return "disabled"
        }
        if (error) {
            return "error"
        }
        if (correct) {
            return "success"
        }
        return "default"
    }

    const renderError = () => {
        if (!error) return null;
        return (
            <ErrorWrapper>
                <ErrorIcon src={icError}/>
                <ErrorText>{error}</ErrorText>
            </ErrorWrapper>
        );
    }

    return (
        <InputWrapper>
            <InputLabel>{label}</InputLabel>
            <InputContainer>
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    onBlur={event => {
                        event.preventDefault()
                        if (onBlur) {
                            onBlur()
                        }
                    }}
                    selected={value}
                    onChange={(date: Date) => onValueChange(date)}
                    customInput={<StyledInput $state={getInputState()}/>}
                />
            </InputContainer>
            {renderError()}
        </InputWrapper>
    );
};

export default StyledDatePicker;
