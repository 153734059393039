import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  min-height: 100vh;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 600px) {
  }
  
`;
export const Headline = styled.div`
  margin-bottom: 40px;

  @media (max-width: 599px) {
    margin-top: 76px;
    margin-left: 20px;
  }

  @media (min-width: 600px) {
    margin-top: 56px;
    margin-left: 40px;
  }
`
export const Title = styled.h3`

  @media (max-width: 599px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
  }

  @media (min-width: 600px) {

  }
`

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 599px) {
    flex-grow: 1;
    
  }

  @media (min-width: 600px) {
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 18px 40px 0 rgba(23, 15, 87, 0.04), 0 73px 73px 0 rgba(23, 15, 87, 0.04), 0 164px 98px 0 rgba(23, 15, 87, 0.02), 0 292px 117px 0 rgba(23, 15, 87, 0.01), 0 456px 128px 0 rgba(23, 15, 87, 0.00);


  @media (max-width: 599px) {
    border-radius: 20px;
    flex-grow: 1;
    padding: 16px;
    gap: 8px;
  }

  @media (min-height: 700px) {
    margin: 20px 20px 70%;
  }

  @media (max-height: 699px) {
    margin: 20px;
  }

  @media (min-width: 600px) {
    padding: 40px 56px 56px 56px;
    gap: 40px;
    border-radius: 32px;
  }
`

export const CardTitle = styled.h4`
  color: ${props => props.theme.color.gray1000};
  
  @media (max-width: 599px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.32px;
  }

  @media (min-width: 600px) {
  }
`

export const CardHeadline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 599px) {
    margin-bottom: 8px;
  }

  @media (min-width: 600px) {
  }
`

export const CardButtonTitle = styled.h4`
  color: ${props => props.theme.color.gray1000};
  
  @media (max-width: 599px) {
    font-size: 18px;
    letter-spacing: -0.36px;
  }

  @media (min-width: 600px) {
    margin-left: 32px;
    margin-top: 32px;
  }
`

export const CardButton = styled.div<{$bgColor:string}>`
  position: relative;

  @media (max-width: 599px) {
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    box-shadow: 0 5px 12px 6px rgba(241, 241, 241, 0.60);
  }

  @media (min-width: 600px) {
    border-radius: 32px;
    width: 400px;
    height: 283px;
    background-color: ${props => props.$bgColor};
  }
`

export const Decoration1Graphic = styled.img`

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    bottom: 24px;
    left: 32px;
    z-index: 0;
  }
`

export const Decoration3Graphic = styled.img`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    bottom: 24px;
    left: 32px;
    z-index: 0;
  }
`

export const Decoration2Graphic = styled.img`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 0;
  }
`
export const Decoration4Graphic = styled.img`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    top: 32px;
    right: 82px;
    z-index: 0;
  }
`
export const Thread1Graphic = styled.img`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
`

export const Thread2Graphic = styled.img`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }
`

export const SymbolChildGraphic = styled.img`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-30%);
    z-index: 0;
  }
`

export const SymbolFetusGraphic = styled.img`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 0;
  }
`

export const CircleButtonWrapper = styled.div`


  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    position: absolute;
    bottom: 24px;
    right: 24px;
  }
`