import React from "react";
import {ButtonWrapper, CircularProgress} from "./ButtonPrimary.styles";


interface ButtonPrimaryProps {
    title: string,
    // width?: string,
    progress?: boolean,
    onClick: () => void,
    icon?: React.ReactNode
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({title,  progress, onClick, icon}) => {
    const renderIcon = () => {
        if (icon) {
            return icon
        }
        if (progress) {
            return <CircularProgress/>
        }
        return null;
    };

    return (
        <ButtonWrapper
            type="submit"
            // $width={width}
            onClick={(e) => {
                e.preventDefault();
                onClick()
            }}>{title}{renderIcon()}</ButtonWrapper>
    )
}

export default ButtonPrimary