import {AppDispatch, AppState} from "../store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {fetchDiagnoses} from "./actions";

export const useFetchDiagnoses = () => {
    const diagnoses = useSelector((state: AppState) => state.aiDiagnosis.diagnoses)
    const [loading, setLoading] = useState(false)

    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        if (!diagnoses && !loading) {
            setLoading(true)
            dispatch(fetchDiagnoses()).finally(() => setLoading(false))
        }
    }, [diagnoses, loading]);

    return diagnoses ?? []
}