import {Patient} from "./types";
import api from "../../config/api";
import {Endpoints} from "../../config/endpoints";

interface FetchSuccess {
    type: "FETCH_PATIENTS_SUCCESS",
    data: Patient[],
}

export const fetchPatients = () => async (dispatch: any) => {
    try {
        const response = await api.get(Endpoints.BASE_URL_PATIENTS)
        dispatch({type: "FETCH_PATIENTS_SUCCESS", data: response.data})
        return Promise.resolve()
    } catch (e) {
        return Promise.reject(e)
    }
}
export type Action = FetchSuccess