import styled from "styled-components";
import icTodayIndicator from "../../../../../assets/images/ic_calendar_indicator_pink.svg";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const DayNames = styled.div`
  display: flex;
`;

export const DayName = styled.div`
  flex: 1;
  text-align: center;
  padding: 20px 0;
`;

export const Days = styled.div`
  outline: 1px solid ${props => props.theme.color.gray500};
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
`;

export const Day = styled.div<{ $today?: boolean, $selected?: boolean }>`
  position: relative;
  margin: 0;
  flex: 1 0 14.28%;
  box-sizing: border-box;
  padding: 20px 0;
  outline: 0.5px solid ${props => props.theme.color.gray500};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;

  background-image: ${props => props.$today ? `url(${icTodayIndicator})` : 'none'};
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${props => props.$selected ? props.theme.color.violet400 : 'none'};

  &:hover {
    background-color: ${props => props.$selected ? props.theme.color.violet400 : props.theme.color.violet100};
  }

  @media (max-width: 599px) {
    background-size: 43px;
  }

  @media (min-width: 600px) {
    background-size: 55px;
  }

`;

export const DisabledDay = styled(Day)`
  background: ${props => props.theme.color.gray400};
  color: ${props => props.theme.color.gray1000};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &:hover {
    background: ${props => props.theme.color.gray400};
  }
`

export const DayText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  line-height: 150%; /* 21px */
`

export const ImageIndicator = styled.img`
  position: absolute;
  right: 50%;
  transform: translateX(50%);

  @media (max-width: 599px) {
    background-size: 40px;
    bottom: 12px;
  }

  @media (min-width: 600px) {
    bottom: 9px;
  }
`
