import styled from "styled-components";

export const TimeSlot = styled.div<{ $selected: boolean, $disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.$disabled ? 'default' : 'pointer'};
  padding: 8px;
  border-radius: 8px;
  

  @media (max-width: 599px) {
    width: 70px;
    box-sizing: border-box;
    background: ${props => props.theme.color.violet100};
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    color: ${props => {
      if (props.$disabled) {
        return props.theme.color.gray700
      }
      if (props.$selected) {
        return props.theme.color.gray100
      }
      return props.theme.color.gray1000
    }};

    background: ${props => {
      if (props.$disabled) {
        return props.theme.color.gray300
      }
      if (props.$selected) {
        return props.theme.color.violet400
      }
      return props.theme.color.violet200
    }};

  }

  @media (min-width: 600px) {
    width: 72px;
    gap: 8px;


    color: ${props => {
      if (props.$disabled) {
        return props.theme.color.gray700
      }
      if (props.$selected) {
        return props.theme.color.gray100
      }
      return props.theme.color.gray1000
    }};

    background: ${props => {
      if (props.$disabled) {
        return props.theme.color.gray300
      }
      if (props.$selected) {
        return props.theme.color.violet600
      }
      return props.theme.color.pink200
    }};

    &:hover {
      background: ${props => {
        if (props.$disabled) {
          return props.theme.color.gray300
        }
        if (props.$selected) {
          return props.theme.color.violet600
        }
        return props.theme.color.violet100
      }}
    }

  }
`

export const TimeGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media (max-width: 599px) {
    gap: 4px;
    justify-content: space-between;
  }

  @media (min-width: 600px) {
    gap: 8px;
    margin-bottom: 40px;
  }
`

export const Container = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    width: 100%;
  }
`
