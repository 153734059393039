import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 599px) {
    padding-bottom: 24px;
  }

  @media (min-width: 600px) {
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
  }
`

export const MobileTabs = styled.div`

  @media (max-width: 599px) {
    display: flex;
    flex-direction: row;
    gap: 18px;
    margin-bottom: 24px;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const ButtonTab = styled.button<{ $isActive: boolean }>`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: ${props => props.$isActive ? props.theme.color.violet400 : props.theme.color.violet200};

  font-size: 14px;
  font-style: normal;
  font-weight: ${props => props.$isActive ? 600 : 400};
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
`


export const ButtonsWrapper = styled.div`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
`

export const ChildImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Container = styled.div`
  padding-bottom: 40px;
  min-height: 100vh;
  box-sizing: border-box;

  @media (max-width: 599px) {
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 600px) {
    padding-top: 10px;
    padding-left: 40px;
  }
`;

export const Title = styled.h3`

  @media (max-width: 599px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
  }

  @media (min-width: 600px) {
    margin-top: 56px;
    margin-bottom: 60px;
  }
`

export const ChildInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    padding: 24px;
    gap: 24px;
    align-self: stretch;

    border-radius: 16px;
    border: 1px solid ${props => props.theme.color.gray400};
    background: ${props => props.theme.color.gray100};
    box-shadow: 0 5px 10px 2px ${props => props.theme.color.gray400};
    
    margin-bottom: 16px;
  }

  @media (min-width: 600px) {
    margin-bottom: 45px;
  }

`

export const ChildInfoDetails = styled.div`
  display: flex;

  @media (max-width: 599px) {
    flex-direction: row;
    gap: 16px;

    align-items: flex-start;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    gap: 15px;
  }
`

export const ChildInfoButtons = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;

  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const ChildInfoButtonDark = styled.button`
  color: ${props => props.theme.color.gray100};

  display: flex;
  flex-grow: 1;

  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 40px;
  background: ${props => props.theme.color.violet600};
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);

  cursor: pointer;
`

export const ChildInfoButtonLight = styled.button`
  color: ${props => props.theme.color.violet600};

  display: flex;
  flex-grow: 1;

  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 40px;
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);

  border: 1px solid ${props => props.theme.color.violet600};
  
  cursor: pointer;
`
export const ChildInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
`

export const ChildInfoName = styled.h4`
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 599px) {
    font-size: 18px;
    letter-spacing: -0.36px;
  }

  @media (min-width: 600px) {

  }
`

export const ChildInfoAge = styled.p`
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 599px) {
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    line-height: 150%;
  }
`
export const ChildImageContainer = styled.div`
  display: flex;
  width: 106px;
  height: 106px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.gray700};
  background: ${props => props.theme.color.gray100};
  overflow: hidden;
`

export const Content = styled.div`
  
  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    margin-right: 40px;
  }
`;
