import {Action} from "./actions";
import {ChildrenState} from "./types";
import {ProfileAction} from "../profile/actions";

const initialState: ChildrenState = {
    children: null,
    loading: false,
    error: null,
};

export const childrenReducer = (state = initialState, action: Action | ProfileAction): ChildrenState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "CHILDREN_SUCCESS":
            return {
                ...state,
                children: action.data || [],
                loading: false,
                error: null,
            };
        default:
            return state;
    }
};
