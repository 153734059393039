import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, AppState} from '../../../state/store';
import {signupConfirm} from '../../../state/profile/actions';
import imgWave from '../../../assets/images/bg_wave_login.png'
import InputField from "../../Shared/Input/Input";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";

import {
    Background,
    BackgroundWave,
    ButtonWrapper,
    Container,
    FormPart,
    HeaderPart,
    HeaderSubtitle,
    HeaderTitle,
    LinkButtonWrapper,
    LoginForm,
    LoginFormContainer,
    RecoveryLink,
    RecoveryText
} from "./RegisterConfirm.styles";

const RegisterSuccess: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const [email, setEmail] = useState<string | null>(null)
    const [emailError, setEmailError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const isLoggedIn = useSelector((state: AppState) => state.profile.profile != null);
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!email) {
            const params = new URLSearchParams(location.search);
            setEmail(params.get('email'))
        }
    }, [email, location]);

    const navigateBack = () => {
        navigate("/register", {replace: true})
    }

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);

    const retryRequest = () => {
        if (!email || !token) {
            return;
        }
        if (loading) {
            return;
        }
        setLoading(true)
        dispatch(signupConfirm(email, token))
            .then((response) => {
                switch (response) {
                    case "FAILURE":
                        setEmailError("Wystąpił nieznany błąd.")
                        break;
                    case "SUCCESS":
                        // Do nothing, redirect will happen automatically
                        break;
                }
            })
            .catch(() => {
                setEmailError("Wystąpił nieznany błąd.")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (!email || !token) {
            return;
        }
        if (loading) {
            return;
        }
        setLoading(true)
        dispatch(signupConfirm(email, token))
            .then((response) => {
                switch (response) {
                    case "FAILURE":
                        setEmailError("Wystąpił nieznany błąd.")
                        break;
                    case "SUCCESS":
                        // Do nothing, redirect will happen automatically
                        break;
                }
            })
            .catch(() => {
                setEmailError("Wystąpił nieznany błąd.")
            })
            .finally(() => {
                setLoading(false)
            })
    }, [email, token, dispatch, loading]);

    function renderButtonSection() {
        return <ButtonWrapper>
            <ButtonPrimary
                title="Spróbuj ponownie"
                onClick={() => {
                    retryRequest();
                }}
                progress={loading}/>
            <div>
                <LinkButtonWrapper>
                    <RecoveryText>Błędny email?</RecoveryText>
                    <RecoveryLink onClick={navigateBack}>Wróc do rejestracji</RecoveryLink>
                </LinkButtonWrapper>
            </div>
        </ButtonWrapper>;
    }

    function renderFormSection() {
        return <FormPart>
            <InputField
                label="Adres email"
                type={"email"}
                value={email ? email : ""}
                onChange={(newValue) => {
                    setEmail(newValue)
                }}
                error={emailError}
                correct={!emailError}
                onBlur={() => {
                }}
            />
        </FormPart>;
    }

    return (
        <Container>
            <Background>
                <BackgroundWave src={imgWave}/>
            </Background>
            <LoginFormContainer>
                <LoginForm>
                    <HeaderPart>
                        <HeaderTitle>Weryfikacja adresu email</HeaderTitle>
                        <HeaderSubtitle>Aby dokończyć rejestrację, nizbędna jest weryfikacja
                            konta {email}.</HeaderSubtitle>
                    </HeaderPart>
                    {renderFormSection()}
                    {renderButtonSection()}
                </LoginForm>
            </LoginFormContainer>
        </Container>
    );
}

export default RegisterSuccess;