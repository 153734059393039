import {Endpoints} from "../../config/endpoints";
import {FetchVaccinesAction} from "./types";
import api from "../../config/api";

export const fetchVaccines = () => async (dispatch: any): Promise<Awaited<boolean>> => {
    try {
        const response = await fetch(Endpoints.VACCINES);
        if (!response.ok) {
            return Promise.resolve(false)
        }
        const data = await response.json();
        const success: FetchVaccinesAction = {
            type: "FETCH_VACCINES_SUCCESS",
            data: data,
        }
        dispatch(success)
        return Promise.resolve(true)
    } catch (error: any) {
        return Promise.resolve(false)
    }
}

export const createVaccination = (vaccineId: number, childId: number, date: Date) => async (dispatch: any): Promise<Awaited<void>> => {
    try {
        const body = JSON.stringify({vaccineId, childId, date})
        const createResponse = await api.post(Endpoints.CREATE_VACCINATION, body, {
            headers: {'Content-Type': 'application/json'},
        })
        if (createResponse.status !== 200) {
            return Promise.reject()
        }
        const fetchResponse = await api.get(Endpoints.GET_VACCINATIONS)
        dispatch({
            type: "FETCH_VACCINATIONS_SUCCESS",
            data: fetchResponse.data,
        })
        return Promise.resolve()
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject()
    }
}

export const fetchVaccination = () => async (dispatch: any): Promise<Awaited<void>> => {
    try {
        const fetchResponse = await api.get(Endpoints.GET_VACCINATIONS)
        dispatch({
            type: "FETCH_VACCINATIONS_SUCCESS",
            data: fetchResponse.data,
        })
        return Promise.resolve()
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject()
    }
}