import React from "react";

const IcIndicatorError: React.FC = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.9805 1.91812C6.77923 1.58728 7.6353 1.41699 8.49984 1.41699C9.36437 1.41699 10.2204 1.58728 11.0192 1.91812C11.8179 2.24896 12.5436 2.73389 13.155 3.34521C13.7663 3.95652 14.2512 4.68227 14.582 5.48099C14.9129 6.27972 15.0832 7.13579 15.0832 8.00033C15.0832 8.86486 14.9129 9.72093 14.582 10.5197C14.2512 11.3184 13.7663 12.0441 13.155 12.6554C12.5436 13.2668 11.8179 13.7517 11.0192 14.0825C10.2204 14.4134 9.36437 14.5837 8.49984 14.5837C7.6353 14.5837 6.77923 14.4134 5.9805 14.0825C5.18178 13.7517 4.45604 13.2668 3.84472 12.6554C3.2334 12.0441 2.74847 11.3184 2.41763 10.5197C2.08679 9.72093 1.9165 8.86486 1.9165 8.00033C1.9165 7.13579 2.08679 6.27972 2.41763 5.48099C2.74847 4.68227 3.2334 3.95652 3.84472 3.34521C4.45604 2.73389 5.18178 2.24896 5.9805 1.91812ZM8.49984 2.58366C7.78851 2.58366 7.08415 2.72377 6.42697 2.99598C5.76979 3.26819 5.17266 3.66718 4.66968 4.17016C4.16669 4.67315 3.7677 5.27028 3.49549 5.92746C3.22328 6.58464 3.08317 7.289 3.08317 8.00033C3.08317 8.71165 3.22328 9.41601 3.49549 10.0732C3.7677 10.7304 4.16669 11.3275 4.66968 11.8305C5.17266 12.3335 5.76979 12.7325 6.42697 13.0047C7.08415 13.2769 7.78851 13.417 8.49984 13.417C9.21116 13.417 9.91553 13.2769 10.5727 13.0047C11.2299 12.7325 11.827 12.3335 12.33 11.8305C12.833 11.3275 13.232 10.7304 13.5042 10.0732C13.7764 9.41601 13.9165 8.71165 13.9165 8.00033C13.9165 7.289 13.7764 6.58464 13.5042 5.92746C13.232 5.27028 12.833 4.67315 12.33 4.17016C11.827 3.66718 11.2299 3.26819 10.5727 2.99598C9.91552 2.72377 9.21116 2.58366 8.49984 2.58366Z"
                  fill="#840D2A"/>
        </svg>

    )
}

export default IcIndicatorError;