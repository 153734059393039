import styled from "styled-components";
import {Card} from "../../../Shared/Div/Card";

export const CardBigContentTitle = styled.h3`
  margin-bottom: 16px;
  
  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    font-size: 24px;
  }



`

export const Headline = styled.h4`

  @media (max-width: 599px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
  }

  @media (min-width: 600px) {
  }
  
`

export const ChildGraphic = styled.img`
  border-radius: 32px;
  overflow: hidden;
  object-fit: cover;

  @media (max-width: 599px) {
    display: none; 
  }

  @media (min-width: 600px) {
  }
`

export const CardBigContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    gap: 29px;
  }

  @media (min-width: 600px) {
    gap: 214px;
  }
`

export const CardBig = styled(Card)`
  box-sizing: border-box;
 

  @media (max-width: 599px) {
    margin-top: 16px;
    padding: 20px;
  }

  @media (min-width: 600px) {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    gap: 52px;
    padding: 24px;
  }
`