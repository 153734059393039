import {styled} from "styled-components"
import bgWave3 from '../../../assets/images/bg_wave_3.svg';

export const SectionWrapper = styled.div`
  position: relative;
  align-self: stretch;
  padding-bottom: 108px;
  margin-bottom: 72px;
`

export const SectionWrapperWrapper = styled.div`
  position: relative;
  align-self: stretch;
  background: ${props => props.theme.color.gray100};
  overflow: hidden;
`

export const Title = styled.h1`
  text-align: center;
  font-style: normal;
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 1199px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 26.4px */
    letter-spacing: -0.48px;
    margin-top: 56px;
  }

  @media (min-width: 1200px) {
    margin-top: 152px;
  }
`

export const LinkButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    margin-top: 12px;
  }
`

export const BottomWave = styled.div`
  position: absolute;
  margin: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  background-image: url(${bgWave3});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  height: 105px;
  width: 100%;
  z-index: 1;
`