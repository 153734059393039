interface SimpleText {
    id: string,
    title: string,
    paragraphs: string[]
}


export const simpleTexts: SimpleText[] = [
    {
        id: "privacy-policy",
        title: "Polityka prywatności",
        paragraphs: [
            "Niniejsza Polityka Prywatności dotyczy danych osobowych zbieranych przez Happy Kiddo, której celem jest dostarczanie diagnozy AI dla dzieci na podstawie podstawowych symptomów oraz umożliwienie umówienia wizyty u lekarza przez wideoczat.",
            "Zbieramy jedynie niezbędne dane: imię dziecka, wiek, opis symptomów. Dane są wykorzystywane wyłącznie do celów diagnostycznych i umówienia wizyty.",
            "Zobowiązujemy się do ochrony danych osobowych i stosowania odpowiednich środków bezpieczeństwa technicznych i organizacyjnych.",
            "Rodzice lub opiekunowie prawni mogą dodawać dzieci, zarządzać ich profilami, oraz mieć dostęp do historii diagnoz.",
            "Użytkownicy mają prawo do wglądu, aktualizacji lub usunięcia swoich danych osobowych.",
            "Zastrzegamy sobie prawo do wprowadzania zmian w Polityce Prywatności. O wszelkich zmianach będziemy informować na stronie serwisu.",
            "W przypadku pytań dotyczących Polityki Prywatności prosimy o kontakt na happykiddoapp@happykiddo.com.pl."
        ]
    },
    {
        id:"tnc",
        title:"Regulamin Serwisu Happy Kiddo",
        paragraphs:[
            "Serwis Happy Kiddo świadczy usługi diagnostyczne za pomocą sztucznej inteligencji dla dzieci oraz umożliwia umawianie wizyt u lekarza przez wideoczat.",
            "Korzystanie z serwisu jest równoznaczne z akceptacją niniejszego regulaminu.",
            "Usługi są dostępne dla użytkowników, którzy założyli konto i są rodzicami lub opiekunami prawnymi dziecka.",
            "Użytkownik zobowiązany jest do podawania prawdziwych i aktualnych danych.",
            "Happy Kiddo nie ponosi odpowiedzialności za decyzje medyczne podjęte na podstawie diagnozy AI.",
            "Serwis służy jako narzędzie wspomagające i nie zastępuje konsultacji z lekarzem.",
            "Wszystkie treści znajdujące się na stronie są chronione prawem autorskim i należą do właściciela serwisu.",
            "Użytkownik zobowiązuje się nie wykorzystywać serwisu do celów niezgodnych z prawem."
        ]
    }
]