import React, {useEffect, useState} from 'react';
import imgPhysician from '../../assets/images/img_physician.svg'
import {
    Answer,
    FreeText,
    FreeTextAnswer,
    Interview,
    Question,
    SingleSelect,
    SingleSelectAnswer
} from "../../state/interview/types";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../../state/store";
import {fetchQuestions, submitInterview} from "../../state/interview/actions";
import ProgressBar from "./ProgressBar";
import {fetchDiagnoses} from "../../state/aiDiagnoses/actions";
import AiDiagnosisResult from "./AiDiagnosisResult";
import BackArrowButtonTitled from "../Shared/Button/BackArrowButtonTitled/BackArrowButtonTitled";
import {useLocation, useNavigate} from "react-router-dom";
import {
    ButtonNext220,
    ButtonNext300,
    CircularProgress2,
    Container,
    ContentCard,
    ImagePhysician,
    Option,
    OptionContainer,
    ProgressContainer,
    Subtitle,
    TextInput,
    Title,
    Titles
} from "./AiDiagnosisInterview.styles";
import ButtonPrimary from "../Shared/Button/ButtonPrimary/ButtonPrimary";
import AuthPage from "./AuthPage";


type InputFields = {
    [key: number]: string;
}

type SingleSelects = {
    [key: number]: number | null;
}

const AiDiagnosisInterview: React.FC = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [keywords, setKeywords] = useState<string[]>([])
    const [childId, setChildId] = useState<number | null>(null)

    const [isGeneratingDiagnosis, setIsGeneratingDiagnosis] = useState(false);
    const [freeTextData, setFreeTextData] = useState<InputFields>({});
    const [diagnosisId, setDiagnosisId] = useState<number | null>(null);
    const [singleSelectData, setSingleSelectData] = useState<SingleSelects>({});
    const questions = useSelector((state: AppState) => state.questions.questions)
    const diagnoses = useSelector((state: AppState) => state.aiDiagnosis.diagnoses || [])
    const profile = useSelector((state: AppState) => state.profile.profile)
    const [answers, setAnswers] = useState<Answer[]>([])
    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const symptomsParam = queryParams.get("symptoms")
        if (symptomsParam) {
            let symptoms: string[] = [];
            if (symptomsParam) {
                try {
                    const trimmedParam = symptomsParam.replace(/^\[|]$/g, '');
                    symptoms = decodeURIComponent(trimmedParam).split(',');
                } catch (error) {
                    console.error('Error parsing symptoms:', error);
                }
            }
            setKeywords(symptoms)
        }

        const childIdParams = queryParams.get("childId")
        if (childIdParams) {
            const childId = Number(childIdParams)
            setChildId(childId)
        }
    }, [queryParams]);

    useEffect(() => {
            if (questions == null) {
                dispatch(fetchQuestions()).then((data) => {
                        if (data === "FAILURE") {
                            // potentially show error instead
                            return
                        }
                        if (data.length > 0) {
                            const freeTexts: InputFields = {};
                            const singleSelects: SingleSelects = {};
                            data.forEach(question => {
                                switch (question.type) {
                                    case 'open':
                                        freeTexts[question.id] = "";
                                        break
                                    case 'closed':
                                        singleSelects[question.id] = null;
                                        break
                                }
                            })
                            setFreeTextData(freeTexts);
                            setSingleSelectData(singleSelects)
                        }
                    }
                )
            }
        },
        [questions, dispatch]
    )

    useEffect(() => {
        if (profile && diagnosisId === null && questions !== null && questions.length === answers.length) {
            setIsGeneratingDiagnosis(true)
            const interview: Interview = {
                childId: childId || undefined,
                answers: answers,
                symptoms: keywords,
            }
            dispatch(submitInterview(interview))
                .then(id => dispatch(fetchDiagnoses())
                    .then(() => setDiagnosisId(id))
                    .catch((error) => console.error("An error occurred:", error))
                    .finally(() => {
                        setIsGeneratingDiagnosis(false)
                    })
                )
                .catch((error) => console.error("An error occurred:", error))

        }
    }, [profile, diagnosisId, questions, answers, dispatch])

    const renderSingleSelectQuestion = (question: SingleSelect) => {
        return (
            <>
                <OptionContainer>
                    {question.options.map((option, index) => <Option
                        $selected={singleSelectData[question.id] === index}
                        key={index}
                        onClick={() => {
                            setSingleSelectData(prevState => ({
                                ...prevState,
                                [question.id]: index
                            }));
                        }}
                    >{option}</Option>)}
                </OptionContainer>

                <ButtonNext300>
                    <ButtonPrimary
                        title="Dalej"
                        onClick={() => {
                            const data = singleSelectData[question.id]
                            if (data != null) {
                                const answer: SingleSelectAnswer = {
                                    type: "closed",
                                    id: question.id,
                                    option: data,
                                }
                                setAnswers(prevState => ([...prevState, answer]));
                            }
                        }}
                    />
                </ButtonNext300>
            </>
        )
    }

    const handleInputChange = (id: number, value: string) => {
        // const {name, value} = event.target;
        setFreeTextData(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const renderOpenQuestion = (question: FreeText) => {
        return (<>
            <TextInput
                name={question.title}
                value={freeTextData[question.id] || ""}
                placeholder="twój tekst"
                onChange={(event) => {
                    event.preventDefault()
                    handleInputChange(question.id, event.target.value)
                }}
            />
            <ButtonNext220>
                <ButtonPrimary
                    title="Dalej"
                    onClick={() => {
                        const answer: FreeTextAnswer = {
                            type: "open",
                            id: question.id,
                            text: freeTextData[question.id] || "",
                        }
                        setAnswers(prevState => ([...prevState, answer]));
                    }}
                />
            </ButtonNext220>
        </>)
    }

    const doRenderQuestion = (question: SingleSelect | FreeText) => {
        switch (question.type) {
            case 'closed':
                return renderSingleSelectQuestion(question as SingleSelect)
            case 'open':
                return renderOpenQuestion(question as SingleSelect)
            default:
                return null
        }
    }

    const renderLoadingCard = () => {
        // potentially show loading indicator in the future
        return null
    };

    const renderEndingCard = () => {
        if (!profile) {
            return (<AuthPage/>)
        } else {
            return null
        }
    }

    function renderQuestionCard(question: Question) {
        return (
            <ContentCard>
                <Titles>
                    <Title>{question.title}</Title>
                    <Subtitle>{question.subtitle}</Subtitle>
                </Titles>
                {doRenderQuestion(question)}
            </ContentCard>
        )
    }

    const renderCard = () => {
        if (!questions) {
            return renderLoadingCard();
        }
        if (questions.length === answers.length) {
            return renderEndingCard();
        }
        return renderQuestionCard(questions[answers.length]);
    }

    const getProgressPercentile = () => {
        const total = (questions?.length || 1)
        const progress = answers.length + 1
        const result = (progress / total) * 100
        if (result > 100) {
            return 100
        }
        return result
    }

    const renderContent = () => {
        if (isGeneratingDiagnosis) {
            return (
                <ContentCard>
                    <ImagePhysician src={imgPhysician}/>
                    <Titles>
                        <Title>Pracujemy nad Twoją diagnozą</Title>
                        <Subtitle>Nasz algorytm przeszukuje bazę chorób</Subtitle>
                    </Titles>
                    <CircularProgress2/>
                </ContentCard>
            )
        } else if (diagnosisId) {
            const diagnosis = diagnoses.find(el => el.id === diagnosisId)
            if (!diagnosis) {
                // potentially show error instead
            }
            return (
                <AiDiagnosisResult diagnosisId={diagnosisId}/>
            )
        } else {
            return (
                <>
                    {renderCard()}
                    <ProgressContainer>
                        <ProgressBar label="Objawy" progress={getProgressPercentile()}/>
                    </ProgressContainer>
                </>
            );
        }
    }

    const renderBackButton = () => {
        return <BackArrowButtonTitled onBackClicked={() => {
            if (diagnosisId) {
                navigate(-1)
            } else if (answers.length > 1) {
                setAnswers(answers.slice(0, -1))
            } else {
                navigate(-1)
            }
        }}
        />;
    }

    return (
        <Container>
            {renderBackButton()}
            {renderContent()}
        </Container>
    );
};

export default AiDiagnosisInterview