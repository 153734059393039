import { Action } from "./actions";
import { SpecializationsState } from "./types";
import {ProfileAction} from "../profile/actions";

const initialState: SpecializationsState = {
    items: null,
    loading: false,
    error: null,
};

export const specializationsReducer = (state = initialState, action: Action | ProfileAction): SpecializationsState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "SPECIALIZATIONS_START":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "SPECIALIZATIONS_SUCCESS":
            return {
                ...state,
                items: action.items || [],
                loading: false,
                error: null,
            };
        case "SPECIALIZATIONS_ERROR":
            return {
                ...state,
                loading: false,
                error: "something went wrong"
            };
        default:
            return state;
    }
};
