import React, {useEffect, useState} from "react";
import BackArrowButtonTitled from "../../Shared/Button/BackArrowButtonTitled/BackArrowButtonTitled";
import DoctorCardComponent from "./DoctorCardComponent";
import {
    formatDateToDMMMMYYYYTimePolishWithComa,
    isLaterThanYesterday,
    parseDate,
    parseDateTime,
    parsePaymentStatus
} from "../../../config/utils";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../../../state/store";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {fetchAppointments, fetchPaymentRedirect} from "../../../state/appointments/actions";
import {fetchSpecializations} from "../../../state/specializations/actions";
import PaymentSummaryCard from "./PaymentSummaryCard";
import AiCard from "./AiCard";
import {fetchDiagnoses} from "../../../state/aiDiagnoses/actions";
import {fetchAllDoctors} from "../../../state/doctors/actions";
import AllSetCard from "./AllSetCard";

const Background = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  box-sizing: border-box;
  min-height: 100vh;

  background: ${props => props.theme.color.beige200};

  @media (max-width: 599px) {

  }

  @media (min-width: 600px) {
    width: 100%;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 599px) {

  }

  @media (min-width: 600px) {
    width: 910px;
    margin-top: 118px;
    margin-bottom: 118px;
  }
`

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}

const AppointmentDetails: React.FC = () => {

    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const showDetailsParam = queryParams.get('urlReturn');
    const isFromPayment = showDetailsParam === 'true';
    const appointments = useSelector((state: AppState) => state.appointments.appointments)
    const diagnosis = useSelector((state: AppState) => state.aiDiagnosis.diagnoses)
    const [isLoadingDiagnosis, setIdLoadingDiagnosis] = useState(false)
    const appointment = useSelector(() => appointments?.find(a => a.id === id))
    const specializations = useSelector((state: AppState) => state.specializations?.items)
    const [loadingSpec, setLoadingSpec] = useState(false)
    const doctors = useSelector((state: AppState) => state.doctors.data)
    const doctor = useSelector(() => doctors?.find(d => d.id === appointment?.doctorId))
    const [loadingDoctors, setLoadingDoctors] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!doctors && !loadingDoctors) {
            setLoadingDoctors(true)
            dispatch(fetchAllDoctors())
                .catch(error => {
                    console.log("Error fetching doctors", error)
                })
                .finally(() => {
                    setLoadingDoctors(false)
                })
        }
    }, [doctors, loadingDoctors, setLoadingDoctors]);

    useEffect(() => {
        if (!diagnosis && !isLoadingDiagnosis) {
            setIdLoadingDiagnosis(true)
            dispatch(fetchDiagnoses())
                .catch(error => {
                    console.log("Error fetching AI diagnoses", error)
                })
                .finally(() => {
                    setIdLoadingDiagnosis(false)
                })
        }
    }, [diagnosis, isLoadingDiagnosis, setIdLoadingDiagnosis]);

    useEffect(() => {
        if (!specializations && !loadingSpec) {
            setLoadingSpec(true)
            dispatch(fetchSpecializations()).catch((error) => {
                console.log("error loading specializations", error)
            }).finally(() => {
                setLoadingSpec(false)
            })
        }
    }, [specializations, loadingSpec, dispatch]);

    useEffect(() => {
        if (!appointments && !loading) {
            setLoading(true)
            dispatch(fetchAppointments())
                .then((result) => {
                    console.log("appointments fetched?", result)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [appointments, loading, dispatch])

    const shouldShowAiCard = () => {
        const hasDiagnosis = diagnosis?.some(d => {
            return isLaterThanYesterday(parseDate(d.date || null));

        })
        return !hasDiagnosis
    }

    const renderContent = (): React.ReactNode => {
        if (parsePaymentStatus(appointment?.status || "") === "pending_payment") {
            return (
                <PaymentSummaryCard
                    onConfirm={() => {
                        const id = appointment?.id
                        if (!loading && id) {
                            setLoading(true)
                            dispatch(fetchPaymentRedirect(id)).catch(error => {
                                console.log("error fetching payment redirect", error)
                            }).then(redirect => {
                                if (redirect) {
                                    window.location.href = redirect
                                }
                            }).finally(() => {
                                setLoading(false)
                            })
                        }
                    }}

                    onCancel={() => {
                        navigate(-1)
                    }}/>
            )
        } else if (shouldShowAiCard()) {
            return (
                <AiCard/>
            )
        } else {
            return (
                <AllSetCard/>
            )
        }

    }

    return (
        <Background>
            <BackArrowButtonTitled
                onBackClicked={() => {
                    if (isFromPayment) {
                        navigate("/", {replace: true})
                    } else {
                        navigate(-1)
                    }
                }}
            />
            <Content>
                <DoctorCardComponent
                    doctor={doctor || null}
                    date={formatDateToDMMMMYYYYTimePolishWithComa(parseDateTime(appointment?.startTime || null))}
                />
                {renderContent()}
            </Content>
        </Background>
    )
}


export default AppointmentDetails