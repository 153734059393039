import styled, {keyframes} from "styled-components";

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 42px;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingIndicator = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 4px solid ${props => props.theme.color.gray400};
  border-top: 4px solid ${props => props.theme.color.violet300};
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`;
