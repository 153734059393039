import React, {useEffect, useRef, useState} from 'react';
import Space from '../../Shared/Div/Space';
import arrowRight from '../../../assets/images/ic_arrow_small_right.svg'
import arrowLeft from '../../../assets/images/ic_arrow_small_left.svg'

import {
    ArrowLeft,
    ArrowRight,
    Date,
    GalleryContainer,
    GalleryImage,
    GalleryItem,
    GallerySection,
    ImageWrapper,
    TagWrapper,
    TakeRest,
    Wrapper
} from "./Gallery.styles";
import {Tag} from "../../Shared/Div/Tag";
import {Paths} from "../../../config/paths";
import {useNavigate} from "react-router-dom";
import {Article} from "../../../state/articles/type";
import {useFetchArticles} from "../../../state/articles/helpers";
import {formatDateToDDMMYYYYWithDashes, parseDate} from "../../../config/utils";
import styled from "styled-components";


const ArticleTitle = styled.h4`
  @media (max-width: 1199px) {
    font-size: 18px;
    letter-spacing: -0.36px;
  }

  @media (min-width: 1200px) {
  }
`

interface Section {
    id: number;
    articles: Article[]
}

const Gallery: React.FC = () => {
    const articles = useFetchArticles()
    const sections: Section[] = [
        {
            id: 0,
            articles: articles
        },
        {
            id: 1,
            articles: articles
        },
        {
            id: 2,
            articles: articles
        }
    ];
    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const navigate = useNavigate()

    const scrollLeftBy = (amount: number) => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollLeft += amount;
        }
    };

    useEffect(() => {
        if (divRef.current) {
            const currentWidth = divRef.current.offsetWidth;
            setWidth(currentWidth + 20);
        }
    }, []);

    return (
        <Wrapper>
            <ArrowLeft src={arrowLeft} onClick={() => scrollLeftBy(-width)}/>
            <GalleryContainer ref={scrollableDivRef}>
                {sections.map(section => (
                    <GallerySection key={section.id}>{section.articles.map(article => (
                        <GalleryItem
                            ref={divRef}
                            key={article.title}
                            onClick={() => navigate(Paths.ARTICLE.replace(":id", article.id.toString()))}
                        >
                            <ImageWrapper>
                                <GalleryImage src={process.env.REACT_APP_API_BASE_URL + article.image}/>
                            </ImageWrapper>
                            <TagWrapper>{article.tags.map(t => (<Tag key={t}>{t}</Tag>))}</TagWrapper>
                            <Space height="16px"/>
                            <ArticleTitle>{article.title}</ArticleTitle>
                            <TakeRest/>
                            <Space height="8px"/>
                            <Date>{formatDateToDDMMYYYYWithDashes(parseDate(article?.createdAt))}</Date>
                        </GalleryItem>
                    ))}</GallerySection>
                ))}
            </GalleryContainer>
            <ArrowRight src={arrowRight} onClick={() => scrollLeftBy(width)}/>
        </Wrapper>
    );
};

export default Gallery;
