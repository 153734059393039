import styled from "styled-components";

export const Thread2Graphic = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {

  }
`

export const Thread1Graphic = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {

  }
`

export const SmallButtonStyledMobile = styled.div`
  @media (max-width: 599px) {
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const SmallButtonStyled = styled.div`
  @media (max-width: 599px) {
    //width: 50%;
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    right: 10px;
    bottom: 10px;
    margin: 10px;
    z-index: 4;
  }


`
export const Container = styled.div`
  display: flex;

  @media (max-width: 599px) {
    flex-direction: column;
    gap: 16px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const DoctorGraphic2 = styled.img`
  position: absolute;
  bottom: 0;
  right: 53px;
  z-index: 1;

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {

  }
`

export const DoctorGraphic = styled.img`
  position: absolute;
  bottom: 0;
  left: 44px;
  z-index: 4;

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {

  }

`

export const Decoration2Graphic = styled.img`
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 0;

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {

  }
`

export const Decoration1Graphic = styled.img`
  position: absolute;
  bottom: 24px;
  left: 32px;
  z-index: 0;

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {

  }
`

export const CardTitle2 = styled.h4`


  @media (max-width: 599px) {
    font-size: 18px;
    letter-spacing: -0.36px;

    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (min-width: 600px) {
    padding: 32px;
    width: 60%;
    color: ${props => props.theme.color.gray1000};
    z-index: 5;
  }
`

export const CardTitle1 = styled.h4`

  @media (max-width: 599px) {
    font-size: 18px;
    letter-spacing: -0.36px;
    
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (min-width: 600px) {
    padding: 32px;
    color: ${props => props.theme.color.gray1000};
    z-index: 5;
  }
`

export const CardBackground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${props => props.theme.color.gray100};
  z-index: -1;

  @media (max-width: 599px) {
    border-radius: 20px;
  }

  @media (min-width: 600px) {
    border-radius: 32px;
  }
`

export const CardSmall = styled.div`
  display: flex;

  align-content: center;
  justify-content: space-between;


  @media (max-width: 599px) {
    width: 100%;
    flex-direction: row;

    border-radius: 20px;
    background: ${props => props.theme.color.gray100};
    
    align-items: center;
    gap: 4px;
  }

  @media (min-width: 600px) {
    width: calc(50% - 10px);
    flex-direction: column;

    height: 283px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
`