import React, {useState} from "react";
import icPersonalDetails from "../../../../../assets/images/ic_personal_details.svg"
import iMeasurements from "../../../../../assets/images/ic_mesurements.svg"
import BigButtonOutline from "../../../../Shared/Button/BigButtonOutline/BigButtonOutline";
import {
    Chart,
    Column1,
    Column2,
    Container,
    Option,
    RightContainer,
    RightContainerTitle,
    Row,
    Section,
    SectionTitle,
    SectionTitleText,
    Select,
    SelectContainer,
    TabWrapper,
    TitleSection
} from "./PersonalDetailsTab.styles";
import {Pregnancy} from "../../../../../state/pregnancies/types";
import {formatSex, getCurrentDayOfPregnancy, getCurrentWeekOfPregnancy, parseDate} from "../../../../../config/utils";

const PersonalDetailsTab: React.FC<{ pregnancy: Pregnancy }> = ({pregnancy}) => {
    const [selected, setSelected] = useState("waga")

    const date = parseDate(pregnancy.due_date)

    function renderMesurements() {
        return <Section>
            <TitleSection>
                <SectionTitle>
                    <img alt="Pomiary" src={iMeasurements}/>
                    <SectionTitleText>Pomiary</SectionTitleText>
                </SectionTitle>

                <BigButtonOutline
                    title="Dodaj pomiary"
                    onClick={() => {
                    }}
                />
            </TitleSection>
            <Row>
                <Column1>Waga</Column1>
                <Column2></Column2>
            </Row>
            <Row>
                <Column1>Waga dziecka</Column1>
                <Column2></Column2>
            </Row>
            <Row>
                <Column1>Długość dziecka</Column1>
                <Column2></Column2>
            </Row>
        </Section>;
    }

    function renderSideContent() {
        return <RightContainer>
            <RightContainerTitle>
                Pomiary
            </RightContainerTitle>
            <SelectContainer>
                <Select
                    value={selected}
                    onChange={(e) => {
                        e.preventDefault()
                        setSelected(e.target.value)
                    }}
                >
                    <Option value="weight">Waga</Option>
                    <Option value="height">Wzrost</Option>
                </Select>
            </SelectContainer>

            <Chart>Brak danych</Chart>
        </RightContainer>;
    }

    return (
        <TabWrapper>
            <Container>
                <Section>
                    <SectionTitle>
                        <img alt="Dane ciąży" src={icPersonalDetails}/>
                        <SectionTitleText>Dane dotyczące ciąży</SectionTitleText>
                    </SectionTitle>
                    <Row>
                        <Column1>Tydzień ciąży</Column1>
                        <Column2>{getCurrentWeekOfPregnancy(date)}</Column2>
                    </Row>
                    <Row>
                        <Column1>Dzień ciąży</Column1>
                        <Column2>{getCurrentDayOfPregnancy(date) % 7}</Column2>
                    </Row>
                    <Row>
                        <Column1>Przewidywana data porodu</Column1>
                        <Column2>{pregnancy.due_date} r.</Column2>
                    </Row>
                    <Row>
                        <Column1>Płeć dziecka</Column1>
                        <Column2>{formatSex(pregnancy.sex)}</Column2>
                    </Row>
                </Section>
                {/*TODO: uncomment when supporting mesurements*/}
                {/*{renderMesurements()}*/}
            </Container>
            {/*TODO: uncomment when supporting mesurements*/}
            {/*{renderSideContent()}*/}
        </TabWrapper>
    )
}


export default PersonalDetailsTab