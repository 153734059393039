import React from "react";
import {useTheme} from "styled-components";
import SvgFill from "../../Shared/Icons/SvgFill";
import IcPerson from "../../Shared/Icons/IcPerson";
import ButtonUpLink from "../../Shared/Button/ButtonUpLink/ButtonUpLink";
import {
    AddChildIconWrapper,
    ChildCardDesktop,
    ChildCardMobile,
    ChildImage,
    ChildName,
    ChildTexts
} from "./ChildCardComponent.styles";

const ChildCardComponent: React.FC<{
    id: number,
    title: string,
    subtitle?: string,
    image: string | null,
    selected?: boolean,
    onClick: () => void,
    onSelected: () => void,
}> = (
    {
        id,
        title,
        image,
        subtitle,
        onClick,
        selected,
        onSelected,
    }
) => {
    const theme = useTheme()
    const renderChildPicture = () => {
        if (image) {
            return (
                <AddChildIconWrapper>
                    <ChildImage src={process.env.REACT_APP_API_BASE_URL + image} alt="Preview"/>
                </AddChildIconWrapper>
            )
        } else {
            return (
                <AddChildIconWrapper>
                    <SvgFill color={theme.color.violet400}>
                        <IcPerson/>
                    </SvgFill>
                </AddChildIconWrapper>
            )
        }

    }

    const renderButtonUpLink = () => {
        if (!subtitle) {
            return null
        }
        return <ButtonUpLink color={theme.color.gray100}>{subtitle}</ButtonUpLink>;
    }


    return (
        <>
            <ChildCardMobile
                key={id}
                onClick={() => {
                    onSelected()
                }}
                $selected={selected}
            >
                {renderChildPicture()}
                <ChildTexts>
                    <ChildName>{title}</ChildName>
                    {renderButtonUpLink()}
                </ChildTexts>
            </ChildCardMobile>
            <ChildCardDesktop
                key={id}
                onClick={() => {
                    onClick()
                }}
                $selected={selected}
            >
                {renderChildPicture()}
                <ChildTexts>
                    <ChildName>{title}</ChildName>
                    {renderButtonUpLink()}
                </ChildTexts>
            </ChildCardDesktop>
        </>

    );
}


export default ChildCardComponent