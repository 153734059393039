import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import Space from "../../Shared/Div/Space";
import ButtonUpLink from "../../Shared/Button/ButtonUpLink/ButtonUpLink";
import Gallery from "./Gallery";
import {BottomWave, LinkButtonWrapper, SectionWrapper, SectionWrapperWrapper, Title} from "./Articles.styles";
import {SecondComponentHandles} from "../Welcome";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../config/paths";

const ArticleSection = forwardRef<SecondComponentHandles, { onClick: () => void }>(({onClick}, ref) => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate()

    useImperativeHandle(ref, () => ({
        scrollToSection: () => {
            sectionRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }));

    return (
        <SectionWrapperWrapper ref={sectionRef}>
            <BottomWave/>
            <SectionWrapper>
                <Title>Poczytaj nasze artykuły</Title>
                <LinkButtonWrapper>
                    <ButtonUpLink onClick={() => navigate(Paths.ARTICLES_SCREEN)}>Przejdź do artykułów</ButtonUpLink>
                </LinkButtonWrapper>
                <Gallery/>
            </SectionWrapper>
        </SectionWrapperWrapper>
    );
})

export default ArticleSection;