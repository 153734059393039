import styled from 'styled-components';
import Space from "../../Shared/Div/Space";
import React from "react";

export const GalleryContainer = styled.div`

  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }


  @media (max-width: 1199px) {
    width: 100%;
  }

  @media (min-width: 1200px) {
    width: 80%;
  }
`;

export const GallerySection = styled.div`
  display: grid;
  grid-gap: 20px;
  flex: none;
  padding: 10px;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
`;

export const GalleryImage = styled.img`
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;


  @media (max-width: 1199px) {
    height: 290px;
    width: 290px;
  }

  @media (min-width: 1200px) {
    width: 100%;
    height: 100%;
  }
`

export const GalleryItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 1199px) {
    width: 290px;
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
`

export const TagWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  
`

export const Date = styled.p`
  color: ${props => props.theme.color.gray800};
  font-family: ${props => props.theme.font.default};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const TakeRest = styled.div`
  flex-grow: 1;
`

export const ImageWrapper = styled.div`
  
  padding-bottom: 100%;
  position: relative;

  @media (max-width: 1199px) {
    height: 290px;
    width: 290px;
    box-sizing: border-box;
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;


  @media (max-width: 1199px) {
    margin-top: 40px;
  }

  @media (min-width: 1200px) {
    margin-top: 84px;
  }
`

export const ArrowLeft = styled.img`
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) { 
  }
`

export const ArrowRight = styled.img`
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {
  }
`