import styled from "styled-components";

export const DashboardWrapper = styled.div<{ $color: string }>`
  display: flex;
  overflow: hidden;
  height: 100vh;


  @media (max-width: 599px) {
    flex-direction: column;
  }

  @media (min-width: 600px) {
    min-width: 1728px;
    background-color: ${props => props.$color};
  }
`;

export const BurgerMenu = styled.img`
  cursor: pointer;
  position: absolute;
  left: 12px;
  top: 12px;
  padding: 4px;
`

export const LoginLogoTop = styled.img`

  @media (max-width: 599px) {
    height: 34px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 600px) {
    display: none;
  }
`

export const Topbar = styled.div`

  @media (max-width: 599px) {
    display: flex;
    justify-content: flex-start;
    height: 60px;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background: white;
    width: 100%;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const Sidebar = styled.div`
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
    display: flex;
    flex: 0 0 310px;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const LogoStyledBlack = styled.img`
  width: 150px;
  cursor: pointer;
`

export const LogoStyledMobile = styled.img`
  width: 150px;
  cursor: pointer;
`
export const LogoStyled = styled.img`
  cursor: pointer;
`

export const ContentAreaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`

export const ContentArea = styled.div`
  box-sizing: border-box;
  background-color: ${props => props.theme.color.beige100};
  border-radius: 80px 0 0 0;
`;

export const BottomSidebar = styled.div`
  margin-bottom: 64px;
`

export const LogoWrapperBlack = styled.div`
  width: 100%;
  display: flex;
  margin-top: 64px;
  margin-bottom: 56px;
  padding-left: 40px;
  align-items: start;
  justify-content: start;
`

export const LogoWrapperMobile = styled.div`
  width: 100%;
  display: flex;
  margin-top: 64px;
  margin-bottom: 56px;
  padding-left: 40px;
  align-items: start;
  justify-content: start;
`

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 64px;
  margin-bottom: 56px;
  align-items: center;
  justify-content: center;
`

export const MenuContainer = styled.div<{$isOpen:boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${props => props.theme.color.violet600};
  padding: 20px;
  box-sizing: border-box;
  width: 310px;
  height: 100%;

  border-radius: 0 24px 24px 0;
  //box-shadow: 10px 0 56px 0 rgba(0, 0, 0, 0.30);

  transform: translateX(${props => (props.$isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;


  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 4;
`

export const MenuContainerDimm= styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  
  background: rgba(0, 0, 0, 0.5);
  display: ${props => props.$isOpen ? 'block' : 'none'};

  z-index: 2;
`
export const MenuContainerWrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 3;
  pointer-events: ${props => props.$isOpen ? 'all' : 'none'};
`;
