import {styled} from "styled-components";
import heroImage from '../../../assets/images/hero.png';

export const HeroFormWrapper = styled.div`
  background-color: ${props => props.theme.color.gray100};
  align-items: flex-start;
  border-radius: 40px;
  box-shadow: 0 2.77px 2.21px #00000005, 0 6.65px 5.32px #00000007, 0 12.52px 10.02px #00000009,
  0 22.34px 17.87px #0000000b, 0 41.78px 33.42px #0000000d, 0 100px 80px #00000012;
  flex-direction: column;
  gap: 48px;
  position: relative;
  display: flex;

  @media (max-width: 1023px) {
    margin: 0 20px;
    top: 237px;
    padding: 24px;
  }
  @media (min-width: 1024px) {
    width: 755px;
    left: 40px;
    top: 164px;
    padding: 40px 56px;

  }
`

export const HeroFormHeadline = styled.div`
 
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1023px) {
    width: 100%;
  }
  
  @media (min-width: 1024px) {
    width: 596px;
  }
`

export const HeroFormHeadlineText = styled.p`
  font-family: ${props => props.theme.font.default};

  letter-spacing: -2.2px;
  line-height: 100%;
  font-style: normal;
  font-weight: 500;
  position: relative;
  width: fit-content;

  @media (max-width: 599px) {
    font-size: 28px;
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 60px;
  }
  
`

export const HeroFormSubtitleText = styled.p`
  line-height: 120%;
  letter-spacing: -0.4px;

  @media (max-width: 599px) {
    font-size: 14px;
    margin-top: 16px;
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    font-size: 18px;
    margin-top: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    margin-top: 24px;
  }
`

export const HeroFormSubtitleText2 = styled.p`
 
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.4px;

  @media (max-width: 599px) {
    font-size: 16px;
    margin-top: 32px;
  }
  @media (min-width: 600px) {
    font-size: 18px;
    margin-top: 48px;
  }
`

export const Divider20 = styled.div`
  height: 20px;
`

export const HeroWrapper = styled.div`
  background-color: ${props => props.theme.color.beige100};
  width: 100%;
`
export const HeroSection = styled.div`

  background-image: url(${heroImage});
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  z-index: 3;

  @media (max-width: 1023px) {
    height: 600px;
  }
  
  @media (min-width: 1024px) {
    height: 949px;
  }
`