import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../store";
import {useEffect, useState} from "react";
import {DoctorAppointment} from "./type";
import {fetchAllAppointments} from "./actions";

export const useFetchDoctorAppointments = (): DoctorAppointment[] => {
    const appointments = useSelector((state: AppState) => state.doctorAppointments.appointments)
    const [loading, setLoading] = useState(false)

    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!appointments && !loading) {
            setLoading(true)
            dispatch(fetchAllAppointments()).finally(() => setLoading(false))
        }
    }, [appointments, loading]);

    return appointments || []
}