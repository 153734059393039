import React, {useState} from "react";
import styled from "styled-components";
import InputField from "../../Shared/Input/Input";
import {AppState} from "../../../state/store";
import {useSelector} from "react-redux";
import {formatDateToDDMMYYYYWithDashes, formatSex, isValidName, isValidPhone, parseDate} from "../../../config/utils";
import CheckBox from "../../Shared/Input/CheckBox";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import PhoneNumberInput from "../../Shared/Input/PhoneNumberInput";
import {useFetchUser} from "../../../state/user/helpers";

const CardWrapper = styled.div`
  background: ${props => props.theme.color.gray100};
  border-radius: 16px;

  @media (max-width: 599px) {
    margin: 0 20px;
    padding: 24px;
  }

  @media (min-width: 600px) {
    box-sizing: border-box;
    align-items: center;
    justify-content: start;
    width: 910px;
    padding: 40px 56px 48px 56px;
  }
`

const CardTitle = styled.h4`
  color: ${props => props.theme.color.gray1000};
`

const SectionTitle = styled.div`
  color: ${props => props.theme.color.gray1000};
  width: 100%;
  margin-top: 15px;

  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    gap: 24px;
    width: 400px;
    margin: auto;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Link = styled.span`
  cursor: pointer;
  color: ${props => props.theme.color.violet600};
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`

const LinkButton = styled.button`
  color: ${props => props.theme.color.violet600};
`

export interface FormInfo {
    name: string,
    phone: string,
}

type InputFields = {
    [key: string]: string;
}

type BooleanFields = {
    [key: string]: boolean;
}

type ErrorFields = {
    [key: string]: string;
}

type OkFields = {
    [key: string]: boolean;
}

type ValidateOnTypeFields = {
    [key: string]: boolean;
}


const AppointmentSelectionForm: React.FC<
    {
        onSubmit: (form: FormInfo) => void,
        onCancel: () => void,
        childId: number,
        progress: boolean,
    }
> = (
    {
        childId,
        onSubmit,
        onCancel,
        progress
    }
) => {

    const [freeTextData, setFreeTextData] = useState<InputFields>({});
    const [checkBoxData, setCheckBoxData] = useState<BooleanFields>({});
    const [errors, setErrorData] = useState<ErrorFields>({});
    const [oks, setOkData] = useState<OkFields>({});
    const [validateOnType, setValidateOnTypeData] = useState<ValidateOnTypeFields>({});
    const child = useSelector((state: AppState) => state.children.children?.find(c => c.id === childId))
    const user = useFetchUser()

    const handleInputChange = (id: string, value: string) => {
        setFreeTextData(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleValidateOnType = (id: string, value: boolean) => {
        setValidateOnTypeData(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleErrorChange = (id: string, value: string | null) => {
        setErrorData(prevState => {
            const newState = {...prevState};
            if (value === null) {
                delete newState[id];
            } else {
                newState[id] = value;
            }
            return newState;
        });
    }
    const handleOkChange = (id: string, value: boolean | null) => {
        setOkData(prevState => {
            const newState = {...prevState};
            if (value === null) {
                delete newState[id];
            } else {
                newState[id] = value;
            }
            return newState;
        });
    }

    const handleBooleanChange = (id: string, value: boolean) => {
        setCheckBoxData(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const validateForm = () => {
        let isValidForm = true
        const nameOk = isValidName(freeTextData["name"] || "")
        if (!nameOk) {
            // display name error
            handleErrorChange("name", "Podaj imię i nzawisko")
            isValidForm = false
        }
        const phoneOk = isValidPhone(freeTextData["phone"] || "")
        if (!phoneOk) {
            handleErrorChange("phone", "Nieprawidłowy numer telefonu")
            isValidForm = false
        }

        const tncOk = checkBoxData["tnc"]
        if (!tncOk) {
            handleErrorChange("tnc", "Zaakceptuj regulamin")
            isValidForm = false
        }

        const privacyOk = checkBoxData["privacy"]
        if (!privacyOk) {
            handleErrorChange("privacy", "Zaakceptuj politykę prywatności")
            isValidForm = false
        }

        return isValidForm
    }

    const handleSubmitForm = () => {
        if (!validateForm()) {
            return
        }
        onSubmit({
            name: freeTextData["name"],
            phone: freeTextData["phone"]
        })
    }

    function validateEmailError(newValue: string) {
        const valid = isValidName(newValue || "")
        if (!valid) {
            handleOkChange("name", null)
            handleErrorChange("name", "Podaj imię i nzawisko")
        }
    }

    function validatePhoneError(newValue: string) {
        const valid = isValidPhone(newValue || "")
        if (!valid) {
            handleOkChange("phone", null)
            handleErrorChange("phone", "Nieprawidłowy numer telefonu")
        }
    }


    return (
        <CardWrapper>
            <CardContent>
                <CardTitle>Sprawdź dane</CardTitle>
                <SectionTitle>Dane dziecka</SectionTitle>
                <InputField
                    label="Imię i nazwisko"
                    value={child?.name || ""}
                    onChange={() => {
                    }}
                    disabled={true}
                />
                <InputField
                    label="PESEL"
                    value={child?.pesel || ""}
                    onChange={() => {
                    }}
                    disabled={true}
                />
                <InputField
                    label="Data urodzenia"
                    value={formatDateToDDMMYYYYWithDashes(parseDate(child?.dob || null))}
                    onChange={() => {
                    }}
                    disabled={true}
                />
                <InputField
                    label="Płeć biologiczna"
                    value={formatSex(child?.sex || null)}
                    onChange={() => {
                    }}
                    disabled={true}
                />

                <SectionTitle>Dane kontaktowe opiekuna</SectionTitle>
                <InputField
                    label="Imię i nazwisko"
                    value={freeTextData["name"]}
                    onChange={(newValue) => {
                        handleInputChange("name", newValue)
                        if (validateOnType["name"]) {
                            validateEmailError(newValue);
                        }
                        const valid = isValidName(newValue || "")
                        if (valid) {
                            handleErrorChange("name", null)
                            handleOkChange("name", true)
                        }
                    }}
                    error={errors["name"]}
                    correct={oks["name"]}
                    onBlur={() => {
                        handleValidateOnType("name", true)
                        validateEmailError(freeTextData["name"]);
                    }}
                />
                <InputField
                    label="Email"
                    value={user?.email || ""}
                    onChange={() => {
                    }}
                    disabled={true}
                />
                <Column>
                    <PhoneNumberInput
                        phoneNumber={freeTextData["phone"]}
                        setPhoneNumber={(newValue) => {
                            handleInputChange("phone", newValue)
                            if (validateOnType["phone"]) {
                                validatePhoneError(newValue);
                            }
                            const valid = isValidPhone(newValue || "")
                            if (valid) {
                                handleErrorChange("phone", null)
                                handleOkChange("phone", true)
                            }
                        }}
                        error={errors["phone"]}
                        correct={oks["phone"]}
                        onBlur={() => {
                            handleValidateOnType("phone", true)
                            validatePhoneError(freeTextData["phone"]);
                        }}
                    />
                    <p>Wyślemy Ci przypomnienie SMS przed wizytą. </p>
                </Column>
                <SectionTitle>Zgody</SectionTitle>
                <CheckBox
                    checked={checkBoxData["privacy"]}
                    // onChange={() => }
                    error={errors["privacy"]}
                    onChange={() => {
                        const newChecked = !checkBoxData["privacy"]
                        handleBooleanChange("privacy", newChecked)
                        handleErrorChange("privacy", newChecked ? null : "Zaakceptuj politykę prywatności")
                    }}
                >
                    <span>Zgadzam się na przetwarzanie moich danych osobowych oraz danych osobowych dziecka w celu umówienia i
                    przeprowadzenia teleporady z wybranym specjalistą. </span>
                    <Link
                        onClick={() => {
                            // TODO: implement privacy policy
                        }}
                    >Przeczytaj politykę prywatności</Link>
                </CheckBox>
                <CheckBox
                    checked={checkBoxData["tnc"]}
                    onChange={() => {
                        const newChecked = !checkBoxData["tnc"]
                        handleBooleanChange("tnc", newChecked)
                        handleErrorChange("tnc", newChecked ? null : "Zaakceptuj regulamin")
                    }}
                    error={errors["tnc"]}
                >
                    <span>Przeczytałem/am i akceptuję </span>
                    <Link
                        onClick={() => {
                            // TODO: implement privacy policy
                        }}
                    >regulamin portalu HappyKiddo</Link>
                </CheckBox>
                <Buttons>
                    <LinkButton onClick={() => {
                        onCancel()
                    }}>Wróć</LinkButton>
                    <ButtonPrimary
                        title="Potwierdź i umów wizytę"
                        onClick={() => {
                            if (!validateForm()) return
                            handleSubmitForm()
                        }}
                        progress={progress}
                    />
                </Buttons>
            </CardContent>
        </CardWrapper>
    )
}

export default AppointmentSelectionForm