import React from "react";
import {
    Indicator,
    IndicatorWrapper,
    MenuItemStyled,
    StyledMenuItem,
    StyledMenuItemBlack,
    Title,
    TitleBlack
} from "./MenuButton.styles";
import {useTheme} from "styled-components";

interface Props {
    id: number,
    style: "user" | "doctor",
    selected: boolean,
    IconComponent: React.FC,
    title: string,
    onClick: (id: number) => void
}

const MenuButton: React.FC<Props> = (
    {
        id,
        style,
        selected,
        IconComponent,
        title,
        onClick
    }
) => {

    const theme = useTheme()

    const renderIndicator = () => {
        if (style === "user") {
            return (<IndicatorWrapper>{selected ? <Indicator $color={theme.color.pink500}/> : null}</IndicatorWrapper>)
        }

        return <IndicatorWrapper>{selected ? <Indicator $color={theme.color.gray1000}/> : null}</IndicatorWrapper>
    }

    const renderIcon = () => {
        if (style === "doctor") {
            return (
                <StyledMenuItemBlack>
                    <IconComponent/>
                </StyledMenuItemBlack>
            );
        }
        return (
            <StyledMenuItem selected={selected}>
                <IconComponent/>
            </StyledMenuItem>
        );
    }

    const renderTitle = () => {
        if (style === "doctor") {
            return <TitleBlack>{title}</TitleBlack>
        }
        return <Title selected={selected}>{title}</Title>
    }

    return (<MenuItemStyled
        onClick={() => onClick(id)}
    >
        {renderIndicator()}
        {renderIcon()}
        {renderTitle()}
    </MenuItemStyled>);
}

export default MenuButton;