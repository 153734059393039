import styled from "styled-components";

export const HelpContainer = styled.div`
  min-height: 100vh;
  box-sizing: border-box;


  @media (max-width: 599px) {
    padding-top: 76px;
  }

  @media (min-width: 600px) {
    padding: 48px;
  }
`

export const InputLabel = styled.h5`
  color: ${props => props.theme.color.gray1000};
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;


  @media (max-width: 599px) {
    padding: 16px;
    gap: 16px;
  }

  @media (min-width: 600px) {
    align-items: center;
    justify-self: center;
  }
`

export const InputHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    margin: 125px 0;
    width: 800px;
    gap: 16px;
  }
`

export const Title = styled.h3`
  @media (max-width: 599px) {
    padding: 0 16px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
  }

  @media (min-width: 600px) {
  }

`


export const CardsRow = styled.div`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    margin-right: 32px;
    margin-bottom: 32px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid #ccc;

  border-radius: ${props => props.theme.radius.radius500};
  border: 1px solid ${props => props.theme.color.gray600};

  &:focus {
    border: 2px solid ${props => props.theme.color.gray900};
    outline: none;
  }

  &:checked {
    background-color: ${props => props.theme.color.violet600};
  }

  background-color: ${props => props.theme.color.gray100};
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  flex-grow: 1;
  height: 50px;
  border-radius: 0;
  border: none;

  &:focus {
    border: none;
    outline: none;
  }

  &:checked {
    background: none;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  padding: 0 16px;
`;
