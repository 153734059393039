import React from "react";
import icError from "../../../assets/images/ic_input_error.svg";
import {
    ErrorIcon,
    ErrorText,
    ErrorWrapper,
    InputContainer,
    InputLabel,
    InputWrapper,
    Option,
    Select,
} from "./DropDownInput.styles";

export interface OptionProp {
    key: string
    label: string
}

type Props = {
    options: OptionProp[]
    label: string;
    value: string;
    onChange: (value: string) => void;

    disabled?: boolean | null
    correct?: boolean | null
    error?: string | null
    onBlur?: () => void
};

const DropDownInput: React.FC<Props> = (
    {
        options,
        label,
        value,
        onChange,
        disabled,
        correct,
        error,
        onBlur,
    }
) => {

    const getInputState = () => {
        if (disabled) {
            return "disabled"
        }
        if (error) {
            return "error"
        }
        if (correct) {
            return "success"
        }
        return "default"
    }

    const renderError = () => {
        if (!error) return null;
        return (
            <ErrorWrapper>
                <ErrorIcon src={icError}/>
                <ErrorText>{error}</ErrorText>
            </ErrorWrapper>
        );
    }

    function renderOptions() {
        return options.map((option, index) => (
            <Option
                key={index}
                value={option.key}>{option.label}</Option>)
        )
    }

    return (
        <InputWrapper>
            <InputLabel>{label}</InputLabel>
            <InputContainer>
                <Select
                    value={value}
                    onChange={(e) => {
                        const value = e.target.value
                        const selected = options.find(el => el.key === value)
                        if (selected) {
                            onChange(selected.key)
                        }
                    }}
                    onBlur={event => {
                        event.preventDefault()
                        if (onBlur) {
                            onBlur()
                        }
                    }}
                    $state={getInputState()}
                >
                    {renderOptions()}
                </Select>
            </InputContainer>
            {renderError()}
        </InputWrapper>
    );
};

export default DropDownInput;
