import styled from "styled-components";
import icChevronDown from "../../../../../assets/images/ic_chevron_down.svg";

export const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
`

export const Container = styled.div`
  width: 60%;
  min-width: 800px;

  padding: 64px 24px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  background-color: ${props => props.theme.color.gray100};
  border-radius: 0 16px 16px 16px;
`

export const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const SectionTitleText = styled.h5`
  color: ${props => props.theme.color.gray1000};

`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Column1 = styled.div`
  width: 290px;
`

export const Column2 = styled.div`
`

export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const NoEntries = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 200px 0;
`


export const RightContainer = styled.div`
  width: 40%;
  padding: 16px;
  background-color: ${props => props.theme.color.gray100};
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RightContainerTitle = styled.h4`
  text-align: center;
`

export const SelectContainer = styled.div`
  margin-top: 4px;
  position: relative;
  display: inline-block;

  &::after {
    content: url(${icChevronDown});
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-40%);
    pointer-events: none;
    color: ${props => props.theme.color.violet600};
  }
`;

export const Option = styled.option`
  width: 100%;
  text-align: center;
`;

export const Chart = styled.p`
  text-align: center;
  margin: 200px 0;
`

export const Select = styled.select`
  box-sizing: border-box;
  border: none;
  color: ${props => props.theme.color.violet600};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &:focus {
    border: none;
  }

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 30px; /* Space for the custom arrow */
  background: transparent;
  width: 100%;
`
