import styled from "styled-components";
import SvgFill from "../../Shared/Icons/SvgFill";


export const PopupDropdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  box-sizing: border-box;

  margin: 0 20px;
`

export const PopupContentText = styled.p<{ $selected?: boolean }>`
  border-radius: 8px;
  font-size: 12px;
  background: ${props => props.$selected ? props.theme.color.violet300 : 'transparent'};
  font-weight: ${props => props.$selected ? 600 : 400};
  line-height: 20px;
  padding: 8px 12px;
  align-items: center;
  align-self: stretch;
`


export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  background-color: ${props => props.theme.color.gray100};

  padding: 6px 12px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.gray500};
`;
export const ContentWrapper = styled.div`
  min-height: 100vh;
  box-sizing: border-box;

  @media (min-height: 800px) {
    padding: 76px 20px 20px;
  }

  @media (min-width: 600px) {
    padding: 10px 40px 40px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;

  @media (min-height: 800px) {
    flex-direction: column;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const Title = styled.h3`
  color: ${props => props.theme.color.gray1000};

  @media (min-height: 800px) {
    margin-bottom: 20px;
  }

  @media (min-width: 600px) {
    margin-top: 56px;
    margin-bottom: 60px;
  }
`

export const CardsContainer = styled.div`

  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
  }

  @media (min-width: 600px) {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }
`;

export const Card = styled.div`
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 10px;
`;

export const MobileSearchDropdownText = styled.p`
  color: ${props => props.theme.color.gray900};
  font-size: 12px;
  line-height: 20px;
`

export const MobileSearchDropdown = styled.div`
  max-width: 167px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.gray500};
  background: ${props => props.theme.color.gray100};
  padding: 6px 12px;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`

export const MobileSearchWrapper = styled.div`
  @media (min-height: 800px) {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 16px;
    justify-content: space-between;
    position: relative;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const DesktopSearchWrapper = styled.div`

  @media (min-height: 800px) {
    display: none;
  }

  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`

export const SearchBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

export const SearchButtonIcon = styled.img`
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
`

export const Container = styled.div`
  position: relative;
`;

export const InputBubbleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

export const Input = styled.input`
  height: 25px;
  padding: 8px;
  border: 1px solid #ccc;

  @media (min-height: 800px) {
    flex-grow: 1;
  }

  @media (min-width: 600px) {
    width: 468px;
  }
`;

export const Suggestions = styled.ul<{ $focus: boolean }>`
  display: ${props => props.$focus ? 'block' : 'none'};
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background: white;
  border-radius: 12px;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
`;

export const SuggestionItem = styled.li`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }
`;

export const BubbleWrapper = styled.div`
  padding: 8px;
  height: 20px;
  background: ${props => props.theme.color.violet600};
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.color.violet700};
  }
`

export const SvgFill2 = styled(SvgFill)`
  margin-top: 4px;
`

export const Bubble = styled.span`
  color: ${props => props.theme.color.gray100};
`