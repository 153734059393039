import {UserState} from "./types";
import {UserAction} from "./actions";
import {ProfileAction} from "../profile/actions";

const initialState: UserState = {
    user: undefined,
}

export const userReducer = (state = initialState, action: UserAction | ProfileAction): UserState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "GET_USER_SUCCESS":
            return {
                ...state,
                user: action.data || null,
            };
        default:
            return state
    }
}
