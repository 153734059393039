import { Action } from "./actions";
import { DoctorsState } from "./types";
import {ProfileAction} from "../profile/actions";

const initialState: DoctorsState = {
    data: null,
    loading: false,
    error: null,
};

export const doctorsReducer = (state = initialState, action: Action | ProfileAction): DoctorsState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "DOCTORS_START":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "DOCTORS_SUCCESS":
            return {
                ...state,
                data: action.data || [],
                loading: false,
                error: null,
            };
        case "DOCTORS_ERROR":
            return {
                ...state,
                loading: false,
                error: "something went wrong"
            };
        default:
            return state;
    }
};
