import {AppDispatch, AppState} from "../store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {fetchAllDoctors} from "./actions";

export const useFetchDoctors = () => {
    const dispatch: AppDispatch = useDispatch();
    const doctors = useSelector((state: AppState) => state.doctors.data);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoading && !doctors) {
            setIsLoading(true)
            dispatch(fetchAllDoctors())
                .catch(e => console.log("Error fetching user", e))
                .then(() => {
                })
                .finally(() => setIsLoading(false));
        }
    }, [doctors, dispatch]);

    return doctors || [];
};