import React, {useState} from "react";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import {isValidEmail, isValidPassword} from "../../../config/utils";
import InputField from "../../Shared/Input/Input";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {loginUser} from "../../../state/profile/actions";
import {
    ButtonWrapper,
    CheckboxContainer,
    FormPart,
    HeaderPart,
    HeaderSubtitle,
    HeaderTitle,
    LinkButtonWrapper,
    LoginFormWrapper,
    RecoveryLink,
    RecoveryText,
    RememberMeText,
    UnderInputs
} from "./LoginForm.styles";


const CheckBox: React.FC<{ checked: boolean, onChecked: () => void }> = ({checked, onChecked}) => {
    return (
        <CheckboxContainer>
            <input
                type="checkbox"
                checked={checked}
                onChange={() => onChecked()}
            />
            <RememberMeText>Zapamiętaj mnie</RememberMeText>
        </CheckboxContainer>
    )
}

const LoginForm: React.FC<{
    navigateToRecover: () => void,
    navigateRegister: () => void,
    navigateDoctorLogin?: () => void,
}> = ({navigateToRecover, navigateRegister, navigateDoctorLogin}) => {

    const [validateEmailOnType, setValidateEmailOnType] = useState(false);
    const [validatePasswordOnType, setValidatePasswordOnType] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [emailCorrect, setEmailCorrect] = useState<boolean | null>(null);
    const [displayPasswordCorrect, setDisplayPasswordCorrect] = useState<boolean | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch: AppDispatch = useDispatch()


    const validateEmailCorrect = (email: string) => {
        const isValid = isValidEmail(email);
        setEmailCorrect(isValid)
    }

    const validatePasswordCorrect = (password: string) => {
        setDisplayPasswordCorrect(isValidPassword(password))
    }

    const validatePasswordError = (password: string) => {
        const isValid = isValidPassword(password)
        setPasswordError(!isValid ? "Nieprawidłowy format hasła" : null);
        return isValid;
    }

    const validateEmailError = (email: string) => {
        const isValid = isValidEmail(email);
        setEmailError(!isValid ? "Nieprawidłowy adres email" : null)
        return isValid;
    }

    const validateForm = () => {
        const isEmailValid = validateEmailError(email)
        const isPasswordValid = validatePasswordError(password)
        return isEmailValid || isPasswordValid
    }

    function renderDoctorLogin() {
        if (!navigateDoctorLogin) {
            return null
        }
        return (
            <LinkButtonWrapper>
                <RecoveryText>Jesteś lekarzem?</RecoveryText>
                <RecoveryLink onClick={navigateDoctorLogin}>Przejdź do strony dla lekarzy</RecoveryLink>
            </LinkButtonWrapper>
        );
    }

    return (
        <LoginFormWrapper>
            <HeaderPart>
                <HeaderTitle>Witaj ponownie</HeaderTitle>
                <HeaderSubtitle>Zaloguj się, żeby przejść do swojego konta</HeaderSubtitle>
            </HeaderPart>
            <FormPart>
                <InputField
                    label="Email"
                    type={"email"}
                    value={email}
                    onChange={(em: string) => {
                        setEmail(em)
                        if (validateEmailOnType) {
                            validateEmailError(em)
                        }
                        validateEmailCorrect(em)
                    }}
                    error={emailError}
                    correct={emailCorrect}
                    onBlur={() => {
                        setValidateEmailOnType(true);
                        validateEmailError(email);
                    }}
                />

                <InputField
                    label="Hasło"
                    type="password"
                    value={password}
                    onChange={(p: string) => {
                        setPassword(p)
                        if (validatePasswordOnType) {
                            validatePasswordError(p)
                        }
                        validatePasswordCorrect(p)
                    }}
                    onBlur={() => {
                        setValidatePasswordOnType(true);
                        validatePasswordError(password);
                    }}
                    correct={!!displayPasswordCorrect}
                    error={passwordError}
                />
                <UnderInputs>
                    <CheckBox
                        checked={checked}
                        onChecked={() => setChecked(!checked)}
                    />

                    <LinkButtonWrapper>
                        <RecoveryLink onClick={navigateToRecover}>Nie pamiętam hasła</RecoveryLink>
                    </LinkButtonWrapper>
                </UnderInputs>
            </FormPart>
            <ButtonWrapper>
                <ButtonPrimary
                    title="Zaloguj się"
                    onClick={() => {
                        if (!validateForm()) return
                        setLoading(true)
                        setEmailError(null)
                        setPasswordError(null)
                        dispatch(loginUser(email, password))
                            .then((response) => {
                                switch (response) {
                                    case "CREDENTIALS_INVALID":
                                        setEmailError("Nie możemy znaleźć takiego konta.")
                                        setPasswordError("Nie możemy znaleźć takiego konta.")
                                        break;
                                    case "SUCCESS":
                                        // do nothing
                                        break;
                                }
                            })
                            .catch(() => {
                                setEmailError("Wystąpił nieznany błąd.")
                                setPasswordError("Wystąpił nieznany błąd.")
                            })
                            .finally(() => setLoading(false))
                    }}
                    progress={loading}/>
                <div>
                    <LinkButtonWrapper>
                        <RecoveryText>Nie masz jeszcze konta?</RecoveryText>
                        <RecoveryLink onClick={navigateRegister}>Dołącz do nas</RecoveryLink>
                    </LinkButtonWrapper>
                    {renderDoctorLogin()}
                </div>
            </ButtonWrapper>
        </LoginFormWrapper>
    );
}

export default LoginForm