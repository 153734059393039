import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const RecoveryLink = styled.a`
`;

export const LinkButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
