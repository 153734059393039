import React, {ReactNode, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {logoutUser} from '../../state/profile/actions';
import {Route, Routes, useNavigate} from 'react-router-dom';
import DoctorsDetails from '../Doctors/DoctorsDetails/DoctorsDetails';
import ChildrenList from '../Children/ChildrenList/ChildrenList';
import DoctorsList from '../Doctors/DoctorsList/DoctorsList';
import ChildDetails from '../Children/ChildDetails/ChildDetails';
import DoctorSettings from '../DoctorSettings/DoctorSettings';
import Home from "../Home/Home";
import {AppDispatch} from "../../state/store";
import MenuButton from "./MenuButton/MenuButton";
import imgLogo from "../../assets/images/img_logo_vertical.svg"
import imgLogoDark from "../../assets/images/img_logo_black.svg"
import {
    BottomSidebar,
    BurgerMenu,
    ContentArea,
    ContentAreaWrapper,
    DashboardWrapper,
    LoginLogoTop,
    LogoStyled,
    LogoStyledBlack,
    LogoStyledMobile,
    LogoWrapper,
    LogoWrapperBlack,
    LogoWrapperMobile,
    MenuContainer,
    MenuContainerDimm,
    MenuContainerWrapper,
    Sidebar,
    Topbar
} from "./Dashboard.styles";
import SelectChildType from "../Children/SelectChildType/SelectChildType";
import AddChild from "../Children/AddChild/AddChild";
import AddPregnancy from "../Children/AddPregnancy/AddPregnancy";
import PregnancyDetails from "../Children/PregnancyDetails/PregnancyDetails";
import {Paths} from "../../config/paths";
import Help from "../Help/Help/Help";
import HelpSection from "../Help/HelpSection/HelpSection";
import Settings from "../Settings/Settings";
import VaccinationCalendar from "../VaccineCalendar/VaccineCalendar";
import {doctorSettingsItems, doctorsMenuItems, MenuItem, menuItems, settingsItems} from "./menuitems";
import DoctorHome from "../DoctorHome/DoctorHome";
import {useFetchUser} from "../../state/user/helpers";
import {useTheme} from "styled-components";
import DoctorPatientsList from "../Patients/PatientsList/PatientsList";
import DoctorPatientDetails from "../Patients/PatientDetails/PatientDetails";
import DoctorAppointmentsUpcoming from "../Visits/UpcomingVisits/UpcomingVisits";
import DoctorAppointmentsUnconfirmed from "../Visits/UnconfirmedVisits/UnconfirmedVisits";
import PatientAppointmentsList from "../PatientAppointments/PatientAppointmentsList";
import PatientAppointment from "../Appointments/PatientAppointment/PatientAppointment";
import DoctorAppointmentDetails from "../Appointments/DoctorAppointment/DoctorAppointmentDetails";
import ArticleListPage from "../Article/List/ArticleListPage";
import ArticleDetailsPage from "../Article/ArticleDetailsPage";
import AiDiagnosesList from "../AiDiagnosesList/AiDiagnosesList";
import AiDiagnosisDetails from "../AiDiagnosesList/AiDiagnosesDetails";
import icLogoMobile from "../../assets/images/ic_logo_mobile.svg";
import icBurgerMenu from "../../assets/images/ic_burger_menu.svg";


const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch()
    const user = useFetchUser()
    const [selected, setSelected] = useState<number | null>(null)
    const theme = useTheme()

    const handleLogoutClick = () => {
        dispatch(logoutUser())
            .then(() => console.log("User logged out"))
            .catch(() => console.log("Error trying to log user out"))
    };

    useEffect(() => {
        const savedValue = localStorage.getItem('menuitemId');
        if (savedValue) {
            setSelected(Number(savedValue))
        }
    }, [setSelected]);

    useEffect(() => {
        if (selected !== null) {
            localStorage.setItem('menuitemId', selected.toString());
        }
    }, [selected]);

    function onItemClicked(itemId: number) {
        setSelected(itemId)
        switch (itemId) {
            // User
            case 0:
                navigate(Paths.HOME);
                break;
            case 1:
                navigate(Paths.DEPENDANTS_ALL);
                break;
            case 2:
                navigate(Paths.VACCINATIONS_CALENDAR);
                break;
            case 3:
                navigate(Paths.DOCTORS_ALL);
                break;
            // Settings
            case 6:
                navigate(Paths.SETTINGS)
                break
            case 7:
                navigate(Paths.HELP);
                break;
            case 8:
                handleLogoutClick()
                break;
            // Doctor
            case 9:
                navigate(Paths.PATIENTS);
                break;
            case 10:
                navigate(Paths.UPCOMING_VISITS);
                break;
            case 11:
                navigate(Paths.CONFIRMATIONS);
                break;
            case 12:
                navigate(Paths.DOCTOR_SETTINGS);
                break
            case 13:
                navigate(Paths.PATIENT_APPOINTMENTS_LIST);
                break
            // Other
            default:
                navigate(Paths.HOME);
                break;
        }
    }

    function isSelectedMenuItem(item: MenuItem) {
        if (item.id === 0 && selected == null) {
            return true
        }
        return item.id === selected;
    }

    function getMenuStyleType() {
        return user?.doctorId ? "doctor" : "user";
    }

    const renderSettingsItems = () => {
        let items: MenuItem[]
        if (user?.doctorId != null) {
            items = doctorSettingsItems
        } else {
            items = settingsItems
        }


        return <>
            {items.map(item => <MenuButton
                key={item.id}
                id={item.id}
                selected={item.id === selected}
                IconComponent={item.IconComponent}
                title={item.label}
                onClick={(id) => {
                    onItemClicked(id)
                    toggleMenu()
                }}
                style={getMenuStyleType()}
            />)}
        </>;
    }

    const renderMenuItems = (andThen?: () => void) => {
        let items: MenuItem[]
        if (user?.doctorId != null) {
            items = doctorsMenuItems
        } else {
            items = menuItems
        }

        return <>
            {items.map(item => <MenuButton
                style={getMenuStyleType()}
                key={item.id}
                id={item.id}
                selected={isSelectedMenuItem(item)}
                IconComponent={item.IconComponent}
                title={item.label}
                onClick={(id) => {
                    onItemClicked(id)
                    if (andThen) andThen()
                }}
            />)}
        </>;
    }

    const renderHome = (): ReactNode => {
        if (user?.doctorId != null) {
            return (<DoctorHome
                navigateToUpcomingDoctorsAppointments={() => onItemClicked(10)}
                navigateToUnconfirmedDoctorsAppointments={() => onItemClicked(11)}
            />)
        }
        return (<Home/>)
    }

    const getSidebarBackgroundColor = () => {
        if (user?.doctorId != null) {
            return theme.color.violet400
        }
        return theme.color.violet600
    }

    const renderLogo = () => {
        if (user?.doctorId != null) {
            return (
                <LogoWrapperBlack>
                    <LogoStyledBlack
                        onClick={() => onItemClicked(menuItems[0].id)}
                        src={imgLogoDark}
                    />
                </LogoWrapperBlack>
            );
        }
        return (
            <LogoWrapper>
                <LogoStyled
                    onClick={() => onItemClicked(menuItems[0].id)}
                    src={imgLogo}
                />
            </LogoWrapper>
        );
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <DashboardWrapper $color={getSidebarBackgroundColor()}>
            <Topbar>
                <BurgerMenu src={icBurgerMenu} onClick={toggleMenu}/>
                <LoginLogoTop src={icLogoMobile}/>

            </Topbar>
            <MenuContainerWrapper $isOpen={isOpen}>
                <MenuContainerDimm $isOpen={isOpen} onClick={toggleMenu}/>
                <MenuContainer $isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
                    <div>
                        <LogoWrapperMobile>
                            <LogoStyledMobile
                                onClick={() => {
                                    onItemClicked(menuItems[0].id)
                                    toggleMenu()
                                }}
                                src={imgLogo}
                            />
                        </LogoWrapperMobile>
                        {renderMenuItems(toggleMenu)}
                    </div>
                    <BottomSidebar>
                        {renderSettingsItems()}
                    </BottomSidebar>
                </MenuContainer>
            </MenuContainerWrapper>

            <Sidebar>
                <div>
                    {renderLogo()}
                    {renderMenuItems()}
                </div>
                <BottomSidebar>
                    {renderSettingsItems()}
                </BottomSidebar>
            </Sidebar>
            <ContentAreaWrapper>
                <ContentArea>
                    <Routes>
                        <Route path={Paths.HOME} element={renderHome()}/>
                        {/*Articles*/}
                        <Route path={Paths.ARTICLES_PAGE} element={<ArticleListPage/>}/>
                        <Route path={Paths.ARTICLE_DETAILS_PAGE} element={<ArticleDetailsPage/>}/>
                        {/* Doctors */}
                        <Route path={Paths.DOCTORS_ALL} element={<DoctorsList/>}/>
                        <Route path={Paths.DOCTORS_DETAILS} element={<DoctorsDetails/>}/>
                        <Route path={Paths.DOCTOR_SETTINGS} element={<DoctorSettings/>}/>
                        {/* Dependants */}
                        <Route path={Paths.DEPENDANTS_ALL} element={<ChildrenList/>}/>
                        <Route path={Paths.DEPENDANTS_SELECT_TYPE} element={<SelectChildType/>}/>
                        {/* Children */}
                        <Route path={Paths.CHILDREN_ADD} element={<AddChild/>}/>
                        <Route path={Paths.CHILDREN_DETAILS} element={<ChildDetails/>}/>
                        {/* Pregnancies */}
                        <Route path={Paths.PREGNANCY_ADD} element={<AddPregnancy/>}/>
                        <Route path={Paths.PREGNANCY_DETAILS} element={<PregnancyDetails/>}/>
                        {/*Vaccines*/}
                        <Route path={Paths.VACCINATIONS_CALENDAR} element={<VaccinationCalendar/>}/>
                        {/*Patient Appointments*/}
                        <Route path={Paths.PATIENT_APPOINTMENTS_LIST} element={<PatientAppointmentsList/>}/>
                        <Route path={Paths.PATIENT_APPOINTMENT} element={<PatientAppointment/>}/>
                        {/* settings */}
                        <Route path={Paths.SETTINGS} element={<Settings/>}/>
                        <Route path={Paths.HELP} element={<Help/>}/>
                        <Route path={Paths.HELP_SECTION} element={<HelpSection/>}/>
                        {/*Doctor*/}
                        {/*Doctor Patients*/}
                        <Route path={Paths.PATIENTS} element={<DoctorPatientsList/>}/>
                        <Route path={Paths.PATIENT_DETAILS} element={<DoctorPatientDetails/>}/>
                        <Route path={Paths.UPCOMING_VISITS} element={<DoctorAppointmentsUpcoming/>}/>
                        <Route path={Paths.CONFIRMATIONS} element={<DoctorAppointmentsUnconfirmed/>}/>
                        {/*Doctor Appointments*/}
                        <Route path={Paths.DOCTOR_APPOINTMENT} element={<DoctorAppointmentDetails/>}/>
                        {/*AI diagnoses*/}
                        <Route path={Paths.AI_DIAGNOSIS_LIST} element={<AiDiagnosesList/>}/>
                        <Route path={Paths.AI_DIAGNOSIS_DETAILS} element={<AiDiagnosisDetails/>}/>
                    </Routes>
                </ContentArea>
            </ContentAreaWrapper>
        </DashboardWrapper>
    );
};

export default Dashboard;
