import IcHome from "../Shared/Icons/IcHome";
import IcKids from "../Shared/Icons/IcKids";
import IcStethoscope from "../Shared/Icons/IStethoscope";
import IcCalendar from "../Shared/Icons/IcCalendar";
import IcSettings from "../Shared/Icons/IcSettings";
import IcHelp from "../Shared/Icons/IcHelp";
import IcLogout from "../Shared/Icons/IcLogout";
import IcPatient from "../Shared/Icons/IcPatient";
import React from "react";
import IcComputer from "../Shared/Icons/IcComputer";
import IcBrowser from "../Shared/Icons/IcBrowser";
import IcMedKit from "../Shared/Icons/IcMedKit";
import IcSyringe from "../Shared/Icons/IcSyringe";

export interface MenuItem {
    id: number;
    label: string;
    IconComponent: React.FC;
}

export const menuItems: MenuItem[] = [
    {
        id: 0,
        label: 'Start',
        IconComponent: IcHome,
    },
    {
        id: 1,
        label: 'Moje dzieci',
        IconComponent: IcKids,
    },
    {
        id: 3,
        label: 'Katalog lekarzy',
        IconComponent: IcStethoscope,
    },
    {
        id: 2,
        label: 'Szczepienia',
        IconComponent: IcSyringe,
    },
    {
        id: 13,
        label: 'Wizyty',
        IconComponent: IcMedKit,
    },
];

export const doctorsMenuItems: MenuItem[] = [
    {
        id: 0,
        label: 'Start',
        IconComponent: IcHome,
    },
    {
        id: 10,
        label: 'Umówione wizyty',
        IconComponent: IcComputer,
    },
    {
        id: 11,
        label: 'Prośby o potwierdzenie',
        IconComponent: IcBrowser,
    },
    {
        id: 9,
        label: 'Pacjenci',
        IconComponent: IcPatient,
    },
];


export const settingsItems: MenuItem[] = [
    {
        id: 6,
        label: 'Ustawienia konta',
        IconComponent: IcSettings,
    },
    {
        id: 7,
        label: 'Pomoc',
        IconComponent: IcHelp,
    },
    {
        id: 8,
        label: 'Wyloguj się',
        IconComponent: IcLogout,
    },
];

export const doctorSettingsItems: MenuItem[] = [
    {
        id: 12,
        label: 'Ustawienia',
        IconComponent: IcSettings,
    },
    {
        id: 8,
        label: 'Wyloguj się',
        IconComponent: IcLogout,
    },
];