import {ProfileState} from "./types";
import {ProfileAction} from "./actions";

const initialState: ProfileState = {
    profile: null,
}

export const profileReducer = (state = initialState, action: ProfileAction): ProfileState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "LOGIN_SUCCESS":
            return {
                ...state,
                profile: action.profile,
            };
        default:
            return state
    }
}
