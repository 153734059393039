import styled from "styled-components";

export const PopupDim = styled.div<{ $isVisible: boolean }>`
  display: ${({$isVisible}) => ($isVisible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const PopupContent = styled.div`
  box-sizing: border-box;
  background-color: ${props => props.theme.color.gray100};


  @media (max-width: 599px) {
    width: 100%;
    margin: 0 20px;
    border-radius: 20px;
    padding: 24px;
  }

  @media (min-width: 600px) {
    border-radius: 32px;
    padding: 40px;
  }

`;