import styled from "styled-components";
import { ReactComponent as IcnSearch } from '../../../assets/images/ic_search.svg'

export const InputWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #e2e2e2;

  @media (max-width: 599px) {
    height: 40px;
    padding: 0 12px;
  }
  @media (min-width: 600px) {
    height: 48px;
    padding: 0 8px 0 16px;
  }
`;

export const SearchIcon = styled(IcnSearch)`
`;


export const InputField = styled.input`
  min-width: 100%;
  font-family: ${props => props.theme.font.default};
 
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &:focus {
    outline: none;
    border: none;
  }

  &:focus-visible {
    outline: none;
    border: none;
  }

  border: none;

  @media (max-width: 599px) {
    font-size: 14px;
  }
  @media (min-width: 600px) {
    font-size: 16px;
  }
`

export const BubblesHolder = styled.div`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  overflow-x: scroll;
  
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* For Webkit browsers */
  }
`;