import styled from "styled-components";

export const Background = styled.div`
  position: relative;
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.color.gray100};
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 187px;
`

export const TagBubble = styled.p`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
  background: var(--Violet-Violet-200, #D5D1EE);
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  line-height: 20px;
`

export const ContentTitle = styled.h1`
  max-width: 910px;
  text-align: center;
`

export const ArticleImage = styled.img`
  max-width: 1220px;
  max-height: 724px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 910px;
  margin-top: 96px;
  gap: 16px;
`

export const ArticleSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const P = styled.p`
  margin-bottom: 4px;
`

export const H3 = styled.h3`
  margin-bottom: 24px;
`

export const BackArrowWrapper = styled.div`
  width: 100%;
  margin-bottom: 60px;
`

export const BackArrowInnerWrapper = styled.div`
  position: relative;
  margin-top: 96px;
  margin-left: 175px;
`

export const BottomWaveContainer = styled.div`
  position: relative;
  align-self: stretch;
  background: ${props => props.theme.color.gray100};
  overflow: hidden;
  height: 160px;
`

export const ArticleDate = styled.p`
  color: ${props => props.theme.color.gray800};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 96px;
`
