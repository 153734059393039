import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../store";
import {useEffect, useState} from "react";
import {fetchAppointments} from "./actions";

export const useFetchPatientAppointments = () => {

    const appointments = useSelector((state: AppState) => state.appointments.appointments)
    const [loading, setLoading] = useState(false)
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (appointments == null && !loading) {
            setLoading(true)
            dispatch(fetchAppointments())
                .catch((error) => {
                    console.log("error fetching appointments", error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [appointments, loading, setLoading, dispatch])

    return appointments || []

}