import React, {useState} from "react";
import icSpyglass from "../../../assets/images/ic_search.svg";
import chevron from "../../../assets/images/ic_chevron.svg";
import {useNavigate, useParams} from "react-router-dom";
import {items} from "../type";
import icBack from "../../../assets/images/ic_back.svg";
import {
    AccordionContainer,
    Answer,
    BackButton,
    BackIcon,
    BackText,
    ChevronImage,
    ContentWrapper,
    FaqItem,
    HelpContainer,
    Icon,
    InputHeaderWrapper,
    InputLabel,
    InputWrapper,
    Question,
    StyledInput,
    Title
} from "./HelpSection.styles";

const HelpSection: React.FC = () => {

    const [query, setQuery] = useState("")
    const [selected, setSelected] = useState<number | null>(null)
    const {id} = useParams();
    const section = items.find(el => el.id === id)
    const navigate = useNavigate()

    return (
        <HelpContainer>
            <Title>{section?.title}</Title>
            <BackButton
                onClick={() => navigate(-1)}>
                <BackIcon src={icBack}/>
                <BackText>Wróć do spisu tematów</BackText>
            </BackButton>
            <ContentWrapper>
                <InputHeaderWrapper>
                    <InputLabel>Jak możemy pomóc?</InputLabel>
                    <InputWrapper>
                        <Icon src={icSpyglass}/>
                        <StyledInput
                            value={query}
                            onChange={e => {
                                e.preventDefault()
                                setQuery(e.target.value)
                            }}
                            onFocus={() => {
                            }}
                            placeholder="Wpisz szukane słowa, np. umawianie wizyty"
                            onBlur={() => {
                            }}
                        />
                    </InputWrapper>
                    <AccordionContainer>
                        {section?.items
                            .filter(el => el.title.toLowerCase().includes(query.toLowerCase()))
                            .map((item, index) => (
                                <FaqItem key={index}>
                                    <Question onClick={() => {
                                        if (index === selected) {
                                            setSelected(null)
                                        } else {
                                            setSelected(index)
                                        }
                                    }}>
                                        {item.title}
                                        <ChevronImage alt="expand" src={chevron}/>
                                    </Question>
                                    <Answer open={index == selected}>{item.content}</Answer>
                                </FaqItem>
                            ))}

                    </AccordionContainer>
                </InputHeaderWrapper>

            </ContentWrapper>
        </HelpContainer>
    )
}

export default HelpSection