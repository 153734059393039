import React from "react";
import {useNavigate} from "react-router-dom";
import imgVisit from "../../../assets/images/img_visit.svg";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {Paths} from "../../../config/paths";
import {Buttons, Card, CardContent, CardText, CardTitle, DesktopContent, LinkButton, VisitImg} from "./AiCard.styles";

const AiCard: React.FC = () => {
    const navigate = useNavigate()

    return (
        <Card>
            <CardContent>
                <CardTitle>Teleporada została umówiona</CardTitle>
                <VisitImg src={imgVisit}/>
                <CardText>
                    Przed każdą wizytą obowiązkowe jest wykonanie diagnozy AI. Czy chcesz wykonać ją teraz?
                </CardText>
                <Buttons>
                    <DesktopContent>
                        <LinkButton onClick={() => {
                            navigate(-1)
                        }}>Zrobię to później</LinkButton>
                    </DesktopContent>
                    <ButtonPrimary
                        title="Wykonaj diagnozę AI"
                        onClick={() => {
                            navigate(Paths.DIAGNOSIS_NEW)
                        }}
                    />
                </Buttons>
            </CardContent>
        </Card>
    )
}

export default AiCard