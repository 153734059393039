import {Pregnancy} from "./types";
import api from "../../config/api";
import {Endpoints} from "../../config/endpoints";

interface FetchSuccess {
    type: "PREGNANCIES_SUCCESS",
    data: Pregnancy[],
}

export const fetchPregnancies = () => async (dispatch: any): Promise<Awaited<boolean>> => {
    try {
        const response = await api.get(Endpoints.PREGNANCY_GET)
        dispatch({type: "PREGNANCIES_SUCCESS", data: response.data})
        return Promise.resolve(true)
    } catch (e) {
        return Promise.resolve(false)
    }
}
export type Action = FetchSuccess