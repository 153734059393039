import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 40px;
  color: ${props => props.theme.color.violet600};
  cursor: pointer;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  border: 1px solid ${props => props.theme.color.violet600};

  @media (max-width: 599px) {
    line-height: 140%;
    letter-spacing: -0.28px;
    box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);
  }
  
  @media (min-width: 600px) {
    line-height: 160%;
  }
`;