import styled from "styled-components";
import imgRegistration from "../../../assets/images/img_registration_background.jpg";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: #FFF;
`;

export const Background = styled.div`
  position: relative;
  flex: 6;
  background-image: url(${imgRegistration});
  background-size: cover;
  background-position: center;
`;

export const BackgroundWave = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  object-fit: contain;
  height: 100%;
`

export const LoginFormContainer = styled.div`
  flex-direction: column;
  gap: 48px;
  border-radius: 40px;
  flex: 4;
  justify-content: center;

  display: flex;
  align-items: center;
  padding: 20px;
`;

export const RegisterSuccessFormWrapper = styled.form`
  padding: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const UnderInputs = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
`

export const HeaderPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
`

export const HeaderTitle = styled.h3`
  color: ${props => props.theme.color.gray1000};
`

export const HeaderSubtitle = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 20px;
`

export const FormPart = styled.div`
  display: flex;
  //justify-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`


export const ButtonWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 24px;
`

export const LinkButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const RecoveryText = styled.p`
  text-decoration: none;
  margin-right: 4px;
`;

export const RecoveryLink = styled.a`
`;

export const PasswordRequirements = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${props => props.theme.spacing.spacing200};
`

export const PasswordRequirement = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.spacing.spacing200};
`
export const PasswordRequirementText = styled.p<{ $color: string }>`
  color: ${props => props.$color};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`