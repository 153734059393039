import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchChildren} from "./actions";
import {AppDispatch, AppState} from "../store";

export const useFetchChildren = () => {
    const dispatch: AppDispatch = useDispatch();
    const children = useSelector((state: AppState) => state.children.children);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoading && children === null) {
            setIsLoading(true)
            dispatch(fetchChildren())
                .catch(e => console.log("Error fetching children", e))
                .then(result => {
                    // DO nothing
                })
                .finally(() => setIsLoading(false));
        }
    }, [children, dispatch]);

    return children || [];
};
