import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../config/paths";
import {
    formatDateToDDMMYYYYWithDelimiter,
    formatDateToDMMMMYYYYTimePolish,
    formatPaymentStatus,
    formatTime,
    parseDateTime,
    parsePaymentStatus
} from "../../config/utils";
import {Appointment} from "../../state/appointments/types";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "../../state/store";
import {fetchAllDoctors} from "../../state/doctors/actions";
import {fetchSpecializations} from "../../state/specializations/actions";
import {fetchChildren} from "../../state/children/actions";
import {
    CardBig,
    CardMobile,
    Cell,
    CellBubble,
    CellCentered,
    CellText,
    ContentWrapper,
    HeaderCell,
    HeaderCellCentered,
    HeaderTableRow,
    Headline,
    IcChild,
    IcChildImage,
    OutlineBubble,
    ResultsCellCentered,
    ResultsCellRight,
    TableCardContent,
    TableCardRows,
    TableRowDesktop,
    Title,
    TitleWrapper
} from "./PatientAppointmentsList.styles";
import {useFetchPatientAppointments} from "../../state/appointments/helpers";
import icChild from "../../assets/images/ic_person_small.svg";
import IcUpRight from "../Shared/Icons/IcUpRight";
import icMessage from "../../assets/images/ic_message.svg";
import {Alpha} from "../Patients/PatientDetails/PatientDetails.styles";
import icAi from "../../assets/images/ic_ai.svg";

const PatientAppointmentsList: React.FC = () => {

    const navigate = useNavigate()
    const doctors = useSelector((state: AppState) => state.doctors.data)
    const specializations = useSelector((state: AppState) => state.specializations.items)
    const children = useSelector((state: AppState) => state.children.children)
    const [childrenLoading, setChildrenLoading] = useState(false)
    const [doctorsLoading, setDoctorsLoading] = useState(false)
    const [specializationsLoading, setSpecializationsLoading] = useState(false)
    const dispatch: AppDispatch = useDispatch()
    const appointments = useFetchPatientAppointments()

    useEffect(() => {
        if (!children && !childrenLoading) {
            setChildrenLoading(true)
            dispatch(fetchChildren())
                .catch(error => {
                    console.log("error loading children", error)
                })
                .finally(() => {
                    setChildrenLoading(false)
                })
        }
    }, [children, childrenLoading, setChildrenLoading, dispatch]);


    useEffect(() => {
        if (!specializations && !specializationsLoading) {
            setSpecializationsLoading(true)
            dispatch(fetchSpecializations())
                .catch(error => {
                    console.log("error loading specializations", error)
                })
                .finally(() => {
                    setSpecializationsLoading(false)
                })
        }
    }, [specializations, specializationsLoading, setSpecializationsLoading, dispatch]);

    useEffect(() => {
        if (!doctors && !doctorsLoading) {
            setDoctorsLoading(true)
            dispatch(fetchAllDoctors())
                .catch(error => {
                    console.log("error loading doctors", error)
                })
                .finally(() => {
                    setDoctorsLoading(false)
                })
        }
    }, [doctors, doctorsLoading, setDoctorsLoading, dispatch]);

    const createChildName = (appointment: Appointment) => {
        if (!appointment.childId) {
            return "nie podano"
        }
        return children?.find(c => c.id === appointment.childId)?.name || "nie podano"
    }
    const createDate = (appointment: Appointment) => {
        if (!appointment.startTime) {
            return "nie podano"
        }
        const date = parseDateTime(appointment.startTime)
        if (!date) {
            return "nie podano"
        }
        return formatDateToDDMMYYYYWithDelimiter(date, "/")
    }

    const createDateLong = (appointment: Appointment) => {
        if (!appointment.startTime) {
            return "nie podano"
        }
        const date = parseDateTime(appointment.startTime)
        if (!date) {
            return "nie podano"
        }
        return formatDateToDMMMMYYYYTimePolish(date)
    }

    const createTime = (appointment: Appointment) => {
        if (!appointment.startTime) {
            return "nie podano"
        }
        const date = parseDateTime(appointment.startTime)
        if (!date) {
            return "nie podano"
        }
        return formatTime(date)
    }

    const createDoctor = (appointment: Appointment): string => {
        const doctor = doctors?.find(doc => doc.id === appointment.doctorId)
        if (!doctor) {
            return "nie podano"
        }
        return doctor.name
    }

    const renderChildIcon = (appointment: Appointment) => {
        const child = children?.find(c => c.id === appointment.childId)
        if (!child?.image) {
            return (<IcChild src={icChild} alt={"dziecko"}/>);
        }
        return (<IcChildImage src={process.env.REACT_APP_API_BASE_URL + child.image} alt={child.name}/>)
    }

    const renderCardMobile = (appointment: Appointment, index: number) => {
        return (
            <CardMobile
                key={"mobile-" + index}
                onClick={() => navigate(Paths.PATIENT_APPOINTMENT.replace(":id", appointment.id.toString()))}
            >
                <Cell>
                    {renderChildIcon(appointment)}
                    <CellText>{createChildName(appointment)}</CellText>
                    <IcUpRight/>
                </Cell>
                <CellCentered>{createDateLong(appointment)}</CellCentered>
                <CellCentered>
                    <OutlineBubble>
                        <CellText>{formatPaymentStatus(parsePaymentStatus(appointment.status))}</CellText>
                    </OutlineBubble>
                </CellCentered>
            </CardMobile>
        );
    }

    const renderTableRowDesktop = (appointment: Appointment, index: number) => <TableRowDesktop
        key={"desktop-" + index}>
        <Cell>
            {renderChildIcon(appointment)}
            <CellText>{createChildName(appointment)}</CellText>
        </Cell>
        <CellCentered><CellText>{createDate(appointment)}</CellText></CellCentered>
        <CellCentered><CellBubble>{createTime(appointment)}</CellBubble></CellCentered>
        <Cell><CellText>{createDoctor(appointment)}</CellText></Cell>
        <CellCentered>
            <OutlineBubble><CellText>{formatPaymentStatus(parsePaymentStatus(appointment.status))}</CellText></OutlineBubble>
        </CellCentered>
        <CellCentered><Alpha $alpha={0.3}><img src={icAi} alt="Message"></img></Alpha></CellCentered>
        <CellCentered><Alpha $alpha={0.3}><img src={icMessage} alt="Message"></img></Alpha></CellCentered>
        <ResultsCellRight
            onClick={() => navigate(Paths.PATIENT_APPOINTMENT.replace(":id", appointment.id.toString()))}
        ><IcUpRight/></ResultsCellRight>
    </TableRowDesktop>;

    const renderTableRow = (appointment: Appointment, index: number) => {
        return (
            <div key={index}>
                {renderCardMobile(appointment, index)}
                {renderTableRowDesktop(appointment, index)}
            </div>
        );
    }

    function renderHeadline() {
        let title = "Ostatnie wizyty"
        if (appointments.length > 0) {
            title += ` (${appointments.length})`
        }
        return <Headline>{title}</Headline>;
    }

    return (
        <ContentWrapper>
            <TitleWrapper>
                <Title>Wizyty</Title>
            </TitleWrapper>
            <CardBig>
                <TableCardContent>
                    {renderHeadline()}
                    <HeaderTableRow>
                        <HeaderCell>Dziecko</HeaderCell>
                        <HeaderCellCentered>Data</HeaderCellCentered>
                        <HeaderCellCentered>Godzina</HeaderCellCentered>
                        <HeaderCell>Specjalista</HeaderCell>
                        <ResultsCellCentered>Płatność</ResultsCellCentered>
                        <HeaderCellCentered>Diagnoza AI</HeaderCellCentered>
                        <HeaderCellCentered>Wiadomość</HeaderCellCentered>
                        <ResultsCellCentered></ResultsCellCentered>
                    </HeaderTableRow>
                    <TableCardRows>
                        {appointments.map((el, i) => renderTableRow(el, i))}
                    </TableCardRows>
                </TableCardContent>
            </CardBig>
        </ContentWrapper>
    )
}

export default PatientAppointmentsList