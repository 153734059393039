import {createGlobalStyle} from 'styled-components';

export const AppStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    background-color: ${props => props.theme.color.window};
    color: ${props => props.theme.color.gray1000};
    font-family: ${props => props.theme.font.default};
  }

  a {
    color: ${props => props.theme.color.violet600};
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @media (min-height: 800px) {
      font-size: 14px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
    }
  }

  input {
    border-radius: ${props => props.theme.radius.radius500};
    border: 1px solid ${props => props.theme.color.gray600};

    &:focus {
      border: 2px solid ${props => props.theme.color.gray900};
      outline: none;
    }

    &:checked {
      background-color: ${props => props.theme.color.violet600};
    }

    @media (max-width: 599px) {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    @media (min-width: 600px) {
    }


  }

  textarea {
    font-family: ${props => props.theme.font.default};
    color: ${props => props.theme.color.gray1000};
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.4px;


  }

  select {
    border-radius: ${props => props.theme.radius.radius500};
    border: 1px solid ${props => props.theme.color.gray600};

    &:focus {
      border: 1px solid ${props => props.theme.color.gray600};
      outline: none;
    }

    &:checked {
      background-color: ${props => props.theme.color.violet600};
    }
  }

  p {
    color: ${props => props.theme.color.gray1000};
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.4px;

    @media (max-width: 599px) {
      font-size: 14px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
    }
  }

  h1 {
    color: ${props => props.theme.color.gray1000};
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 52.8px */
    letter-spacing: -0.96px;
  }

  h2 {
    color: ${props => props.theme.color.gray1000};
    font-style: normal;

    @media (max-width: 599px) {
      font-size: 24px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.48px;
    }

    @media (min-width: 600px) {
      font-size: 33px;
      font-weight: 400;
    }
  }

  h3 {
    color: ${props => props.theme.color.gray1000};
    margin: 0;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    @media (max-width: 599px) {
      font-size: 18px;
      letter-spacing: -0.36px;
    }

    @media (min-width: 600px) {
      font-size: 36px;
      letter-spacing: -0.72px;

    }


  }

  h4 {
    color: ${props => props.theme.color.gray1000};
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin: 0;


    @media (max-width: 599px) {
      font-size: 18px;
      letter-spacing: -0.36px;
    }

    @media (min-width: 600px) {
      font-size: 24px;
      letter-spacing: -0.48px;
    }
  }

  h5 {
    color: ${props => props.theme.color.gray1000};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin: 0;


    @media (max-width: 599px) {
      font-size: 18px;
      font-style: normal;
      line-height: 120%;
      letter-spacing: -0.36px;
    }

    @media (min-width: 600px) {

    }
  }

  text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  button {
    border: none;
    background: none;
    font-style: normal;
    font-weight: 500;

    @media (max-width: 599px) {
      font-size: 14px;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      line-height: 160%;
    }

  }

  body {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

`
