import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppState} from '../../../state/store';
import imgWave from '../../../assets/images/bg_wave_login.png'
import icLogoMobile from '../../../assets/images/ic_logo_mobile.svg'

import {Background, BackgroundWave, Container, RegisterFormContainer, RegisterLogoTop} from "./Register.styles";
import RegisterForm from "./RegisterForm";


const RegisterPage: React.FC = () => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state: AppState) => state.profile.profile != null);

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);

    const navigateLogin = () => {
        navigate("/login");
    }
    const navigateDoctorLogin = () => {
        navigate("/doctor-login");
    }

    const onRegisterComplete = (email: string, password: string) => {
        navigate(`/register-complete?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`);
    };

    return (
        <Container>
            <Background>
                <BackgroundWave src={imgWave}/>
            </Background>
            <RegisterFormContainer>
                <RegisterLogoTop src={icLogoMobile}/>
                <RegisterForm
                    navigateDoctorLogin={navigateDoctorLogin}
                    navigateLogin={navigateLogin}
                    onRegisterComplete={onRegisterComplete}/>
            </RegisterFormContainer>
        </Container>
    );
}

export default RegisterPage;