import React from "react";
import {Container, NoEntries, Row, Section} from "./NotImplementedTab.styles";

const NotImplementedTab: React.FC<{ title: string }> = ({title}) => {
    return (
        <Container>
            <Section>
                <Row>
                    <NoEntries>{title}</NoEntries>
                </Row>
            </Section>
        </Container>
    )
}

export default NotImplementedTab