import {styled} from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.color.violet600};
  cursor: pointer;

  @media (max-width: 599px) {
    width: 46px;
    height: 46px;

    border-radius: 46px;
    box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);
  }

  @media (min-width: 600px) {
    width: 58px;
    height: 58px;
    border-radius: 58px;
    box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);
  }
`

export const ButtonIcon = styled.img``