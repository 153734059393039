import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
    progress: number;
    label: string;
}

const ProgressBarWrapper = styled.div`
  position: relative;

  @media (max-width: 599px) {
    margin: 0 16px;
    height: 6px;
    background: ${props => props.theme.color.violet300};
  }

  @media (min-width: 600px) {
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
  }
`;

const Progress = styled.div<{ $progress: number }>`
  position: absolute;
  height: 6px;
  width: ${props => props.$progress}%;
  background: ${props => props.theme.color.violet800};
`;

const Label = styled.h5`
  font-weight: 400;
  line-height: 140%;


  @media (max-width: 599px) {
    margin: 16px 20px;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    margin: 40px;
    letter-spacing: -0.4px;
  }
`;

const ProgressBar: React.FC<ProgressBarProps> = ({progress, label}) => {
    return (
        <div>
            <Label>{label}</Label>
            <ProgressBarWrapper>
                <Progress $progress={progress}/>
            </ProgressBarWrapper>
        </div>
    );
}

export default ProgressBar;
