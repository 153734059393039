import IcnSearch from '../../../../assets/images/ic_right_up_white.svg'
import React from "react";
import {ButtonContainer, ButtonIcon} from "./ButtonUpCircle.styles";

const ButtonUpCircle: React.FC = () => {
    return (
        <ButtonContainer>
            <ButtonIcon src={IcnSearch}/>
        </ButtonContainer>
    );
}

export default ButtonUpCircle;