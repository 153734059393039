import React from "react";
import {Doctor} from "../../../state/doctors/types";
import {
    DoctorAvatarContainer,
    DoctorAvatarImage,
    DoctorDetailsName,
    DoctorDetailsSpecialization,
    DoctorDetailsTexts,
    DoctorDetailsWrapper
} from "./DoctorInfoWidget.styles";

const DoctorInfoWidget: React.FC<{ doctor: Doctor | undefined | null }> = ({doctor}) => {

    const renderDoctorImage = () => {
        const src = doctor?.image
        if (!src) {
            return null
        }
        return <DoctorAvatarImage alt="Liliana Skorski" src={process.env.REACT_APP_API_BASE_URL + src}/>;
    };


    return (
        <DoctorDetailsWrapper>
            <DoctorAvatarContainer>
                {renderDoctorImage()}
            </DoctorAvatarContainer>
            <DoctorDetailsTexts>
                <DoctorDetailsName>{doctor?.name}</DoctorDetailsName>
                <DoctorDetailsSpecialization>{doctor?.specializations.map(e => e.name).join(", ")}</DoctorDetailsSpecialization>
            </DoctorDetailsTexts>
        </DoctorDetailsWrapper>
    )
}

export default DoctorInfoWidget