import styled from "styled-components";

type InputState = 'default' | 'error' | 'disabled' | 'success';

interface InputProps {
    $state?: InputState;
}

export const StyledInput = styled.input<InputProps>`
  box-sizing: border-box;
  width: 100%;
  min-height: ${props => props.theme.size.size1400};
  padding: ${props => props.theme.spacing.spacing400};

  ${({$state, theme}) => $state === 'error' && `
      border-width: 2px;
      border-color: ${theme.color.error600};
      background-color: ${theme.color.error100};
      &:focus {
        border: 2px solid ${theme.color.error600};
      }
    `
  }

  ${({$state, theme}) => $state === 'success' && `
      border-width: 2px;
      border-color: ${theme.color.violet600};
      background-color: ${theme.color.violet100};
      &:focus {
        border: 2px solid ${theme.color.violet600};
      }
    `
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.spacing200};
`

export const ErrorIcon = styled.img`
`

export const ErrorText = styled.p`
  color: ${props => props.theme.color.error600};
  font-size: 14px;
  line-height: 16px;
`
export const InputLabel = styled.p`
  color: #161618;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`
export const InputContainer = styled.div`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }
`