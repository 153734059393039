import {Title, TitleWrapper} from "../../PatientAppointments/PatientAppointmentsList.styles";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Card} from "../../Shared/Div/Card";
import icBack from "../../../assets/images/ic_back.svg";
import {useNavigate, useParams} from "react-router-dom";
import {formatDateToDMMMMYYYYTimePolish, formatPaymentStatus, parseDateTime, parsePaymentStatus} from "../../../config/utils";
import IcUpRight from "../../Shared/Icons/IcUpRight";
import {Paths} from "../../../config/paths";
import dialogIcon from "../../../assets/images/ic_chat.svg"
import penIcon from "../../../assets/images/ic_pencil.svg"
import {useFetchDoctorDiagnoses} from "../../../state/doctorDiagnoses/helpers";
import {useFetchDoctorAppointments} from "../../../state/doctorAppointments/helpers";
import {DoctorAppointment} from "../../../state/doctorAppointments/type";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import {createDoctorDiagnosis, updateDoctorDiagnosis} from "../../../state/doctorDiagnoses/actions";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";

export const BackIcon = styled.img`
`

export const BackButton = styled.div`
  margin-top: 16px;
  cursor: pointer;
  display: inline-flex;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1;
`

export const BackText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-weight: 400;
  line-height: 150%;
`
export const ContentWrapper = styled.div`
  padding: 10px 40px 40px;
  min-height: 100vh;
  box-sizing: border-box;
`;
export const CardBig = styled(Card)`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
`
export const CardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 16px;
  border: 2px solid ${props => props.theme.color.gray400};
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 10px 20px 0 rgba(241, 241, 241, 0.25);
`

export const ClickableCardSection = styled(CardSection)`
  cursor: pointer;
`

export const DoctorAvatarContainer = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  z-index: 1;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardRowGapBig = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`
export const CardRowGapSmall = styled(CardRowGapBig)`
  gap: 8px;
`

export const CardRowWithTextArea = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const PlaceholderText = styled.p`
  color: ${props => props.theme.color.gray900};
  line-height: 150%;
`

export const ChildAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`


export const CardTitle = styled.h4`
`

export const CardSectionTitle = styled.h5`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
`

export const CardRowSpceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

const TextAreaInput = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  min-height: 216px;
  display: flex;
  padding: 12px;
  gap: 6px;
  border-radius: 12px;
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);

  justify-content: start;
  align-items: flex-start;

  line-height: 20px;
  text-align: left;
  vertical-align: top;
  resize: vertical;

  border: 1px solid ${props => props.theme.color.gray600};
  outline: none;

  &:focus {
    border: 2px solid ${props => props.theme.color.gray600};
    outline: none;
  }

`

export const CancelButton = styled.p`
  color: ${props => props.theme.color.gray800};
  font-weight: 500;
  line-height: 160%;
  cursor: pointer;
`

export const SaveButton = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-weight: 500;
  line-height: 160%;
  cursor: pointer;
`

export const CircularProgressIndicator = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid ${props => props.theme.color.beige300};
  border-radius: 50%;
  border-top-color: ${props => props.theme.color.violet500};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}

const DoctorAppointmentDetails: React.FC = () => {

    const navigate = useNavigate()

    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);

    const appointments = useFetchDoctorAppointments()
    const appointment: DoctorAppointment | undefined = appointments?.find(a => a.id === id)

    const doctorDiagnoses = useFetchDoctorDiagnoses()
    const doctorDiagnosis = doctorDiagnoses.find(d => d.appointmentId === id)

    const [isEditingDiagnosis, setIsEditingDiagnosis] = useState(false)
    const [diagnosisText, setDiagnosisText] = useState<string | null>(null)
    const [isLoadingDoctorDiagnosisUpdate, setIsLoadingDoctorDiagnosisUpdate] = useState(false)

    const [isEditingRecommendations, setIsEditingRecommendations] = useState(false)
    const [recommendationsText, setRecommendationsText] = useState<string | null>(null)
    const [isLoadingDoctorRecommendationsUpdate, setIsLoadingDoctorRecommendationsUpdate] = useState(false)

    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        setDiagnosisText(doctorDiagnosis?.diagnosis || "")
        setRecommendationsText(doctorDiagnosis?.recommendations || "")
    }, [doctorDiagnosis]);

    const getAppointmentDate = () => {
        return formatDateToDMMMMYYYYTimePolish(parseDateTime(appointment?.date))
    };

    const getAppointmentStatus = () => {
        return formatPaymentStatus(parsePaymentStatus(appointment?.status))
    };

    const renderAppointmentDetailsSection = () => (
        <CardSection>
            <CardSectionTitle>Teleporada</CardSectionTitle>
            <p>
                Data:
                <br/>
                <b>{getAppointmentDate()}</b>
                <br/>
                <br/>
                Status:
                <br/>
                <b>{getAppointmentStatus()}</b>
            </p>
        </CardSection>
    )

    const renderChildImage = () => {
        if (!appointment) {
            return null
        }
        const {name, image} = appointment.patient
        return <ChildAvatarImage alt={name} src={`${process.env.REACT_APP_API_BASE_URL}/images/children/${image}`}/>;
    }

    const onChildClicked = () => {
        if (!appointment) {
            return
        }
        navigate(Paths.CHILDREN_DETAILS.replace(":id", appointment.patient.id.toString()))
    }

    const renderChildDetailsSection = () => (
        <ClickableCardSection onClick={onChildClicked}>
            <CardSectionTitle>Dziecko</CardSectionTitle>
            <CardRowSpceBetween>
                <CardRowGapBig>
                    <DoctorAvatarContainer>{renderChildImage()}</DoctorAvatarContainer>
                    <PlaceholderText>{appointment?.patient.name}</PlaceholderText>
                </CardRowGapBig>
                <IcUpRight/>
            </CardRowSpceBetween>
        </ClickableCardSection>
    )

    const renderAiDiagnosisDetailsSection = () => (
        <ClickableCardSection onClick={() => {
            let query = ""
            if (appointment?.patient.id) {
                query = "?childId=" + appointment.patient.id.toString()
            }
            navigate(Paths.DIAGNOSIS_NEW + query)
        }}>
            <CardSectionTitle>Diagnoza AI</CardSectionTitle>
            <CardRowGapSmall>
                <img src={dialogIcon} alt="Diagnoza AI"/>
                <PlaceholderText>Wykonaj diagnozę</PlaceholderText>
            </CardRowGapSmall>
        </ClickableCardSection>
    )

    const renderDoctorDiagnosisProgress = () => {
        if (!isLoadingDoctorDiagnosisUpdate) {
            return null
        }
        return <CircularProgressIndicator/>;
    };

    const renderDoctorRecommendationsProgress = () => {
        if (!isLoadingDoctorRecommendationsUpdate) {
            return null
        }
        return <CircularProgressIndicator/>;
    };

    const renderDoctorDiagnosis = () => {
        if (isEditingDiagnosis || diagnosisText != "") {
            return (
                <CardSection>
                    <CardSectionTitle>Diagnoza</CardSectionTitle>
                    <CardRowWithTextArea>
                        <TextAreaInput
                            name="Diagnoza"
                            placeholder="Diagnoza"
                            value={diagnosisText || ""}
                            onChange={(e) => {
                                e.preventDefault()
                                setDiagnosisText(e.target.value)
                            }}
                            onBlur={() => {
                                setIsEditingDiagnosis(false);
                            }}
                            autoFocus
                        />
                        <ButtonsContainer>
                            <CancelButton>Anuluj</CancelButton>
                            <SaveButton onClick={() => {
                                setIsLoadingDoctorDiagnosisUpdate(true)

                                const formData = new FormData()
                                formData.append('appointmentId', id.toString())
                                formData.append('diagnosis', diagnosisText || "")

                                if (doctorDiagnosis) {
                                    formData.append('id', doctorDiagnosis.id.toString())
                                    dispatch(updateDoctorDiagnosis(formData))
                                        .finally(() => setIsLoadingDoctorDiagnosisUpdate(false))
                                } else {
                                    dispatch(createDoctorDiagnosis(formData))
                                        .finally(() => setIsLoadingDoctorDiagnosisUpdate(false))
                                }

                            }}>Zapisz</SaveButton>
                            {renderDoctorDiagnosisProgress()}
                        </ButtonsContainer>
                    </CardRowWithTextArea>
                </CardSection>
            )
        } else {
            return (
                <ClickableCardSection onClick={() => {
                    setIsEditingDiagnosis(true)
                }}>
                    <CardSectionTitle>Diagnoza</CardSectionTitle>
                    <CardRowGapSmall>
                        <img src={penIcon} alt="Edytuj Diagnozę"/>
                        <PlaceholderText>Dodaj diagnozę</PlaceholderText>
                    </CardRowGapSmall>
                </ClickableCardSection>
            )
        }
    }

    const renderRecommendations = () => {
        if (isEditingRecommendations || recommendationsText != "") {
            return (
                <CardSection>
                    <CardSectionTitle>Zalecenia</CardSectionTitle>
                    <CardRowWithTextArea>
                        <TextAreaInput
                            name="Zalecenia"
                            placeholder="Zalecenia"
                            value={recommendationsText || ""}
                            onChange={(e) => {
                                e.preventDefault()
                                setRecommendationsText(e.target.value)
                            }}
                            onBlur={() => setIsEditingRecommendations(false)}
                            autoFocus
                        />
                        <ButtonsContainer>
                            <CancelButton>Anuluj</CancelButton>
                            <SaveButton onClick={() => {
                                setIsLoadingDoctorRecommendationsUpdate(true)

                                const formData = new FormData()
                                formData.append('appointmentId', id.toString())
                                formData.append('recommendations', recommendationsText || "")

                                if (doctorDiagnosis) {
                                    formData.append('id', doctorDiagnosis.id.toString())
                                    dispatch(updateDoctorDiagnosis(formData))
                                        .finally(() => setIsLoadingDoctorRecommendationsUpdate(false))
                                } else {
                                    dispatch(createDoctorDiagnosis(formData))
                                        .finally(() => setIsLoadingDoctorRecommendationsUpdate(false))
                                }

                            }}>Zapisz</SaveButton>
                            {renderDoctorRecommendationsProgress()}
                        </ButtonsContainer>
                    </CardRowWithTextArea>
                </CardSection>
            )
        } else {
            return (
                <ClickableCardSection onClick={() => {
                    setIsEditingRecommendations(true)
                }}>
                    <CardSectionTitle>Zalecenia</CardSectionTitle>
                    <CardRowGapSmall>
                        <img src={penIcon} alt="Edytuj Zalecenia"/>
                        <PlaceholderText>Dodaj zalecenia</PlaceholderText>
                    </CardRowGapSmall>
                </ClickableCardSection>
            )
        }

    }

    function renderConnectButton() {
        if (!appointment) {
            return
        }
        if (!appointment.roomUrl) {
            return null
        }
        if (parsePaymentStatus(appointment.status) !== "paid") {
            return null
        }
        return (
            <ButtonWrapper>
                <ButtonPrimary
                    title="Zacznij wideokonsultację"
                    onClick={() => window.open(appointment.roomUrl, '_blank', 'noopener,noreferrer')}
                />
            </ButtonWrapper>
        )
    }

    return (
        <ContentWrapper>
            <TitleWrapper>
                <Title>Wizyta telemedyczna</Title>
                <BackButton onClick={() => navigate(-1)}>
                    <BackIcon src={icBack}/>
                    <BackText>Wróć do wszystkich wizyt</BackText>
                </BackButton>
            </TitleWrapper>
            <CardBig>
                <CardContent>
                    <CardTitle>Szczegóły wizyty</CardTitle>
                    {renderAppointmentDetailsSection()}
                    {renderChildDetailsSection()}
                    {renderAiDiagnosisDetailsSection()}
                    {renderDoctorDiagnosis()}
                    {renderRecommendations()}
                    {renderConnectButton()}
                </CardContent>
            </CardBig>
        </ContentWrapper>
    )
}

export default DoctorAppointmentDetails