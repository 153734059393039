import styled from "styled-components";
import SvgFill from "../../../../Shared/Icons/SvgFill";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
`

export const SvgFillAndFlip = styled(SvgFill)`
  transform: scaleX(-1);
`

export const HeaderTitle = styled.h4`
  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
  }
`