import React, {useEffect, useState} from 'react';
import {Doctor} from '../../../state/doctors/types';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import icStar from "../../../assets/images/ic_star.svg";
import Tabs from "./Tabs/Tabs";
import PersonalDetailsTab from "./Tabs/PersonalDetailsTab/PersonalDetailsTab";
import NotImplementedTab from "./Tabs/NotImplementedTab/NotImplementedTab";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import ButtonOutline from "../../Shared/Button/ButtonOutline/ButtonOutline";
import {AppDispatch, AppState} from "../../../state/store";
import {fetchSpecializations} from "../../../state/specializations/actions";
import {fetchDoctorAppointments, fetchDoctors} from "../../../state/doctors/actions";
import {Paths} from "../../../config/paths";
import {
    AboutDoctor,
    Container,
    Content,
    ContentWrapper,
    DesktopTitleWrapper,
    DoctorButtons,
    DoctorCard,
    DoctorImage,
    DoctorInfo,
    DoctorLeftSection,
    DoctorName,
    DoctorSeparator,
    DoctorSpecialization,
    MobileTitleWrapper,
    PageWrapper,
    Rating,
    RatingRow,
    Star
} from "./DoctorsDetails.styles";
import CalendarSideWidget from "./Calendar/CalendarSideWidget/CalendarSideWidget";
import styled from "styled-components";
import CalendarMobilePopup from "./Calendar/CalendarMobilePopup/CalendarMobilePopup";
import Popup from "../../Shared/PopUp/PoPup";


export const ChildInfoButtonDark = styled.button`
  color: ${props => props.theme.color.gray100};
  background: ${props => props.theme.color.violet600};
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);

  padding: 8px 24px;
  border-radius: 40px;

  cursor: pointer;
`


export const ChildInfoButtonLight = styled.button`
  color: ${props => props.theme.color.violet600};
  border: 1px solid ${props => props.theme.color.violet600};

  padding: 8px 24px;
  border-radius: 40px;
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);

  cursor: pointer;
`
export const ChildInfoButtons = styled.div`

  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    margin-top: 24px;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const ButtonsWrapper = styled.div`
  @media (max-width: 599px) {

    display: flex;
    flex-direction: row;
    gap: 40px;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}


const DoctorDetails: React.FC = () => {
    const navigate = useNavigate()
    const tabs: string[] = [
        'Lekarz',
        // 'Usługi',
        // 'Opinie'
    ]
    const [activeTab, setActiveTab] = useState(0);
    const [disabledSlots, setDisabledSlots] = useState<Date[] | null>(null);
    const [loadingDisabledSlots, setIsLoadingDisabledSlots] = useState(false);

    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);
    const doctors = useSelector((state: AppState) => state.doctors);
    const doctor = useSelector((state: AppState) => state.doctors.data?.find((doctor) => doctor.id === id));
    const specializations = useSelector((state: AppState) => state.specializations);
    const [popupShowing, setPopupShowing] = useState(false);

    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!disabledSlots && !loadingDisabledSlots) {
            setIsLoadingDisabledSlots(true)
            dispatch(fetchDoctorAppointments(id))
                .then(disabled => setDisabledSlots(disabled))
                .catch(error => console.log("error fetching appointment", error))
                .finally(() => setIsLoadingDisabledSlots(false))
        }
    }, [disabledSlots, loadingDisabledSlots, dispatch]);

    const renderRating = (doctor: Doctor) => {
        const rating = doctor.rating
        const ratingTxt = rating ? `${rating.value} (${rating.count} opinie)` : null

        if (!ratingTxt) return null
        return (
            <RatingRow>
                <Star src={icStar}/>
                <Rating>{ratingTxt}</Rating>
            </RatingRow>
        )
    }

    const renderTabContent = () => {
        if (!doctor) {
            return null
        }
        switch (activeTab) {
            case 0:
                return (<PersonalDetailsTab doctor={doctor}/>)
            case 1:
                return (<NotImplementedTab title="Brak usług"/>)
            case 2:
                return (<NotImplementedTab title="Brak opinii"/>)
            default:
                return null
        }
    };

    const shouldLoad = useSelector(() =>
        !doctors.loading
        && !doctors.error
        && doctors.data === null
        && !specializations.loading
        && !specializations.error
        && specializations.items === null
    );

    const isLoading = useSelector(() => doctors.loading || specializations.loading);

    useEffect(() => {
        if (shouldLoad && !isLoading) {
            dispatch(fetchDoctors(null, [])).then(() => {
            });
            dispatch(fetchSpecializations()).then(() => {
            });
        }
    }, [shouldLoad, isLoading, dispatch]);

    const createNewAppointmentPath = (doctor: Doctor) => Paths.APPOINTMENT_CREATE
        .replace(":doctorId", doctor.id.toString());

    const renderMainContent = (doctor: Doctor) => (
        <ContentWrapper>
            <DoctorCard>
                <AboutDoctor>
                    <DoctorLeftSection>
                        <DoctorImage src={process.env.REACT_APP_API_BASE_URL + doctor.image}/>
                        <DoctorInfo>
                            <DoctorName>{doctor.name}</DoctorName>
                            <DoctorSpecialization>{doctor.specializations.map(el => el.name).join(', ')}</DoctorSpecialization>
                            {renderRating(doctor)}
                        </DoctorInfo>
                    </DoctorLeftSection>
                </AboutDoctor>
                <ChildInfoButtons>
                    <ChildInfoButtonLight
                        onClick={() => navigate(Paths.DIAGNOSIS_NEW)}
                    >Wykonaj diagnozę AI</ChildInfoButtonLight>
                    <ChildInfoButtonDark
                        onClick={() => setPopupShowing(true)}
                    >Umów wizytę</ChildInfoButtonDark>
                </ChildInfoButtons>
                <DoctorSeparator/>
                <DoctorButtons>
                    <ButtonOutline title="Wykonaj diagnozę AI" onClick={() => {
                        navigate(Paths.DIAGNOSIS_NEW)
                    }}/>
                    <ButtonPrimary title="Umów wizytę" onClick={() => navigate(createNewAppointmentPath(doctor))}/>
                </DoctorButtons>
            </DoctorCard>
            <Tabs
                tabs={tabs}
                activeTab={activeTab}
                onTabClick={setActiveTab}
            />
            <Content>
                {renderTabContent()}
            </Content>
        </ContentWrapper>
    );

    const renderSideContent = (doctor: Doctor) => <CalendarSideWidget
        disabled={disabledSlots || []}
        doctorId={doctor.id}
    />;

    const renderContent = (doctor: Doctor | undefined) => {
        if (doctor) {
            return (
                <PageWrapper>
                    <Popup visible={popupShowing} onClose={() => setPopupShowing(false)}>
                        <CalendarMobilePopup
                            onClose={() => setPopupShowing(false)}
                            doctorId={doctor.id}
                            disabled={disabledSlots || []}
                        />
                    </Popup>
                    <DesktopTitleWrapper>{'Katalog lekarzy > Profil lekarza'}</DesktopTitleWrapper>
                    <MobileTitleWrapper>Profil lekarza</MobileTitleWrapper>
                    <Container>
                        {renderMainContent(doctor)}
                        {renderSideContent(doctor)}
                    </Container>
                </PageWrapper>
            );
        } else {
            return (<></>);
        }
    }

    return renderContent(doctor)
}

export default DoctorDetails;
