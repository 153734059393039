import React from "react";
import icMedicalResults from "../../../../../assets/images/ic_medical_results.svg"
import icRecipe from "../../../../../assets/images/ic_recipe.svg"
import {
    Column1,
    Column2,
    Container,
    HorizontalLine,
    Row,
    Section,
    SectionContent,
    SectionTitle,
    SectionTitleText,
    TitleSection
} from "./MedicalDocumentsTab.styles";
import ButtonOutline from "../../../../Shared/Button/ButtonOutline/ButtonOutline";

const PersonalDetails: React.FC = () => {
    return (
        <Container>
            <Section>
                <TitleSection>
                    <SectionTitle>
                        <img alt="Wyniki badań" src={icMedicalResults}/>
                        <SectionTitleText>Wyniki badań</SectionTitleText>
                    </SectionTitle>
                    <ButtonOutline
                        title="Dodaj dokument"
                        onClick={() => {
                        }}
                    />
                </TitleSection>
                <Row>
                    <Column1>Badanie</Column1>
                    <Column1>Data Badania</Column1>
                    <Column2>Opis</Column2>
                </Row>
            </Section>
            <HorizontalLine/>
            <Section>
                <TitleSection>
                    <SectionTitle>
                        <img alt="Wypisy ze szpitala" src={icRecipe}/>
                        <SectionTitleText>Wypisy ze szpitala</SectionTitleText>
                    </SectionTitle>
                    <ButtonOutline
                        title="Dodaj wypis"
                        onClick={() => {
                        }}
                    />
                </TitleSection>
                <SectionContent>
                    <Row>
                        <Column1>Powód przyjęcia</Column1>
                        <Column1>Data pobytu</Column1>
                        <Column1>Opis</Column1>
                    </Row>
                </SectionContent>
            </Section>
        </Container>
    )
}

export default PersonalDetails