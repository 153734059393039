import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPatients} from "./actions";
import {AppDispatch, AppState} from "../store";

export const useFetchPatients = () => {
    const dispatch: AppDispatch = useDispatch();
    const patients = useSelector((state: AppState) => state.patients.patients);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoading && !patients) {
            setIsLoading(true)
            dispatch(fetchPatients())
                .catch(e => console.log("Error fetching patients", e))
                .then(() => {
                })
                .finally(() => setIsLoading(false));
        }
    }, [patients, dispatch]);

    return patients || [];
}