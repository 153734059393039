import styled from "styled-components";
import {Card} from "../../Shared/Div/Card";

export const TableCardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`

export const CardBig = styled(Card)`
  position: relative;
  width: 100%;
  height: 400px;
  flex-shrink: 0;
  overflow: hidden;
`

export const HeaderCell = styled.div`
  width: 17%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
`

export const HeaderTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  padding: 8px 16px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray300};
`

export const Cell = styled.div`
  width: 17%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 8px;
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
`

export const CellText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const TableCardRows = styled.div`
  display: flex;
  flex-direction: column;
  height: 210px;
  padding-top: 16px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* For Webkit browsers */
  }
`

export const Headline = styled.h4`
  margin-bottom: 8px;
`

export const OkBubble = styled.div`
  display: flex;
  height: 24px;
  padding: 0 8px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
`

export const ResultsCell = styled(Cell)`
  justify-content: space-between;
  cursor: pointer;
  width: 17%;
`