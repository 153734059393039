import React, {useState} from 'react';
import ExpandCollapseButton from "../../../../Shared/Button/ExpandCollapseButton/ExpandCollapseButton";
import {TextContainer, TextContainerText} from "./ExpandableText.styles";

interface ExpandableTextProps {
    content: string;
    maxLength?: number;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({content, maxLength = 500}) => {
    const [textExpanded, setTextExpanded] = useState(false);

    const shouldTruncate = content.length > maxLength;
    const displayText = textExpanded || !shouldTruncate ? content : (content.slice(0, maxLength) + "...");

    const newText = (txt: string) => txt.split('\n').map((str, index, array) =>
        index === array.length - 1 ? str : <div key={index}>
            {str}
            <br key={index}/>
        </div>
    );

    const renderText = () => {
        if (textExpanded) {
            return newText(displayText)
        } else {
            return displayText
        }
    };

    const renderButton = () => {
        if (!shouldTruncate) return null
        return <ExpandCollapseButton
            buttonExpanded={textExpanded}
            expandedText="Zwiń opis"
            collapsedText="Rozwiń pełen opis"
            onChanged={ex => {
                setTextExpanded(ex)
            }}
        />;
    };

    return (
        <div>
            <TextContainer>
                <TextContainerText>{renderText()}</TextContainerText>
            </TextContainer>
            {renderButton()}
        </div>
    );
};

export default ExpandableText;
