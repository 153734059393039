import React from 'react';

const IcSettings: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="fi-rr-computer" clipPath="url(#clip0_2103_7046)">
            <path id="Vector"
                  d="M19 0.999756H5C3.67441 1.00134 2.40356 1.52864 1.46622 2.46598C0.528882 3.40331 0.00158786 4.67416 0 5.99976L0 13.9998C0.00158786 15.3254 0.528882 16.5962 1.46622 17.5335C2.40356 18.4709 3.67441 18.9982 5 18.9998H11V20.9998H7C6.73478 20.9998 6.48043 21.1051 6.29289 21.2926C6.10536 21.4802 6 21.7345 6 21.9998C6 22.265 6.10536 22.5193 6.29289 22.7069C6.48043 22.8944 6.73478 22.9998 7 22.9998H17C17.2652 22.9998 17.5196 22.8944 17.7071 22.7069C17.8946 22.5193 18 22.265 18 21.9998C18 21.7345 17.8946 21.4802 17.7071 21.2926C17.5196 21.1051 17.2652 20.9998 17 20.9998H13V18.9998H19C20.3256 18.9982 21.5964 18.4709 22.5338 17.5335C23.4711 16.5962 23.9984 15.3254 24 13.9998V5.99976C23.9984 4.67416 23.4711 3.40331 22.5338 2.46598C21.5964 1.52864 20.3256 1.00134 19 0.999756ZM5 2.99976H19C19.7956 2.99976 20.5587 3.31583 21.1213 3.87844C21.6839 4.44104 22 5.20411 22 5.99976V12.9998H2V5.99976C2 5.20411 2.31607 4.44104 2.87868 3.87844C3.44129 3.31583 4.20435 2.99976 5 2.99976ZM19 16.9998H5C4.38174 16.9972 3.77937 16.8037 3.2753 16.4457C2.77123 16.0877 2.39008 15.5827 2.184 14.9998H21.816C21.6099 15.5827 21.2288 16.0877 20.7247 16.4457C20.2206 16.8037 19.6183 16.9972 19 16.9998Z"
                  fill="#161618"/>
        </g>
        <defs>
            <clipPath id="clip0_2103_7046">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default IcSettings;
