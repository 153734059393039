import styled from "styled-components";
import {Card} from "../../../Shared/Div/Card";

export const BlogTextTitle = styled.h4`
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
  }
`

export const TagMobile = styled.p`
  height: 24px;
  display: flex;
  padding: 0 8px;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.theme.color.violet200};
  color: ${props => props.theme.color.gray1000};
  font-size: 12px;
  
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  
`

export const BodySmall = styled.p`
  color: ${props => props.theme.color.gray800};
  
  @media (max-width: 599px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    
    margin-top: 4px;
  }

  @media (min-width: 600px) {
    font-size: 14px;
    line-height: 150%;
  }
 
`

export const BlogTitle = styled.h4`
  color: ${props => props.theme.color.gray1000};
  margin-bottom: 8px;

  @media (max-width: 599px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.32px;
    
    margin-top: 16px;
  }

  @media (min-width: 600px) {
  }
`

export const BlogTexts = styled.div`
  display: flex;
  flex-direction: column;

  align-items: baseline;

  @media (max-width: 599px) {
    justify-content: space-between;
    flex-grow: 1;
  }

  @media (min-width: 600px) {
    gap: 16px;
  }
`

export const BlogTextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const BlogSection = styled.div`

`

export const BlogImage = styled.img`
  
  @media (max-width: 599px) {
    width: 122px;
    height: 122px;
    border-radius: 16px;
  }

  @media (min-width: 600px) {
    width: 225px;
    align-self: stretch;
    border-radius: 24px;
  }
`

export const BlogCardMobile = styled(Card)`
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  cursor: pointer;
`

export const BlogCard = styled(Card)`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    padding: 24px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    cursor: pointer;
    margin-bottom: 16px;
  }
`

export const BlogHeading = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
`
