import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  padding: 24px;
  justify-content: start;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray100};


  @media (max-width: 599px) {
    margin: 0 20px;
  }

  @media (min-width: 600px) {
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: auto;

  @media (max-width: 599px) {

  }

  @media (min-width: 600px) {
    width: 400px;
  }
`

export const CardTitle = styled.h4`
  color: ${props => props.theme.color.gray1000};
  text-align: center;
`

export const VisitImg = styled.img`
  width: 214px;
`


export const DesktopContent = styled.div`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

export const LinkButton = styled.button`
  color: ${props => props.theme.color.violet600};
`

export const CardText = styled.p`
  text-align: center;
`