import {styled} from "styled-components"
import heroImage from '../../../assets/images/girl.jpg';
import bgWave from '../../../assets/images/bg_wave_4.svg';
import bgWave8 from '../../../assets/images/bg_wave_8.png';
import {ReactComponent as imgThread} from '../../../assets/images/img_string_1.svg';

export const Section = styled.div`
  padding-top: 64px;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  z-index: 1;
`

export const Background = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.beige100};
`

export const SectionBackground = styled.div`
  position: absolute;
  background-repeat: no-repeat;
  background-position: bottom;

  z-index: -1;

  @media (max-width: 1199px) {
    background-image: url(${bgWave8});
    height: 100%;
    width: 100%;
    scale: 1 50%;
    bottom: -25%;
  }

  @media (min-width: 1200px) {
    background-image: url(${bgWave});
    height: 100%;
    width: 100%;
    margin: 0;
    left: 0;
    bottom: 0;
  }
`

export const SectionDecorationThread1 = styled(imgThread)`
  position: absolute;
  margin: 0;
  left: 0;
  bottom: 0;
  flex-shrink: 0;
  z-index: -1;

  @media (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {

  }
`

export const Title = styled.div`
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};
  text-align: center;
  font-style: normal; 
  line-height: 110%;
  margin-top: 64px;
  
  @media (max-width: 1199px) {
    font-size: 24px;
    letter-spacing: -0.48px;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    letter-spacing: -0.96px;
  }
  
`

export const GalleryContainer = styled.div`
  @media (max-width: 1199px) {
    width: 100%;
  }
  
`

export const GalleryImage = styled.div`
  background-image: url(${heroImage});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 1199px) {
    width: 100%;
    height: 252px;
  }

  @media (min-width: 1200px) {
    width: 754px;
    height: 554px;
  }
`

export const DescriptionCardTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

export const DescriptionCardTitleText = styled.div`
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};

  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  
  @media (max-width: 1199px) {
    font-size: 18px; 
    letter-spacing: -0.36px;
  }

  @media (min-width: 1200px) {
    font-size: 36px;
    letter-spacing: -0.72px;
  }
`

export const DescriptionCardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;


  @media (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    margin-bottom: 48px;
  }
`

export const BenefitCardContentText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};

  font-style: normal;
  font-weight: 400;
  line-height: 140%;


  @media (max-width: 1199px) {
    font-size: 14px;
    letter-spacing: -0.28px;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    letter-spacing: -0.4px;
  }
`

export const BelowTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  
  @media (max-width: 1199px) {
    max-width: 350px;
    margin: auto;
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    
  }
`

export const BelowTitle = styled.div`
 
  width: 1532px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 72px 0 176px;
  border-radius: 24px;
  background-color: white;
  overflow: hidden;
  gap: 20px;
  box-shadow: 0 18px 40px 0 rgba(23, 15, 87, 0.04), 0 73px 73px 0 rgba(23, 15, 87, 0.04), 0 164px 98px 0 rgba(23, 15, 87, 0.02), 0 292px 117px 0 rgba(23, 15, 87, 0.01), 0 456px 128px 0 rgba(23, 15, 87, 0.00);


  @media (max-width: 1199px) {
    flex-direction: column;
    margin: 20px;
    min-width: 350px;
    padding-bottom: 20px;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    height: 554px;
  }
`

export const DescriptionSection = styled.div`
  display: flex;
  font-size: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    width: 445px;
  }
  @media (max-width: 1199px) {
    margin: 0 16px;
  }

`

export const LinkButtonWrapper = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
`