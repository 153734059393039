import React, {useState} from "react";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import {
    ButtonWrapper,
    FormPart,
    HeaderPart,
    HeaderSubtitle,
    HeaderTitle,
    LinkButtonWrapper,
    RecoveryLink,
    RecoveryText,
    RegisterSuccessFormWrapper
} from "./RegisterSuccessForm.styles";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {signupUser} from "../../../state/profile/actions";
import InputField from "../../Shared/Input/Input";

const RegisterSuccessForm: React.FC<{
    email: string | null,
    password: string | null,
    navigateBack: () => void,
}> = (
    {
        email,
        password,
        navigateBack
    }
) => {

    const [emailError, setEmailError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const dispatch: AppDispatch = useDispatch()

    function renderButtonSection() {
        return <ButtonWrapper>
            <ButtonPrimary
                title="Wyślij link aktywacyjny"
                onClick={() => {
                    if (!email || !password) {
                        return
                    }
                    setLoading(true)
                    dispatch(signupUser(email, password))
                        .then((response) => {
                            switch (response) {
                                case "CREDENTIALS_INVALID":
                                    setEmailError("Wystąpił nieznany błąd.")
                                    break;
                                case "SUCCESS":
                                    // TODO: restart counter
                                    break;
                            }
                        })
                        .catch(() => {
                            setEmailError("Wystąpił nieznany błąd.")
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                }}
                progress={loading}/>
            <div>
                <LinkButtonWrapper>
                    <RecoveryText>Błędny email?</RecoveryText>
                    <RecoveryLink onClick={navigateBack}>Wróc do rejestracji</RecoveryLink>
                </LinkButtonWrapper>
            </div>
        </ButtonWrapper>;
    }

    function renderFormSection() {
        return <FormPart>
            <InputField
                label="Email nie doszedł? Możesz wysłać ponownie"
                type={"email"}
                value={email ? email : ""}
                onChange={() => {
                }}
                error={emailError}
                correct={!emailError}
                onBlur={() => {
                }}
            />
        </FormPart>;
    }

    const renderRegisterSuccessForm = () => {
        return <RegisterSuccessFormWrapper>
            <HeaderPart>
                <HeaderTitle>Potwierdź adres email</HeaderTitle>
                <HeaderSubtitle>Wysłaliśmy wiadomość na adres {email}. Aby dokończyć rejestrację, kliknij w link
                    aktywacyjny, który tam znajdziesz.</HeaderSubtitle>
            </HeaderPart>
            {renderFormSection()}
            {renderButtonSection()}
        </RegisterSuccessFormWrapper>;
    }

    return renderRegisterSuccessForm()
}

export default RegisterSuccessForm