import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  min-height: 100vh;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 600px) {
  }
`;
export const Headline = styled.div`
  margin-bottom: 40px;

  @media (max-width: 599px) {
    margin-top: 76px;
    margin-left: 20px;
  }
  @media (min-width: 600px) {
    margin-top: 56px;
    margin-left: 40px;
  }
`
export const Title = styled.h3`
  @media (max-width: 599px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
  }
  
  @media (min-width: 600px) {
  }
`

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 599px) {
    flex-grow: 1;
  }

  @media (min-width: 600px) {
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 18px 40px 0 rgba(23, 15, 87, 0.04), 0 73px 73px 0 rgba(23, 15, 87, 0.04), 0 164px 98px 0 rgba(23, 15, 87, 0.02), 0 292px 117px 0 rgba(23, 15, 87, 0.01), 0 456px 128px 0 rgba(23, 15, 87, 0.00);

  @media (max-width: 599px) {
    padding: 24px;
    flex-grow: 1;
    border-radius: 20px;
    gap: 32px;
  }

  @media (min-height: 800px) {
    margin-bottom: 30%;
  }

  @media (min-width: 600px) {
    width: 400px;
    padding: 40px 56px 56px 56px;
    gap: 40px;
    border-radius: 32px;
  }
`

export const CardTitle = styled.h4`
  color: ${props => props.theme.color.gray1000};

  @media (min-height: 800px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.32px;
  }

  @media (min-width: 600px) {
  }
`

export const CardHeadline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;


  @media (min-height: 800px) {
    margin-top: 16px;
  }

  @media (min-width: 600px) {
    gap: 41px;
  }
`

export const PeselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`