import React from 'react';

const IcSearch: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M9.90381 14.0962C11.0801 15.2725 12.7051 16 14.5 16C18.0899 16 21 13.0899 21 9.5C21 5.91015 18.0899 3 14.5 3C10.9101 3 8 5.91015 8 9.5C8 11.2949 8.72754 12.9199 9.90381 14.0962ZM9.90381 14.0962L3.5 20.5"
            stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default IcSearch;







