import React from "react";
import icChat from "../../assets/images/ic_chat.svg";
import {
    CellSubtitle,
    CellTitle,
    Container,
    ContentColumn,
    ContentColumnCell20,
    ContentColumnCell60,
    ContentRow,
    Section,
    SectionTitle,
    SectionTitleText,
    TabWrapper,
    TitleSection
} from "./DoctorMessages.styles";

const DoctorMessages: React.FC = () => {

    return (
        <TabWrapper>
            <Container>
                <Section>
                    <TitleSection>
                        <SectionTitle>
                            <img alt="O mnie" src={icChat}/>
                            <SectionTitleText>Wiadomości</SectionTitleText>
                        </SectionTitle>
                    </TitleSection>
                    <CellTitle>
                        Ustaw domyślne wiadomości oraz automatyczne odpowiedzi dla swoich pacjentów.
                    </CellTitle>
                    <ContentRow>
                        <ContentColumn>
                            <ContentColumnCell20>
                                <CellTitle>Potwierdzenie wizyty</CellTitle>
                            </ContentColumnCell20>
                            <ContentColumnCell60>
                                <CellSubtitle>Twoja teleporada została potwierdzona. Zadzwonimy do Ciebie [data wizyty]
                                    około godziny [godzina wizyty]. Zalecam zarezerwować sobie wolną chwilę pół godziny
                                    przed wyznaczoną datą.</CellSubtitle>
                            </ContentColumnCell60>
                            <ContentColumnCell20>
                                <CellSubtitle style={{textAlign: "right", opacity: 0.4}}>Edytuj wiadomość</CellSubtitle>
                            </ContentColumnCell20>
                        </ContentColumn>
                        <ContentColumn>
                            <ContentColumnCell20>
                                <CellTitle>Odwołanie wizyty</CellTitle>
                            </ContentColumnCell20>
                            <ContentColumnCell60>
                                <CellSubtitle>Twoja wizyta została odwołana.</CellSubtitle>
                            </ContentColumnCell60>
                            <ContentColumnCell20>
                                <CellSubtitle style={{textAlign: "right", opacity: 0.4}}>Edytuj wiadomość</CellSubtitle>
                            </ContentColumnCell20>
                        </ContentColumn>
                        <ContentColumn>
                            <ContentColumnCell20>
                                <CellTitle>Zalecenia</CellTitle>
                            </ContentColumnCell20>
                            <ContentColumnCell60>
                                <CellSubtitle>Zalecenia: [treść zaleceń].</CellSubtitle>
                            </ContentColumnCell60>
                            <ContentColumnCell20>
                                <CellSubtitle style={{textAlign: "right", opacity: 0.4}}>Edytuj wiadomość</CellSubtitle>
                            </ContentColumnCell20>
                        </ContentColumn>
                        <ContentColumn>
                            <ContentColumnCell20>
                                <CellTitle>Zlecone badania</CellTitle>
                            </ContentColumnCell20>
                            <ContentColumnCell60>
                                <CellSubtitle>Zlecono badania [badania]. Proszę umówić się na wizytę
                                    zabiegową.</CellSubtitle>
                            </ContentColumnCell60>
                            <ContentColumnCell20>
                                <CellSubtitle style={{textAlign: "right", opacity: 0.4}}>Edytuj wiadomość</CellSubtitle>
                            </ContentColumnCell20>
                        </ContentColumn>
                    </ContentRow>
                </Section>
            </Container>
        </TabWrapper>
    )
}

export default DoctorMessages