import React from "react";
import {useTheme} from "styled-components";
import SvgFill from "../../../../Shared/Icons/SvgFill";
import IcChevronLeft from "../../../../Shared/Icons/IcChevronLeft";
import {Header, HeaderTitle, SvgFillAndFlip} from "./CalendarHeader.styles";

interface Props {
    onNextClicked: () => void
    onPreviousClicked: () => void
    isBackDisabled: boolean
    title: string
}

const CalendarHeader: React.FC<Props> = (
    {
        onPreviousClicked,
        onNextClicked,
        isBackDisabled,
        title
    }
) => {
    const theme = useTheme()

    return (
        <Header>
            <button onClick={onPreviousClicked}>
                <SvgFill color={isBackDisabled ? theme.color.gray600 : theme.color.gray1000}>
                    <IcChevronLeft/>
                </SvgFill>
            </button>
            <HeaderTitle>{title}</HeaderTitle>
            <button onClick={onNextClicked}>
                <SvgFillAndFlip color={theme.color.gray1000}>
                    <IcChevronLeft/>
                </SvgFillAndFlip>
            </button>
        </Header>
    )
}

export default CalendarHeader