import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;

export const Tab = styled.button<{ $isActive: boolean }>`
  cursor: pointer;
  outline: none;
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0 0;
  background: ${props => props.$isActive ? props.theme.color.gray100 : props.theme.color.violet100};
  color: ${props => props.theme.color.violet600};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
`;