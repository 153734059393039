import {AppointmentsState} from "./types";
import {Action} from "./actions";
import {ProfileAction} from "../profile/actions";

const initialState: AppointmentsState = {
    appointments: null,
}

export const appointmentsReducer = (state = initialState, action: Action | ProfileAction): AppointmentsState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "APPOINTMENTS_SUCCESS":
            return {
                ...state,
                appointments: action.appointments || [],
            };
        default:
            return state;
    }
};