import styled from "styled-components";

const SvgFill = styled.div<{ color: string }>`
  svg path {
    fill: ${props => props.color};
  }
  
  display: flex;
  align-items: center;
  justify-content: center;
`;


export default SvgFill;