import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${props => props.theme.color.gray100};

  @media (max-width: 599px) {
    border-radius: 16px;
    padding: 24px;
    gap: 8px;
    border: 1px solid ${props => props.theme.color.gray400};

    box-shadow: 0 5px 10px 2px #F1F1F1;
  }
  @media (min-width: 600px) {
    border-radius: 0 16px 16px 16px;
    padding: 64px 24px;
    gap: 64px;

  }
`
export const HorizontalDivider = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${props => props.theme.color.gray500};
`

export const Section = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;


  @media (max-width: 599px) {
    
  }
  
  @media (min-width: 600px) {
    gap: 16px;
  }
`

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const MobileButton = styled.div`

  @media (max-width: 599px) {
    margin-top: 24px;
  }
  @media (min-width: 600px) {
    display: none;
  }
`

export const DesktopContent = styled.div`
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
  }
`

export const SectionTitleText = styled.h5`
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const Column1 = styled.div`
`

export const TitleSection = styled.div`
  display: flex;
  
  @media (max-width: 599px) {
    flex-direction: column;
    margin-bottom: 24px;
  }
  
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

export const TabWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
`

export const Chart = styled.p`
  text-align: center;
  margin: 200px 0;
`
