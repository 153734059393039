import {combineReducers} from "redux";
import {configureStore} from '@reduxjs/toolkit';
import {profileReducer} from "./profile/reducer";
import {doctorsReducer} from "./doctors/reducer";
import {PERSIST, persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import {specializationsReducer} from "./specializations/reducer";
import {childrenReducer} from "./children/reducer";
import {aiDiagnosisReducer} from "./aiDiagnoses/reducer";
import {pregnanciesReducer} from "./pregnancies/reducer";
import {interviewReducer} from "./interview/reducer";
import {appointmentsReducer} from "./appointments/reducer";
import {settingsReducer} from "./settings/reducer";
import {vaccinesReducer} from "./vaccines/reducer";
import {userReducer} from "./user/reducer";
import {patientsReducer} from "./patients/reducer";
import {articlesReducer} from "./articles/reducer";
import {doctorDiagnosesReducer} from "./doctorDiagnoses/reducer";
import {doctorAppointmentsReducer} from "./doctorAppointments/reducer";
import {humanDiagnosesReducer} from "./humanDiagnoses/reducer";

const rootReducer = combineReducers({
    appointments: appointmentsReducer,
    questions: interviewReducer,
    profile: profileReducer,
    doctors: doctorsReducer,
    specializations: specializationsReducer,
    children: childrenReducer,
    pregnancies: pregnanciesReducer,
    aiDiagnosis: aiDiagnosisReducer,
    humanDiagnosis: humanDiagnosesReducer,
    doctorDiagnoses: doctorDiagnosesReducer,
    doctorAppointments: doctorAppointmentsReducer,
    settings: settingsReducer,
    vaccines: vaccinesReducer,
    user: userReducer,
    patients: patientsReducer,
    articles: articlesReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['profile']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [PERSIST],
            },
        })
});
export const persistor = persistStore(store);

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;