import {useEffect, useState} from "react";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import {PatientAppointment, fetchPatientAppointments} from "../../../state/appointments/actions";

export const useFetchPatientAppointments = (childId: number | undefined) => {
    const [appointments, setAppointments] = useState<PatientAppointment[] | null>(null)
    const [loading, setLoading] = useState(false)

    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (childId && !loading && !appointments) {
            setLoading(true)
            dispatch(fetchPatientAppointments(childId))
                .then(apt => setAppointments(apt))
                .catch((err) => {
                    console.log("Error fetching child appointments", err)
                })
                .finally(() => setLoading(false))
        }
    }, [childId, loading, appointments]);


    return appointments || []
}