import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../../state/store';
import {useNavigate} from "react-router-dom";
import imgWave from "../../../assets/images/bg_wave_login.png";
import {Background, BackgroundWave, Container, LoginFormContainer, LoginLogoTop} from "./RecoverPassword.styles";
import RecoverForm from "./RecoverForm";
import icLogoMobile from "../../../assets/images/ic_logo_mobile.svg";

const RecoverPage: React.FC = () => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state: AppState) => state.profile.profile != null);

    const navigateLogin = () => {
        navigate("/login", {replace: true});
    }

    const navigateSuccess = (email: string) => {
        navigate(`/recover-success?email=${encodeURIComponent(email)}`, {replace: true});
    }

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);


    return (
        <Container>
            <Background>
                <BackgroundWave src={imgWave}/>
            </Background>
            <LoginFormContainer>
                <LoginLogoTop src={icLogoMobile}/>
                <RecoverForm
                    navigateLogin={navigateLogin}
                    navigateSuccess={navigateSuccess}
                />
            </LoginFormContainer>
        </Container>
    );
}

export default RecoverPage;
