import React from 'react';
import {useParams} from 'react-router-dom';
import {
    convertToAge,
    formatDateToDDMMYYYYWithDashes,
    formatPaymentStatus,
    formatTime,
    parseDateTime,
    parsePaymentStatus
} from "../../../config/utils";
import {useTheme} from "styled-components";
import {useFetchPatientAppointments} from "./helpers";
import icAi from "../../../assets/images/ic_ai.svg";
import icMessage from "../../../assets/images/ic_message.svg";
import {PatientAppointment} from "../../../state/appointments/actions";
import {useFetchPatients} from "../../../state/patients/helpers";
import {
    Alpha,
    Card,
    CardBig,
    Cell,
    CellBubble,
    CellCentered,
    CellText,
    ClickableCellCentered,
    Column1,
    Column2,
    Container,
    HeaderCell,
    HeaderCellCentered,
    HeaderTableRow,
    Row,
    SectionTitleText,
    TableCardContent,
    TableCardRows,
    TableRow,
    Title,
    TitleWrapper
} from './PatientDetails.styles';

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}

const PatientDetails: React.FC = () => {
    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);
    const patients = useFetchPatients()
    const patient = patients.find(c => c.id === id)

    const appointments = useFetchPatientAppointments(patient?.id)

    const getChildAge = () => {
        const dob = patient?.dob
        if (dob) {
            const [day, month, year] = dob.split('.').map(Number);
            const birthDate = new Date(year, month - 1, day);
            return convertToAge(birthDate)
        }
        return ""
    }

    const getAppointmentStatus = (appointment: PatientAppointment) => {
        const dateTime = parseDateTime(appointment.startTime)
        if (dateTime && dateTime < new Date()) {
            return "Przeprowadzona"
        }
        return "Umówiona"
    }

    const theme = useTheme()

    const getBubbleTextColor = (appointment: PatientAppointment) => {
        const dt = parseDateTime(appointment.startTime)
        if (dt != null && dt < new Date()) {
            return theme.color.violet700
        }
        return theme.color.violet500
    }

    const getBubbleColor = (appointment: PatientAppointment) => {
        const dt = parseDateTime(appointment.startTime)
        if (dt != null && dt < new Date()) {
            return theme.color.violet100
        }
        return theme.color.violet200
    }

    const renderTableRow = (appointment: PatientAppointment, index: number) => {
        return (
            <TableRow key={index}>
                <Cell>
                    <CellText>{getAppointmentStatus(appointment)}</CellText>
                </Cell>
                <Cell>
                    <CellText>{formatDateToDDMMYYYYWithDashes(parseDateTime(appointment.startTime))}</CellText>
                </Cell>
                <Cell>
                    <CellText>
                        <CellBubble $color={getBubbleColor(appointment)} $textColor={getBubbleTextColor(appointment)}>
                            {formatTime(parseDateTime(appointment.startTime))}
                        </CellBubble>
                    </CellText>
                </Cell>
                <Cell>
                    <CellText>{formatPaymentStatus(parsePaymentStatus(appointment.status))}</CellText>
                </Cell>
                <CellCentered>
                    <Alpha $alpha={appointment.aiDiagnosisId ? 1 : 0.3}>
                        <img src={icAi} alt="AI"></img>
                    </Alpha>
                </CellCentered>
                <CellCentered>
                    <Alpha $alpha={0.3}>
                        <img src={icMessage} alt="Message"></img>
                    </Alpha>
                </CellCentered>
                <ClickableCellCentered>
                    <CellText>Zadzwoń</CellText>
                </ClickableCellCentered>
            </TableRow>
        );
    }

    return (
        <Container>
            <Title>{patient?.name} ({getChildAge()})</Title>
            <TitleWrapper>
                {'Pacjenci > ' + patient?.name}
            </TitleWrapper>
            <Card>
                <SectionTitleText>Dane osobowe</SectionTitleText>
                <Row>
                    <Column1>Imię i nazwisko</Column1>
                    <Column2>{patient?.name}</Column2>
                </Row>
                <Row>
                    <Column1>Data urodzenia</Column1>
                    <Column2>{patient?.dob} r.</Column2>
                </Row>
                <Row>
                    <Column1>PESEL</Column1>
                    <Column2>{patient?.pesel}</Column2>
                </Row>
                <Row>
                    <Column1>Numer ubezpieczenia</Column1>
                    <Column2></Column2>
                </Row>
            </Card>

            <CardBig>
                <TableCardContent>
                    <SectionTitleText>Ostatnie wizyty (3)</SectionTitleText>
                    <HeaderTableRow>
                        <HeaderCell>Status</HeaderCell>
                        <HeaderCell>Data</HeaderCell>
                        <HeaderCell>Godzina</HeaderCell>
                        <HeaderCell>Płatność</HeaderCell>
                        <HeaderCellCentered>Diagnoza AI</HeaderCellCentered>
                        <HeaderCellCentered>Kontakt</HeaderCellCentered>
                        <HeaderCell></HeaderCell>
                    </HeaderTableRow>
                    <TableCardRows>
                        {appointments.map((el, i) => renderTableRow(el, i))}
                    </TableCardRows>
                </TableCardContent>
            </CardBig>


        </Container>
    );
}

export default PatientDetails;