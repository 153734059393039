import styled from "styled-components";

export const RightSection = styled.div`

  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    width: 100%;
    border-radius: 20px;
    background: ${props => props.theme.color.gray100};
  }

  @media (min-width: 600px) {
    width: 40%;
    gap: 10px;
  }
`