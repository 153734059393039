import React, {useEffect, useState} from 'react';
import heroImage1 from '../../../assets/images/girl.jpg';
import heroImage2 from '../../../assets/images/img_gallety_2.jpg';
import heroImage3 from '../../../assets/images/img_gallery_3.jpg';

import ButtonSecondary from "../../Shared/Button/ButtonSecondary/ButtonSecondary";
import {Paths} from "../../../config/paths";
import theme from "../../../Theme";

import {styled} from "styled-components"
import {useNavigate} from "react-router-dom";


const ButtonSecondaryLight = styled.div`
  margin: auto;
  
  @media (max-width: 1199px) {
    display: none;
  }
  @media (min-width: 1200px) {
    display: block;
  }
`

const ButtonSecondaryDark = styled.div`
  margin: auto;
  margin-top: 12px;

  @media (max-width: 1199px) {
    display: block;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`

export const Section = styled.div`
  padding-top: 64px;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  z-index: 1;
`

export const Background = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.beige100};
`

export const Title = styled.div`
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};
  text-align: center;
  font-style: normal;
  line-height: 110%;
  margin-top: 64px;

  @media (max-width: 1199px) {
    font-size: 24px;
    letter-spacing: -0.48px;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    letter-spacing: -0.96px;
  }

`

export const GalleryImage = styled.div<{ $image: string }>`
  background-image: url(${props => props.$image});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 1199px) {
    width: 100%;
    height: 252px;
  }

  @media (min-width: 1200px) {
    width: 754px;
    height: 554px;
  }
`

export const DescriptionCardTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

export const DescriptionCardTitleText = styled.div`
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};

  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  @media (max-width: 1199px) {
    font-size: 18px;
    letter-spacing: -0.36px;
  }

  @media (min-width: 1200px) {
    font-size: 36px;
    letter-spacing: -0.72px;
  }
`

export const DescriptionCardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;


  @media (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    margin-bottom: 48px;
  }
`

export const BenefitCardContentText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-family: ${props => props.theme.font.default};

  font-style: normal;
  font-weight: 400;
  line-height: 140%;


  @media (max-width: 1199px) {
    font-size: 14px;
    letter-spacing: -0.28px;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    letter-spacing: -0.4px;
  }
`

export const BelowTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;

  @media (max-width: 1199px) {
    max-width: 350px;
    margin: auto;
    margin-top: 20px;
  }

  @media (min-width: 1200px) {

  }
`

export const GalleryCardItem = styled.div`

  width: 1532px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 72px 0 176px;
  border-radius: 24px;
  background-color: white;
  overflow: hidden;
  gap: 20px;
  box-shadow: 0 18px 40px 0 rgba(23, 15, 87, 0.04), 0 73px 73px 0 rgba(23, 15, 87, 0.04), 0 164px 98px 0 rgba(23, 15, 87, 0.02), 0 292px 117px 0 rgba(23, 15, 87, 0.01), 0 456px 128px 0 rgba(23, 15, 87, 0.00);


  @media (max-width: 1199px) {
    flex-direction: column;
    margin: 20px;
    min-width: 350px;
    padding-bottom: 20px;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    height: 554px;
  }
`

export const DescriptionSection = styled.div`
  display: flex;
  font-size: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin: 0 auto;
  

  @media (min-width: 1200px) {
    width: 445px;
  }
  @media (max-width: 1199px) {
    margin: 0 16px;
  }

`

export const LinkButtonWrapper = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Dots = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: 1200px) {
    margin-top: 32px;
  }
  @media (max-width: 1199px) {
  }
`;

const Dot = styled.span<{ active: boolean }>`
  height: 8px;
  width: 8px;
  margin: 0 5px;
  background-color: ${props => (props.active ? props.theme.color.gray1000 : props.theme.color.gray500)};
  border-radius: 50%;
  display: inline-block;
`;

type CardData = {
    imageSrc: string;
    title: string;
    description: string;
};

const cards: CardData[] = [
    {
        imageSrc: heroImage1,
        title: 'Wprowadzasz objawy do formularza',
        description: 'Proces diagnozy rozpoczyna się od zebrania danych dotyczących dziecka, takich jak historii chorób, wyników badań laboratoryjnych, a także informacji o symptomach zgłaszanych przez opiekuna.',
    },
    {
        imageSrc: heroImage2,
        title: 'Wygeneruj diagnozę AI',
        description: 'Po dostarczeniu informacji o stanie zdrowia, uruchom diagnozę. Algorytmy AI przeanalizują zgromadzone dane kliniczne i porównają je z bazą wiedzy medycznej, co pomoże w identyfikacji potencjalnych 18 chorób.',
    },
    {
        imageSrc: heroImage3,
        title: 'Skorzystaj z diagnozy',
        description: 'Algorytm sztucznej inteligencji może dostarczyć dodatkowe rekomendacje, takie jak optymalne dawki leków, zalecane procedury medyczne czy wskazówki dotyczące opieki nad dzieckiem w trakcie leczenia. W razie wątpliwości skorzystaj z natychmiastowej teleporady z lekarzem.',
    },
];

const HeroGallery = () => {

    const navigate = useNavigate()
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((current) => (current + 1) % cards.length);
        }, 5000); // change card every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <BelowTitleWrapper>
            <GalleryCardItem>
                <GalleryImage $image={cards[activeIndex].imageSrc}/>
                <DescriptionSection>
                    <DescriptionCardTitle>
                        <DescriptionCardTitleText>{cards[activeIndex].title}</DescriptionCardTitleText>
                    </DescriptionCardTitle>
                    <DescriptionCardContent>
                        <BenefitCardContentText>{cards[activeIndex].description}</BenefitCardContentText>
                    </DescriptionCardContent>
                    <ButtonSecondaryLight>
                        <ButtonSecondary
                            title='Otrzymaj diagnozę AI'
                            onClick={() => navigate(Paths.DIAGNOSIS_NEW)}
                        />
                    </ButtonSecondaryLight>
                    <ButtonSecondaryDark>
                        <ButtonSecondary
                            backgroundColor={theme.color.violet400}
                            fontColor={theme.color.gray1000}
                            title=" Otrzymaj diagnozę AI"
                            icon={(<div style={{width: 8}}></div>)}
                            onClick={() => navigate(Paths.DIAGNOSIS_NEW)}
                        />
                    </ButtonSecondaryDark>

                    <Dots>
                        {cards.map((_, index) => (
                            <Dot key={index} active={index === activeIndex}/>
                        ))}
                    </Dots>

                </DescriptionSection>


            </GalleryCardItem>
        </BelowTitleWrapper>
    );
};

export default HeroGallery;
