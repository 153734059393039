import React, {useRef, useState} from 'react';
import {TopNavigation} from './TopNavigation/TopNavigation';
import HeroForm from './Hero/HeroForm';
import BenefitsSection from './Benefits/BenefitsSection';
import DoctorsSection from './Doctors/DoctorsSection';
import FaqSection from './Faq/FaqSection';
import ArticlesSection from './Articles/Articles';
import {Container} from "./Welcome.styles";
import DiagnosisSection from "./Diagnosis/DiagnosisSection";
import Popup from "../Shared/PopUp/PoPup";
import {useNavigate} from "react-router-dom";
import FooterSection from "./Footer/Footer";
import LoginForm from "../Authentication/Login/LoginForm";

export interface SecondComponentHandles {
    scrollToSection: () => void;
}

const WelcomeScreen: React.FC = () => {
    const diagnosisComponentRef = useRef<SecondComponentHandles>(null);
    const doctorsComponentRef = useRef<SecondComponentHandles>(null);
    const faqComponentRef = useRef<SecondComponentHandles>(null);
    const blogComponentRef = useRef<SecondComponentHandles>(null);

    const [popup, setPopUp] = useState(false)
    const navigate = useNavigate()

    const renderPopup = () => {
        return (
            <Popup visible={popup} onClose={() => setPopUp(false)}>
                <LoginForm
                    navigateToRecover={() => navigate("/recover")}
                    navigateRegister={() => navigate("/register")}
                />
            </Popup>
        );
    }

    const renderDesktopContent = () => (
        <Container>
            <TopNavigation
                onAiDiagnosisClicked={() => {
                    diagnosisComponentRef.current?.scrollToSection();
                }}
                onDoctorsClicked={() => {
                    doctorsComponentRef.current?.scrollToSection();
                }}
                onFaqClicked={() => {
                    faqComponentRef.current?.scrollToSection()
                }}
                onBlogClicked={() => {
                    blogComponentRef.current?.scrollToSection();
                }}
            />
            <HeroForm/>
            <BenefitsSection/>
            <DiagnosisSection ref={diagnosisComponentRef}/>
            <DoctorsSection ref={doctorsComponentRef} onClick={() => {
                setPopUp(true)
            }}/>
            <FaqSection ref={faqComponentRef}/>
            <ArticlesSection ref={blogComponentRef} onClick={() => {
                setPopUp(true)
            }}/>
            <FooterSection
                openPopup={() => setPopUp(true)}
                onFaqClicked={() => faqComponentRef.current?.scrollToSection()}
            />
        </Container>
    );

    return (
        <div>
            {renderPopup()}
            <div style={{overflowY: 'auto', maxHeight: '100vh'}}>
                {renderDesktopContent()}
            </div>
        </div>
    );
}

export default WelcomeScreen;
