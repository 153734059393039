import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const HelpContainer = styled.div`
  min-height: 100vh;
  box-sizing: border-box;

  @media (max-width: 599px) {
    padding: 76px 20px 20px;
  }
  @media (min-width: 600px) {
    padding: 48px;
  }
`

export const Title = styled.h3`
  @media (max-width: 599px) {
    margin-bottom: 40px;
  }
  
  @media (min-width: 600px) {
    margin-bottom: 68px;
  }
`