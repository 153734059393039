import React, {useEffect, useState} from 'react';
import {useTheme} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import imgPlusSymbol from "../../../assets/images/ic_plus_big.svg"
import ButtonSecondary from "../../Shared/Button/ButtonSecondary/ButtonSecondary";
import icPlus from "../../../assets/images/ic_plus.svg"
import {AppDispatch, AppState} from "../../../state/store";
import {fetchChildren} from "../../../state/children/actions";
import {fetchPregnancies} from "../../../state/pregnancies/actions";
import {Child} from "../../../state/children/types";
import {Pregnancy} from "../../../state/pregnancies/types";
import {
    AddChildIcon,
    AddChildIconWrapper,
    CardButton,
    CardButtonText,
    ChildCard,
    ChildrenGrid,
    Container,
    Headline,
    Title
} from "./ChildrenList.styles";
import {Paths} from "../../../config/paths";
import ChildCardComponent from "../../Shared/ChildCardComponent/ChildCardComponent";

interface ListItem {
    id: number
    title: string
    image: string | null
    subtitle: string
    type: string
}


const ChildrenList: React.FC = () => {

    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch: AppDispatch = useDispatch()
    const shouldLoadPregnancies = useSelector((state: AppState) => !state.pregnancies.pregnancies)
    const isLoadingChildren = useSelector((state: AppState) => state.children.loading)
    const [isLoadingPregnancies, setIsLoadingPregnancies] = useState(false)

    const shouldLoad = useSelector((state: AppState) =>
        !state.children.loading
        && !state.children.error
        && state.children.children === null
    );

    function createChildListItem(child: Child) {
        return {
            id: child.id,
            type: "child",
            title: child.name,
            subtitle: "Otwórz kartę dziecka",
            image: child.image,
        };
    }

    function createPregnancyListItem(pregnancy: Pregnancy) {
        return {
            id: pregnancy.id,
            type: "pregnancy",
            title: `Ciąża (${pregnancy.due_date} r.)`,
            subtitle: "Otwórz kartę ciąży",
            image: null,
        };
    }

    const children: ListItem[] = useSelector((state: AppState) => {
        const children: ListItem[] = (state.children.children || []).map(el => createChildListItem(el))
        const pregnancies: ListItem[] = (state.pregnancies.pregnancies || []).map(el => createPregnancyListItem(el))
        return children.concat(pregnancies)
    });

    useEffect(() => {
        if (shouldLoad && !isLoadingChildren) {
            dispatch(fetchChildren())
                .then(() => {
                })
                .catch(() => {
                    // display error?
                })
                .finally(() => {

                })

        }
    }, [shouldLoad, isLoadingChildren, dispatch]);

    useEffect(() => {
        if (shouldLoadPregnancies && !isLoadingPregnancies) {
            setIsLoadingPregnancies(true)
            dispatch(fetchPregnancies())
                .then((result) => {
                    if (!result) {
                        //display error?
                    }
                })
                .catch(() => {
                    // display error?
                })
                .finally(() => setIsLoadingPregnancies(false))
        }
    }, [shouldLoadPregnancies, isLoadingPregnancies, dispatch])


    const renderButton = () => {
        if (children.length === 0) {
            return null
        }
        return <ButtonSecondary
            title="Dodaj Kartę"
            backgroundColor={theme.color.violet600}
            fontColor={theme.color.gray100}
            icon={<img alt="add" src={icPlus}/>}
            onClick={() => navigateToAdd()}
        />;
    }

    function navigateToAdd() {
        return navigate(Paths.DEPENDANTS_SELECT_TYPE);
    }

    const renderChildrenGrid = () => {
        if (children.length === 0) {
            return (
                <ChildCard onClick={() => navigateToAdd()}>
                    <AddChildIconWrapper>
                        <AddChildIcon src={imgPlusSymbol}/>
                    </AddChildIconWrapper>
                    <CardButton>
                        <CardButtonText>Dodaj Kartę</CardButtonText>
                    </CardButton>
                </ChildCard>
            )
        }
        return children.map((ch, index) => <ChildCardComponent
            key={index}
            onClick={() => {
                switch (ch.type) {
                    case "child":
                        navigate(Paths.CHILDREN_DETAILS.replace(":id", ch.id.toString()))
                        break
                    case "pregnancy":
                        navigate(Paths.PREGNANCY_DETAILS.replace(":id", ch.id.toString()));
                        break
                }
            }}
            id={ch.id}
            subtitle={ch.subtitle}
            title={ch.title}
            image={ch.image}/>)
    }

    return (
        <Container>
            <Headline>
                <Title>Moje dzieci</Title>
                {renderButton()}
            </Headline>
            <ChildrenGrid>
                {renderChildrenGrid()}
            </ChildrenGrid>
        </Container>
    );
};

export default ChildrenList;
