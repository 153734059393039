import React from "react";
import icUp from "../../assets/images/ic_right_up.svg";
import {useFetchVaccinations, useFetchVaccines} from "../../state/vaccines/helpers";
import {Vaccination} from "../../state/vaccines/types";
import {useFetchChildren} from "../../state/children/useFetchChildren";
import {formatDateToDDMMYYYYWithDashes, formatDateToDMMMMYYYYPolish, parseDateTime} from "../../config/utils";
import {
    CardBig,
    Cell,
    CellText,
    HeaderCell,
    HeaderTableRow,
    Headline,
    MobileCardNameText,
    MobileCards,
    MobileMessageEmptyTitle,
    MobileMessageIfEmpty,
    OkBubble,
    ResultsCell,
    TableCardContent,
    TableCardRows,
    TableRow
} from "./VaccinationTopContent.styles";
import {
    ChildAvatarContainer,
    ChildAvatarImage,
    ChildName,
    MobileCardBadge,
    MobileCardBadgeText,
    MobileCardBadgeWrapper,
    MobileCardContent,
    MobileCardDate,
    MobileCardHeader,
    MobileCardItem
} from "../Home/LeftBottomContent/LeftBottomAppointmentsTable/LeftBottomAppointmentsTable.styles";


const VaccinationTopContent: React.FC = () => {
    const vaccinations = useFetchVaccinations()
    const children = useFetchChildren()
    const vaccines = useFetchVaccines()


    const renderMobileCard = (vaccination: Vaccination, index: number) => {
        const child = children.find(c => c.id === vaccination.childId)
        const dateTime = parseDateTime(vaccination.date)
        const vaccine = vaccines.find(vac => vac.id === vaccination.vaccineId)

        if (!child) {
            return
        }
        if (!vaccine) {
            return
        }
        return (
            <MobileCardContent>
                <MobileCardItem>
                    <MobileCardHeader>
                        <ChildAvatarContainer>
                            <ChildAvatarImage
                                alt={child.name}
                                src={`${process.env.REACT_APP_API_BASE_URL}${child.image}`}
                            />
                        </ChildAvatarContainer>
                        <ChildName>{child.name}</ChildName>
                    </MobileCardHeader>
                    <MobileCardDate>{formatDateToDMMMMYYYYPolish(dateTime)}</MobileCardDate>
                    <MobileCardNameText>{vaccine.name}</MobileCardNameText>
                    <MobileCardBadgeWrapper>
                        <MobileCardBadge>
                            <MobileCardBadgeText>{vaccine.mandatory ? "obowiązkowe" : "zalecane"}</MobileCardBadgeText>
                        </MobileCardBadge>
                    </MobileCardBadgeWrapper>
                </MobileCardItem>
            </MobileCardContent>
        );
    }
    const renderTableRow = (vaccination: Vaccination, index: number) => {
        const child = children.find(c => c.id === vaccination.childId)
        const dateTime = parseDateTime(vaccination.date)
        const vaccine = vaccines.find(vac => vac.id === vaccination.vaccineId)

        return (
            <TableRow key={index}>
                <Cell>
                    <CellText>{child?.name || ""}</CellText>
                </Cell>
                <Cell>
                    <CellText>{formatDateToDDMMYYYYWithDashes(dateTime)}</CellText>
                </Cell>
                <Cell>
                    <CellText>{vaccine?.name || ""}</CellText>
                </Cell>
                <ResultsCell onClick={() => {
                }}>
                    <OkBubble><CellText>{vaccine?.mandatory ? "obowiązkowe" : "opcjonalne"}</CellText></OkBubble>
                    <img alt="Zobacz diagnozę" src={icUp}/>
                </ResultsCell>
            </TableRow>
        );
    }


    const renderMobileMessageIfEmpty = () => {
        if (vaccinations.length > 0) {
            return null
        }
        return (
            <MobileMessageIfEmpty>
                <MobileMessageEmptyTitle>Brak zaplanowanych szczepień</MobileMessageEmptyTitle>
                {/*<ButtonUpLink>Zobacz Wszystkie</ButtonUpLink>*/}
            </MobileMessageIfEmpty>
        )
    }

    return (
        <CardBig>
            <TableCardContent>
                <Headline>Zaplanowane szczepienia</Headline>
                <HeaderTableRow>
                    <HeaderCell>Dziecko</HeaderCell>
                    <HeaderCell>Data</HeaderCell>
                    <HeaderCell>Typ szczepienia</HeaderCell>
                    <HeaderCell>Szczepienie</HeaderCell>
                </HeaderTableRow>
                {renderMobileMessageIfEmpty()}
                <MobileCards>
                    {vaccinations.map((el, i) => renderMobileCard(el, i))}
                </MobileCards>
                <TableCardRows>
                    {vaccinations.map((el, i) => renderTableRow(el, i))}
                </TableCardRows>
            </TableCardContent>
        </CardBig>
    )
}

export default VaccinationTopContent