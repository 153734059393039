import React, {useState} from "react";
import icTreatment from "../../../../../assets/images/ic_treatment.svg"
import icBook from "../../../../../assets/images/ic_book.svg"
import icStetoscope from "../../../../../assets/images/ic_stetoscope.svg"
import {
    Container,
    ContentRow, Divider,
    EducationRow,
    Section,
    SectionTitle,
    SectionTitleText,
    TabWrapper,
    TitleSection
} from "./PersonalDetailsTab.styles";
import {Doctor} from "../../../../../state/doctors/types";
import ExpandableText from "./ExpandableText";
import ExpandCollapseButton from "../../../../Shared/Button/ExpandCollapseButton/ExpandCollapseButton";


const PersonalDetailsTab: React.FC<{ doctor: Doctor }> = ({doctor}) => {
    const [educationExpanded, setEducationExpanded] = useState(false)
    const [experienceExpanded, setExperienceExpanded] = useState(false)

    const renderEducation = () => {
        const edu = doctor.education ? doctor.education : []

        if (educationExpanded) {
            return edu.map((el, index) => (<EducationRow key={index}>- {el}</EducationRow>));
        } else {
            return (edu.slice(0, 1).map((el, index) => <EducationRow key={index}>- {el}</EducationRow>));
        }
    };

    const renderExperience = () => {
        const exp = doctor.experience ? doctor.experience : []
        if (experienceExpanded) {
            return exp.map((el, i) => (<EducationRow key={i}>{el}</EducationRow>));
        } else {
            return (exp.slice(0, 5).map((el, i) => <EducationRow key={i}>{el}</EducationRow>));
        }
    };

    return (
        <TabWrapper>
            <Container>
                <Section>
                    <SectionTitle>
                        <img alt="O mnie" src={icTreatment}/>
                        <SectionTitleText>O mnie</SectionTitleText>
                    </SectionTitle>
                    <ContentRow>
                        <ExpandableText content={doctor.description} maxLength={500}></ExpandableText>
                    </ContentRow>
                </Section>
                <Divider/>
                <Section>
                    <TitleSection>
                        <SectionTitle>
                            <img alt="Pomiary" src={icBook}/>
                            <SectionTitleText>Edukacja i certyfikaty</SectionTitleText>
                        </SectionTitle>
                    </TitleSection>
                    <ContentRow>
                        {renderEducation()}
                        <ExpandCollapseButton
                            buttonExpanded={educationExpanded}
                            expandedText="Zwiń opis"
                            collapsedText="Rozwiń pełen opis"
                            onChanged={expanded => {
                                setEducationExpanded(expanded)
                            }}
                        />
                    </ContentRow>
                </Section>
                <Divider/>
                <Section>
                    <TitleSection>
                        <SectionTitle>
                            <img alt="Pomiary" src={icStetoscope}/>
                            <SectionTitleText>Specjalizacje</SectionTitleText>
                        </SectionTitle>
                    </TitleSection>
                    <ContentRow>
                        {renderExperience()}
                        <ExpandCollapseButton
                            buttonExpanded={experienceExpanded}
                            expandedText="Zwiń opis"
                            collapsedText="Rozwiń pełen opis"
                            onChanged={expanded => {
                                setExperienceExpanded(expanded)
                            }}
                        />
                    </ContentRow>
                </Section>
            </Container>

        </TabWrapper>
    )
}

export default PersonalDetailsTab