import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../../state/store';
import {useLocation, useNavigate} from "react-router-dom";
import imgWave from "../../../assets/images/bg_wave_login.png";
import {Background, BackgroundWave, Container, RegisterSuccessFormContainer} from "./RecoverSuccess.styles";
import RecoverSuccessForm from "./RecoverSuccessForm";
import {LoginLogoTop} from "../Login/Login.styles";
import icLogoMobile from "../../../assets/images/ic_logo_mobile.svg";

const RecoverSuccess: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const isLoggedIn = useSelector((state: AppState) => state.profile.profile != null);

    const navigateLogin = () => {
        navigate("/login", {replace: true});
    }

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);

    return (
        <Container>
            <Background>
                <BackgroundWave src={imgWave}/>
            </Background>
            <RegisterSuccessFormContainer>
                <LoginLogoTop src={icLogoMobile}/>
                <RecoverSuccessForm
                    email={email || null}
                    navigateLogin={navigateLogin}
                />
            </RegisterSuccessFormContainer>
        </Container>
    );
}

export default RecoverSuccess;
