import {Action} from "./actions";
import {PregnancyState} from "./types";
import {ProfileAction} from "../profile/actions";

const initialState: PregnancyState = {
    pregnancies: null,
};

export const pregnanciesReducer = (state = initialState, action: Action | ProfileAction): PregnancyState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "PREGNANCIES_SUCCESS":
            return {
                ...state,
                pregnancies: action.data || [],
            };
        default:
            return state;
    }
};
