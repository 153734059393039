import icClose from "../../../../assets/images/ic_close.svg";
import React from "react";
import {CloseButtonStyle} from "./ButtonClose.styles";

interface Props {
    onClick: () => void
}

const ButtonClose: React.FC<Props> = ({onClick}) => {
    return <CloseButtonStyle src={icClose} onClick={event => {
        event.preventDefault()
        onClick()
    }}/>
}

export default ButtonClose