import React, {useState} from 'react';
import VaccinationTable from "./VaccinationTable";
import VaccinationTopContent from "./VaccinationTopContent";
import ButtonUpCircle from "../Shared/Button/ButtonUpCircle/ButtonUpCircle";
import imgDecoration4 from "../../assets/images/img_decoration_4.svg";
import icClose from "../../assets/images/ic_close.svg";
import Popup from "../Shared/PopUp/PoPup";
import StyledDatePicker from "../Shared/Input/StyledDatePicker";
import DropDownPicker, {OptionProp} from "../Shared/Input/DropDownInput";
import ButtonPrimary from "../Shared/Button/ButtonPrimary/ButtonPrimary";
import {useFetchChildren} from "../../state/children/useFetchChildren";
import {useFetchVaccines} from "../../state/vaccines/helpers";
import {AppDispatch} from "../../state/store";
import {useDispatch} from "react-redux";
import {createVaccination} from "../../state/vaccines/actions";
import {
    ButtonWrapper,
    CardBackground,
    CardSmall,
    CardTitle2,
    Content,
    ContentWrapper,
    Decoration1Graphic,
    DoctorGraphic2,
    PopupContent,
    PopupTitle,
    SmallButtonStyled,
    Title,
    TitleWrapper,
    TopContent
} from "./VaccineCalendar.styles";

type BooleanFields = {
    [key: string]: boolean;
}

const VaccineCalendar: React.FC = () => {

    const [isPopupVisible, setIsPopupVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [selectedVaccineId, setSelectedVaccineId] = useState<number | null>(null)
    const [selectedChildId, setSelectedChildId] = useState<number | null>(null)
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)

    const [errorData, setErrorData] = useState<BooleanFields>({})

    const children = useFetchChildren()
    const vaccines = useFetchVaccines()
    const dispatch: AppDispatch = useDispatch()

    const togglePopup = () => {
        setSelectedVaccineId(null)
        setSelectedChildId(null)
        setSelectedDate(null)
        setErrorData({})
        setIsPopupVisible(!isPopupVisible)
    }

    const setError = (id: string, value: boolean) => {
        setErrorData(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const validate = () => {
        let valid = true
        if (!selectedVaccineId) {
            valid = false
        }
        setError("selectedVaccineId", !selectedVaccineId)

        if (!selectedChildId) {
            valid = false
        }
        setError("selectedChildId", !selectedChildId)

        if (!selectedDate) {
            valid = false
        }
        setError("selectedDate", !selectedDate)
        return valid;
    }

    const onSubmitClicked = () => {
        if (isLoading) {
            return
        }
        if (!validate()) {
            return;
        }

        setIsLoading(true)
        dispatch(createVaccination(selectedVaccineId!, selectedChildId!, selectedDate!))
            .then(togglePopup)
            .catch(e => console.log("failed creating vaccination", e))
            .finally(() => setIsLoading(false))
    }

    function getChildrenInputs(): OptionProp[] {
        const result = children.map(child => (
            {
                key: child.id.toString(),
                label: child.name
            }
        ));
        const emptyProp: OptionProp = {
            key: "",
            label: "",
        }
        return [emptyProp, ...result]
    }

    function getVaccinesOptions(): OptionProp[] {
        const options = vaccines.map(vaccine => ({
            key: vaccine.id.toString(),
            label: vaccine.name
        }));
        const emptyProp: OptionProp = {
            key: "",
            label: "",
        }
        return [emptyProp, ...options]
    }

    function renderPopup() {
        return <Popup visible={isPopupVisible} onClose={togglePopup}>
            <PopupContent>
                <TitleWrapper>
                    <PopupTitle>Dodaj szczepienie</PopupTitle>
                    <img alt="close" src={icClose} onClick={togglePopup}/>
                </TitleWrapper>
                <DropDownPicker
                    error={errorData["selectedVaccineId"] ? "To pole jest wymagane" : null}
                    label="Wybierz typ szczepienia"
                    value={vaccines.find(v => v.id === selectedVaccineId)?.id.toString() || ""}
                    onChange={(selected) => {
                        if (selected === "") {
                            setSelectedVaccineId(null)
                        } else {
                            setSelectedVaccineId(Number(selected))
                        }
                        setError("selectedVaccineId", selected === "")
                    }}
                    options={getVaccinesOptions()}
                />
                <DropDownPicker
                    label="Wybierz dziecko"
                    value={children.find(child => (child.id === selectedChildId))?.id?.toString() || ""}
                    error={errorData["selectedChildId"] ? "To pole jest wymagane" : null}
                    onChange={(selected) => {
                        if (selected === "") {
                            setSelectedChildId(null)
                        } else {
                            setSelectedChildId(Number(selected))
                        }
                        setError("selectedChildId", selected === "")
                    }}
                    options={getChildrenInputs()}
                />
                <StyledDatePicker
                    label="Data szczepienia"
                    value={selectedDate}
                    correct={false}
                    error={errorData["selectedDate"] ? "To pole jest wymagane" : null}
                    onValueChange={(newValue) => {
                        setSelectedDate(newValue)
                        setError("selectedDate", false)
                    }}
                />

                <ButtonWrapper>
                    <ButtonPrimary
                        progress={isLoading}
                        title="Zapisz"
                        onClick={onSubmitClicked}/>
                </ButtonWrapper>
            </PopupContent>
        </Popup>;
    }

    return (
        <div>
            {renderPopup()}
            <div style={{overflowY: 'auto', maxHeight: '100vh'}}>
                <ContentWrapper>
                    <TitleWrapper>
                        <Title>Szczepienia</Title>
                    </TitleWrapper>
                    <Content>
                        <TopContent>
                            <VaccinationTopContent/>
                            <CardSmall onClick={togglePopup}>
                                <DoctorGraphic2/>
                                <Decoration1Graphic src={imgDecoration4}/>
                                <CardBackground/>
                                <CardTitle2>Dodaj szczepienie swojego dziecka lub swoje jeśli jesteś w
                                    ciąży</CardTitle2>
                                <SmallButtonStyled>
                                    <ButtonUpCircle/>
                                </SmallButtonStyled>
                            </CardSmall>
                        </TopContent>
                        <VaccinationTable vaccines={vaccines}/>
                    </Content>
                </ContentWrapper>
            </div>
        </div>

    );
};

export default VaccineCalendar;
