import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {TopNavigation} from "../Welcome/TopNavigation/TopNavigation";
import Footer from "../Welcome/Footer/Footer";
import {BottomWave} from "../Welcome/Articles/Articles.styles";
import {Paths} from "../../config/paths";
import {
    ArticleDetailsContentWrapper,
    Background,
    BottomWaveContainer,
    TopNavigationWrapper
} from "./ArticleDetails.styles";
import ArticleDetailsContent from "./ArticleDetailsContent";

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}


const ArticleDetails: React.FC = () => {


    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);

    const navigate = useNavigate()

    return (
        <Background>
            <TopNavigationWrapper>
                <TopNavigation
                    onAiDiagnosisClicked={() => navigate(Paths.HOME)}
                    onDoctorsClicked={() => navigate(Paths.HOME)}
                    onFaqClicked={() => navigate(Paths.HOME)}
                    onBlogClicked={() => navigate(Paths.HOME)}
                />
            </TopNavigationWrapper>
            <ArticleDetailsContentWrapper>
                <ArticleDetailsContent id={id}/>
            </ArticleDetailsContentWrapper>

            <BottomWaveContainer>
                <BottomWave/>
            </BottomWaveContainer>
            <Footer
                openPopup={() => navigate(Paths.HOME)}
                onFaqClicked={() => navigate(Paths.HOME)}
            />
        </Background>
    )
}

export default ArticleDetails