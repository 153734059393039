import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 24px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  background-color: ${props => props.theme.color.gray100};
  border-radius: 0 16px 16px 16px;
`

export const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const SectionTitleText = styled.h5`
  color: ${props => props.theme.color.gray1000};
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Column1 = styled.div`
  width: 290px;
`

export const Column2 = styled.div`
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.color.gray500};
`