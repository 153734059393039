import React, {useEffect, useState} from "react";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import DoctorInfoWidget from "../../Shared/DoctorInfoWidget/DoctorInfoWidget";
import {useFetchUserDoctor} from "../../../state/user/helpers";
import UnconfirmedAppointments from "../../DoctorHome/UnconfirmedAppointments/UnconfirmedAppointments";
import {Card, Container, Title, TitleWrapper} from "./UnconfirmedVisits.styles";
import {confirmAppointment, fetchUnconfirmedAppointments,} from "../../../state/doctorAppointments/actions";
import {UnconfirmedAppointment} from "../../../state/doctorAppointments/type";

const UnconfirmedVisits: React.FC = () => {

    const doctor = useFetchUserDoctor();
    const [unconfirmed, setUnconfirmedAppointments] = useState<UnconfirmedAppointment[] | null>(null);
    const [isLoadingUnconfirmed, setIsLoadingUnconfirmed] = useState(false)

    const [, setLoadingAction] = useState(false)
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (!isLoadingUnconfirmed && unconfirmed === null) {
            doFetchUnconfirmedAppointments();
        }
    }, [unconfirmed, dispatch]);

    const doFetchUnconfirmedAppointments = () => {
        setIsLoadingUnconfirmed(true)
        dispatch(fetchUnconfirmedAppointments())
            .then(fetchedAppointments => {
                setUnconfirmedAppointments(fetchedAppointments)
            })
            .catch(e => {
                console.log("Error fetching user", e)
                setUnconfirmedAppointments(null)
            })
            .finally(() => setIsLoadingUnconfirmed(false));
    }

    const handleConfirmAppointment = (id: number) => {
        setLoadingAction(true)
        dispatch(confirmAppointment(id))
            .then(() => {
                doFetchUnconfirmedAppointments()
            })
            .catch(e => console.log("could not confirm appointment", e))
            .finally(() => setLoadingAction(false))
    }

    return (
        <Container>
            <TitleWrapper>
                <Title>Wizyty</Title>
                <DoctorInfoWidget doctor={doctor}/>
            </TitleWrapper>
            <Card>
                <UnconfirmedAppointments
                    appointments={unconfirmed || []}
                    onConfirmClicked={(id) => handleConfirmAppointment(id)}
                    onCancelClicked={() => {
                        console.log("cancel clicked")
                    }}
                />
            </Card>
        </Container>
    )
}

export default UnconfirmedVisits