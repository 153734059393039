import styled from "styled-components";
import imgChild from "../../assets/images/img_child_1.jpg";

export const DoctorGraphic2 = styled.div`
  
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    bottom: 24px;
    top: 24px;
    right: 24px;
    width: 50%;
    z-index: 1;
    border-radius: 24px;

    background: url(${imgChild}) no-repeat;


  }
`
export const CardBackground = styled.div`
  
  
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 32px;
    z-index: -1;
  }
`
export const CardTitle2 = styled.h4`
  
  @media (max-width: 599px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.36px;
  }

  @media (min-width: 600px) {
    position: absolute;
    top: 32px;
    left: 32px;
    width: 40%;
    color: ${props => props.theme.color.gray1000};
    z-index: 5;
    font-size: 22px;
  }
`

export const Decoration1Graphic = styled.img`


  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    position: absolute;
    bottom: 32px;
    left: 32px;
    width: 95px;
    height: 95px;
  }
`

export const SmallButtonStyled = styled.div`
  
  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    position: absolute;
    right: 35px;
    bottom: 35px;
    z-index: 4;
  }
`
export const CardSmall = styled.div`

  display: flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;


  @media (max-width: 599px) {
    flex-direction: row;
    background-color: ${props => props.theme.color.gray100};
    border-radius: 20px;
    box-shadow: 0 5px 12px 6px rgba(241, 241, 241, 0.60);
    padding: 20px;
    gap: 16px;
  }

  @media (min-width: 600px) {
    height: 322px;
    flex-shrink: 0;
    width: calc(40% - 40px);

    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    z-index: 1;

    border-radius: 32px;
    background: #EAE2D8;
  }
`

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${props => props.theme.color.beige100};


  @media (max-width: 599px) {
    margin: 76px 20px 32px;
  }

  @media (min-width: 600px) {
    border-radius: 80px 0 0 0;
    padding: 10px 40px 40px;
    min-height: 100vh;

  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const TopContent = styled.div`
  display: flex;

  @media (max-width: 599px) {
    flex-direction: column-reverse;
    gap: 16px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    gap: 40px;
  }
`

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 599px) {
    
  }

  @media (min-width: 600px) {
    width: 400px;
  }
`

export const PopupTitle = styled.h4`
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Title = styled.h3`
  
  @media (max-width: 599px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
    
    margin-bottom: 40px;
  }

  @media (min-width: 600px) {
    margin-top: 56px;
    margin-bottom: 60px;
  }
`
