import styled from "styled-components";
import {Card} from "../../Shared/Div/Card";

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 599px) {
    margin-bottom: 40px;
  }
  @media (min-width: 600px) {
    margin-bottom: 60px;
  }
`

export const Title = styled.h3`
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 599px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 26.4px */
    letter-spacing: -0.48px;
  }

  @media (min-width: 600px) {
    margin-top: 56px;
  }
`
export const BackIcon = styled.img`
`

export const BackButton = styled.div`
  margin-top: 16px;
  cursor: pointer;
  display: inline-flex;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1;


  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`

export const BackText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-weight: 400;
  line-height: 150%;
`
export const ContentWrapper = styled.div`
  min-height: 100vh;
  box-sizing: border-box;

  @media (max-width: 599px) {
    padding: 80px 20px 20px;
  }

  @media (min-width: 600px) {
    padding: 10px 40px 40px;
  }
`;

export const CardBig = styled(Card)`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
`
export const CardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    gap: 24px;
  }
`

export const CardSection = styled.div`

  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 16px;
  background: ${props => props.theme.color.gray100};

  @media (max-width: 599px) {
    border: 1px solid ${props => props.theme.color.gray400};
    box-shadow: 0 5px 10px 2px ${props => props.theme.color.gray400};
  }

  @media (min-width: 600px) {
    border: 2px solid ${props => props.theme.color.gray400};
    box-shadow: 0 10px 20px 0 rgba(241, 241, 241, 0.25);
  }
`

export const ClickableCardSection = styled(CardSection)`
  cursor: pointer;
  gap: 16px;
`

export const AvatarContainer = styled.div`

  overflow: hidden;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 599px) {
    width: 36px;
    height: 36px;
    border-radius: 18px;
  }

  @media (min-width: 600px) {
    width: 64px;
    height: 64px;
    border-radius: 32px;
  }
`

export const CardRowGapBig = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    gap: 25px;

  }
`
export const CardRowGapSmall = styled(CardRowGapBig)`
  gap: 8px;
`

export const NameTitle = styled.p`
  color: ${props => props.theme.color.gray900};
  line-height: 150%;
`

export const DoctorAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const CardTitleMobile = styled.h3`

`

export const CardHeaderMobile = styled.div`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const CardTitle = styled.h4`

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`

export const CardSectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

`
export const IconMobile = styled.div`
  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    display: none;
  }
`


export const IconDesktop = styled.div`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`

export const CardSectionTitle = styled.h5`

  @media (max-width: 599px) {
    font-size: 16px;
    font-weight: 600;
  }

  @media (min-width: 600px) {
    font-size: 18px;
    font-weight: 700;
  }
`

export const CardRowSpceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`