import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, AppState} from "../store";
import {fetchVaccination, fetchVaccines} from "./actions";

export const useFetchVaccines = () => {
    const dispatch: AppDispatch = useDispatch();
    const vaccines = useSelector((state: AppState) => state.vaccines.vaccines);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoading && vaccines === null) {
            setIsLoading(true)
            dispatch(fetchVaccines())
                .catch(e => console.log("Error fetching children", e))
                .then(result => {
                    // do nothing
                })
                .finally(() => setIsLoading(false));
        }
    }, [vaccines, dispatch]);

    return vaccines || [];
};

export const useFetchVaccinations = () => {
    const dispatch: AppDispatch = useDispatch();
    const vaccinations = useSelector((state: AppState) => state.vaccines.vaccinations);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!isLoading && vaccinations === null) {
            setIsLoading(true)
            dispatch(fetchVaccination())
                .catch(e => console.log("Error fetching children", e))
                .then(() => {
                })
                .finally(() => setIsLoading(false));
        }
    }, [vaccinations, dispatch]);

    return vaccinations || [];
};
