import {Endpoints} from "../../config/endpoints";
import {Profile} from "./types";

interface LoginSuccess {
    type: "LOGIN_SUCCESS";
    profile: Profile;
}

interface LogoutSuccess {
    type: "LOGOUT_SUCCESS";
}

export const signupConfirm = (email: string, token: string) => async (dispatch: any): Promise<Awaited<"SUCCESS" | "FAILURE">> => {
    try {
        const body = JSON.stringify({email, token})
        const response1 = await fetch(Endpoints.SIGNUP_CONFIRM, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: body,
        });

        if (!response1.ok) {
            return Promise.resolve("FAILURE")
        }

        const data = await response1.json();

        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);

        const profile: Profile = {
            token: data.token,
            refreshToken: data.refreshToken,
        }
        dispatch({
            type: "LOGIN_SUCCESS",
            profile: profile,
        });

        return Promise.resolve("SUCCESS")
    } catch (error: any) {
        return Promise.resolve("FAILURE")
    }
};

export const resetPassword = (email: string, password: string, token: string) => async (dispatch: any): Promise<Awaited<"FAILURE" | "LOGIN_FAILED" | "SUCCESS">> => {
    try {
        const body1 = JSON.stringify({password, token})
        const response = await fetch(Endpoints.RESET_PASSWORD, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: body1,
        });

        if (!response.ok) {
            return Promise.resolve("FAILURE")
        }

        const body2 = JSON.stringify({email, password})
        const response2 = await fetch(Endpoints.LOGIN, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: body2,
        });

        if (!response2.ok) {
            return Promise.resolve("LOGIN_FAILED")
        }

        const data = await response2.json();

        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);

        dispatch({
            type: "LOGIN_SUCCESS",
            profile: {
                username: email,
            },
        });
        return Promise.resolve("SUCCESS")
    } catch (error: any) {
        return Promise.resolve("FAILURE")
    }
};


export const loginUser = (email: string, password: string) => async (dispatch: any): Promise<Awaited<"CREDENTIALS_INVALID" | "SUCCESS">> => {
    try {
        const body = JSON.stringify({email, password})
        const response = await fetch(Endpoints.LOGIN, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: body,
        });

        if (!response.ok) {
            return Promise.resolve("CREDENTIALS_INVALID")
        }

        const data = await response.json();

        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);

        dispatch({
            type: "LOGIN_SUCCESS",
            profile: {
                username: email,
            },
        });
        return Promise.resolve("SUCCESS")
    } catch (error: any) {
        return Promise.resolve("CREDENTIALS_INVALID")
    }
};

export const signupUser = (email: string, password: string) => async (): Promise<Awaited<"CREDENTIALS_INVALID" | "SUCCESS" | "USER_EXISTS">> => {
    try {
        const body = JSON.stringify({email, password})
        const response1 = await fetch(Endpoints.SIGNUP, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: body,
        });

        if (response1.status === 409) {
            return Promise.resolve("USER_EXISTS")
        }

        if (!response1.ok) {
            return Promise.resolve("CREDENTIALS_INVALID")
        }

        return Promise.resolve("SUCCESS")
    } catch (error: any) {
        if (error.response && error.response.status === 409) {
            return Promise.resolve("USER_EXISTS")
        } else {
            return Promise.resolve("CREDENTIALS_INVALID")
        }
    }
};

export const recoverPassword = (email: string) => async (): Promise<Awaited<"SUCCESS" | "FAILURE" | "NO_USER">> => {
    try {
        const body = JSON.stringify({email})
        const response = await fetch(Endpoints.RECOVER, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: body,
        });
        if (response.status === 404) {
            return Promise.resolve("NO_USER")
        }
        if (!response.ok) {
            return Promise.resolve("FAILURE")
        }
        return Promise.resolve("SUCCESS")
    } catch (error: any) {
        return Promise.resolve("FAILURE")
    }
}

export const logoutUser = () => async (dispatch: any) => {
    localStorage.clear()
    dispatch({
        type: "LOGOUT_SUCCESS",
    });
};

export type ProfileAction = LogoutSuccess | LoginSuccess;
