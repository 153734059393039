import React from "react";
import {useTheme} from "styled-components";
import icCheck from "../../../assets/images/ic_check.svg"
import {Container, ErrorText, RememberMeText, Row1, TickBox} from "./CheckBox.styles";

const CheckBox: React.FC<{ checked: boolean, children: React.ReactNode, error?: string | null, onChange: () => void }> = (
    {
        checked,
        children,
        error,
        onChange
    }) => {

    const theme = useTheme()

    const renderTickContent = () => {
        if (checked) {
            return (<img src={icCheck} alt="check"/>);
        }
        return null;
    }

    const renderError = () => {
        if (!error) return null
        return (
            <ErrorText>{error}</ErrorText>
        );
    }
    return (
        <Container>
            <Row1>
                <TickBox
                    $background={checked ? theme.color.violet600 : theme.color.gray200}
                    $borderColor={error ? theme.color.error600 : theme.color.gray800}
                    onClick={(event) => {
                        event.preventDefault()
                        onChange()
                    }}
                >{renderTickContent()}</TickBox>
                <RememberMeText
                    $color={error ? theme.color.error600 : theme.color.gray800}
                >{children}</RememberMeText>
            </Row1>
            {renderError()}
        </Container>
    )
}

export default CheckBox;