import imgBenefit1Mobile from '../../../assets/images/img_benefit_1_mobile.svg';
import imgBenefit2Mobile from '../../../assets/images/img_benefit_2_mobile.svg';
import imgBenefit3Mobile from '../../../assets/images/img_benefit_3_mobile.svg';
import imgBenefit4Mobile from '../../../assets/images/img_benefit_4_mobile.svg';
import imgBenefit5Mobile from '../../../assets/images/img_benefit_5_mobile.svg';
import imgBenefit from '../../../assets/images/img_benefit.png';
import imgBenefit2 from '../../../assets/images/img_benefit_2.png';
import imgBenefit3 from '../../../assets/images/img_benefit_3.png';
import imgBenefit4 from '../../../assets/images/img_benefit_4.png';
import imgBenefit5 from '../../../assets/images/img_benefit_5.png';
import React from "react";
import {
    Background,
    BenefitCard,
    BenefitCardContent,
    BenefitCardContentText,
    BenefitCardItems,
    BenefitCardTitleText,
    BenefitsCards,
    BenefitsCardTitle,
    BenefitsTitle,
    BenefitsTitleMobile,
    BenefitsTitleWrapper,
    BenefitsTitleWrapperMobile,
    Container,
    Marker,
    SectionDecorationThread, SectionDecorationThread1Mobile,
    SectionDecorationThread2
} from "./BenefitsSection.styles";
import styled from "styled-components";


const ImgDesktop = styled.img`
  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`

const ImgMobile = styled.img`
  margin-top: 20px;

  @media (max-width: 1023px) {
    display: block;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`
const BenefitSection: React.FC = () => {
    return (
        <Background>
            <Container>
                <SectionDecorationThread/>
                <SectionDecorationThread2/>

                <SectionDecorationThread1Mobile/>
                <BenefitsTitleWrapper>
                    <BenefitsTitle>
                        Dlaczego warto używać<Marker>HappyKiddo</Marker>
                    </BenefitsTitle>
                </BenefitsTitleWrapper>
                <BenefitsTitleWrapperMobile>
                    <BenefitsTitleMobile>
                        Dlaczego warto używać<br/>HappyKiddo
                    </BenefitsTitleMobile>
                </BenefitsTitleWrapperMobile>
                <BenefitsCards>
                    <BenefitCard>
                        <BenefitCardItems>
                            <BenefitsCardTitle>
                                <BenefitCardTitleText>Monitoruj stan zdrowia swoich dzieci</BenefitCardTitleText>
                            </BenefitsCardTitle>
                            <BenefitCardContent>
                                <BenefitCardContentText>Nasz portal umożliwia Ci pełen monitoring zdrowia Twojego
                                    dziecka. Dzięki nam będziesz zawsze na bieżąco z wynikami badań, szczegółami
                                    dotyczącymi wzrostu i wagi oraz historią chorób.</BenefitCardContentText>
                            </BenefitCardContent>
                            <ImgDesktop src={imgBenefit} alt="benefit1"/>
                            <ImgMobile src={imgBenefit1Mobile} alt="benefit1"/>
                        </BenefitCardItems>
                    </BenefitCard>
                    <BenefitCard>
                        <BenefitCardItems>
                            <ImgDesktop src={imgBenefit2} alt="benefit2"/>
                            <BenefitsCardTitle>
                                <BenefitCardTitleText>Już nigdy nie przegapisz szczepienia</BenefitCardTitleText>
                            </BenefitsCardTitle>
                            <BenefitCardContent>
                                <BenefitCardContentText>Zapomnij o martwieniu się harmonogramem szczepień. HappyKiddo
                                    pozwoli Ci śledzić plan szczepień zarówno obowiązkowych, jak i zalecanych,
                                    zapewniając, że Twoje dziecko otrzyma wszystkie niezbędne szczepienia na
                                    czas.</BenefitCardContentText>
                            </BenefitCardContent>

                            <ImgMobile src={imgBenefit2Mobile} alt="benefit2"/>
                        </BenefitCardItems>
                    </BenefitCard>

                    <BenefitCard>
                        <BenefitCardItems>
                            <BenefitsCardTitle>
                                <BenefitCardTitleText>Skorzystaj z innowacyjnej diagnozy AI</BenefitCardTitleText>
                            </BenefitsCardTitle>
                            <BenefitCardContent>
                                <BenefitCardContentText>HappyKiddo wykorzystuje zaawansowaną sztuczną inteligencję do
                                    diagnozowania aż 18 chorób. Dzięki temu opiekun uzyskuje wstępną ocenę zdrowia
                                    dziecka, co może być szczególnie przydatne w sytuacjach, gdy nie ma możliwości
                                    natychmiastowej wizyty u lekarza.</BenefitCardContentText>
                            </BenefitCardContent>
                            <ImgDesktop src={imgBenefit3} alt="benefit3"/>
                            <ImgMobile src={imgBenefit3Mobile} alt="benefit3"/>
                        </BenefitCardItems>
                    </BenefitCard>

                    <BenefitCard>
                        <BenefitCardItems>
                            <ImgDesktop src={imgBenefit4} alt="benefit4"/>
                            <BenefitsCardTitle>
                                <BenefitCardTitleText>Profesjonalni lekarze dostępni 24h/7</BenefitCardTitleText>
                            </BenefitsCardTitle>
                            <BenefitCardContent>
                                <BenefitCardContentText>Jesteśmy dostępni dla Ciebie całą dobę. Dzięki teleporadom
                                    online, możesz skonsultować się z lekarzem w dowolnym momencie, niezależnie od
                                    godziny. To pełna elastyczność i szybki dostęp do opieki
                                    medycznej.</BenefitCardContentText>
                            </BenefitCardContent>
                            <ImgMobile src={imgBenefit4Mobile} alt="benefit4"/>
                        </BenefitCardItems>
                    </BenefitCard>

                    <BenefitCard>
                        <BenefitCardItems>
                            <BenefitsCardTitle>
                                <BenefitCardTitleText>Dołącz do społeczności rodziców i opiekunów</BenefitCardTitleText>
                            </BenefitsCardTitle>
                            <BenefitCardContent>
                                <BenefitCardContentText>Nasza społeczność rodziców to miejsce, gdzie możesz dzielić się
                                    swoimi doświadczeniami i pytaniami. Znajdziesz tu wsparcie emocjonalne od innych
                                    rodziców, którzy stoją przed podobnymi wyzwaniami.</BenefitCardContentText>
                            </BenefitCardContent>
                            <ImgDesktop src={imgBenefit5} alt="benefit5"/>
                            <ImgMobile src={imgBenefit5Mobile} alt="benefit5"/>
                        </BenefitCardItems>
                    </BenefitCard>
                </BenefitsCards>
            </Container>
        </Background>
    );
}

export default BenefitSection;