import {Endpoints} from "../../config/endpoints";
import {ArticlesFetchedAction} from "./type";


export const fetchArticles = () => async (dispatch: any) => {
    try {
        const url = Endpoints.ARTICLES
        const response = await fetch(url);
        const data = await response.json();
        if (!response.ok) {
            return Promise.reject()
        }
        const success: ArticlesFetchedAction = {
            articles: data,
            type: "ARTICLES_FETCHED"
        }
        dispatch(success)
        return Promise.resolve()
    } catch (error: any) {
        return Promise.reject(error)
    }
}