import React, {useEffect, useState} from "react";
import {AppDispatch, AppState} from "../../../state/store";
import {useDispatch, useSelector} from "react-redux";
import ChildCardComponent from "./ChildCardComponent";
import {Child} from "../../../state/children/types";
import {fetchChildren} from "../../../state/children/actions";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {AboutDoctor, Buttons, CardWrapper, ChildrenWrapper, LinkButton} from "./ChildSelectionCard.styles";

const ChildSelectionCard: React.FC<{
    onCancel: () => void
    onConfirm: (child: number) => void
}> = (
    {
        onCancel,
        onConfirm,
    }
) => {

    const children = useSelector((state: AppState) => state.children.children);
    const [loading, setLoading] = useState(false)
    const [selectedChildId, setSelectedChildId] = useState<number | null>(null)
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (children == null && !loading) {
            setLoading(true)
            dispatch(fetchChildren()).catch((err) => {
                console.log("error fetching children", err)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [children, loading, setLoading, dispatch])

    const renderChild = (child: Child) => {
        return (
            <ChildCardComponent
                key={child.id}
                id={child.id}
                title={child.name}
                selected={selectedChildId === child.id}
                onClick={() => setSelectedChildId(child.id)}
                onSelected={() => {
                    setSelectedChildId(child.id)
                    onConfirm(child.id)
                }}
                image={child.image || null}
            />
        );
    }

    return (
        <CardWrapper>
            <AboutDoctor>Dla którego dziecka umawiasz wizytę?</AboutDoctor>
            <ChildrenWrapper>
                {children?.map(child => renderChild(child))}
            </ChildrenWrapper>
            <Buttons>
                <LinkButton onClick={() => {
                    onCancel()
                }}>Wróć</LinkButton>
                <ButtonPrimary title="Potwierdź i przejdź dalej" onClick={() => {
                    if (!selectedChildId) {
                        return
                    }
                    onConfirm(selectedChildId)
                }}/>
            </Buttons>
        </CardWrapper>
    )
}

export default ChildSelectionCard