import { styled } from "styled-components";

interface DividerProps {
    height?: string,
    width?: string,
}

const Space = styled.div<DividerProps>`
    height: ${props => props.height || '0'};
    width: ${props => props.width || '0'};
`

export default Space;