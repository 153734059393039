import styled from "styled-components";

export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
export const TabWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
`
export const SectionTitleText = styled.h5`
  color: ${props => props.theme.color.gray1000};
`
export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
export const Section = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;
`
export const Container = styled.div`
  flex-grow: 1;
  padding: 64px 24px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  background-color: ${props => props.theme.color.gray100};
  border-radius: 0 16px 16px 16px;
`
export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 52px;
  flex-grow: 1;
  margin-top: 57px;
`

export const ContentColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`

export const ContentColumnCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ContentColumnCell60 = styled(ContentColumnCell)`
  width: 60%;
`

export const ContentColumnCell20 = styled(ContentColumnCell)`
  width: 20%;
`

export const CellTitle = styled.p`
  color: ${props => props.theme.color.gray1000};
`

export const CellSubtitle = styled.p`
  color: ${props => props.theme.color.gray800};
  font-size: 14px;
`
