import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../state/store';
import {addChild} from '../../../state/children/actions';
import {useNavigate} from 'react-router-dom';
import ButtonClose from "../../Shared/Button/ButtonClose/ButtonClose";
import InputField from "../../Shared/Input/Input";
import CheckBox from "../../Shared/Input/CheckBox";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import StyledDatePicker from "../../Shared/Input/StyledDatePicker";
import DropDownInput from "../../Shared/Input/DropDownInput";
import {getPeselBirthDate, isAllDigits, isValidPESEL} from "../../../config/utils";
import {
    ButtonsWrapper,
    Card,
    CardContainer,
    CardHeadline,
    CardTitle,
    Container,
    Headline,
    PeselWrapper,
    Title
} from "./AddChild.styles";
import {Paths} from "../../../config/paths";

const ChildForm: React.FC = () => {
    const [sex, setSex] = useState("");
    const [sexError, setSexError] = useState<string | null>(null);
    const [sexCorrect, setSexCorrect] = useState<boolean | null>(null);

    const [validatePeselOnType, setValidatePeselOnType] = useState(false);
    const [peselError, setPeselError] = useState<string | null>(null);
    const [peselCorrect, setPeselCorrect] = useState<boolean | null>(null);
    const [pesel, setPesel] = useState("");
    const [noPesel, setNoPesel] = useState(false);
    const [firstAndLastName, setFirstAndLastName] = useState("");

    const [validateFirstAndLastNameOnType, setValidateFirstAndLastNameOnType] = useState(false);
    const [firstAndLastNameError, setFirstAndLastNameError] = useState<string | null>(null);
    const [firstAndLastNameCorrect, setFirstAndLastNameCorrect] = useState<boolean | null>(null);

    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
    const [dateOfBirthCorrect, setDateOfBirthCorrect] = useState<boolean | null>(null);
    const [dateOfBirthError, setDateOfBirthError] = useState<string | null>(null);

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = () => {
        dispatch(addChild(
            firstAndLastName,
            pesel,
            dateOfBirth!,
            sex,
        ))
            .then(() => {
                navigate(Paths.DEPENDANTS_ALL);
            })
            .catch(() => {
                // setIsError(true);
            });
    };

    const validatePeselError = (pesel: string) => {
        const isValid = isValidPESEL(pesel);
        setPeselError(!isValid ? "Nieprawidłowy numer PESEL" : null)
        return isValid;
    }

    const validateFirstAndLastNameError = (firstAndLastName: string) => {
        const isValid = firstAndLastName !== "";
        setFirstAndLastNameError(!isValid ? "To pole nie może być puste" : null)
        return isValid;
    }

    const validatePeselCorrect = (pesel: string) => {
        const isValid = isValidPESEL(pesel);
        setPeselCorrect(isValid)
    }

    const validateDateOfBirthCorrect = (date: Date | null) => {
        setDateOfBirthCorrect(date != null)
    }

    const validatefirstAndLastNameCorrect = (firstAndLastName: string) => {
        const isValid = firstAndLastName !== "";
        setFirstAndLastNameCorrect(isValid)
    }

    const validateFields = () => {
        setValidatePeselOnType(true);
        setValidateFirstAndLastNameOnType(true);

        validateFirstAndLastNameError(firstAndLastName);
        validatePeselError(pesel);
        validateDateOfBirthError(dateOfBirth)
        validateSexError(sex)
    }

    const isFormValid = () => {
        return !peselError || !firstAndLastNameError || !dateOfBirthError
    }

    const validateDateOfBirthError = (dateOfBirth: Date | null) => {
        const isValid = dateOfBirth != null;
        setDateOfBirthError(!isValid ? "Nieprawidłowy format daty" : null)
        return isValid;
    }

    const validateSexError = (sex: string) => {
        const isValid = sex === "M" || sex === "F";
        setSexError(!isValid ? "To pole jest wymagane" : null)
        return isValid;
    }

    const validateSexCorrect = (sex: string) => {
        const isValid = sex === "M" || sex === "F";
        setSexCorrect(isValid)
    }


    return (
        <Container>
            <Headline>
                <Title>Dodaj dziecko</Title>
            </Headline>
            <CardContainer>
                <Card>
                    <CardHeadline>
                        <CardTitle>Dane dziecka</CardTitle>
                        <ButtonClose onClick={() => {
                            navigate(Paths.DEPENDANTS_ALL, {replace: true})
                        }}/>
                    </CardHeadline>
                    <InputField
                        type="text"
                        label="Imię i nazwisko"
                        value={firstAndLastName}
                        correct={firstAndLastNameCorrect}
                        error={firstAndLastNameError}
                        onChange={newValue => {
                            setFirstAndLastName(newValue)
                            if (validateFirstAndLastNameOnType) {
                                validateFirstAndLastNameError(newValue)
                                validatefirstAndLastNameCorrect(newValue)
                            }
                        }}
                        onBlur={() => {
                            setValidateFirstAndLastNameOnType(true);
                            validateFirstAndLastNameError(firstAndLastName);
                            validatefirstAndLastNameCorrect(firstAndLastName)
                        }}
                    />
                    <PeselWrapper>
                        <InputField
                            type="text"
                            label="PESEL"
                            value={!noPesel ? pesel : ""}
                            correct={peselCorrect}
                            error={peselError}
                            onChange={newValue => {
                                if (newValue === "" || isAllDigits(newValue)) {
                                    setPesel(newValue)
                                }
                                if (validatePeselOnType) {
                                    validatePeselError(newValue)
                                }
                                validatePeselCorrect(newValue)
                            }}
                            onBlur={() => {
                                setValidatePeselOnType(true);
                                validatePeselError(pesel);
                                const date = getPeselBirthDate(pesel)
                                if (date) {
                                    setDateOfBirth(date)
                                    validateDateOfBirthCorrect(date)
                                }
                            }}

                            disabled={noPesel}
                        />
                        <CheckBox
                            checked={noPesel}
                            onChange={() => {
                                setNoPesel(!noPesel)
                            }}
                        >Brak peselu</CheckBox>
                    </PeselWrapper>
                    <StyledDatePicker
                        label="Data urodzenia"
                        value={dateOfBirth}
                        correct={dateOfBirthCorrect}
                        error={dateOfBirthError}
                        onValueChange={(newValue) => {
                            setDateOfBirth(newValue)
                            validateDateOfBirthError(newValue)
                            validateDateOfBirthCorrect(newValue)
                        }}
                        onBlur={() => {
                            validateDateOfBirthError(dateOfBirth)
                        }}
                    />
                    <DropDownInput
                        options={[
                            {
                                key: "",
                                label: "",
                            },
                            {
                                key: "F",
                                label: "Dziewczynka",
                            },
                            {
                                key: "M",
                                label: "Chłopczyk",
                            },
                        ]}
                        label="Płeć biologiczna"
                        value={sex}
                        onChange={newValue => {
                            setSex(newValue)
                            validateSexError(newValue)
                            validateSexCorrect(newValue)
                        }}
                        correct={sexCorrect}
                        error={sexError}
                        onBlur={() => {
                            validateSexError(sex)
                        }}
                    />
                    <ButtonsWrapper>
                        <ButtonPrimary
                            title="Dodaj"
                            onClick={() => {
                                validateFields()
                                if (isFormValid()) {
                                    handleSubmit()
                                }
                            }}/>
                    </ButtonsWrapper>
                </Card>

            </CardContainer>
        </Container>
    );
};

export default ChildForm;
