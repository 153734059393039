import api from "../../config/api";
import {Endpoints} from "../../config/endpoints";
import {ConfirmedAppointment, DoctorAppointment, UnconfirmedAppointment} from "./type";

export const fetchAllAppointments = () => async (dispatch: any): Promise<Awaited<ConfirmedAppointment[]>> => {
    try {
        const path = Endpoints.DOCTORS_APPOINTMENTS_ALL
        const response = await api.get(path)
        if (response.status !== 200) {
            return Promise.reject()
        }
        const data: ConfirmedAppointment[] = response.data
        dispatch({
            type: "DOCTOR_APPOINTMENTS_SUCCESS",
            data: data
        })
        return Promise.resolve(data)
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject()
    }
}

export const fetchConfirmedAppointments = () => async (): Promise<Awaited<ConfirmedAppointment[]>> => {
    try {
        const response = await api.get(Endpoints.DOCTORS_APPOINTMENTS_CONFIRMED)
        const data: ConfirmedAppointment[] = response.data
        return Promise.resolve(data)
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject()
    }
}

export const cancelAppointment = (appointmentId: number) => async () => {
    try {
        const response = await api.put(Endpoints.DOCTORS_APPOINTMENT_CANCEL.replace(":id", appointmentId.toString()))
        if (response.status !== 200) {
            return Promise.reject()
        }
        return Promise.resolve()
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject()
    }
}

export const fetchUnconfirmedAppointments = () => async (): Promise<Awaited<UnconfirmedAppointment[]>> => {
    try {
        const response = await api.get(Endpoints.DOCTORS_APPOINTMENTS_UNCONFIRMED)
        const data: UnconfirmedAppointment[] = response.data
        return Promise.resolve(data)
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject()
    }
}

export const confirmAppointment = (appointmentId: number) => async () => {
    try {
        const response = await api.put(Endpoints.DOCTORS_APPOINTMENT_CONFIRM.replace(":id", appointmentId.toString()))
        if (response.status !== 200) {
            return Promise.reject()
        }
        return Promise.resolve()
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject()
    }
}

interface FetchAppointmentsSuccess {
    type: "DOCTOR_APPOINTMENTS_SUCCESS",
    data: DoctorAppointment[];
}

export type Action = FetchAppointmentsSuccess;