import DoctorCard from "../../Doctors/DoctorCard";
import {
    BackgroundThread, BackgroundThread11,
    BelowTitle,
    ButtonWrapper,
    MainButton,
    Section,
    SectionWrapper,
    SectionWrapperWrapper,
    Title
} from "./DoctorsSection.styles";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {SecondComponentHandles} from "../Welcome";
import {Endpoints} from "../../../config/endpoints";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import ButtonSecondary from "../../Shared/Button/ButtonSecondary/ButtonSecondary";
import {Paths} from "../../../config/paths";
import theme from "../../../Theme";
import styled from "styled-components";

const ButtonSecondaryLight = styled.div`
  @media (max-width: 1199px) {
    display: none;
  }
  @media (min-width: 1200px) {
    display: block;
  }
`

const ButtonSecondaryDark = styled.div`

  @media (max-width: 1199px) {
    display: block;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`

interface FeaturedDoctor {
    id: number
    name: string
    image: string
    specialization: string
    rating: string
    price: number
    availability: string[]
}

const getFeaturedDoctors = () => async () => {
    try {
        const resp = await fetch(process.env.REACT_APP_API_BASE_URL + "/api" + Endpoints.DOCTORS_FEATURED);
        if (!resp.ok) {
            return Promise.reject()
        }
        const data = await resp.json();
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
};


const useFetchDoctors = () => {
    const [docs, setDocs] = useState<FeaturedDoctor[] | null>(null)
    const [loading, setLoading] = useState(false)

    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!loading && docs === null) {
            setLoading(true)
            dispatch(getFeaturedDoctors())
                .then((docs) => setDocs(docs))
                .catch(error => console.log("error fetching doctors", error))
                .finally(() => setLoading(false))
        }

    }, []);

    return docs || []
}

const DoctorsSection = forwardRef<SecondComponentHandles, { onClick: () => void }>(({onClick}, ref) => {

    const sectionRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
        scrollToSection: () => {
            sectionRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }));

    const doctors = useFetchDoctors()

    const renderDoctor = (doctor: FeaturedDoctor) => {
        return (<DoctorCard
            key={doctor.name}
            image={process.env.REACT_APP_API_BASE_URL + doctor.image}
            name={doctor.name}
            specializations={[doctor.specialization]}
            rating={doctor.rating}
            price={(doctor.price / 100) + " zł"}
            when="Dzisiaj"
            dates={doctor.availability}
            onClick={onClick}
        />);
    }

    const renderAllDoctors = () => {
        return doctors.map((doctor) => (renderDoctor(doctor)))
    }

    return (
        <SectionWrapperWrapper ref={sectionRef}>
            <SectionWrapper>
                <BackgroundThread/>
                <BackgroundThread11/>
                <Section>
                    <Title>Polecani lekarze</Title>
                    <BelowTitle>{renderAllDoctors()}</BelowTitle>
                    <ButtonWrapper>
                        <ButtonSecondaryLight>
                            <MainButton
                                title='Przejdź do pełnej listy lekarzy'
                                onClick={onClick}
                            />
                        </ButtonSecondaryLight>
                        <ButtonSecondaryDark>
                            <ButtonSecondary
                                backgroundColor={theme.color.violet800}
                                fontColor={theme.color.gray100}
                                title="Przejdź do pełnej listy lekarzy"
                                icon={(<div style={{width: 8}}></div>)}
                                onClick={onClick}
                            />
                        </ButtonSecondaryDark>
                    </ButtonWrapper>
                </Section>
            </SectionWrapper>
        </SectionWrapperWrapper>
    );
})

export default DoctorsSection;