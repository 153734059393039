import styled from "styled-components";
export const LoginFormWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    gap: 16px;
    flex-grow: 1;
  }

  @media (min-width: 600px) {
    gap: 48px;
    width: 400px;
  }
`;

export const UnderInputs = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
`

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: #FFF;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
`

export const HeaderTitle = styled.h3`
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 599px) {
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;
  }
  @media (min-width: 600px) {
  }
`

export const HeaderSubtitle = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 20px;

  @media (max-width: 599px) {
    font-size: 14px;
    letter-spacing: -0.28px;
  }
  @media (min-width: 600px) {
    font-size: 20px;
  }
`

export const FormPart = styled.div`
  display: flex;
  justify-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const RememberMeText = styled.p`
  color: ${props => props.theme.color.gray800};
  line-height: 20px;
  margin-left: 8px;

  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: 600px) {
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 24px;
`

export const LinkButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const RecoveryText = styled.p`
  text-decoration: none;
  margin-right: 4px;
  
  @media (max-width: 599px) {
    font-size: 14px;

  }

  @media (min-width: 600px) {
  }
`;

export const RecoveryLink = styled.a`
  @media (max-width: 599px) {
    font-size: 14px;
  }

  @media (min-width: 600px) {
  }
`;