import styled from "styled-components";

export const AiBannerWrapper = styled.div`
  background: ${props => props.theme.color.information100};
  
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    margin: 0 20px;
    border-radius: 20px;
    padding: 24px;
    gap: 24px;
  }

  @media (min-width: 600px) {

    align-items: flex-start;
    gap: 24px;
    border-radius: 32px;
    padding: 40px 56px;
    
  }
`

export const HeadlineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const Headline = styled.h4`
  color: ${props => props.theme.color.gray1000};
`

export const AiBannerContent = styled.p`
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
`
