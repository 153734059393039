import styled from "styled-components";

export const Background = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.color.gray100};

  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    width: 100%;
    min-width: 1200px;
  }
`;

export const TopNavigationWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
`

export const ArticleDetailsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 599px) {
    margin: 0 20px;
  }

  @media (min-width: 600px) {
    width: 100%;
    min-width: 1200px;
  }
`

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  @media (max-width: 599px) {
    margin-top: 87px;
    
  }

  @media (min-width: 600px) {
    margin-top: 187px;
  }
  
`

export const TagBubble = styled.p`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
  background: var(--Violet-Violet-200, #D5D1EE);
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  line-height: 20px;
`

export const ArticleTitle = styled.h1`
  text-align: center;


  @media (max-width: 599px) {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
  }

  @media (min-width: 600px) {
    max-width: 910px;
  }
`

export const ArticleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;


  @media (max-width: 599px) {
    max-height: 208px;
  }

  @media (min-width: 600px) {
    max-width: 1220px;
    max-height: 724px;
  }
`

export const ArticleColumn = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 599px) {
    margin-top: 48px;
    gap: 48px;
  }

  @media (min-width: 600px) {

    margin-top: 96px;
    gap: 96px;
    max-width: 910px;
  }
`

export const ArticleSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const P = styled.p`
  @media (max-width: 599px) {
    margin-bottom: 16px;
  }

  @media (min-width: 600px) {
    margin-bottom: 24px;
  }
`

export const H3 = styled.h3`

  @media (max-width: 599px) {
    margin-bottom: 16px;
  }

  @media (min-width: 600px) {
    margin-bottom: 24px;
  }
`

export const B = styled.b`
  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
  }
`

export const BackArrowWrapper = styled.div`
  width: 100%;
`

export const BackArrowInnerWrapper = styled.div`
  position: relative;
  
  @media (max-width: 599px) {
    margin-top: 76px;
  }

  @media (min-width: 600px) {
    margin-top: 96px;
    margin-left: 175px;
  }
`

export const BottomWaveContainer = styled.div`
  position: relative;
  align-self: stretch;
  background: ${props => props.theme.color.gray100};
  overflow: hidden;
  height: 160px;
`

export const ArticleDate = styled.p`
  color: ${props => props.theme.color.gray800};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 96px;

  @media (max-width: 599px) {
    margin: 8px 0 116px;
  }

  @media (min-width: 600px) {

  }
`
