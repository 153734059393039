import styled from "styled-components";

export const ErrorText = styled.p`
  color: ${props => props.theme.color.error900};
  font-size: 14px;
  line-height: 16px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.spacing300};
`

export const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.spacing400};
`

export const TickBox = styled.div<{ $background: string, $borderColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.theme.size.size20};
  height: ${props => props.theme.size.size20};
  border-radius: 4px;
  border: 1.5px solid ${props => props.$borderColor};
  background: ${props => props.$background};
  box-shadow: 0 0 0 2px ${props => props.theme.color.violet200};
  flex-shrink: 0;
`

export const RememberMeText = styled.p<{ $color: string }>`
  color: ${props => props.$color};
  line-height: 20px;


  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 20px;
  }

  @media (min-width: 600px) {
  }
`
