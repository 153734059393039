import React from "react";
import icUpRight from "../../../assets/images/ic_right_up.svg";
import {Card, LinkButton} from "./SettingsCard.styles";
import styled from "styled-components";


const ImgIcon = styled.img`
  height: 50px;
`
const SettingsCard: React.FC<{ img: string, title: string, onClick: () => void }> = ({img, title, onClick}) => {
    return (
        <Card onClick={() => onClick()}>
            <ImgIcon alt={title} src={img}/>
            <LinkButton>
                <p>{title}</p>
                <img alt="open" src={icUpRight}/>
            </LinkButton>
        </Card>
    )
}

export default SettingsCard