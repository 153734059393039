import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Title, TitleWrapper} from "../PatientAppointments/PatientAppointmentsList.styles";
import icBack from "../../assets/images/ic_back.svg";
import styled from "styled-components";
import {Card} from "../Shared/Div/Card";
import {AiDiagnosis} from "../../state/aiDiagnoses/types";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../state/store";
import {fetchPatientDiagnoses} from "../../state/aiDiagnoses/actions";
import {formatDateToDMMMMYYYYPolish, parseDate} from "../../config/utils";

export const BackIcon = styled.img`
`

export const BackButton = styled.div`
  margin-top: 16px;
  cursor: pointer;
  display: inline-flex;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1;
`

export const BackText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-weight: 400;
  line-height: 150%;
`
export const ContentWrapper = styled.div`
  padding: 10px 40px 40px;
  min-height: 100vh;
  box-sizing: border-box;
`;
export const CardBig = styled(Card)`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
`
export const CardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 16px;
  border: 2px solid ${props => props.theme.color.gray400};
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 10px 20px 0 rgba(241, 241, 241, 0.25);
`

export const CardRowGapBig = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`
export const CardRowGapSmall = styled(CardRowGapBig)`
  gap: 8px;
  width: 100%;
`

export const PlaceholderText = styled.p`
  color: ${props => props.theme.color.gray900};
  line-height: 150%;
`

export const CardTitle = styled.h4`
`

export const CardSubtitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 16px;
`

export const CardSectionTitle = styled.h5`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const AiDiagnosesSection = styled.div`
`

interface RouteParams {
    [key: string]: string | undefined;

    childId: string;
    id: string;
}


const AiDiagnosisDetails: React.FC = () => {

    const navigate = useNavigate()

    const {childId: childIdString} = useParams<RouteParams>();
    const childId = parseInt(childIdString ?? "", 10);

    const {id: diagnosisIdString} = useParams<RouteParams>();
    const diagnosisId = parseInt(diagnosisIdString ?? "", 10);

    const [aiDiagnoses, setAiDiagnoses] = useState<AiDiagnosis[] | null>(null)
    const [loading, setLoading] = useState(false)

    const aiDiagnosis = aiDiagnoses?.find(d => d.id === diagnosisId)

    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!aiDiagnoses && !loading) {
            setLoading(true)
            dispatch(fetchPatientDiagnoses(childId))
                .then(elements => setAiDiagnoses(elements))
                .finally(() => setLoading(false))
        }
    }, [aiDiagnoses, loading]);

    const renderDiagnosis = (diagnosis: AiDiagnosis | undefined) => {
        if (!diagnosis) {
            return null
        }
        return (
            <CardSection>
                <CardSectionTitle>Diagnoza AI</CardSectionTitle>
                <CardRowGapSmall>
                    <PlaceholderText>{diagnosis.diagnosis}</PlaceholderText>
                </CardRowGapSmall>
            </CardSection>
        )
    }


    return (
        <ContentWrapper>
            <TitleWrapper>
                <Title>Diagnoza AI</Title>
                <BackButton onClick={() => navigate(-1)}>
                    <BackIcon src={icBack}/>
                    <BackText>Wróć do wszystkich diagnoz AI</BackText>
                </BackButton>
            </TitleWrapper>
            <CardBig>
                <CardContent>
                    <CardTitle>Opis diagnozy AI</CardTitle>
                    <AiDiagnosesSection>
                        <CardSubtitle>{formatDateToDMMMMYYYYPolish(parseDate(aiDiagnosis?.date))}</CardSubtitle>
                        {renderDiagnosis(aiDiagnosis)}
                    </AiDiagnosesSection>
                </CardContent>
            </CardBig>
        </ContentWrapper>
    )
}

export default AiDiagnosisDetails