import React from "react";
import icChild from '../../../../assets/images/ic_person_small.svg'
import icUp from '../../../../assets/images/ic_right_up.svg'
import {AiDiagnosis} from "../../../../state/aiDiagnoses/types";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../../config/paths";
import {formatDateToDDMMYYYYWithDashes, parseDate} from "../../../../config/utils";
import {
    CardBig,
    Cell,
    CellText,
    ChildAvatarContainer,
    ChildAvatarImage,
    HeaderCell,
    HeaderTableRow,
    Headline,
    IcChild,
    OkBubble,
    ResultsCell,
    TableCardContent,
    TableCardRows,
    TableRow
} from "./LeftBottomDiagnosisTable.styles";
import {useFetchChildren} from "../../../../state/children/useFetchChildren";
import {Child} from "../../../../state/children/types";
import {
    ChildName,
    MobileCardContent,
    MobileCardDate,
    MobileCardDescription,
    MobileCardHeader,
    MobileCardItem
} from "../LeftBottomAppointmentsTable/LeftBottomAppointmentsTable.styles";
import IcUpRight from "../../../Shared/Icons/IcUpRight";

const LeftBottomDiagnosisTable: React.FC<{
    diagnoses: AiDiagnosis[],
    size: "big" | "small"
}> = ({diagnoses, size}) => {

    const navigate = useNavigate()
    const createChildName = (child: Child | undefined) => {
        if (child) {
            return child.name
        }
        return "nie podano"
    }
    const createDate = (diagnosis: AiDiagnosis) => {
        if (!diagnosis.date) {
            return "nie podano"
        }
        const date = parseDate(diagnosis.date)
        if (!date) {
            return "nie podano"
        }
        return formatDateToDDMMYYYYWithDashes(date)
    }

    const children = useFetchChildren()

    const renderChildImage = (child: Child | undefined) => {
        if (!child) {
            return (<IcChild src={icChild} alt={"Matylda"}/>)
        }
        return (
            <ChildAvatarContainer>
                <ChildAvatarImage
                    alt={child.name}
                    src={`${process.env.REACT_APP_API_BASE_URL}${child.image}`}
                />
            </ChildAvatarContainer>
        );
    }

    function renderChildContent(diagnosis: AiDiagnosis) {

        const child = children.find(c => c.id === diagnosis.childId)

        return (<Cell>
            {renderChildImage(child)}
            <CellText>{createChildName(child)}</CellText>
        </Cell>);
    }

    const renderTableRow = (diagnosis: AiDiagnosis, index: number) => {
        return (
            <TableRow key={index}>
                {renderChildContent(diagnosis)}
                <Cell>
                    <CellText>{createDate(diagnosis)}</CellText> </Cell>
                <ResultsCell onClick={() => {
                    navigate(Paths.DIAGNOSIS_RESULT.replace(":id", diagnosis.id.toString()))
                }}>
                    <OkBubble><CellText>Diagnoza stworzona</CellText></OkBubble>
                    <img alt="Zobacz diagnozę" src={icUp}/>
                </ResultsCell>
            </TableRow>
        );
    }

    const getHeight = () => {
        switch (size) {
            case "big":
                return "564px"
            case "small":
                return "331px"
        }
    }


    const getScrollHeight = () => {
        switch (size) {
            case "big":
                return "430px"
            case "small":
                return "180px"
        }
    }


    const renderMobileChildAvatar = (child: Child | undefined) => {
        // if (!child) {
        //     return "no child"
        // }

        if (!child) {
            return (<IcChild src={icChild} alt={"Nie podano"}/>)
        }

        return (
            <ChildAvatarImage
                alt={child.name}
                src={`${process.env.REACT_APP_API_BASE_URL}${child.image}`}
            />
        )
    }


    const renderMobileCardContent = () => {
        if (diagnoses.length == 0) {
            return null
        }
        const diagnosis = diagnoses[0]
        const child = children?.find(el => el.id === diagnosis.childId)
        return (
            <MobileCardContent>
                <MobileCardItem onClick={() => {
                    navigate(Paths.DIAGNOSIS_RESULT.replace(":id", diagnosis.id.toString()))
                }}>
                    <MobileCardHeader>
                        <ChildAvatarContainer>
                            {renderMobileChildAvatar(child)}
                        </ChildAvatarContainer>
                        <ChildName>{child?.name || "nie podano"}</ChildName>
                        <IcUpRight/>
                    </MobileCardHeader>
                    <MobileCardDate>Diagnoza AI</MobileCardDate>
                    <MobileCardDescription>Przejdź do doagnozy</MobileCardDescription>
                </MobileCardItem>
            </MobileCardContent>
        );
    };

    return (
        <CardBig $height={getHeight()}>
            <TableCardContent>
                <Headline>Ostatnie diagnozy AI</Headline>
                {renderMobileCardContent()}
                <HeaderTableRow>
                    <HeaderCell>Dziecko</HeaderCell>
                    <HeaderCell>Data</HeaderCell>
                    <HeaderCell>Wynik</HeaderCell>
                </HeaderTableRow>
                <TableCardRows $height={getScrollHeight()}>
                    {diagnoses.map((el, i) => renderTableRow(el, i))}
                </TableCardRows>
            </TableCardContent>
        </CardBig>
    )
}

export default LeftBottomDiagnosisTable