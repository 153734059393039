import styled from "styled-components";

export const ChildrenWrapper = styled.div`
  display: flex;

  @media (max-width: 599px) {
    flex-direction: column;
    gap: 40px;
  }

  @media (min-width: 600px) {
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 30%;
    flex-direction: row;
  }
`

export const CardWrapper = styled.div`
  background: ${props => props.theme.color.gray100};

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 16px;

  @media (max-width: 599px) {
    margin: 0 20px;
    padding: 24px;
    gap: 24px;
  }

  @media (min-width: 600px) {
    width: 910px;
    padding: 40px 56px 48px 56px;
    gap: 40px;
    align-items: center;
    justify-content: start;
  }
`

export const AboutDoctor = styled.h4`
  @media (max-width: 599px) {

  }

  @media (min-width: 600px) {
    
  }
`

export const Buttons = styled.div`
  
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

  }
`

export const LinkButton = styled.button`
  color: ${props => props.theme.color.violet600};
`
