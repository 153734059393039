import React from "react";
import {useNavigate} from "react-router-dom";
import imgVisit from "../../../assets/images/img_visit.svg";
import imgDoctor from "../../../assets/images/img_doctor.svg";
import styled from "styled-components";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";

const Card = styled.div`
  display: flex;
  padding: 24px;
  gap: 12px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray100};

  @media (max-width: 599px) {
    margin: 0 20px;
    flex-direction: column;
  }

  @media (min-width: 600px) {
    justify-content: start;
    align-items: center;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 599px) {

  }

  @media (min-width: 600px) {
    width: 400px;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
`
const CardTitle = styled.h4`

  @media (max-width: 599px) {
    text-align: start;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.36px;
  }

  @media (min-width: 600px) {
    text-align: center;
  }
`

const VisitImgMobile = styled.img`
  @media (max-width: 599px) {
    height: 98.529px;
    margin: auto;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

const VisitImgDesktop = styled.img`
  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    width: 214px;
  }
`

const MobileButtons = styled.div`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

const DesktopButtons = styled.div`

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`
const LinkButton = styled.button`
  color: ${props => props.theme.color.violet600};
`

const CardText = styled.p`

  @media (max-width: 599px) {
    text-align: start;
  }

  @media (min-width: 600px) {
    text-align: center;
  }
`

const AllSetCard: React.FC = () => {
    const navigate = useNavigate()

    return (
        <Card>
            <CardContent>
                <VisitImgMobile src={imgDoctor}/>
                <CardTitle>Teleporada została umówiona</CardTitle>
                <VisitImgDesktop src={imgVisit}/>
                <CardText>Poczekaj na wizytę z lekarzem</CardText>
                <DesktopButtons>
                    <LinkButton onClick={() => {
                        navigate(-1)
                    }}>Wróć</LinkButton>
                </DesktopButtons>
                <MobileButtons>
                    <ButtonPrimary onClick={() => {
                        navigate(-1)
                    }} title="Wróć"/>
                </MobileButtons>
            </CardContent>
        </Card>
    )
}

export default AllSetCard