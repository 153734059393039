import {styled} from "styled-components"
import bgWaveInverse from '../../../assets/images/bg_wave_inverse.svg';
import bgWave2 from '../../../assets/images/bg_wave_7.png';
import bgThread from '../../../assets/images/bg_thread_7.png';
import bgThread2 from '../../../assets/images/bg_thread_6.png';

export const AccordionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1199px) {
    margin: 0 20px 88px 20px;
  }

  @media (min-width: 1200px) {
    width: 100%;
    margin-bottom: 88px;
  }
`;

export const FaqItem = styled.div`
  width: 100%;
  max-width: 1220px;
  position: relative;
  justify-content: stretch;
`

export const Question = styled.p`
  cursor: pointer;
  border-radius: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: relative;
  z-index: 2;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;
  margin-top: 45px;

  @media (max-width: 1199px) {
    background: ${props => props.theme.color.violet700};
    border-bottom: 2px solid ${props => props.theme.color.violet700};
    color: ${props => props.theme.color.gray100};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */

    padding: 12px 24px 12px 32px;
  }

  @media (min-width: 1200px) {
    background: ${props => props.theme.color.pink600};
    border-bottom: 2px solid ${props => props.theme.color.pink600};

    padding: 18px 32px 18px 40px;
  }
`;

export const Answer = styled.p <{ open: boolean }>`
  max-height: ${props => (props.open ? '1000px' : '0')};
  padding: ${props => (props.open ? '70px 32px 32px 40px' : '0')};
  margin-bottom: ${props => (props.open ? '-30px' : '0')};
  transition: ${props => (props.open ? 'all 0.3s cubic-bezier(0, 1, 0, 1)' : 'all 0.3s cubic-bezier(1, 0, 1, 0)')};
  background: ${props => props.theme.color.gray100};
  overflow: hidden;
  border-radius: 0 0 24px 24px;
  margin-top: -30px;
  color: ${props => props.theme.color.gray1000};
  
  @media (max-width: 1199px) {
    border: 2px solid ${props => props.theme.color.violet700};
     
  }

  @media (min-width: 1200px) {
    border: 2px solid ${props => props.theme.color.pink600};
  }
`;

export const SectionWrapper = styled.div`
  position: relative;
  align-self: stretch;
  @media (max-width: 1199px) {
    height: 900px;

  }

  @media (min-width: 1200px) {
    height: 1200px;
  }
  
  z-index: 1;
`

export const SectionWrapperWrapper = styled.div`
  position: relative;
  align-self: stretch;
  background: ${props => props.theme.color.beige100};
  overflow: hidden;
  width: 100%;
`

export const TopWave = styled.div`
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  background-image: url(${bgWaveInverse});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 75.5px;
  width: 100%;
  z-index: 1;
`

export const BottomWave = styled.div`
  position: absolute;
  margin: 0;
  left: 0;
  bottom: 0;
  background-image: url(${bgWave2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 75.5px;
  width: 100%;
  z-index: 1;
`

export const BackgroundThread = styled.div`
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  background-image: url(${bgThread});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 40%;
  height: 1200px;
  
  @media (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {

  }
`

export const BackgroundThread2 = styled.div`
  position: absolute;
  margin: 0;
  right: 50px;
  top: 0;
  background-image: url(${bgThread2});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 40%;
  height: 1155px;

  @media (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {

  }
`

export const Title = styled.h1`
  text-align: center;
  font-style: normal;
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 1199px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
    margin-top: 100px;
  }

  @media (min-width: 1200px) {
    margin-top: 152px;
    padding-bottom: 75px;
  }
`