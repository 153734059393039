import React, {useState} from "react";
import {Article} from "../../../state/articles/type";
import {useFetchArticles} from "../../../state/articles/helpers";
import {
    ArticleBubbles,
    ArticleDate,
    ArticleImage,
    ArticleItem,
    ArticlesSubtitle,
    ArticlesTitle,
    ArticlesTitles,
    ArticleTitlesTexts,
    CardsContainer,
    Container,
    DesktopContent,
    FeaturedArticleBubbles,
    FeaturedArticleDate,
    FeaturedArticleImage,
    FeaturedArticleTexts,
    FeaturedArticleTitle,
    FeaturedArticleWrapper,
    Input,
    SearchBoxWrapper,
    SearchButtonClear,
    TagBubble
} from "./ArticlesList.styles";
import icClear from "../../../assets/images/ic_close.svg";
import icSearch from "../../../assets/images/ic_search.svg";
import {formatDateToDDMMYYYYWithDelimiter, parseDate, parseDateTime} from "../../../config/utils";
import ButtonUpLink from "../../Shared/Button/ButtonUpLink/ButtonUpLink";


const ArticleListContent: React.FC<{ onArticleClicked: (articleId: number) => void }> = ({onArticleClicked}) => {

    const articles = useFetchArticles()

    const [query, setQuery] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
    };

    const renderInput = () => (
        <Container>
            <Input
                value={query}
                onChange={handleInputChange}
                placeholder="Szukaj"
            />
        </Container>
    );

    const renderInputButton = () => {
        if (query) {
            return <SearchButtonClear
                src={icClear}
                onClick={() => {
                    setQuery("")
                }}
            />;
        } else {
            return <SearchButtonClear
                src={icSearch}
            />;
        }
    };

    const renderSearchBox = () => {
        return (
            <SearchBoxWrapper>
                {renderInput()}
                {renderInputButton()}
            </SearchBoxWrapper>
        )
    }

    const getFilteredArticles = () => {
        if (query === "") {
            return articles
        } else {
            return articles.filter(a => a.title.includes(query))
        }
    }

    const renderTag = (t: string, index: number) => (<TagBubble key={`${t}-${index}`}>{t}</TagBubble>);

    const renderFeaturedArticle = (art: Article | null) => {
        if (!art) {
            return null
        }

        return (
            <FeaturedArticleWrapper onClick={() => {
                if (art?.id !== null) {
                    onArticleClicked(art?.id)
                }
            }}>
                <FeaturedArticleImage src={process.env.REACT_APP_API_BASE_URL + art.image}/>
                <FeaturedArticleTexts>
                    <FeaturedArticleBubbles>{art.tags.map((tag, index) => renderTag(tag, index))}</FeaturedArticleBubbles>
                    <FeaturedArticleTitle>{art.title}</FeaturedArticleTitle>
                    <FeaturedArticleDate>{formatDateToDDMMYYYYWithDelimiter(parseDate(art.createdAt), "/")}</FeaturedArticleDate>
                    <DesktopContent><ButtonUpLink>Przejdź do artykułu</ButtonUpLink></DesktopContent>
                </FeaturedArticleTexts>
            </FeaturedArticleWrapper>
        )
    };

    const renderBubbles = (article: Article) => {
        const tags = article?.tags
        if (!tags) {
            return null
        }
        return tags.map((tag, index) => (<TagBubble key={`bubble-${index}`}>{tag}</TagBubble>));
    }

    const mapArticle = (art: Article, index: number) => (
        <ArticleItem key={`${art.id}-${index}`} onClick={() => onArticleClicked(art.id)}>
            <ArticleImage src={process.env.REACT_APP_API_BASE_URL + art.image}/>
            <ArticleBubbles>{renderBubbles(art)}</ArticleBubbles>
            {art.title}
            <ArticleDate>{formatDateToDDMMYYYYWithDelimiter(parseDateTime(art.createdAt), "/")}</ArticleDate>
        </ArticleItem>
    );

    return (
        <>
            <ArticlesTitles>
                <ArticleTitlesTexts>
                    <ArticlesTitle>Artykuły</ArticlesTitle>
                    <ArticlesSubtitle>Poczytaj nasze artykuły</ArticlesSubtitle>
                </ArticleTitlesTexts>
                {renderSearchBox()}
            </ArticlesTitles>

            {renderFeaturedArticle(getFilteredArticles()[0] || null)}
            <CardsContainer>
                {getFilteredArticles().slice(1).map((article, index) => mapArticle(article, index))}
            </CardsContainer>
        </>
    )
}

export default ArticleListContent