import React from "react";
import {Doctor} from "../../../state/doctors/types";
import icStar from "../../../assets/images/ic_star.svg";
import SvgFill from "../../Shared/Icons/SvgFill";
import theme from "../../../Theme";
import IcCalendar from "../../Shared/Icons/IcCalendar";
import {formatPrice} from "../../../config/utils";
import {
    AboutDoctor,
    CalendarRow,
    DoctorCard,
    DoctorDetails, DoctorDetailsDate,
    DoctorDetailsHeadline,
    DoctorDetailsSubtitle,
    DoctorImage,
    DoctorInfo,
    DoctorLeftSection,
    DoctorName,
    DoctorSeparator,
    DoctorSpecialization, MobileDivider,
    PriceDesktop, PriceMobile,
    Rating,
    RatingRow,
    Star
} from "./DoctorCardComponent.styles";

const DoctorCardComponent: React.FC<{ doctor: Doctor | null, date: string | null }> = (
    {
        doctor,
        date
    }
) => {

    const renderRating = () => {
        const rating = doctor?.rating
        const ratingTxt = rating ? `${rating.value} (${rating.count} opinie)` : null

        if (!ratingTxt) return null
        return (
            <RatingRow>
                <Star src={icStar}/>
                <Rating>{ratingTxt}</Rating>
            </RatingRow>
        )
    }

    const getSpecializations = () => {
        const specializations = doctor?.specializations || []
        return specializations.map(s => s.name).join(", ")
    }


    return (
        <DoctorCard>
            <AboutDoctor>
                <DoctorLeftSection>
                    <DoctorImage
                        src={process.env.REACT_APP_API_BASE_URL + (doctor?.image || "")}></DoctorImage>
                    <DoctorInfo>
                        <DoctorName>{doctor?.name}</DoctorName>
                        <DoctorSpecialization>{doctor?.specializations.map(el => el.name).join(', ')}</DoctorSpecialization>
                        {renderRating()}
                    </DoctorInfo>
                </DoctorLeftSection>
            </AboutDoctor>
            <DoctorSeparator/>
            <DoctorDetails>
                <MobileDivider/>
                <DoctorDetailsHeadline>Podsumowanie</DoctorDetailsHeadline>
                <DoctorDetailsSubtitle>
                    <div>{getSpecializations()}</div>
                    <PriceMobile>{formatPrice(doctor?.price || null)}</PriceMobile>
                </DoctorDetailsSubtitle>
                <CalendarRow>
                    <SvgFill color={theme.color.gray1000}>
                        <IcCalendar/>
                    </SvgFill>
                    <DoctorDetailsDate>{date || ""}</DoctorDetailsDate>
                </CalendarRow>
            </DoctorDetails>
            <PriceDesktop>{formatPrice(doctor?.price || null)}</PriceDesktop>
        </DoctorCard>
    )
}

export default DoctorCardComponent