import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../state/store';
import {addPregnancy} from '../../../state/children/actions';
import {useNavigate} from 'react-router-dom';
import ButtonClose from "../../Shared/Button/ButtonClose/ButtonClose";
import InputField from "../../Shared/Input/Input";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import StyledDatePicker from "../../Shared/Input/StyledDatePicker";
import DropDownInput from "../../Shared/Input/DropDownInput";
import {
    ButtonsWrapper,
    Card,
    CardContainer,
    CardHeadline,
    CardTitle,
    Container,
    Headline,
    Title
} from "./AddPregnancy.styles";
import {fetchPregnancies} from "../../../state/pregnancies/actions";
import {Paths} from "../../../config/paths";

const AddPregnancyForm: React.FC = () => {
    const [sex, setSex] = useState("");
    const [sexError, setSexError] = useState<string | null>(null);
    const [sexCorrect, setSexCorrect] = useState<boolean | null>(null);

    const [day, setDay] = useState("");
    const [dayError, setDayError] = useState<string | null>(null);
    const [dayCorrect, setDayCorrect] = useState<boolean | null>(null);

    const [week, setWeek] = useState("");
    const [weekError, setWeekError] = useState<string | null>(null);
    const [weekCorrect, setWeekCorrect] = useState<boolean | null>(null);

    const [dueDate, setDueDate] = useState<Date | null>(null);
    const [dateDueDateCorrect, setDateDueDateCorrect] = useState<boolean | null>(null);
    const [dueDateError, setDueDateError] = useState<string | null>(null);

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = () => {
        const weeks = parseInt(week, 10);
        if (isNaN(weeks)) {
            return
        }
        const days = parseInt(day, 10);
        if (isNaN(days)) {
            return
        }
        if (!dueDate) {
            return
        }
        const isValid = sex === "unknown" || sex === "M" || sex === "F";
        if (!isValid) {
            return
        }
        let s: string | null = sex;
        if (s === "unknown") {
            s = null
        }

        dispatch(addPregnancy(weeks, days, dueDate, s,))
            .then(() => dispatch(fetchPregnancies()))
            .then(() => {
                navigate(Paths.DEPENDANTS_ALL);
            })
            .catch(() => {
                // setIsError(true);
            });
    };

    const validateDay = (pesel: string) => {
        const isValid = isValidPregnancyDay(pesel);
        setDayError(!isValid ? "Nieprawidłowa wartość" : null)
        setDayCorrect(isValid)
        return isValid;
    }

    const validateWeek = (week: string) => {
        const isValid = isValidPregnancyWeek(week);
        setWeekCorrect(isValid)
        setWeekError(!isValid ? "Nieprawidłowa wartość" : null)
        return isValid;
    }

    const validateFields = () => {
        validateWeek(week);
        validateDay(day);
        validateDateOfBirth(dueDate)
        validateSex(sex)
    }

    const isFormValid = () => {
        return !dayError || !weekError || !dueDateError
    }

    const validateDateOfBirth = (dateOfBirth: Date | null) => {
        const isValid = dateOfBirth != null;
        setDueDateError(!isValid ? "Nieprawidłowy format daty" : null)
        setDateDueDateCorrect(isValid)
        return isValid;
    }

    const validateSex = (sex: string) => {
        const isValid = sex === "unknown" || sex === "M" || sex === "F";
        setSexError(!isValid ? "To pole jest wymagane" : null)
        setSexCorrect(isValid)
        return isValid;
    }


    return (
        <Container>
            <Headline>
                <Title>Dodaj ciążę</Title>
            </Headline>
            <CardContainer>
                <Card>
                    <CardHeadline>
                        <CardTitle>Dane dotyczące ciąży</CardTitle>
                        <ButtonClose onClick={() => {
                            navigate(Paths.DEPENDANTS_ALL, {replace: true})
                        }}/>
                    </CardHeadline>
                    <InputField
                        type="number"
                        label="Tydzień ciąży"
                        value={week}
                        correct={weekCorrect}
                        error={weekError}
                        onChange={newValue => {
                            setWeek(newValue)
                            validateWeek(newValue)
                        }}
                        onBlur={() => {
                            validateWeek(week);
                        }}
                    />
                    <InputField
                        type="number"
                        label="Dzień ciąży"
                        value={day}
                        correct={dayCorrect}
                        error={dayError}
                        onChange={newValue => {
                            setDay(newValue)
                            validateDay(newValue)
                        }}
                        onBlur={() => {
                            validateDay(day);
                            /*const weeks = daysToWeeks(day)
                            if (weeks) {
                                setWeek(weeks.toString())
                                validateWeek(weeks.toString())
                            }*/
                        }}
                    />
                    <StyledDatePicker
                        label="Przewidywana data porodu"
                        value={dueDate}
                        correct={dateDueDateCorrect}
                        error={dueDateError}
                        onValueChange={(newValue) => {
                            setDueDate(newValue)
                            validateDateOfBirth(newValue)
                        }}
                        onBlur={() => {
                            validateDateOfBirth(dueDate)
                        }}
                    />
                    <DropDownInput
                        options={[
                            {
                                key: "",
                                label: "",
                            },
                            {
                                key: "unknown",
                                label: "Nie określono",
                            },
                            {
                                key: "F",
                                label: "Dziewczynka",
                            },
                            {
                                key: "M",
                                label: "Chłopczyk",
                            },
                        ]}
                        label="Płeć dziecka"
                        value={sex}
                        onChange={newValue => {
                            setSex(newValue)
                            validateSex(newValue)
                        }}
                        correct={sexCorrect}
                        error={sexError}
                        onBlur={() => {
                            validateSex(sex)
                        }}
                    />
                    <ButtonsWrapper>
                        <ButtonPrimary
                            title="Dodaj"
                            onClick={() => {
                                validateFields()
                                if (isFormValid()) {
                                    handleSubmit()
                                }
                            }}/>
                    </ButtonsWrapper>
                </Card>

            </CardContainer>
        </Container>
    );
};

function isValidPregnancyDay(day: string): boolean {
    const dayNumber = parseInt(day, 10);

    if (day !== dayNumber.toString()) {
        return false;
    }

    // Check if the day number is within the valid range
    return dayNumber >= 1 && dayNumber <= 6;
}

function isValidPregnancyWeek(week: string): boolean {
    const weekNumber = parseInt(week, 10);
    if (week !== weekNumber.toString()) {
        return false;
    }
    return weekNumber >= 1 && weekNumber <= 42;
}

export default AddPregnancyForm;
