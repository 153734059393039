const theme = {
    color: {
        // Grey
        gray100: "#ffffff",
        gray200: "#fdfdfd",
        gray300: "#f6f6f6",
        gray400: "#f1f1f1",
        gray500: "#dcdcdc",
        gray600: "#c5c5c5",
        gray700: "#969697",
        gray800: "#555556",
        gray900: "#29292a",
        gray1000: "#161618",
        // Violet
        violet100: "#edecf8",
        violet200: "#d5d1ee",
        violet300: "#b3ade1",
        violet400: "#9087d3",
        violet500: "#6f62c6",
        violet600: "#4f40b9",
        violet700: "#43369d",
        violet800: "#382d83",
        violet900: "#2d2469",
        violet1000: "#241d53",
        // Pink
        pink100: "#fef6fd",
        pink200: "#fce9fb",
        pink300: "#fad8f8",
        pink400: "#f7c6f4",
        pink500: "#f5b5f1",
        pink600: "#f3a5ee",
        pink700: "#cf8cca",
        pink800: "#ad75a9",
        pink900: "#8b5e88",
        pink1000: "#6d4a6b",
        // Error
        error100: "#fff1f2",
        error300: "#f59bb1",
        error600: "#e7174a",
        error900: "#840d2a",
        // Success
        success100: "#e9f9e9",
        success300: "#9ee59e",
        success600: "#1ec21e",
        success900: "#116f11",
        // Warning
        warning100: "#fdf7e7",
        warning300: "#f8de96",
        warning600: "#efb20b",
        warning900: "#886506",
        // Additional
        beige100: "#fcf9f2",
        beige200: "#F9F6F3",
        beige300: "#fff8e7",
        beige500: "#E9DAD1",
        // Semantic
        information100: "#E7F6FD",
    },
    spacing: {
        spacing200: "4px",
        spacing300: "8px",
        spacing400: "12px",
        spacing600: "24px"
    },
    size: {
        size1400: "48px",
        size20: "20px",
    },
    radius: {
        radius500: "12px",
        radius200: "4px",
    },
    typography: {
        fontWeightBold: 600
    },
    font: {
        default: '"Work Sans", Helvetica',
    }
};

export default theme;