import React from "react";
import imgChild from "../../../../assets/images/img_child_1.jpg";
import ButtonUpLink from "../../../Shared/Button/ButtonUpLink/ButtonUpLink";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../../config/paths";
import {
    CardBig,
    CardBigContent,
    CardBigContentTitle,
    ChildGraphic,
    Headline
} from "./LeftBottomOnboardingContent.styles";

const LeftBottomOnboardingContent: React.FC = () => {
    const navigate = useNavigate()
    return (
        <CardBig>
            <ChildGraphic src={imgChild}/>
            <div>
                <CardBigContent>
                    <Headline>Ostatnie diagnozy AI</Headline>
                    <div>
                        <CardBigContentTitle>Wygląda na to, że nie skorzystałeś/aś jeszcze z diagnozy
                            AI</CardBigContentTitle>
                        <ButtonUpLink onClick={() => navigate(Paths.DIAGNOSIS_NEW)}>Wykonaj diagnozę AI</ButtonUpLink>
                    </div>
                </CardBigContent>
            </div>
        </CardBig>
    )
}

export default LeftBottomOnboardingContent