import styled from "styled-components";
import SvgFill from "../../../../Shared/Icons/SvgFill";

export const Option = styled.option`
  width: 100%;
  text-align: center;
`

export const RightContainer = styled.div`

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    width: 40%;
    padding: 24px;
    background-color: ${props => props.theme.color.gray100};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

  }
`

export const RightContainerTitle = styled.h4`
  text-align: center;
`

export const ButtonSchedule = styled.div`
  align-self: flex-end;
`

export const BackButton = styled.div`
  position: relative;
  display: flex;
  width: 36px;
  height: 36px;

  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 36px;
  background: ${props => props.theme.color.violet600}; //var(--violet-violet-600-main, #4F40B9);
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);
`

export const SvgFillPositioned = styled(SvgFill)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-45%);
`

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
`

export const SummaryTitle = styled.body`
  color: ${props => props.theme.color.gray1000};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`

export const DateSummary = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

export const SummaryValue = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 20px;
`

