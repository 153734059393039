import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 15px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  background-color: ${props => props.theme.color.gray100};
  border-radius: 0 16px 16px 16px;
`

export const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const NoEntries = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 200px 0;
`