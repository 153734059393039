export class Endpoints {
    static readonly BASE_URL = process.env.REACT_APP_API_BASE_URL + '/api'
    // Auth
    static readonly BASE_URL_AUTH = Endpoints.BASE_URL + '/auth'
    static readonly LOGIN = Endpoints.BASE_URL_AUTH + '/login'
    static readonly SIGNUP = Endpoints.BASE_URL_AUTH + '/signup'
    static readonly SIGNUP_CONFIRM = Endpoints.BASE_URL_AUTH + '/signup/confirm'
    static readonly RECOVER = Endpoints.BASE_URL_AUTH + '/password/recover'
    static readonly RESET_PASSWORD = Endpoints.BASE_URL_AUTH + '/password/reset'
    static readonly REFRESH_TOKEN = Endpoints.BASE_URL_AUTH + '/token/refresh'
    // Doctors
    static readonly BASE_URL_DOCTORS = '/doctors'
    static readonly DOCTORS_FEATURED = Endpoints.BASE_URL_DOCTORS + '/featured'
    static readonly DOCTORS_SEARCH = Endpoints.BASE_URL_DOCTORS + '/search'
    static readonly DOCTORS_APPOINTMENTS = Endpoints.BASE_URL_DOCTORS + '/:doctorId/slots/unavailable'
    static readonly DOCTORS_SLOTS = Endpoints.BASE_URL_DOCTORS + '/slots'
    // Children
    static readonly BASE_URL_CHILDREN = '/children'
    static readonly CHILDREN_GET = Endpoints.BASE_URL_CHILDREN
    static readonly CHILDREN_ADD = Endpoints.BASE_URL_CHILDREN
    static readonly CHILDREN_EDIT = Endpoints.BASE_URL_CHILDREN
    static readonly CHILDREN_DELETE = Endpoints.BASE_URL_CHILDREN
    static readonly CHILDREN_APPOINTMENTS = Endpoints.BASE_URL_CHILDREN + "/:id/appointments"
    // Pregnancies
    static readonly BASE_URL_PREGNANCIES = '/pregnancies'
    static readonly PREGNANCY_ADD = Endpoints.BASE_URL_PREGNANCIES
    static readonly PREGNANCY_GET = Endpoints.BASE_URL_PREGNANCIES
    // Specializations
    static readonly BASE_URL_SPECIALIZATIONS = '/specializations'
    static readonly SPECIALIZATIONS_SEARCH = Endpoints.BASE_URL_SPECIALIZATIONS + '/search'
    // Diagnosis
    static readonly BASE_URL_AI_DIAGNOSIS = '/diagnoses/ai'
    static readonly AI_DIAGNOSES_GET_ALL = Endpoints.BASE_URL_AI_DIAGNOSIS
    // Doctor diagnoses
    static readonly BASE_URL_DOCTOR_DIAGNOSIS = '/doctor-diagnoses'
    static readonly HUMAN_DIAGNOSIS_GET_ALL = Endpoints.BASE_URL_DOCTOR_DIAGNOSIS
    // Questions
    static readonly INTERVIEW_QUESTIONS = process.env.REACT_APP_API_BASE_URL + '/questions'
    static readonly INTERVIEW_SUBMIT = '/interview'
    // Appointments
    static readonly BASE_URL_APPOINTMENTS = '/appointment'
    static readonly APPOINTMENTS_CREATE = Endpoints.BASE_URL_APPOINTMENTS
    static readonly APPOINTMENTS_GET_ALL = Endpoints.BASE_URL_APPOINTMENTS
    // Payments
    static readonly BASE_URL_PAYMENTS = '/payments'
    static readonly PAYMENTS_REDIRECT = Endpoints.BASE_URL_PAYMENTS + "/redirect"
    // Settings
    static readonly BASE_URL_SETTINGS = '/settings'
    // Questions
    static readonly VACCINES = process.env.REACT_APP_API_BASE_URL + '/json/vaccines'
    static readonly CREATE_VACCINATION = '/vaccinations'
    static readonly GET_VACCINATIONS = '/vaccinations'
    // users
    static readonly BASE_URL_USERS = '/user'
    // patients
    static readonly BASE_URL_PATIENTS = '/patients'
    // Questions
    static readonly ARTICLES = process.env.REACT_APP_API_BASE_URL + '/json/articles'
    // Doctor Appointments
    static readonly DOCTORS_APPOINTMENTS_ALL = Endpoints.BASE_URL_USERS + '/doctor/appointments/all'
    static readonly DOCTORS_APPOINTMENTS_UNCONFIRMED = Endpoints.BASE_URL_USERS + '/doctor/appointments/unconfirmed'
    static readonly DOCTORS_APPOINTMENTS_CONFIRMED = Endpoints.BASE_URL_USERS + "/doctor/appointments/confirmed"
    static readonly DOCTORS_APPOINTMENT_CONFIRM = Endpoints.BASE_URL_USERS + "/doctor/appointments/confirm/:id"
    static readonly DOCTORS_APPOINTMENT_CANCEL = Endpoints.BASE_URL_USERS + "/doctor/appointments/cancel/:id"
    static readonly DOCTOR_DIAGNOSIS_GET_ALL = Endpoints.BASE_URL_USERS + "/doctor/diagnoses"
    static readonly DOCTOR_DIAGNOSIS_UPDATE = Endpoints.BASE_URL_USERS + "/doctor/diagnoses"
    static readonly DOCTOR_AI_DIAGNOSES_GET_ALL_FOR_CHILD = Endpoints.BASE_URL_USERS + "/doctor/ai_diagnoses/:childId"


}