import {DoctorAppointmentsState} from "./type";
import {ProfileAction} from "../profile/actions";
import {Action} from "./actions";


const initialState: DoctorAppointmentsState = {
    appointments: null
};

export const doctorAppointmentsReducer = (state = initialState, action: Action | ProfileAction): DoctorAppointmentsState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "DOCTOR_APPOINTMENTS_SUCCESS":
            return {
                ...state,
                appointments: action.data || [],
            };
        default:
            return state;
    }
};