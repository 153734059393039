import styled from "styled-components";
import {Card} from "../Shared/Div/Card";

export const OutlineBubble = styled.div`
  display: flex;
  height: 24px;
  padding: 0 8px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.gray1000};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const TableCardContent = styled.div`

  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
  }

  @media (min-width: 600px) {
    padding: 24px;
  }
`
export const ContentWrapper = styled.div`

  min-height: 100vh;
  box-sizing: border-box;

  @media (max-width: 599px) {
    padding: 70px 20px 20px;
  }
  @media (min-width: 600px) {
    padding: 10px 40px 40px;
  }
`;
export const CardBig = styled(Card)`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;

  @media (max-width: 599px) {
    border-radius: 20px;
  }
  @media (min-width: 600px) {
  }
`

export const HeaderCell = styled.div`
  width: 12.5%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
`

export const HeaderCellCentered = styled(HeaderCell)`
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const HeaderTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  padding: 8px 16px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray300};

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
  }
`

export const Cell = styled.div`
  display: flex;
  color: ${props => props.theme.color.gray1000};
  flex-direction: row;
  justify-content: left;
  align-items: center;

  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    width: 12.5%;
    line-height: 150%;
    gap: 8px;
  }
`

export const CellCentered = styled(Cell)`
  @media (max-width: 599px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.32px;
  }
  @media (min-width: 600px) {
    justify-content: center;
  }
`

export const CardMobile = styled.div`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 24px;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
    border: 1px solid ${props => props.theme.color.gray400};
    background: ${props => props.theme.color.gray100};
    box-shadow: 0 5px 10px 2px #F1F1F1;
    margin: 0 16px;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const TableRowDesktop = styled.div`
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
    display: flex;
    padding: 8px 16px;
    flex-direction: row;
  }
`

export const IcChild = styled.img`
  background-color: ${props => props.theme.color.gray300};
  
  @media (max-width: 599px) {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 36px;
  }

  @media (min-width: 600px) {
    padding: 10px;
    width: 26px;
    height: 26px;
    border-radius: 23px;
  }
`

export const IcChildImage = styled.img`
  background-color: ${props => props.theme.color.gray300};
 

  @media (max-width: 599px) {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 36px;
  }

  @media (min-width: 600px) {
    width: 26px;
    height: 26px;
    border-radius: 23px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 599px) {
    margin-bottom: 40px;
  }
  @media (min-width: 600px) {
    margin-bottom: 60px;
  }
`
export const Title = styled.h3`
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 599px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 26.4px */
    letter-spacing: -0.48px;
  }

  @media (min-width: 600px) {
    margin-top: 56px;
  }
`

export const CellText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: 599px) {
    display: flex;
    flex-grow: 1;
  }

  @media (min-width: 600px) {
  }

`

export const CellBubble = styled.p`
  display: flex;
  padding: 4px 8px;
  min-width: 47px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${props => props.theme.color.violet700};
  background: ${props => props.theme.color.violet200};
`
export const TableCardRows = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* For Webkit browsers */
  }

  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    padding-top: 8px;
  }
`

export const Headline = styled.h4`

  @media (max-width: 599px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.36px;
    margin: 16px;
  }

  @media (min-width: 600px) {

  }
`
export const ResultsCell = styled(Cell)`
  justify-content: space-between;
  cursor: pointer;
`

export const ResultsCellCentered = styled(ResultsCell)`
  align-items: center;
  justify-content: center;
`

export const ResultsCellRight = styled(ResultsCell)`
  box-sizing: border-box;
  align-items: end;
  justify-content: right;
  padding: 0 52px;
`


