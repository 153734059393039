import styled from "styled-components";
import imgRecover from "../../../assets/images/img_recovery_background.jpg";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: #FFF;
`;

export const Background = styled.div`
  position: relative;
  flex: 6;
  background-image: url(${imgRecover});
  background-size: cover;
  background-position: center;

  @media (max-width: 599px) {
    display: none;
  }
`;

export const BackgroundWave = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  object-fit: contain;
  height: 100%;
`

export const LoginFormContainer = styled.div`
  flex-direction: column;
  gap: 48px;
  border-radius: 40px;
  flex: 4;
  justify-content: center;

  display: flex;
  align-items: center;
  padding: 20px;
`;

export const RecoverFormWrapper = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    gap: 48px;
    width: 400px;
  }
`;

export const HeaderPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
`

export const HeaderTitle = styled.h3`
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 599px) {
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;
  }
  @media (min-width: 600px) {
  }
`

export const HeaderSubtitle = styled.p`
  color: ${props => props.theme.color.gray1000};

  @media (max-width: 599px) {
    font-size: 14px;
    letter-spacing: -0.28px;
  }
  @media (min-width: 600px) {
    font-size: 20px;
  }
`

export const FormPart = styled.div`
  display: flex;
  justify-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 24px;
`

export const LinkButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  gap: 4px;
`

export const RecoveryText = styled.p`
  text-decoration: none;

  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: 600px) {
  }
`;

export const RecoveryLink = styled.a`
  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: 600px) {
  }
`;
