import {styled} from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  padding: 16px 40px;
  position: relative;
  width: 100%;
`;

export const Logo = styled.img`
  @media (max-width: 1023px) {
    display: none;
  }
  cursor: pointer;
`

export const LogoMobile = styled.p`
  @media (min-width: 1024px) {
    display: none;
  }
  cursor: pointer;

  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  
`

export const LogoContainer = styled.div`
  padding-top: 8px;
`


export const Navbar = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  gap: 48px;
  justify-content: flex-end;
  position: relative;
  
  @media (max-width: 1023px) {
    display: none;
  }
`

export const LinkButton = styled.p`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
`

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: flex-end;
  position: relative;
`

export const LoginButton = styled.p`
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 160%;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 40px;
  border: 1px solid ${props => props.theme.color.primary};
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);
  color: ${props => props.theme.color.primary};
`

export const RegisterButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  width: fit-content;
  padding: 8px 16px 8px 24px;
  align-items: center;
  gap: 6px;
  border-radius: 40px;
  box-shadow: 0 1px 2px 0 rgba(105, 81, 255, 0.05);
  background: #4F40B9;
`

export const RegisterButtonText = styled.p`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 160%;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
