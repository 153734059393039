import React from "react";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../config/paths";
import imgDoctor from "../../../assets/images/img_graphic_1.svg";
import imgThread1 from "../../../assets/images/bg_thread_8.svg";
import imgThread2 from "../../../assets/images/bg_thread_9.svg";
import ButtonSecondary from "../../Shared/Button/ButtonSecondary/ButtonSecondary";
import imgDoctor2 from "../../../assets/images/img_graphic_2.svg";
import imgDecoration1 from "../../../assets/images/img_decoration_1.svg";
import imgDecoration2 from "../../../assets/images/img_decoration_2.svg";
import ButtonUpCircle from "../../Shared/Button/ButtonUpCircle/ButtonUpCircle";
import {
    CardBackground,
    CardSmall,
    CardTitle1,
    CardTitle2,
    Container,
    Decoration1Graphic,
    Decoration2Graphic,
    DoctorGraphic,
    DoctorGraphic2,
    SmallButtonStyled,
    SmallButtonStyledMobile,
    Thread1Graphic,
    Thread2Graphic
} from "./TopLeftContent.styles";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import IcUpRight from "../../Shared/Icons/IcUpRight";
import SvgFill from "../../Shared/Icons/SvgFill";
import theme from "../../../Theme";

const TopLeftContent: React.FC = () => {
    const navigate = useNavigate()
    return (
        <Container>
            <CardSmall onClick={() => {
                navigate(Paths.DIAGNOSIS_NEW)
            }}>
                <DoctorGraphic src={imgDoctor}/>
                <Thread1Graphic src={imgThread1}/>
                <Thread2Graphic src={imgThread2}/>
                <CardBackground/>
                <CardTitle1>Skorzystaj z diagnozy AI</CardTitle1>
                <SmallButtonStyledMobile>
                    <ButtonPrimary
                        title="Rozpocznij"
                        onClick={() => navigate(Paths.DIAGNOSIS_NEW)}
                        icon={<SvgFill color={theme.color.gray100}>
                            <IcUpRight/>
                        </SvgFill>}
                    />
                </SmallButtonStyledMobile>
                <SmallButtonStyled>
                    <ButtonSecondary title="Rozpocznij"/>
                </SmallButtonStyled>
            </CardSmall>
            <CardSmall onClick={() => {
                navigate(Paths.DOCTORS_ALL)
            }}>
                <DoctorGraphic2 src={imgDoctor2}/>
                <Decoration1Graphic src={imgDecoration1}/>
                <Decoration2Graphic src={imgDecoration2}/>
                <CardBackground/>
                <CardTitle2>Umów wizytę</CardTitle2>
                <SmallButtonStyledMobile>
                    <ButtonUpCircle/>
                </SmallButtonStyledMobile>
                <SmallButtonStyled>
                    <ButtonUpCircle/>
                </SmallButtonStyled>
            </CardSmall>
        </Container>
    )
}

export default TopLeftContent