import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Title, TitleWrapper} from "../PatientAppointments/PatientAppointmentsList.styles";
import icBack from "../../assets/images/ic_back.svg";
import styled from "styled-components";
import {Card} from "../Shared/Div/Card";
import dialogIcon from "../../assets/images/ic_chat.svg";
import {AiDiagnosis} from "../../state/aiDiagnoses/types";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../state/store";
import {fetchPatientDiagnoses} from "../../state/aiDiagnoses/actions";
import {formatDateApi, formatDateToDMMMMYYYYPolish, parseDate, parseDateTime} from "../../config/utils";
import {Paths} from "../../config/paths";

export const BackIcon = styled.img`
`

export const BackButton = styled.div`
  margin-top: 16px;
  cursor: pointer;
  display: inline-flex;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1;
`

export const BackText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-weight: 400;
  line-height: 150%;
`
export const ContentWrapper = styled.div`
  padding: 10px 40px 40px;
  min-height: 100vh;
  box-sizing: border-box;
`;
export const CardBig = styled(Card)`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
`
export const CardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 16px;
  border: 2px solid ${props => props.theme.color.gray400};
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 10px 20px 0 rgba(241, 241, 241, 0.25);
`

export const ClickableCardSection = styled(CardSection)`
  cursor: pointer;
`

export const CardRowGapBig = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`
export const CardRowGapSmall = styled(CardRowGapBig)`
  gap: 8px;
  width: 100%;
`

export const PlaceholderText = styled.p`
  color: ${props => props.theme.color.gray900};
  line-height: 150%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CardTitle = styled.h4`
`

export const CardSubtitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 16px;
`

export const CardSectionTitle = styled.h5`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const AiDiagnosesSection = styled.div`
`

interface RouteParams {
    [key: string]: string | undefined;

    childId: string;
}

interface Section {
    date: Date,
    diagnoses: AiDiagnosis[]
}

const groupByDate = (items: AiDiagnosis[] | null): Section[] => {
    if (!items) {
        return []
    }

    const grouped: { [key: string]: AiDiagnosis[] } = {};

    items.forEach(diagnosis => {
        const key = formatDateApi(parseDate(diagnosis.date))
        if (!grouped[key]) {
            grouped[key] = [];
        }
        grouped[key].push(diagnosis);
    });

    return Object.keys(grouped).map(date => ({
        date: parseDate(date)!,
        diagnoses: grouped[date]
    }));
};
const AiDiagnosesList: React.FC = () => {

    const navigate = useNavigate()

    const {childId: idString} = useParams<RouteParams>();
    const childId = parseInt(idString ?? "", 10);

    const [aiDiagnoses, setAiDiagnoses] = useState<AiDiagnosis[] | null>(null)
    const [loading, setLoading] = useState(false)

    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        if (!aiDiagnoses && !loading) {
            setLoading(true)
            dispatch(fetchPatientDiagnoses(childId))
                .then(elements => setAiDiagnoses(elements))
                .finally(() => setLoading(false))
        }
    }, [aiDiagnoses, loading]);

    const sections: Section[] = groupByDate(aiDiagnoses)

    const renderDiagnosis = (diagnosis: AiDiagnosis, index: number) => {
        return (
            <ClickableCardSection
                key={index}
                onClick={() => {
                    navigate(Paths.AI_DIAGNOSIS_DETAILS
                        .replace(":childId", diagnosis.childId.toString())
                        .replace(":id", diagnosis.id.toString())
                    )
                }}
            >
                <CardSectionTitle>Diagnoza AI</CardSectionTitle>
                <CardRowGapSmall>
                    <img src={dialogIcon} alt="Diagnoza AI"/>
                    <PlaceholderText>{diagnosis.diagnosis.trimStart()}</PlaceholderText>
                </CardRowGapSmall>
            </ClickableCardSection>
        )
    }

    const renderSection = (section: Section, index: number) => (
        <AiDiagnosesSection key={index}>
            <CardSubtitle>{formatDateToDMMMMYYYYPolish(section.date)}</CardSubtitle>
            {section.diagnoses.map((diagnosis, index) => renderDiagnosis(diagnosis, index))}
        </AiDiagnosesSection>
    )

    return (
        <ContentWrapper>
            <TitleWrapper>
                <Title>Diagnozy AI</Title>
                <BackButton onClick={() => navigate(-1)}>
                    <BackIcon src={icBack}/>
                    <BackText>Wróć</BackText>
                </BackButton>
            </TitleWrapper>
            <CardBig>
                <CardContent>
                    <CardTitle>Wszystkie diagnozy AI</CardTitle>
                    {sections.map((section, index) => renderSection(section, index))}
                </CardContent>
            </CardBig>
        </ContentWrapper>
    )
}

export default AiDiagnosesList