import React, {useState} from "react";
import {Input} from "./HeroFormInput";
import ButtonSecondary from "../../Shared/Button/ButtonSecondary/ButtonSecondary";
import {
    Divider20,
    HeroFormHeadline,
    HeroFormHeadlineText,
    HeroFormSubtitleText,
    HeroFormSubtitleText2,
    HeroFormWrapper,
    HeroSection,
    HeroWrapper
} from "./HeroForm.styles";
import {Paths} from "../../../config/paths";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import theme from "../../../Theme";

const ButtonSecondaryLight = styled.div`
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
    display: block;
  }
`

const ButtonSecondaryDark = styled.div`
  margin: auto;
  margin-top: 12px;

  @media (max-width: 599px) {
    display: block;
  }
  @media (min-width: 600px) {
    display: none;
  }
`

const HeroForm: React.FC = () => {

    const [selected, setSelected] = useState<string[]>([])
    const navigate = useNavigate()

    return (
        <HeroWrapper>
            <HeroSection>
                <HeroFormWrapper>
                    <HeroFormHeadline>
                        <HeroFormHeadlineText>Zadbaj o zdrowie swojego dziecka z AI</HeroFormHeadlineText>
                        <HeroFormSubtitleText>Uzyskaj natychmiastową diagnozę AI wraz z zaleceniami, a następnie umów
                            wizytę u odpowiedniego lekarza specjalisty.</HeroFormSubtitleText>
                        <HeroFormSubtitleText2>Co dolega Twojemu dziecku?</HeroFormSubtitleText2>
                        <Input selected={selected} setSelected={setSelected}/>
                        <Divider20/>
                        <ButtonSecondaryLight>
                            <ButtonSecondary
                                title=" Otrzymaj diagnozę AI"
                                onClick={() => navigate(Paths.DIAGNOSIS_NEW + "?symptoms=[" + selected.join(",") + "]")}
                            />
                        </ButtonSecondaryLight>
                        <ButtonSecondaryDark>
                            <ButtonSecondary
                                backgroundColor={theme.color.violet400}
                                fontColor={theme.color.gray1000}
                                title=" Otrzymaj diagnozę AI"
                                icon={(<div style={{width: 8}}></div>)}
                                onClick={() => navigate(Paths.DIAGNOSIS_NEW + "?symptoms=[" + selected.join(",") + "]")}
                            />
                        </ButtonSecondaryDark>
                        <Divider20/>
                    </HeroFormHeadline>
                </HeroFormWrapper>

            </HeroSection>
        </HeroWrapper>
    );
};

export default HeroForm;