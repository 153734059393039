import React from "react";
import {Button} from "./BigButtonOutline.styles";

const BigButtonOutline: React.FC<{ title: String, onClick: () => void }> = ({title, onClick}) => {
    return (
        <Button type="submit" onClick={(e) => {
            e.preventDefault();
            onClick()
        }}>{title}</Button>
    )
}

export default BigButtonOutline