import {Appointment} from "./types";
import api from "../../config/api";
import {Endpoints} from "../../config/endpoints";

export interface CreateAppointmentPayload {
    parentName: string,
    parentPhone: string,
    doctorId: number,
    childId: number,
    startTime: Date,
}

export interface PatientAppointment {
    id: number
    status: string
    parent: string
    phone: string
    startTime: string
    aiDiagnosisId?: string
}

export const fetchPatientAppointments = (childId: number) => async () => {
    try {
        const url = Endpoints.CHILDREN_APPOINTMENTS.replace(":id", childId.toString())
        const response = await api.get(url)
        if (response.status != 200) {
            return Promise.reject()
        }
        const data: PatientAppointment[] = response.data
        return Promise.resolve(data)
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject(e)
    }
}

export const createAppointment = (payload: CreateAppointmentPayload) => async (dispatch: any): Promise<Awaited<number>> => {
    try {
        const body = JSON.stringify(payload)
        const createResponse = await api.post(Endpoints.APPOINTMENTS_CREATE, body, {
            headers: {'Content-Type': 'application/json'},
        })
        const fetchAllResponse = await api.get(Endpoints.APPOINTMENTS_GET_ALL)
        dispatch({
            type: "APPOINTMENTS_SUCCESS",
            appointments: fetchAllResponse.data,
        })
        return Promise.resolve(createResponse.data.appointmentId)
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject()
    }
}

export const fetchAppointments = () => async (dispatch: any): Promise<Awaited<boolean>> => {
    try {
        const response = await api.get(Endpoints.APPOINTMENTS_GET_ALL)
        dispatch({
            type: "APPOINTMENTS_SUCCESS",
            appointments: response.data,
        })
        return Promise.resolve(true)
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.resolve(false)
    }
}

export const fetchPaymentRedirect = (appointmentId: number) => async (): Promise<Awaited<string>> => {
    try {
        const response = await api.get(Endpoints.PAYMENTS_REDIRECT + "?appointmentId=" + appointmentId)
        return Promise.resolve(response.data)
    } catch (e) {
        console.log("error fetching appointments because of an exception", e)
        return Promise.reject()
    }
}

interface FetchAppointmentsSuccess {
    type: "APPOINTMENTS_SUCCESS",
    appointments: Appointment[],
}

export type Action = FetchAppointmentsSuccess