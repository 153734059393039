import React, {useState} from "react";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import TimeSlotPicker from "../../Doctors/DoctorsDetails/Calendar/TimeSlotPicker/TimeSlotPicker";
import {Buttons, CardWrapper, LinkButton} from "./TimeSlotPickerCard.styles";

const CalendarPickerCard: React.FC<{
    date: Date,
    onSubmit: (date: Date) => void,
    onCancel: () => void,
    disabled: Date[]
}> = ({
          date,
          onSubmit,
          onCancel,
          disabled
      }) => {
    const [appointmentDate, setAppointmentDate] = useState<Date>(date)
    const [appointmentTime, setAppointmentTime] = useState<Date | null>(null)

    return (
        <CardWrapper>
            <TimeSlotPicker
                onTimeSlotClick={(time) => setAppointmentTime(time)}
                onDateClick={(date) => setAppointmentDate(date)}
                selectedTime={appointmentTime}
                currentDate={appointmentDate}
                disabled={disabled}/>
            <Buttons>
                <LinkButton onClick={() => onCancel()}>Wróć</LinkButton>
                <ButtonPrimary
                    title="Potwierdź i przejdź dalej"
                    onClick={() => {
                        if (!appointmentTime) {
                            return
                        }
                        onSubmit(appointmentTime)
                    }}
                />
            </Buttons>
        </CardWrapper>
    )
}

export default CalendarPickerCard