import React, {useEffect, useState} from "react";
import ConfirmedAppointments from "../../DoctorHome/ConfirmedAppointments/ConfirmedAppointments";
import {cancelAppointment, fetchConfirmedAppointments} from "../../../state/doctorAppointments/actions";
import {AppDispatch} from "../../../state/store";
import {useDispatch} from "react-redux";
import DoctorInfoWidget from "../../Shared/DoctorInfoWidget/DoctorInfoWidget";
import {useFetchUserDoctor} from "../../../state/user/helpers";
import {Card, Container, Title, TitleWrapper} from "./UpcomingVisits.styles";
import {ConfirmedAppointment} from "../../../state/doctorAppointments/type";

const UpcomingVisits: React.FC = () => {

    const doctor = useFetchUserDoctor();
    const [confirmed, setConfirmed] = useState<ConfirmedAppointment[] | null>(null);
    const [isLoadingConfirmed, setIsLoadingConfirmed] = useState(false)

    const [, setLoadingAction] = useState(false)
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (!isLoadingConfirmed && confirmed === null) {
            doFetchConfirmedAppointments();
        }
    }, [confirmed, dispatch]);

    const doFetchConfirmedAppointments = () => {
        setIsLoadingConfirmed(true)
        dispatch(fetchConfirmedAppointments())
            .then(fa => setConfirmed(fa))
            .catch(() => setConfirmed(null))
            .finally(() => setIsLoadingConfirmed(false));
    }

    const handleCancelAppointment = (id: number) => {
        setLoadingAction(true)
        dispatch(cancelAppointment(id))
            .then(() => {
                doFetchConfirmedAppointments()
            })
            .catch(e => console.log("could not confirm appointment", e))
            .finally(() => setLoadingAction(false))
    }

    return (
        <Container>
            <TitleWrapper>
                <Title>Wizyty</Title>
                <DoctorInfoWidget doctor={doctor}/>
            </TitleWrapper>
            <Card>
                <ConfirmedAppointments
                    appointments={confirmed || []}
                    onCancelClicked={(id) => handleCancelAppointment(id)}/>
            </Card>
        </Container>
    )
}

export default UpcomingVisits