import styled from "styled-components";

export const Card = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  border-radius: 16px;
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 10px 20px 0 rgba(233, 218, 209, 0.26);


  @media (max-width: 599px) {
    align-items: center;
    justify-content: space-between;
    padding: 16px 8px;
    gap: 16px;
  }

  @media (min-width: 600px) {
    height: 268px;
    padding: 50px;

    justify-content: space-between;
    align-items: center;
  }
`

export const LinkButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  
  @media (max-width: 599px) {
    flex-grow: 1;
    justify-content: space-between;
  }

  @media (min-width: 600px) {
    
  }
`