import React from "react";
import {
    ButtonWrapper,
    HeaderPart,
    HeaderSubtitle,
    HeaderTitle,
    LinkButtonWrapper,
    RecoveryLink,
    RecoveryText,
    RegisterSuccessFormWrapper
} from "./RecoverSuccessForm.styles";

const RecoverSuccessForm: React.FC<{
    email: string | null,
    navigateLogin: () => void
}> = ({email, navigateLogin}) => {

    const renderRegisterSuccessForm = () => {
        return <RegisterSuccessFormWrapper>
            <HeaderPart>
                <HeaderTitle>Sprawdź adres email</HeaderTitle>
                <HeaderSubtitle>Wysłaliśmy email z linkiem do aktywacji konta na {email}</HeaderSubtitle>
            </HeaderPart>
            <ButtonWrapper>
                <div>
                    <LinkButtonWrapper>
                        <RecoveryText>Zamiast tego</RecoveryText>
                        <RecoveryLink onClick={navigateLogin}>Wróć do logowania</RecoveryLink>
                    </LinkButtonWrapper>
                </div>
            </ButtonWrapper>
        </RegisterSuccessFormWrapper>;
    }

    return renderRegisterSuccessForm()
}

export default RecoverSuccessForm