import React, {useEffect, useState} from "react";
import ConfirmedAppointments from "./ConfirmedAppointments/ConfirmedAppointments";
import {useFetchUser, useFetchUserDoctor} from "../../state/user/helpers";
import {
    cancelAppointment,
    confirmAppointment,
    fetchConfirmedAppointments,
    fetchUnconfirmedAppointments,
} from "../../state/doctorAppointments/actions";
import {AppDispatch} from "../../state/store";
import {useDispatch} from "react-redux";
import UnconfirmedAppointments from "./UnconfirmedAppointments/UnconfirmedAppointments";
import DoctorInfoWidget from "../Shared/DoctorInfoWidget/DoctorInfoWidget";
import {Container, ContentRow, Title, TitleWrapper} from "./DoctorHome.style";
import {ConfirmedAppointment, UnconfirmedAppointment} from "../../state/doctorAppointments/type";


const DoctorHome: React.FC<{
    navigateToUpcomingDoctorsAppointments: () => void
    navigateToUnconfirmedDoctorsAppointments: () => void
}> = (
    {
        navigateToUpcomingDoctorsAppointments,
        navigateToUnconfirmedDoctorsAppointments,
    }
) => {
    const dispatch: AppDispatch = useDispatch();

    const user = useFetchUser();
    const doctor = useFetchUserDoctor();

    const [confirmed, setConfirmed] = useState<ConfirmedAppointment[] | null>(null);
    const [isLoadingConfirmed, setIsLoadingConfirmed] = useState(false)

    const [unconfirmed, setUnconfirmedAppointments] = useState<UnconfirmedAppointment[] | null>(null);
    const [isLoadingUnconfirmed, setIsLoadingUnconfirmed] = useState(false)

    const [, setLoadingAction] = useState(false)
    const doFetchUnconfirmedAppointments = () => {
        setIsLoadingUnconfirmed(true)
        dispatch(fetchUnconfirmedAppointments())
            .then(fetchedAppointments => {
                setUnconfirmedAppointments(fetchedAppointments)
            })
            .catch(e => {
                console.log("Error fetching user", e)
                setUnconfirmedAppointments(null)
            })
            .finally(() => setIsLoadingUnconfirmed(false));
    }

    const doFetchConfirmedAppointments = () => {
        setIsLoadingConfirmed(true)
        dispatch(fetchConfirmedAppointments())
            .then(fa => setConfirmed(fa))
            .catch(() => setConfirmed(null))
            .finally(() => setIsLoadingConfirmed(false));
    }


    useEffect(() => {
        if (!isLoadingUnconfirmed && unconfirmed === null) {
            doFetchUnconfirmedAppointments();
        }
    }, [unconfirmed, dispatch]);

    useEffect(() => {
        if (!isLoadingConfirmed && confirmed === null) {
            doFetchConfirmedAppointments();
        }
    }, [confirmed, dispatch]);


    const handleConfirmAppointment = (id: number) => {
        setLoadingAction(true)
        dispatch(confirmAppointment(id))
            .then(() => {
                doFetchUnconfirmedAppointments()
                doFetchConfirmedAppointments()
            })
            .catch(e => console.log("could not confirm appointment", e))
            .finally(() => setLoadingAction(false))
    }

    const handleCancelAppointment = (id: number) => {
        setLoadingAction(true)
        dispatch(cancelAppointment(id))
            .then(() => {
                doFetchUnconfirmedAppointments()
                doFetchConfirmedAppointments()
            })
            .catch(e => console.log("could not confirm appointment", e))
            .finally(() => setLoadingAction(false))
    }

    return (
        <Container>
            <TitleWrapper>
                <Title>Witaj {user?.email || ""}</Title>
                <DoctorInfoWidget doctor={doctor}/>
            </TitleWrapper>
            <ContentRow>
                <ConfirmedAppointments
                    appointments={confirmed || []}
                    onCancelClicked={(id) => handleCancelAppointment(id)}
                    onShowAllClicked={navigateToUpcomingDoctorsAppointments}
                />
                <UnconfirmedAppointments
                    appointments={unconfirmed || []}
                    onConfirmClicked={(id) => handleConfirmAppointment(id)}
                    onCancelClicked={() => {
                        console.log("cancel clicked")
                    }}
                    onShowAllClicked={navigateToUnconfirmedDoctorsAppointments}
                />
            </ContentRow>
        </Container>
    );
}

export default DoctorHome;