import React from "react";
import {useNavigate} from "react-router-dom";
import {useFetchArticles} from "../../state/articles/helpers";
import {Article, Part, Section} from "../../state/articles/type";
import {
    ArticleColumn,
    ArticleDate,
    ArticleImage,
    ArticleSection,
    ArticleTitle,
    B,
    BackArrowInnerWrapper,
    BackArrowWrapper,
    H3,
    P,
    TagBubble,
    Tags
} from "./ArticleDetails.styles";
import BackArrowButtonTitled from "../Shared/Button/BackArrowButtonTitled/BackArrowButtonTitled";
import {formatDateToDDMMYYYYWithDelimiter, parseDate} from "../../config/utils";


const ArticleDetailsContent: React.FC<{ id: number }> = ({id}) => {

    const navigate = useNavigate()


    const articles = useFetchArticles()

    const article = articles?.find(el => el.id === id)

    const renderPart = (part: Part, index: number) => {
        switch (part.type) {
            case "paragraph":
                return (<P key={`part-${index}`}>{part.text}</P>)
            case "title":
                return (<H3 key={`part-${index}`}>{part.text}</H3>)
            case "bold":
                return (<B key={`part-${index}`}>{part.text}</B>)
            default:
                return null
        }
    }

    const renderSection = (section: Section, index: number) => {
        return (
            <ArticleSection key={`section-${index}`}>
                {section.parts.map((part, index) => renderPart(part, index))}
            </ArticleSection>
        )
    }

    const renderSections = () => {
        const article: Article | undefined = articles.find(a => a.id === id)
        if (!article) {
            return
        }
        return article.sections.map((section, index) => renderSection(section, index));
    }

    const renderBubbles = () => {
        const tags = article?.tags
        if (!tags) {
            return null
        }
        return tags.map((tag, index) => (<TagBubble key={`bubble-${index}`}>{tag}</TagBubble>));
    }

    const renderArticleImage = (image?: string) => {
        if (!image) {
            return null
        }
        return <ArticleImage src={process.env.REACT_APP_API_BASE_URL + image}/>;
    };

    return (
        <>
            <BackArrowWrapper>
                <BackArrowInnerWrapper>
                    <BackArrowButtonTitled onBackClicked={() => navigate(-1)}/>
                </BackArrowInnerWrapper>
            </BackArrowWrapper>
            <Tags>{renderBubbles()}</Tags>
            <ArticleTitle>{article?.title || ""}</ArticleTitle>
            <ArticleDate>{formatDateToDDMMYYYYWithDelimiter(parseDate(article?.createdAt || null), "/")}</ArticleDate>
            {renderArticleImage(article?.image)}
            <ArticleColumn>{renderSections()}</ArticleColumn>
        </>
    )
}

export default ArticleDetailsContent