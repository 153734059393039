import styled from "styled-components";

export const TextContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

export const TextContainerText = styled.div`
  color: ${props => props.theme.color.gray1000};
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.4px;

  @media (max-width: 599px) {
    font-size: 14px;
  }

  @media (min-width: 600px) {
    font-size: 16px;
  }
`
