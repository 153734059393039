import React from 'react';

const IcCross: React.FC = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.9998 6.50006C17.8123 6.31259 17.558 6.20728 17.2928 6.20728C17.0277 6.20728 16.7733 6.31259 16.5858 6.50006L11.9998 11.0861L7.41382 6.50006C7.22629 6.31259 6.97198 6.20728 6.70682 6.20728C6.44165 6.20728 6.18735 6.31259 5.99982 6.50006C5.81235 6.68759 5.70703 6.9419 5.70703 7.20706C5.70703 7.47223 5.81235 7.72653 5.99982 7.91406L10.5858 12.5001L5.99982 17.0861C5.81235 17.2736 5.70703 17.5279 5.70703 17.7931C5.70703 18.0582 5.81235 18.3125 5.99982 18.5001C6.18735 18.6875 6.44165 18.7928 6.70682 18.7928C6.97198 18.7928 7.22629 18.6875 7.41382 18.5001L11.9998 13.9141L16.5858 18.5001C16.7733 18.6875 17.0277 18.7928 17.2928 18.7928C17.558 18.7928 17.8123 18.6875 17.9998 18.5001C18.1873 18.3125 18.2926 18.0582 18.2926 17.7931C18.2926 17.5279 18.1873 17.2736 17.9998 17.0861L13.4138 12.5001L17.9998 7.91406C18.1873 7.72653 18.2926 7.47223 18.2926 7.20706C18.2926 6.9419 18.1873 6.68759 17.9998 6.50006Z"
            fill="#161618"/>
    </svg>
);

export default IcCross;

