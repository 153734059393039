import styled from "styled-components";

export const BackIcon = styled.img`
`

export const BackButton = styled.div`
  position: absolute;
  top: 20px;
  cursor: pointer;
  display: inline-flex;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1;
  
  @media (max-width: 599px) {
    left: 0;
  }

  @media (min-width: 600px) {
    left: 20px;

  }
`

export const BackText = styled.p`
  
  @media (max-width: 599px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  @media (min-width: 600px) {
    font-weight: 400;
    line-height: 150%;
  }
`
