import {Endpoints} from "../../config/endpoints";
import {Interview, Question, QuestionsSuccess} from "./types";
import api from "../../config/api";

export const fetchQuestions = () => async (dispatch: any): Promise<Awaited<Question[] | "FAILURE">> => {
    try {
        const response = await fetch(Endpoints.INTERVIEW_QUESTIONS);
        const data = await response.json();
        const success: QuestionsSuccess = {
            questions: data,
            type: "QUESTIONS_SUCCESS"
        }
        dispatch(success)
        if (!response.ok) {
            return Promise.resolve("FAILURE")
        }
        return Promise.resolve(data)
    } catch (error: any) {
        return Promise.resolve("FAILURE")
    }
}

export const submitInterview = (interview: Interview) => async (): Promise<Awaited<number>> => {
    try {
        const url = Endpoints.INTERVIEW_SUBMIT
        const body = JSON.stringify(interview)
        const response = await api.post(url, body, {
            timeout: 120000, // 2 minutes
            headers: {'Content-Type': 'application/json'},
        })
        if (response.status !== 200) {
            return Promise.reject()
        }
        const data: number = response.data
        return Promise.resolve(data)
    } catch (e) {
        return Promise.reject()
    }
}