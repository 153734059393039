import styled from "styled-components";
import {Card} from "../../Shared/Div/Card";

export const HeaderCell = styled.p<{ $alignment: "left" | "center" | "right" }>`
  width: 20%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
  justify-content: ${props => props.$alignment};
  align-items: ${props => props.$alignment};
  text-align: ${props => props.$alignment};
`

export const Title = styled.h3`
  padding-top: 56px;
  margin-bottom: 40px;
  font-size: 36px;
`

export const Container = styled.div`
  box-sizing: border-box;
  padding: 0 40px;
  min-height: 100vh;
`

export const Headline = styled.h4`
  margin-bottom: 8px;
`

export const TableCardRows = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 675px;
  padding-top: 16px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* For Webkit browsers */
  }
`

export const HeaderTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  padding: 8px 16px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray300};
`

export const TableCardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`
export const CardBig = styled(Card)`
  position: relative;
  width: 100%;
  min-height: 800px;
  flex-shrink: 0;
  overflow: hidden;
`
export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const IcChild = styled.img`
  padding: 10px;
  background-color: ${props => props.theme.color.gray300};
  width: 26px;
  height: 26px;
  border-radius: 23px;
`

export const IcChildImage = styled.img`
  background-color: ${props => props.theme.color.gray300};
  width: 46px;
  height: 46px;
  border-radius: 23px;
`

export const DoctorAvatarContainer = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  z-index: 1;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DoctorAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const DoctorDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`

export const DoctorDetailsTexts = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 25px;

`

export const DoctorDetailsSpecialization = styled.p`
  color: ${props => props.theme.color.gray700};
`

export const DoctorDetailsName = styled.h5`
  line-height: normal;
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
`
export const Cell = styled.div<{ $alignment: 'left' | "center" | "right" }>`
  width: 20%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
  display: flex;
  flex-direction: row;
  
  gap: 8px;
  align-items: center;
  justify-content: ${props => props.$alignment};
`

export const CellText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const ClickableCell = styled(Cell)`
  cursor: pointer;
`

export const Alpha = styled.div<{ $alpha: number }>`
  opacity: ${props => props.$alpha};
`