import {Action, QuestionsState} from "./types";
import {ProfileAction} from "../profile/actions";

const initialState: QuestionsState = {
    questions: null,
}

export const interviewReducer = (state = initialState, action: Action | ProfileAction): QuestionsState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "QUESTIONS_SUCCESS":
            return {
                ...state,
                questions: action.questions,
            };
        default:
            return state
    }
}
