import styled from "styled-components";
import icChevronDown from "../../../../../assets/images/ic_chevron_down.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.gray100};

  @media (max-width: 599px) {
    padding: 24px;
    align-items: center;
    align-content: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;

    border-radius: 16px;
    border: 1px solid ${props => props.theme.color.gray400};
    
    flex-grow: 1;

    box-shadow: 0 5px 10px 2px #F1F1F1;
  }

  @media (min-width: 600px) {
    width: 100%;
    padding: 64px 24px;
    gap: 64px;
    min-width: 800px;
    border-radius: 0 16px 16px 16px;
    align-items: flex-start;

  }
`

export const RightContainer = styled.div`
  width: 40%;
  padding: 16px;
  background-color: ${props => props.theme.color.gray100};
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;


  @media (max-width: 599px) {
    margin-bottom: 24px;
  }

  @media (min-width: 600px) {
    

  }
`

export const SectionTitleText = styled.h5`
  color: ${props => props.theme.color.gray1000};
  
  @media (max-width: 599px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%; 
    letter-spacing: -0.32px;
  }

  @media (min-width: 600px) {
  }

`

export const Row = styled.div`
  width: 100%;
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;


  @media (max-width: 599px) {
    flex-direction: column;
    flex-grow: 1;
    
  }

  @media (min-width: 600px) {
    flex-direction: row;
    
  }

`

export const Column1 = styled.div`
  
  @media (max-width: 599px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
  }

  @media (min-width: 600px) {
    width: 290px;
  }
`

export const Column2 = styled.div`

  @media (max-width: 599px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
  }

  @media (min-width: 600px) {
  }
`

export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;


  @media (max-width: 599px) {

  }

  @media (min-width: 600px) {
    align-items: flex-start;

  }
`

export const RightContainerTitle = styled.h4`
  text-align: center;

`

export const Select = styled.select`
  box-sizing: border-box;
  border: none;
  color: ${props => props.theme.color.violet600};

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */


  &:focus {
    border: none;
  }

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 30px; /* Space for the custom arrow */
  background: transparent;
  width: 100%;
`

export const Option = styled.option`
  width: 100%;
  text-align: center;
`;


export const SelectContainer = styled.div`
  margin-top: 4px;
  position: relative;
  display: inline-block;

  &::after {
    content: url(${icChevronDown});
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-40%);
    pointer-events: none;
    color: ${props => props.theme.color.violet600};
  }
`;

export const Chart = styled.p`
  text-align: center;
  margin: 200px 0;
`
