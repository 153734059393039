import React from "react";
import {useTheme} from "styled-components";
import {BackButton, SvgFillPositioned} from "./CalendarSideWidget.styles";
import IcChevronLeft from "../../../../Shared/Icons/IcChevronLeft";
import TimeSlotPicker from "../TimeSlotPicker/TimeSlotPicker";
import CalendarPicker from "../CalendarPicker/CalendarPicker";

interface Props {
    day: Date | null
    setDay: (newValue: Date | null) => void
    time: Date | null
    setTime: (newValue: Date | null) => void
    disabled: Date[]
}

const CalendarDateTimePicker: React.FC<Props> = ({day, setDay, time, setTime, disabled}) => {

    const theme = useTheme()

    const renderWidgetPicker = () => {
        if (day) {
            return (
                <>
                    <BackButton onClick={() => {
                        setDay(null)
                        setTime(null)
                    }}>
                        <SvgFillPositioned color={theme.color.gray100}>
                            <IcChevronLeft/>
                        </SvgFillPositioned>
                    </BackButton>
                    <TimeSlotPicker
                        onTimeSlotClick={(time) => setTime(time)}
                        onDateClick={(date) => setDay(date)}
                        selectedTime={time}
                        currentDate={day}
                        disabled={disabled}
                    />
                </>
            )
        }
        return <CalendarPicker onDateSelected={(date) => {
            setDay(date)
        }}/>;
    }

    return renderWidgetPicker()
}

export default CalendarDateTimePicker