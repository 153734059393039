import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppState} from '../../../state/store';
import imgWave from '../../../assets/images/bg_wave_login.png'
import {Background, BackgroundWave, Container, LoginFormContainer} from "./RegisterSuccess.styles";
import RegisterSuccessForm from "./RegisterSuccessForm";

const RegisterSuccess: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();


    const queryParams = new URLSearchParams(location.search);

    const email = queryParams.get('email');
    const password = queryParams.get('password');

    const isLoggedIn = useSelector((state: AppState) => state.profile.profile != null);

    const navigateBack = () => {
        navigate("/register", {replace: true})
    }

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);


    return (
        <Container>
            <Background>
                <BackgroundWave src={imgWave}/>
            </Background>
            <LoginFormContainer>
                <RegisterSuccessForm
                    email={email}
                    password={password}
                    navigateBack={navigateBack}
                />
            </LoginFormContainer>
        </Container>
    );
}

export default RegisterSuccess;