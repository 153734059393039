import {DoctorDiagnosesState} from "./type";
import {ProfileAction} from "../profile/actions";
import {Action} from "./actions";

const initialState: DoctorDiagnosesState = {
    diagnoses: null
};

export const doctorDiagnosesReducer = (state = initialState, action: Action | ProfileAction): DoctorDiagnosesState => {
    switch (action.type) {
        case "LOGOUT_SUCCESS":
            return initialState
        case "DOCTOR_DIAGNOSIS_SUCCESS":
            return {
                ...state,
                diagnoses: action.data || [],
            };
        default:
            return state;
    }
};