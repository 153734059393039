import React from "react";
import ArticlesListContent from "./ArticlesListContent";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../config/paths";

const ArticleListPage: React.FC = () => {
    const navigate = useNavigate()

    const onArticleClicked = (articleId: number) => {
        navigate(Paths.ARTICLE_DETAILS_PAGE.replace(":id", articleId.toString()));
    }

    return (
        <div style={{minHeight: '100vh', boxSizing: 'border-box'}}>
            <ArticlesListContent
                onArticleClicked={onArticleClicked}/>
        </div>
    )
}

export default ArticleListPage