import React from "react";
import {useNavigate} from "react-router-dom";
import {TopNavigation} from "../../Welcome/TopNavigation/TopNavigation";
import Footer from "../../Welcome/Footer/Footer";
import {BottomWave} from "../../Welcome/Articles/Articles.styles";
import {Paths} from "../../../config/paths";
import {Background, BottomWaveContainer, TopNavigationWrapper} from "./ArticlesList.styles";
import ArticleListContent from "./ArticlesListContent";


const ArticleList: React.FC = () => {

    const navigate = useNavigate()

    const onArticleClicked = (articleId: number) => {
        navigate(Paths.ARTICLE.replace(":id", articleId.toString()));
    };

    return (
        <Background>
            <TopNavigationWrapper>
                <TopNavigation
                    onAiDiagnosisClicked={() => navigate(Paths.HOME)}
                    onDoctorsClicked={() => navigate(Paths.HOME)}
                    onFaqClicked={() => navigate(Paths.HOME)}
                    onBlogClicked={() => navigate(Paths.HOME)}
                />
            </TopNavigationWrapper>
            <ArticleListContent onArticleClicked={onArticleClicked}/>
            <BottomWaveContainer>
                <BottomWave/>
            </BottomWaveContainer>
            <Footer
                openPopup={() => navigate(Paths.HOME)}
                onFaqClicked={() => navigate(Paths.HOME)}
            />
        </Background>

    )
}

export default ArticleList