import {HumanDiagnosis} from "./type";
import {Endpoints} from "../../config/endpoints";
import api from "../../config/api";

interface FetchHumanDiagnosisSuccess {
    type: "HUMAN_DIAGNOSIS_SUCCESS",
    data: HumanDiagnosis[];
}

export const fetchHumanDiagnoses = () => async (dispatch: any) => {
    try {
        const url = Endpoints.HUMAN_DIAGNOSIS_GET_ALL
        const response = await api.get(url)
        if (response.status !== 200) {
            return Promise.reject()
        }
        dispatch({type: "HUMAN_DIAGNOSIS_SUCCESS", data: response.data})
        return Promise.resolve()
    } catch (error) {
        return Promise.reject(error)
    }
}

export type Action = FetchHumanDiagnosisSuccess;