import React from "react";
import {useTheme} from "styled-components";
import SvgFill from "../Icons/SvgFill";
import IcPerson from "../Icons/IcPerson";
import ButtonUpLink from "../Button/ButtonUpLink/ButtonUpLink";
import {AddChildIconWrapper, ChildCard, ChildImage, ChildName, ChildTexts} from "./ChildCardComponent.styles";

const ChildCardComponent: React.FC<{
    id: number,
    title: string,
    subtitle?: string,
    image: string | null,
    selected?: boolean,
    onClick: () => void,
}> = (
    {
        id,
        title,
        image,
        subtitle,
        onClick,
        selected,
    }
) => {
    const theme = useTheme()
    const renderChildPicture = () => {
        if (image) {
            return (
                <AddChildIconWrapper>
                    <ChildImage src={process.env.REACT_APP_API_BASE_URL + image} alt="Preview"/>
                </AddChildIconWrapper>
            )
        } else {
            return (
                <AddChildIconWrapper>
                    <SvgFill color={theme.color.violet400}>
                        <IcPerson/>
                    </SvgFill>
                </AddChildIconWrapper>
            )
        }

    }

    const renderButtonUpLink = () => {
        if (!subtitle) {
            return null
        }
        return <ButtonUpLink color={theme.color.gray100}>{subtitle}</ButtonUpLink>;
    }

    return (
        <ChildCard
            key={id}
            onClick={() => {
                onClick()
            }}
            $selected={selected}
        >
            {renderChildPicture()}
            <ChildTexts>
                <ChildName>{title}</ChildName>
                {renderButtonUpLink()}
            </ChildTexts>
        </ChildCard>
    );
}


export default ChildCardComponent