import {styled} from "styled-components"
import Space from "../../Shared/Div/Space";
import React from "react";

export const SectionWrapperWrapperWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${props => props.theme.color.violet600};
  overflow: hidden;
`

export const SectionWrapperWrapper = styled.div`
  display: flex;


  @media (max-width: 1199px) {
    flex-direction: column;
    padding: 20px;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    width: 100%;
  }
`

export const LeftSideWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  align-self: stretch;

  @media (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    margin-left: 96px;
  }
`

export const RightSideWrapper = styled.div`
  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1200px) {
    display: inline-flex;
    align-items: flex-start;
    gap: 20px;
    margin-right: 242px;
    margin-left: 20px;
    margin-bottom: 64px;
    width: 40%;
  }
`

export const LeftSideTitle = styled.h3`
  color: ${props => props.theme.color.gray100};
  
  @media (max-width: 1199px) {
    font-size: 24px; 
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
  }

  @media (min-width: 1200px) {
    margin: 0;
    width: 583px;
  }
`
export const LeftSideLogo = styled.p`
  color: ${props => props.theme.color.gray100};
  

  @media (max-width: 1199px) {
    margin-top: 24px;
    margin-bottom: 40px;
  }

  @media (min-width: 1200px) {
    margin-top: 46px;
    margin-bottom: 144px;
  }
`

export const MenuColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @media (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    gap: 24px;
    width: 33%;
  }
`

export const MenuTitle = styled.p`
  color: ${props => props.theme.color.gray100};

  font-style: normal;

  @media (max-width: 1199px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.36px;
  }

  @media (min-width: 1200px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 160%;
  }

`

export const MenuTitleLast = styled(MenuTitle)`
  @media (max-width: 1199px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    letter-spacing: -0.36px;
    
    margin-bottom: 8px;
  }

  @media (min-width: 1200px) {
    min-width: 210px;
  }
`

export const MenuItemWrapper = styled.div`
  color: ${props => props.theme.color.gray100};

  @media (max-width: 1199px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1200px) {
    margin-top: 8px;
  }
`

export const MenuItem = styled.p`
  color: ${props => props.theme.color.gray100};
  cursor: pointer;

  @media (max-width: 1199px) {
    margin-top: 4px;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
  }

  @media (min-width: 1200px) {
    margin-top: 8px;
  }

`

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const RightsText = styled.p`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.color.gray100};
  padding: 16px 0;
  
  @media (max-width: 1199px) {
    font-size: 12px;
  }

  @media (min-width: 1200px) {
    font-size: 14px;
    gap: 8px;
  }
`