import React from "react";
import icBack from "../../../../assets/images/ic_back.svg";
import {BackButton, BackIcon, BackText} from "./BackArrowButtonTitled.styles";

const BackArrowButtonTitled: React.FC<{ onBackClicked: () => void, title?: string }> = ({onBackClicked, title}) => {
    return (
        <BackButton
            onClick={() => {
                onBackClicked()
            }}>
            <BackIcon src={icBack}/>
            <BackText>{title || "Wstecz"}</BackText>
        </BackButton>
    );
}

export default BackArrowButtonTitled