import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {AppDispatch, AppState} from '../../../state/store';
import {resetPassword} from '../../../state/profile/actions';
import {useDispatch, useSelector} from 'react-redux';
import imgWave from "../../../assets/images/bg_wave_login.png";
import InputField from "../../Shared/Input/Input";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import {
    Background,
    BackgroundWave,
    ButtonWrapper,
    Container,
    FormPart,
    HeaderPart,
    HeaderSubtitle,
    HeaderTitle,
    LinkButtonWrapper,
    LoginForm,
    LoginFormContainer, LoginLogoTop,
    RecoveryLink,
    RecoveryText
} from "./ResetPassword.styles";
import {isValidPassword} from "../../../config/utils";
import icLogoMobile from "../../../assets/images/ic_logo_mobile.svg";

const ResetPasswordPage: React.FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const email = queryParams.get('email');

    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [passwordAgainError, setPasswordAgainError] = useState<string | null>(null);
    const [passwordCorrect, setPasswordCorrect] = useState<boolean | null>(null);
    const [passwordAgainCorrect, setPasswordAgainCorrect] = useState<boolean | null>(null);
    const [validatePasswordOnType, setValidatePasswordOnType] = useState(false);
    const [validatePasswordAgainOnType, setValidatePasswordAgainOnType] = useState(false);
    const [loading, setLoading] = useState(false);
    const isLoggedIn = useSelector((state: AppState) => state.profile.profile != null);

    const navigateLogin = () => {
        navigate("/login", {replace: true});
    }

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/", {replace: true});
        }
    }, [isLoggedIn, navigate]);


    const validatePasswordCorrect = (psw: string) => {
        const isValid = isValidPassword(psw)
        setPasswordCorrect(isValid)
        return isValid
    }

    const validatePasswordAgainCorrect = (pswa: string) => {
        const areSame = password === pswa
        const isValid = isValidPassword(password) && areSame;
        setPasswordAgainCorrect(isValid)
        return isValid
    }


    const validatePasswordError = (psw: string) => {
        const isValid = isValidPassword(psw)
        setPasswordError(!isValid ? "Nieprawidłowy format hasła" : null)
        return isValid;
    }

    const validatePasswordAgainError = (pswa: string) => {
        const areSame = password === pswa
        const isValid = isValidPassword(pswa) && areSame;
        if (!areSame) {
            setPasswordAgainError("Hasła nie są takie same")
        } else if (!isValid) {
            setPasswordAgainError("Nieprawidłowy format hasła")
        } else {
            setPasswordAgainError(null)
        }
        return isValid;
    }

    const validateForm = () => {
        const isValidPassword = validatePasswordError(password)
        const isValidPasswordAgain = validatePasswordAgainError(password)
        return isValidPassword && isValidPasswordAgain
    }

    return (
        <Container>
            <Background>
                <BackgroundWave src={imgWave}/>
            </Background>
            <LoginFormContainer>
                <LoginLogoTop src={icLogoMobile}/>
                <LoginForm>
                    <HeaderPart>
                        <HeaderTitle>Wpisz nowe hasło</HeaderTitle>
                        <HeaderSubtitle>Ustaw nowe hasło dla swojego konta.</HeaderSubtitle>
                    </HeaderPart>
                    <FormPart>
                        <InputField
                            label="Nowe hasło"
                            type="password"
                            value={password}
                            onChange={(psw: string) => {
                                setPassword(psw)
                                if (validatePasswordOnType) {
                                    validatePasswordError(psw)
                                }
                                validatePasswordCorrect(psw)
                            }}
                            error={passwordError}
                            correct={passwordCorrect}
                            onBlur={() => {
                                setValidatePasswordOnType(true);
                                validatePasswordError(password);
                            }}
                        />
                        <InputField
                            label="Nowe hasło ponownie"
                            type="password"
                            value={passwordAgain}
                            onChange={(pswa: string) => {
                                setPasswordAgain(pswa)
                                if (validatePasswordAgainOnType) {
                                    validatePasswordAgainError(pswa)
                                }
                                validatePasswordAgainCorrect(pswa)
                            }}
                            error={passwordAgainError}
                            correct={passwordAgainCorrect}
                            onBlur={() => {
                                setValidatePasswordAgainOnType(true);
                                validatePasswordAgainError(passwordAgain);
                            }}
                        />
                    </FormPart>
                    <ButtonWrapper>
                        <ButtonPrimary
                            title="Zmień hasło"
                            onClick={() => {
                                if (!validateForm()) return
                                if (!email) return;
                                if (!token) return;
                                setLoading(true)
                                setPasswordError(null)
                                setPasswordAgainError(null)
                                dispatch(resetPassword(email, password, token))
                                    .then((response) => {
                                        switch (response) {
                                            case "FAILURE":
                                                setPasswordError("Wystąpił nieznany błąd.")
                                                setPasswordAgainError("Wystąpił nieznany błąd.")
                                                break;
                                            case "SUCCESS":
                                                // it will automatically navigate when profile is set in reducer
                                                break;
                                            case "LOGIN_FAILED":
                                                setPasswordError("Wystąpił nieznany błąd.")
                                                setPasswordAgainError("Wystąpił nieznany błąd.")
                                                break
                                        }
                                    })
                                    .catch(() => {
                                        setPasswordError("Wystąpił nieznany błąd.")
                                        setPasswordAgainError("Wystąpił nieznany błąd.")
                                    })
                                    .finally(() => setLoading(false))
                            }}
                            progress={loading}/>
                        <div>
                            <LinkButtonWrapper>
                                <RecoveryText>Zamiast tego</RecoveryText>
                                <RecoveryLink onClick={navigateLogin}>Wróć do logowania</RecoveryLink>
                            </LinkButtonWrapper>
                        </div>
                    </ButtonWrapper>
                </LoginForm>
            </LoginFormContainer>
        </Container>
    );
}

export default ResetPasswordPage;