import styled from "styled-components";
import {Card} from "../../../Shared/Div/Card";

export const OutlineBubble = styled.p`
  display: flex;
  height: 24px;
  padding: 0 8px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.gray1000}; // var(--greys-grey-1000, #161618)
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
`

export const TableCardContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`

export const CardBig = styled(Card)`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;


  @media (max-width: 599px) {
    margin-top: 16px;
  }

  @media (min-width: 600px) {
  }
`

export const HeaderCell = styled.div`
  width: 17%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
`

export const HeaderTableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  padding: 8px 16px;
  border-radius: 16px;
  background: ${props => props.theme.color.gray300};

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`

export const Cell = styled.div`
  width: 17%;
  color: ${props => props.theme.color.gray1000};
  line-height: 150%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 8px;
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
`

export const CellText = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const TableCardRows = styled.div`
  display: flex;
  flex-direction: column;
  height: 170px;
  padding-top: 8px;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* For Webkit browsers */
  }

  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
  }
`

export const Headline = styled.h4`

  @media (max-width: 599px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;

  }

  @media (min-width: 600px) {
  }
`

export const OkBubble = styled.div<{ $color: string }>`
  display: flex;
  height: 24px;
  padding: 0 8px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${props => {
    switch (props.$color) {
      case "green":
        return props.theme.color.success100
      case "red":
        return props.theme.color.error300
      case "yellow":
        return props.theme.color.warning100
    }
  }};
`

export const ResultsCell = styled(Cell)`
  justify-content: space-between;
  cursor: pointer;
`

export const ResultsCellCentered = styled(ResultsCell)`
  align-items: center;
  justify-content: center;
`

export const MobileCardContent = styled.div`

  @media (max-width: 599px) {
    margin-top: 20px;
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const MobileCardItem = styled.div`
  border-radius: 16px;
  border: 1px solid ${props => props.theme.color.gray400};
  background: ${props => props.theme.color.gray100};
  box-shadow: 0 5px 10px 2px ${props => props.theme.color.gray100};

  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const MobileCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  margin: 20px 20px 0 20px;
  align-items: center;
  justify-content: center;
`
export const ChildAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`


export const ChildAvatarContainer = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  overflow: hidden;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ChildName = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  flex-grow: 1;
`

export const MobileCardDate = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  margin: 0 20px;
`
export const MobileCardDescription = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
  margin: 0 20px 20px 20px;
`

export const MobileCardStatus = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
  margin: 0 20px;
`

export const MobileCardBadgeWrapper = styled.div`
  margin: 0 20px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

`

export const MobileCardBadge = styled.div`
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color.gray1000};
`

export const MobileCardBadgeText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  padding: 4px;
`


