import React from "react";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";
import przelewy24Logo from "../../../assets/images/img_przelewy24.svg"
import {
    Buttons,
    CardContent,
    CardTitle,
    CardWrapper,
    Link,
    LinkButton,
    PaymentDetailsContainer,
    PaymentDetailsSubtitle,
    PaymentDetailsTitle,
    Przelewy24CardWrapper,
    Przelewy24Logo
} from "./PaymentSummaryCard.styles";


interface Props {
    progress?: boolean,
    onCancel: () => void,
    onConfirm: () => void
}

const PaymentSummaryCard: React.FC<Props> = ({progress, onCancel, onConfirm}) => {
    return (
        <CardWrapper>
            <CardContent>
                <CardTitle>Zapłać za wizytę</CardTitle>
                <Przelewy24CardWrapper>
                    <Przelewy24Logo src={przelewy24Logo}></Przelewy24Logo>
                    <PaymentDetailsContainer>
                        <PaymentDetailsTitle>Przelew online</PaymentDetailsTitle>
                        <PaymentDetailsSubtitle>Wybierz swój bank</PaymentDetailsSubtitle>
                    </PaymentDetailsContainer>
                </Przelewy24CardWrapper>
                <p>
                    <span>Klikając “Potwierdź i umów wizytę” oświadczasz, że przeczytałeś/aś i akceptujesz </span>
                    <Link
                        onClick={() => {
                            // TODO: implement  privacy policy
                        }}
                    >regulamin</Link>
                    <span> portalu HappyKiddo.</span>
                </p>

                <Buttons>
                    <LinkButton onClick={() => {
                        onCancel()
                    }}>Wróć</LinkButton>

                    <ButtonPrimary
                        title="Przejdź do płatności"
                        progress={progress}
                        onClick={() => {
                            onConfirm()
                        }}/>
                </Buttons>

            </CardContent>
        </CardWrapper>
    )
}

export default PaymentSummaryCard