import React from "react";
import icBack from "../../../assets/images/ic_back.svg";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useFetchPatientAppointments} from "../../../state/appointments/helpers";
import {
    formatDateToDMMMMYYYYTimePolish,
    formatPaymentStatus,
    parseDateTime,
    parsePaymentStatus
} from "../../../config/utils";
import {useFetchDoctors} from "../../../state/doctors/helpers";
import IcUpRight from "../../Shared/Icons/IcUpRight";
import {Paths} from "../../../config/paths";
import {useFetchChildren} from "../../../state/children/useFetchChildren";
import dialogIcon from "../../../assets/images/ic_chat.svg"
import {useFetchHumanDiagnoses} from "../../../state/humanDiagnoses/helpers";
import {
    AvatarContainer,
    BackButton,
    BackIcon,
    BackText,
    CardBig,
    CardContent,
    CardHeaderMobile,
    CardRowGapBig,
    CardRowGapSmall,
    CardRowSpceBetween,
    CardSection,
    CardSectionTitle,
    CardSectionTitleWrapper,
    CardTitle,
    CardTitleMobile,
    ClickableCardSection,
    ContentWrapper,
    DoctorAvatarImage,
    IconDesktop,
    IconMobile,
    NameTitle,
    Title,
    TitleWrapper
} from "./PatientAppointment.styles";

interface RouteParams {
    [key: string]: string | undefined;

    id: string;
}

const PatientAppointments: React.FC = () => {

    const navigate = useNavigate()

    const {id: idString} = useParams<RouteParams>();
    const id = parseInt(idString ?? "", 10);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const showDetailsParam = queryParams.get('urlReturn');
    const isFromPayment = showDetailsParam === 'true';

    const appointments = useFetchPatientAppointments()
    const appointment = appointments?.find(a => a.id === id)

    const doctors = useFetchDoctors()
    const doctor = doctors?.find(el => el.id === appointment?.doctorId)

    const children = useFetchChildren()
    const child = children?.find(el => el.id === appointment?.childId)

    const humanDiagnoses = useFetchHumanDiagnoses()
    const humanDiagnosis = humanDiagnoses.find(el => el.appointmentId == appointment?.id)

    const getAppointmentStatus = () => {
        return formatPaymentStatus(parsePaymentStatus(appointment?.status))
    };

    const renderAppointmentDetailsSection = () => (
        <CardSection>
            <CardSectionTitle>Teleporada</CardSectionTitle>
            <p>
                Data:
                <br/>
                <b>{formatDateToDMMMMYYYYTimePolish(parseDateTime(appointment?.startTime))}</b>
                <br/>
                <br/>
                Status:
                <br/>
                <b>{getAppointmentStatus()}</b>
            </p>
        </CardSection>
    )

    const renderDoctorImage = () => {
        if (!doctor) {
            return null
        }
        return <DoctorAvatarImage alt={doctor?.name} src={process.env.REACT_APP_API_BASE_URL + doctor.image}/>;
    }

    const renderChildImage = () => {
        if (!child) {
            return null
        }
        return <DoctorAvatarImage alt={child?.name} src={process.env.REACT_APP_API_BASE_URL + child.image}/>;
    }

    const onDoctorClicked = () => {
        if (!doctor) {
            return
        }
        navigate(Paths.DOCTORS_DETAILS.replace(":id", doctor.id.toString()))
    }

    const onChildClicked = () => {
        if (!child) {
            return
        }
        navigate(Paths.CHILDREN_DETAILS.replace(":id", child.id.toString()))
    }

    const renderChildDetailsSection = () => (
        <ClickableCardSection onClick={onChildClicked}>
            <CardSectionTitleWrapper>
                <CardSectionTitle>Dziecko</CardSectionTitle>
                <IconMobile><IcUpRight/></IconMobile>
            </CardSectionTitleWrapper>

            <CardRowSpceBetween>
                <CardRowGapBig>
                    <AvatarContainer>{renderChildImage()}</AvatarContainer>
                    <NameTitle>{child?.name}</NameTitle>
                </CardRowGapBig>
                <IconDesktop><IcUpRight/></IconDesktop>
            </CardRowSpceBetween>
        </ClickableCardSection>
    )

    const renderAiDiagnosisDetailsSection = () => (
        <ClickableCardSection onClick={() => {
            let query = ""
            if (child?.id) {
                query = "?childId=" + child.id.toString()
            }
            navigate(Paths.DIAGNOSIS_NEW + query)
        }}>
            <CardSectionTitleWrapper>
                <CardSectionTitle>Diagnoza AI</CardSectionTitle>
                <IconMobile><IcUpRight/></IconMobile>
            </CardSectionTitleWrapper>
            <CardRowGapSmall>
                <img src={dialogIcon} alt="Diagnoza AI"/>
                <NameTitle>Wykonaj diagnozę</NameTitle>
            </CardRowGapSmall>
        </ClickableCardSection>
    )
    const renderDoctorDetailsSection = () => (
        <ClickableCardSection onClick={onDoctorClicked}>
            <CardSectionTitleWrapper>
                <CardSectionTitle>Lekarz</CardSectionTitle>
                <IconMobile><IcUpRight/></IconMobile>
            </CardSectionTitleWrapper>
            <CardRowSpceBetween>
                <CardRowGapBig>
                    <AvatarContainer>{renderDoctorImage()}</AvatarContainer>
                    <NameTitle>{doctor?.name}</NameTitle>
                </CardRowGapBig>
                <IconDesktop><IcUpRight/></IconDesktop>
            </CardRowSpceBetween>
        </ClickableCardSection>
    )

    const renderDoctorDiagnosis = () => {
        if (!humanDiagnosis) {
            return null
        }
        return (
            <CardSection>
                <CardSectionTitleWrapper>
                    <CardSectionTitle>Diagnoza</CardSectionTitle>
                </CardSectionTitleWrapper>
                <CardRowGapSmall>
                    <NameTitle>{humanDiagnosis.diagnosis}</NameTitle>
                </CardRowGapSmall>
            </CardSection>
        )
    }

    const doOnClick = () => {
        if (isFromPayment) {
            navigate("/", {replace: true})
        } else {
            navigate(-1)
        }
    }

    const renderPrescription = () => {
        if (!humanDiagnosis) {
            return null
        }
        return null
    }

    const renderRecommendations = () => {
        if (!humanDiagnosis) {
            return null
        }
        return (
            <CardSection>
                <CardSectionTitleWrapper>
                    <CardSectionTitle>Zalecenia</CardSectionTitle>
                </CardSectionTitleWrapper>
                <CardRowGapSmall>
                    <NameTitle>{humanDiagnosis.recommendations}</NameTitle>
                </CardRowGapSmall>
            </CardSection>
        )
    }

    return (
        <ContentWrapper>
            <TitleWrapper>
                <Title>Wizyta telemedyczna</Title>
                <BackButton
                    onClick={doOnClick}>
                    <BackIcon src={icBack}/>
                    <BackText>Wróć do wszystkich wizyt</BackText>
                </BackButton>
            </TitleWrapper>
            <CardBig>
                <CardContent>
                    <CardHeaderMobile
                        onClick={doOnClick}
                    >
                        <BackIcon src={icBack}/>
                        <CardTitleMobile>Szczegóły wizyty</CardTitleMobile>
                    </CardHeaderMobile>
                    <CardTitle>Szczegóły wizyty</CardTitle>

                    {renderAppointmentDetailsSection()}
                    {renderChildDetailsSection()}
                    {renderDoctorDetailsSection()}
                    {renderPrescription()}
                    {renderAiDiagnosisDetailsSection()}
                    {renderDoctorDiagnosis()}
                    {renderRecommendations()}

                </CardContent>
            </CardBig>
        </ContentWrapper>
    )
}

export default PatientAppointments