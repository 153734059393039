import React from 'react';
import {Provider} from "react-redux";
import {AppStyle} from './App.styles';
import LoginPage from './components/Authentication/Login/Login';
import RegisterPage from './components/Authentication/Register/Register';
import {ThemeProvider} from 'styled-components';
import theme from './Theme';
import {persistor, store} from "./state/store";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import RecoverPage from './components/Authentication/RecoverPassword/RecoverPassword';
import RootPage from './components/Root/Root';
import ResetPassword from './components/Authentication/ResetPassword/ResetPassword';
import {PersistGate} from 'redux-persist/integration/react';
import RegisterComplete from "./components/Authentication/Register/RegisterSuccess";
import RecoverSuccess from "./components/Authentication/RecoverPassword/RecoverSuccess";
import RegisterConfirm from "./components/Authentication/Register/RegisterConfirm";
import AiDiagnosisInterview from "./components/AiDiagnosis/AiDiagnosisInterview";
import {Paths} from "./config/paths";
import AiDiagnosisResultsScreen from "./components/AiDiagnosis/AidDiagnosisResultsScreen";
import CreateAppointment from "./components/Appointments/CreateAppointment/CreateAppointment";
import AppointmentDetails from "./components/Appointments/CreateAppointment/AppointmentDetails";
import ArticleDetails from "./components/Article/ArticleDetails";
import ArticlesList from "./components/Article/List/ArticlesListScreen";
import SimpleTextPage from "./components/SimpleTextPage/SimpleTextPage";

const App: React.FC = () => {
    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <AppStyle/>
                        <Router>
                            <Routes>
                                {/*Dashboard*/}
                                <Route path="/*" element={<RootPage/>}/>
                                {/*Auth*/}
                                <Route path="/login" element={<LoginPage/>}/>
                                <Route path="/register" element={<RegisterPage/>}/>
                                <Route path="/register-complete" element={<RegisterComplete/>}/>
                                <Route path="/register-confirm" element={<RegisterConfirm/>}/>
                                <Route path="/recover" element={<RecoverPage/>}/>
                                <Route path="/recover-success" element={<RecoverSuccess/>}/>
                                <Route path="/reset-password" element={<ResetPassword/>}/>
                                {/*AI Diagnosis*/}
                                <Route path={Paths.DIAGNOSIS_NEW} element={<AiDiagnosisInterview/>}/>
                                <Route path={Paths.DIAGNOSIS_RESULT} element={<AiDiagnosisResultsScreen/>}/>
                                {/*Appointment*/}
                                <Route path={Paths.APPOINTMENT_CREATE} element={<CreateAppointment/>}/>
                                <Route path={Paths.APPOINTMENT_DETAILS} element={<AppointmentDetails/>}/>
                                {/*Articles*/}
                                <Route path={Paths.ARTICLE} element={<ArticleDetails/>}/>
                                <Route path={Paths.ARTICLES_SCREEN} element={<ArticlesList/>}/>
                                {/*Other*/}
                                <Route path={Paths.SIMPLE_TEXT_PAGE} element={<SimpleTextPage/>}/>
                            </Routes>
                        </Router>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
