import React, {useState} from "react";
import CalendarPicker from "../../Doctors/DoctorsDetails/Calendar/CalendarPicker/CalendarPicker";
import styled from "styled-components";
import ButtonPrimary from "../../Shared/Button/ButtonPrimary/ButtonPrimary";


const CardWrapper = styled.div`
  box-sizing: border-box;
  align-items: center;
  justify-content: start;
  border-radius: 16px;
  background: ${props => props.theme.color.gray100};

  @media (max-width: 599px) {
    margin: 0 20px;
    padding: 24px;
  }

  @media (min-width: 600px) {
    width: 910px;
    padding: 40px 56px 48px 56px;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 599px) {
    gap: 16px;
  }

  @media (min-width: 600px) {
    gap: 40px;
    width: 100%;
  }
`

const LinkButton = styled.button`
  color: ${props => props.theme.color.violet600};
`

const CalendarPickerCard: React.FC<{ onSubmit: (date: Date) => void, onCancel: () => void }> = (
    {
        onSubmit,
        onCancel
    }
) => {
    const [appointmentDate, setAppointmentDate] = useState<Date | null>(null)

    return (
        <CardWrapper>
            <CalendarPicker
                date={appointmentDate}
                onDateSelected={(date) => {
                    setAppointmentDate(date)
                }}
            />
            <Buttons>
                <LinkButton onClick={() => {
                    onCancel()
                }}>Wróć</LinkButton>
                <ButtonPrimary title="Potwierdź i przejdź dalej" onClick={() => {
                    if (!appointmentDate) {
                        return
                    }
                    onSubmit(appointmentDate)
                }}/>
            </Buttons>
        </CardWrapper>
    )
}

export default CalendarPickerCard