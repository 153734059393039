import styled from "styled-components";

export const MenuItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  padding: 10px 20px;
  cursor: pointer;
`;

export const IndicatorWrapper = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 4px;
  cursor: pointer;
`

export const Indicator = styled.div<{ $color: string }>`
  width: 8px;
  height: 8px;
  background-color: ${props => props.$color};
  border-radius: 50%;
`

export const Title = styled.p<{ selected: boolean }>`
  color: ${props => props.selected ? props.theme.color.pink500 : props.theme.color.gray100};
  font-weight: 500;
  line-height: 160%;
  font-size: 16px;
  font-style: normal;
`

export const TitleBlack = styled.p`
  color: ${props => props.theme.color.gray1000};
  font-weight: 500;
  line-height: 160%;
  font-size: 16px;
  font-style: normal;
`

export const StyledMenuItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  svg path {
    fill: ${props => (props.selected ? props.theme.color.pink500 : props.theme.color.gray100)};
  }
`;

export const StyledMenuItemBlack = styled.div`
  svg path {
    fill: ${props => props.theme.color.gray1000};
  }
`;