import React from "react";
import ButtonUpLink from "../../Shared/Button/ButtonUpLink/ButtonUpLink";
import {formatDateToDDMMYYYYWithDashes, formatPaymentStatus, formatTime, parseDateTime, parsePaymentStatus} from "../../../config/utils";
import {
    CardBig,
    Cell,
    CellText,
    HeaderCell,
    HeaderTableRow,
    Headline,
    OkBubble,
    ResultsCell,
    TableCardContent,
    TableCardRows,
    TableRow
} from "./UnconfirmedAppointments.styles"
import {UnconfirmedAppointment} from "../../../state/doctorAppointments/type";

const UnconfirmedAppointments: React.FC<{
    appointments: UnconfirmedAppointment[],
    onConfirmClicked: (id: number) => void
    onCancelClicked: (id: number) => void,
    onShowAllClicked?: () => void,
}> = ({appointments, onCancelClicked, onConfirmClicked, onShowAllClicked}) => {

    const renderTableRow = (appointment: UnconfirmedAppointment, index: number) => {
        return (
            <TableRow key={index}>
                <Cell>
                    <CellText>{appointment.childName}</CellText>
                </Cell>
                <Cell>
                    <CellText>{formatDateToDDMMYYYYWithDashes(parseDateTime(appointment.date))}</CellText>
                </Cell>
                <Cell>
                    <CellText>{formatTime(parseDateTime(appointment.date))}</CellText>
                </Cell>
                <Cell>
                    <CellText>{appointment.parentName}</CellText>
                </Cell>
                <Cell>
                    <CellText>{formatPaymentStatus(parsePaymentStatus(appointment.status))}</CellText>
                </Cell>
                <ResultsCell onClick={() => onConfirmClicked(appointment.id)}>
                    <OkBubble><CellText>Potwierdź</CellText></OkBubble>
                </ResultsCell>
            </TableRow>
        );
    }

    function renderButtonUpLink() {
        if (!onShowAllClicked) {
            return null
        }
        return <ButtonUpLink
            children="Zobacz wszystkie"
            onClick={onShowAllClicked}
        />;
    }

    return (
        <CardBig>
            <TableCardContent>
                <Headline>Prośby o potwierdzenie ({appointments.length})</Headline>
                {renderButtonUpLink()}
                <HeaderTableRow>
                    <HeaderCell>Dziecko</HeaderCell>
                    <HeaderCell>Data</HeaderCell>
                    <HeaderCell>Godzina</HeaderCell>
                    <HeaderCell>Opiekun</HeaderCell>
                    <HeaderCell>Płatność</HeaderCell>
                    <HeaderCell></HeaderCell>
                </HeaderTableRow>
                <TableCardRows>
                    {appointments.map((el, i) => renderTableRow(el, i))}
                </TableCardRows>
            </TableCardContent>
        </CardBig>
    )
}

export default UnconfirmedAppointments