import {Settings} from "./types";
import {Endpoints} from "../../config/endpoints";
import api from "../../config/api";

export interface UpdateSettingsParams {
    fullName?: string
    phone?: string

    emailConfirmations?: boolean
    emailReminders?: boolean
    emailPlanned?: boolean

    smsConfirmations?: boolean
    smsReminders?: boolean
}

export const updateSettings = (payload: UpdateSettingsParams) => async (dispatch: any) => {
    try {
        const endpoint = Endpoints.BASE_URL_SETTINGS;
        const ignore = await api.put(endpoint, payload)
        if (ignore.status !== 200) {
            return Promise.reject()
        }
        const response = await api.get(endpoint)
        dispatch({type: "GET_SETTINGS", settings: response.data})
        return Promise.resolve()
    } catch (error: any) {
        return Promise.reject()
    }
}
export const fetchSettings = () => async (dispatch: any) => {
    try {
        const endpoint = Endpoints.BASE_URL_SETTINGS;
        const response = await api.get(endpoint)
        dispatch({type: "GET_SETTINGS", settings: response.data})
        return Promise.resolve(true)
    } catch (error: any) {
        return Promise.resolve(false)
    }
}

interface GetSettingsAction {
    type: "GET_SETTINGS"
    settings: Settings
}

export type SettingsAction = GetSettingsAction