import React from "react";
import icAi from "../../../assets/images/ic_ai.svg";
import {useFetchPatients} from "../../../state/patients/helpers";
import {Patient} from "../../../state/patients/types";
import {formatDateToDDMMYYYYWithDashes, parseDate, parseDateTime} from "../../../config/utils";
import {useFetchUserDoctor} from "../../../state/user/helpers";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../../config/paths";
import {
    Alpha,
    CardBig,
    Cell,
    CellText,
    ClickableCell,
    Container,
    ContentRow,
    DoctorAvatarContainer,
    DoctorAvatarImage,
    DoctorDetailsName,
    DoctorDetailsSpecialization,
    DoctorDetailsTexts,
    DoctorDetailsWrapper,
    HeaderCell,
    HeaderTableRow,
    Headline,
    IcChild,
    IcChildImage,
    TableCardContent,
    TableCardRows,
    TableRow,
    Title,
    TitleWrapper
} from "./PatientsList.styles";
import IcUpRight from "../../Shared/Icons/IcUpRight";
import icChild from "../../../assets/images/ic_person_small.svg";


const PatientsList: React.FC = () => {
    const doctor = useFetchUserDoctor()
    const patients = useFetchPatients()
    const navigate = useNavigate()

    function renderDoctorImage() {
        const src = doctor?.image
        if (!src) {
            return null
        }
        return <DoctorAvatarImage alt="Liliana Skorski" src={process.env.REACT_APP_API_BASE_URL + src}/>;
    }

    const renderChildIcon = (image?: string, name?: string) => {
        if (!image) {
            return (<IcChild src={icChild} alt={"dziecko"}/>);
        }
        return (<IcChildImage src={process.env.REACT_APP_API_BASE_URL + image} alt={name}/>)
    }

    const renderAiDiagnosisCell = (patient: Patient) => {
        if (patient.aiDiagnosisId) {
            return (
                <ClickableCell
                    $alignment="center"
                    onClick={() => navigate(Paths.AI_DIAGNOSIS_LIST.replace(":childId", patient.id.toString()))}
                >
                    <Alpha $alpha={1}><img src={icAi} alt="AI"></img></Alpha>
                </ClickableCell>
            );
        } else {
            return (
                <Cell $alignment="center">
                    <Alpha $alpha={0.3}><img src={icAi} alt="AI"></img></Alpha>
                </Cell>
            );
        }

    };

    const renderTableRow = (patient: Patient, index: number) => {
        return (
            <TableRow key={index}>
                <Cell $alignment="left">
                    {renderChildIcon(patient.image, patient.name)}
                    <CellText>{patient.name}</CellText>
                </Cell>
                <Cell $alignment="left">
                    <CellText>{formatDateToDDMMYYYYWithDashes(parseDate(patient.dob))}</CellText>
                </Cell>
                <Cell $alignment="left">
                    <CellText>{formatDateToDDMMYYYYWithDashes(parseDateTime(patient.lastAppointmentDate))}</CellText>
                </Cell>
                {renderAiDiagnosisCell(patient)}
                <ClickableCell
                    $alignment="center"
                    onClick={() => navigate(Paths.PATIENT_DETAILS.replace(":id", patient.id.toString()))}>
                    <CellText>Zobacz kartę</CellText>
                    <IcUpRight/>
                </ClickableCell>
            </TableRow>
        );
    }

    return (
        <Container>
            <TitleWrapper>
                <Title>Pacjenci</Title>
                <DoctorDetailsWrapper>
                    <DoctorAvatarContainer>
                        {renderDoctorImage()}
                    </DoctorAvatarContainer>
                    <DoctorDetailsTexts>
                        <DoctorDetailsName>{doctor?.name}</DoctorDetailsName>
                        <DoctorDetailsSpecialization>{doctor?.specializations.map(e => e.name).join(", ")}</DoctorDetailsSpecialization>
                    </DoctorDetailsTexts>
                </DoctorDetailsWrapper>
            </TitleWrapper>
            <ContentRow>
                <CardBig>
                    <TableCardContent>
                        <Headline>Wszyscy pacjenci ({patients.length})</Headline>
                        <HeaderTableRow>
                            <HeaderCell $alignment="left">Pacjent</HeaderCell>
                            <HeaderCell $alignment="left">Data urodzenia</HeaderCell>
                            <HeaderCell $alignment="left">Ostatnia wizyta</HeaderCell>
                            <HeaderCell $alignment="center">Diagnozy AI</HeaderCell>
                            <HeaderCell $alignment="left"></HeaderCell>
                        </HeaderTableRow>
                        <TableCardRows>
                            {patients.map((el, i) => renderTableRow(el, i))}
                        </TableCardRows>
                    </TableCardContent>
                </CardBig>
            </ContentRow>
        </Container>
    );
}

export default PatientsList;