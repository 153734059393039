import styled from "styled-components";

export const ChildCardMobile = styled.div<{ $selected?: boolean }>`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 32px;
  background: ${props => {
    if (props.$selected) {
        return props.theme.color.violet800
    } else {
        return props.theme.color.violet600
    }
}};

  &:hover {
    background: ${props => props.theme.color.violet800};
  }


  @media (max-width: 599px) {
    
  }

  @media (min-width: 600px) {
    display: none;
    
  }
`

export const ChildCardDesktop = styled.div<{ $selected?: boolean }>`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 32px;
  background: ${props => {
    if (props.$selected) {
        return props.theme.color.violet800
    } else {
        return props.theme.color.violet600
    }
}};

  &:hover {
    background: ${props => props.theme.color.violet800};
  }


  @media (max-width: 599px) {
    display: none;
  }

  @media (min-width: 600px) {
    width: 242px;
    
  }
`

export const ChildTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ChildName = styled.h4`
  color: ${props => props.theme.color.gray100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 599px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.48px;
  }

  @media (min-width: 600px) {
  }
`

export const AddChildIconWrapper = styled.div`

  border-radius: 24px;
  background-color: ${props => props.theme.color.violet500};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 599px) {
    height: 100%;
  }

  @media (min-width: 600px) {
    width: 242px;
    height: 242px;
  }
`

export const ChildImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
