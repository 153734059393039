import styled from "styled-components";
import React from "react";

export const LinkButtonStyle = styled.p`
  color: ${props => props.theme.color.violet600};
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
`

const LinkButton: React.FC<{ title: String, onClick: () => void }> = ({title, onClick}) => {
    return (
        <LinkButtonStyle onClick={onClick}>{title}</LinkButtonStyle>
    )
}

export default LinkButton