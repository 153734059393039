import {styled} from "styled-components"
import ButtonSecondary from "../../Shared/Button/ButtonSecondary/ButtonSecondary";
import bgThread from '../../../assets/images/bg_thread_1.png';
import bgThread12 from '../../../assets/images/bg_thread_11.svg';

export const Section = styled.div`
  overflow: hidden;
  padding: 25px;
  position: relative;
  z-index: 1;
`

export const SectionWrapper = styled.div`
  position: relative;
  align-self: stretch;
  background: ${props => props.theme.color.violet600};
`

export const SectionWrapperWrapper = styled.div`
  position: relative;
  align-self: stretch;
`

export const Title = styled.h1`
  text-align: center;
  font-style: normal;
  color: ${props => props.theme.color.gray100};


  @media (max-width: 1199px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.48px;
    padding-bottom: 20px;
  }

  @media (min-width: 1200px) {
 
  }
  
`

export const BelowTitle = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 1199px) {
    flex-direction: column;
    margin: auto;
    align-items: center;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    margin: 56px 0;
  }
`

export const MainButton = styled(ButtonSecondary)`
  align-self: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1199px) {
    margin-top: 40px;
  }

  @media (min-width: 1200px) {

  }
`

export const BackgroundThread = styled.div`
  position: absolute;
  margin: 0;
  left: 0;
  background-image: url(${bgThread});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;

  @media (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {
  }
`
export const BackgroundThread11 = styled.div`
  position: absolute;
  left: -25%;
  right: 0;
  bottom: -25%;
  
  background-image: url(${bgThread12});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
  // border: 10px solid ${props=>props.theme.pink200};
  
  width: 100%;
  height: 100%;
  flex-shrink: 0;

  scale: 50%;
  
  @media (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    display: none;
  }
`
